import toastr from 'utils/toastr'
import { toastrErrors } from 'utils/error_utils'
import AT from '../../actionTypes'
import {
  create,
  update,
  deleteQuestion,
  updateAllQuestions,
} from '../../BackendRequest/LoanAppTemplateQuestion'

import { toggleLoanAppLoadingText } from './loan_app'
import { fetchLoanAppTemplate } from './templates'
import { toggleOverlaySpinner } from '../globalUI'

export function setLoanAppTemplateQuestions(loanAppTemplateQuestions) {
  return { type: AT.SET_LOAN_APP_TEMPLATE_QUESTIONS, payload: loanAppTemplateQuestions }
}

function addLoanAppTemplateQuestion(loanAppTemplateQuestion) {
  return { type: AT.ADD_LOAN_APP_TEMPLATE_QUESTION, payload: loanAppTemplateQuestion }
}

function editLoanAppTemplateQuestion(loanAppTemplateQuestion) {
  return { type: AT.EDIT_LOAN_APP_TEMPLATE_QUESTION, payload: loanAppTemplateQuestion }
}

function removeLoanAppTemplateQuestion(loanAppTemplateQuestion) {
  return { type: AT.DESTROY_LOAN_APP_TEMPLATE_QUESTION, payload: loanAppTemplateQuestion }
}

export function updateLoanAppTemplateQuestion() {
  return (dispatch, getState) => {
    dispatch(toggleOverlaySpinner(true, 'Editing Question...'))
    const stagedRecord = getState().v2.loanAppTemplateQuestions.stagedRecord
    const template = getState().v2.loanAppTemplates.stagedRecord

    const changes = {
      label: stagedRecord.label,
      options: stagedRecord.options,
      questionType: stagedRecord.questionType,
    }

    update(stagedRecord, changes)
      .done((data) => {
        toastr.success('Question updated successfully!')
        if (template.id !== data.loanAppTemplateQuestions[0].templateId) {
          window.location.reload()
        } else {
          dispatch(editLoanAppTemplateQuestion(data.loanAppTemplateQuestions[0]))
          dispatch(toggleOverlaySpinner(false))
          dispatch(endLoanAppTemplateQuestionEdit())
        }
      })
      .fail((data) => {
        toastrErrors(data.responseJSON.errors)
        dispatch(toggleOverlaySpinner(false))
      })
  }
}

// This is just to provide a sink for all the event where you update one of the inputs for a template questions.

export const updateLoanAppTemplateQuestionDefaultValue = (_question, _updatedField) => {
  return () => {
    return null
  }
}

export function deleteLoanAppTemplateQuestion() {
  return (dispatch, getState) => {
    dispatch(toggleOverlaySpinner(true, 'Deleting Question...'))
    const stagedRecord = getState().v2.loanAppTemplateQuestions.stagedRecord
    const template = getState().v2.loanAppTemplates.stagedRecord

    deleteQuestion(stagedRecord)
      .done((data) => {
        toastr.success('Question deleted successfully!')

        dispatch(removeLoanAppTemplateQuestion(stagedRecord))

        if (template.id !== data.loanAppTemplateQuestions[0].templateId) {
          window.location.reload()
        } else {
          dispatch(endLoanAppTemplateQuestionEdit())
          dispatch(toggleOverlaySpinner(false))
        }
      })
      .fail((data) => {
        toastrErrors(data.responseJSON.errors)
        dispatch(toggleOverlaySpinner(false))
      })
  }
}

export function createLoanAppTemplateQuestion() {
  return (dispatch, getState) => {
    dispatch(toggleOverlaySpinner(true, 'Adding Question...'))
    const loanAppTemplateQuestion = getState().v2.loanAppTemplateQuestions.stagedRecord
    const template = getState().v2.loanAppTemplates.stagedRecord

    create(loanAppTemplateQuestion, template.id, loanAppTemplateQuestion.templateSectionId)
      .done((data) => {
        toastr.success('Question added successfully!')
        dispatch(addLoanAppTemplateQuestion(data.loanAppTemplateQuestion))
        dispatch(endLoanAppTemplateQuestionCreate())
        if (template.id !== data.loanAppTemplateQuestion.templateId) {
          window.location.reload()
        } else {
          dispatch(toggleOverlaySpinner(false))
        }
      })
      .fail((data) => {
        toastrErrors(data.responseJSON.errors)
        dispatch(toggleOverlaySpinner(false))
      })
  }
}

export function toggleRequireAllQuestions(templateId = null) {
  return (dispatch, getState) => {
    const { apiToken } = getState().pageInfo.userInfo

    const template = getState().v2.loanAppTemplates.stagedRecord
    const tId = templateId || template.id

    // if false, toggle is true
    const toggle = template.allRequired === false
    const loadingPrefix = toggle ? 'Mark' : 'Unmark'

    dispatch(toggleLoanAppLoadingText(`${loadingPrefix}ing all required`))

    updateAllQuestions(apiToken, tId, 'requireAllQuestions', toggle)
      .done((data) => {
        const { template: loanAppTemplate } = data

        dispatch(fetchLoanAppTemplate(loanAppTemplate.id))
      })
  }
}

export function toggleRequiredQuestion(question) {
  return (dispatch, getState) => {
    // dispatch(toggleOverlaySpinner(true, 'Updating Question...'))
    const changes = { required: !question.required, ...changes }
    dispatch(editLoanAppTemplateQuestion({ ...question, ...changes }))
    const template = getState().v2.loanAppTemplates.stagedRecord

    update(question, changes)
      .done((data) => {
        toastr.success('Question successfully required!')

        if (template.id !== data.loanAppTemplateQuestions[0].templateId) {
          // Reload if a new version of the loan app has been created
          window.location.reload()
        } else {
          data.loanAppTemplateQuestions.forEach((ques) => {
            dispatch(editLoanAppTemplateQuestion(ques))
          })
          // dispatch(toggleOverlaySpinner(false))
        }
      })
      .fail((data) => {
        toastrErrors(data.responseJSON.errors)
        dispatch(editLoanAppTemplateQuestion(question))
        // dispatch(toggleOverlaySpinner(false))
      })
  }
}

export function toggleShowQuestion(question) {
  return (dispatch, getState) => {
    dispatch(toggleOverlaySpinner(true, 'Updating Question...'))
    const changes = { hidden: !question.hidden }
    dispatch(editLoanAppTemplateQuestion({ ...question, ...changes }))
    const template = getState().v2.loanAppTemplates.stagedRecord
    update(question, changes)
      .done((data) => {
        toastr.success('Question updated successfully!')
        if (template.id !== data.loanAppTemplateQuestions[0].templateId) {
          // Reload if a new version of the loan app has been created
          window.location.reload()
        } else {
          data.loanAppTemplateQuestions.forEach((ques) => {
            dispatch(editLoanAppTemplateQuestion(ques))
          })
          dispatch(toggleOverlaySpinner(false))
        }
      })
      .fail((data) => {
        toastrErrors(data.responseJSON.errors)
        dispatch(editLoanAppTemplateQuestion(question))
        dispatch(toggleOverlaySpinner(false))
      })
  }
}

export function startLoanAppTemplateQuestionCreate(section) {
  return (dispatch) => {
    dispatch(setStagedLoanAppTemplateQuestion({
      questionType: 'text',
      templateSectionId: section.id,
      options: [{ value: '', display_value: '' }],
    }))
    dispatch(toggleLoanAppTemplateQuestionCreateModal(true))
  }
}

export function startLoanAppTemplateQuestionEdit(question) {
  return (dispatch) => {
    dispatch(setStagedLoanAppTemplateQuestion(question))
    dispatch(toggleLoanAppTemplateQuestionEditModal(true))
  }
}

export function endLoanAppTemplateQuestionCreate() {
  return (dispatch) => {
    dispatch(toggleLoanAppTemplateQuestionCreateModal(false))
    dispatch(setStagedLoanAppTemplateQuestion({}))
  }
}

export function endLoanAppTemplateQuestionEdit() {
  return (dispatch) => {
    dispatch(toggleLoanAppTemplateQuestionEditModal(false))
    dispatch(setStagedLoanAppTemplateQuestion({}))
  }
}

export function updateStagedLoanAppTemplateQuestion(updates) {
  return { type: AT.UPDATE_STAGED_LOAN_APP_TEMPLATE_QUESTION, payload: updates }
}

export function setStagedLoanAppTemplateQuestion(loanAppTemplateQuestion) {
  return { type: AT.SET_STAGED_LOAN_APP_TEMPLATE_QUESTION, payload: loanAppTemplateQuestion }
}

function toggleLoanAppTemplateQuestionCreateModal(payload) {
  return { type: AT.SHOW_CREATE_LOAN_APP_TEMPLATE_QUESTION_MODAL, payload: payload }
}

function toggleLoanAppTemplateQuestionEditModal(payload) {
  return { type: AT.SHOW_EDIT_LOAN_APP_TEMPLATE_QUESTION_MODAL, payload: payload }
}
