import AT from 'v2/actionTypes/'

const initialState = {
  connect: '',
  connectFix: '',
}

export default function connectUrl(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case AT.SET_FINICITY_CONNECT_URL: {
      return { ...state, connect: payload }
    }
    case AT.SET_FINICITY_CONNECT_FIX_URL: {
      return { ...state, connectFix: payload }
    }
    default:
      return state
  }
}
