import { pipelineSettings as actionTypes } from 'v2/actionTypes'

const initialState = { id: null }

export default function records(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case actionTypes.setPipeline: {
      return { ...state, pipeline: payload }
    }
    case actionTypes.setPipelineId: {
      return { id: payload, pipeline: null }
    }
    default:
      return state
  }
}
