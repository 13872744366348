import { createSlice } from '@reduxjs/toolkit'
import { uniq } from 'lodash'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'
import {
  createAnswerAPI,
  indexAnswerAPI,
  destroyAnswerAPI,
  sectionDestroyAPI,
  updateAnswerAPI,
  destroyBulkAnswerAPI,
} from './asyncActions'

const answerSlice = createSlice({
  name: 'answer',
  initialState: baseInitialState,
  reducers: baseReducers,
  extraReducers: {
    [indexAnswerAPI.pending](state, action) {
      const { meta: { arg: { background } } } = action
      if (!background) state.uiFlags.isFetching = true
    },
    [indexAnswerAPI.fulfilled](state, action) {
      state.records = action.payload
      state.uiFlags.isFetching = false
    },
    [indexAnswerAPI.rejected](state) {
      state.uiFlags = false
    },
    [createAnswerAPI.pending](state) {
      state.uiFlags.isCreating = true
    },
    [createAnswerAPI.fulfilled](state, action) {
      state.records = [...state.records, action.payload]
      state.uiFlags.isCreating = false
    },
    [createAnswerAPI.rejected](state) {
      state.uiFlags = false
    },
    [updateAnswerAPI.pending](state) {
      state.uiFlags.isUpdating = true
    },
    [updateAnswerAPI.fulfilled](state, action) {
      const { id } = action.payload
      state.records = [...state.records.filter(r => r.id !== id), action.payload]
      state.uiFlags.isUpdating = false
    },
    [updateAnswerAPI.rejected](state) {
      state.uiFlags = false
    },
    [destroyAnswerAPI.pending](state) {
      state.uiFlags.isDeleting = true
    },
    [destroyAnswerAPI.fulfilled](state, action) {
      const { id, reindexedAnswers } = action.payload
      if (reindexedAnswers) {
        state.records = reindexedAnswers
      } else {
        state.records = state.records.filter(r => r.id !== id)
      }
      state.uiFlags.isDeleting = false
    },
    [destroyAnswerAPI.rejected](state) {
      state.uiFlags.isDeleting = false
    },
    [sectionDestroyAPI.pending](state) {
      state.uiFlags.isDeleting = true
    },
    [sectionDestroyAPI.fulfilled](state, action) {
      const { sectionId, answers = [] } = action.payload
      const sectionIds = answers.length ? uniq(answers.map(a => +a.sectionId)) : [+sectionId]
      state.records = [...state.records.filter(r => !sectionIds.includes(+r.sectionId)), ...answers]
      state.uiFlags.isDeleting = false
    },
    [sectionDestroyAPI.rejected](state) {
      state.uiFlags.isDeleting = false
    },
    [destroyBulkAnswerAPI.pending](state) {
      state.uiFlags.isDeleting = true
    },
    [destroyBulkAnswerAPI.fulfilled](state, action) {
      state.uiFlags.isDeleting = false
      state.records = state.records.filter(answer => !action.payload.answerIds.includes(answer.id))
    },
    [destroyBulkAnswerAPI.rejected](state) {
      state.uiFlags.isDeleting = false
    },
  },
})

export default answerSlice
