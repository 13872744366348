import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'
import { errorHandler, popToast } from 'v2/actions/system_notifications'

export const updateSettingsAPI = createAsyncThunk(
  'organizationSettings/update',
  async (payload, thunkAPI) => {
    try {
      const { organizationId, enhancedLoanAppEnabled } = payload
      delete payload.organizationId
      const { data } = await JSONAPIClient.request(
        '/api/v2/organization_setting',
        'PATCH',
        { id: organizationId, organizationId },
        {
          id: organizationId,
          type: 'organization_settings',
          attributes: payload,
        },
      )

      const notice = 'Your organization was successfully upgraded to the Enhanced Loan Application'
      if (enhancedLoanAppEnabled) thunkAPI.dispatch(popToast({ notice }))
      return data
    } catch (e) {
      const { data: { errors } } = e.response
      thunkAPI.dispatch(errorHandler({}, errors[0].title))

      return {}
    }
  }
)

export const showSettingsAPI = createAsyncThunk(
  'organizationSettings/show',
  async args => {
    try {
      const { organizationId } = args
      const response = await JSONAPIClient.request('/api/v2/organization_setting', 'GET', { organizationId })
      return response.data
    } catch {
      return {
        disabledLoanAppV2TemplateIds: [],
        enhancedLoanAppEnabled: false,
        spanishEnabled: false,
        templateManagementAllowed: false,
        childCanManageTemplate: false,
      }
    }
  }
)
