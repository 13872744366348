import { preApprovalLetterTasks as ActionTypes } from '../../actionTypes/'

const initialState = {
  submitting: false,
  creating: false,
}

export default function uiFlags(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case ActionTypes.updatingRecord:
      return { ...state, submitting: payload }
    case ActionTypes.creatingRecord:
      return { ...state, submitting: payload }
    default:
      return state
  }
}
