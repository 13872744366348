import AT from 'actionTypes/'

const initialState = {
  connections: [],
  selectedConnection: null,
};

export default function connections(state = initialState, action) {
  switch (action.type) {
    case AT.SET_SELECTED_CONNECTION: {
      return { ...state, selectedConnection: action.payload }
    }
    case AT.SET_CONNECTIONS: {
      return { ...state, connections: action.payload }
    }
    case AT.REMOVE_SELECTED_CONNECTION: {
      return initialState
    }
    default: {
      return state
    }
  }
}
