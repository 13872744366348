import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'
import { indexCreditVendors, createCreditLiabilityAPI } from './asyncActions'

export const creditSlice = createSlice({
  name: 'credit',
  initialState: baseInitialState,
  reducers: baseReducers,
  extraReducers: {
    [indexCreditVendors.pending](state) {
      state.uiFlags.isFetching = true
    },
    [indexCreditVendors.fulfilled](state, action) {
      state.records = action.payload
      state.uiFlags.isFetching = false
    },
    [indexCreditVendors.rejected](state) {
      state.uiFlags.isFetching = false
    },
    [createCreditLiabilityAPI.pending](state) {
      state.uiFlags.isCreating = true
    },
    [createCreditLiabilityAPI.fulfilled](state, action) {
      state.records = action.payload
      state.uiFlags.isCreating = false
    },
    [createCreditLiabilityAPI.rejected](state) {
      state.uiFlags.isCreating = false
    },
  },
})

export default creditSlice.reducer
