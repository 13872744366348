import AT from '../../actionTypes'

const initialState = {
  loading: false,
  deleting: false,
  showCreditReportModal: false,
}

export default function uiFlags(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case AT.SET_CREDIT_TASK_LOADING: {
      return { ...state, loading: payload }
    }
    case AT.SET_CREDIT_TASK_DELETING: {
      return { ...state, deleting: payload }
    }
    case AT.TOGGLE_CREDIT_REPORT_MODAL: {
      return { ...state, showCreditReportModal: payload }
    }
    default: {
      return state
    }
  }
}
