import AT, { preApprovalLetterTemplates as ActionTypes } from '../../actionTypes'

const initialState = {
  showCreateModal: false,
  showEditModal: false,
  showTaskModal: false,
  showTemplateRenameForm: false,
  creatingRecord: false,
  updatingRecord: false,
}

export default function uiFlags(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case ActionTypes.showCreateModal:
      return { ...state, showCreateModal: payload }
    case ActionTypes.showEditModal:
      return { ...state, showEditModal: payload }
    case ActionTypes.creatingRecord:
      return { ...state, creatingRecord: payload }
    case ActionTypes.updatingRecord:
      return { ...state, updatingRecord: payload }
    case ActionTypes.showTemplateRenameForm:
      return { ...state, showTemplateRenameForm: payload }
    case AT.TOGGLE_PRE_APPROVAL_LETTER_TEMPLATE_MODAL:
      return { ...state, showTaskModal: payload }
    default:
      return state
  }
}
