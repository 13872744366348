import AT from '../../actionTypes/'

export default function records(state = [], action) {
  const { type, payload } = action

  switch (type) {
    case AT.SET_CREDIT_REPORTEES: {
      return payload
    }
    default: {
      return state
    }
  }
}
