import JSONAPIClient from '../JSONAPIClient'

JSONAPIClient.define('loan_note', {
  lender_id: '',
  loan_file_id: '',
  message: '',
  twilio_message_sid: '',
  created_at: '',
  updated_at: '',
  lender: {
    jsonApi: 'hasOne',
    type: 'lender',
  },
})

