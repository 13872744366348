import AT from '../../actionTypes/'

const initialState = {}

export default function stagedRecord(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case AT.SET_STAGED_EMAIL_TEMPLATE:
      return payload
    case AT.UPDATE_STAGED_EMAIL_TEMPLATE:
      return { ...state, ...payload }
    default:
      return state
  }
}
