import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'
import { popToast } from 'v2/actions'

export const indexTemplateAPI = createAsyncThunk(
  'template/index',
  async (args = {}) => {
    const { shareToken, active, organizationId, teamId } = args
    const response = await JSONAPIClient
      .all('templates')
      .get({ shareToken, organizationId, filter: { active }, teamId })
    return response.data
  }
)

export const showTemplateAPI = createAsyncThunk(
  'template/show',
  async (templateId) => {
    const response = await JSONAPIClient.one('template', templateId).get()
    return response.data
  }
)

export const updateTemplateAPI = createAsyncThunk(
  'template/update',
  async ({ templateId, payload }) => {
    const response = await JSONAPIClient.one('template', templateId).patch({ id: templateId, ...payload })
    return response.data
  }
)

export const createTemplateAPI = createAsyncThunk(
  'template/create',
  async ({ payload }) => {
    const response = await JSONAPIClient.create('template', payload, { organizationId: payload.ownerId })
    return response.data
  }
)

export const deleteTemplateAPI = createAsyncThunk(
  'template/delete',
  async ({ templateId }, thunkAPI) => {
    try {
      await JSONAPIClient.destroy('template', templateId)
      thunkAPI.dispatch(popToast({ notice: 'You successfully deleted a template' }))
      return { id: templateId }
    } catch {
      return {}
    }
  }
)
