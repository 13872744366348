import { combineReducers } from 'redux'
import uiFlags from './uiFlags'
import stagedRecord from './stagedRecord'
import records from './records'

export default combineReducers({
  uiFlags,
  records,
  stagedRecord,
})
