import { usersSettings as actionTypes } from 'v2/actionTypes'

const initialState = {
  loading: false,
  organizations: [],
  organizationsLoading: false,
  sendingInvite: false,
  showCreateModal: false,
  showDeleteModal: false,
  updating: false,
}

export default function uiFlags(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case actionTypes.loading:
      return { ...state, loading: payload }
    case actionTypes.setOrganizations:
      return { ...state, organizations: payload }
    case actionTypes.organizationsLoading:
      return { ...state, organizationsLoading: payload }
    case actionTypes.sendingInvite:
      return { ...state, sendingInvite: payload }
    case actionTypes.showCreateModal:
      return { ...state, showCreateModal: payload }
    case actionTypes.showDeleteModal:
      return { ...state, showDeleteModal: payload }
    case actionTypes.updatingRecord:
      return { ...state, updating: payload }
    default:
      return state
  }
}
