import JSONAPIClient from '../JSONAPIClient'

JSONAPIClient.define('loan_app_task_item', {
  assigned_user_id: '',
  display_title: '',
  due_date: '',
  last_user_answer: {},
  loan_app_v2_task_id: '',
  loan_app_id: '',
  loan_app_status: '',
  overdue: '',
  participant_id: '',
  prefill_used: '',
  status: '',
  section_reviews: {
    jsonApi: 'hasMany',
    type: 'section_reviews',
  },
  spanish_acknowledgement: false,
  skip_section_dismissed: false,
  user_id: '',
  updated_at: '',
})
