import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'

export const createScenarioAnswerAPI = createAsyncThunk(
  'externalConfigs/scenarioAnswers/create',
  async ({ scenarioId, payload }) => {
    const response = await JSONAPIClient.one('scenario', scenarioId).all('scenario_answer').post(
      payload
    )
    return response.data
  }
)

const indexScenarioAnswers = async ({ scenarioId, participantId, page = 1 }) => {
  const response = await JSONAPIClient.one('scenario', scenarioId)
    .all('scenario_answers')
    .get({ filter: { loanFileParticipantId: participantId }, page: { number: page } })

  const nextData = response.data

  const { meta: { pageCount } } = response
  if (pageCount > page) {
    return nextData.concat(
      await indexScenarioAnswers({
        scenarioId,
        participantId,
        page: page + 1,
      })
    )
  } else {
    return nextData
  }
}

export const indexScenarioAnswersAPI = createAsyncThunk(
  'externalConfigs/scenarioAnswers/index',
  async ({ scenarioId, participantId: rawParticipantId }) => {
    const participantId = Number(rawParticipantId)

    return indexScenarioAnswers({
      scenarioId,
      participantId,
    })
  }
)

export const showScenarioAnswerAPI = createAsyncThunk(
  'externalConfigs/scenarioAnswers/show',
  async ({ scenarioId, answerId }) => {
    const response = await JSONAPIClient.one('scenario', scenarioId).one('scenario_answer', answerId).get()
    return response.data
  }
)

export const updateScenarioAnswerAPI = createAsyncThunk(
  'externalConfigs/scenarioAnswers/update',
  async ({ scenarioId, answerId, payload }) => {
    const finalPayload = { ...payload, id: answerId }
    try {
      const response = await JSONAPIClient
        .one('scenario', scenarioId)
        .one('scenario_answer', answerId)
        .patch(finalPayload)
      return response.data
    } catch {
      return {}
    }
  }
)

export const destroyScenarioAnswerAPI = createAsyncThunk(
  'externalConfigs/scenarioAnswers/destroy',
  async ({ scenarioId, answer, participantId }) => {
    try {
      const { id: answerId, questionType } = answer
      await JSONAPIClient.one('scenario', scenarioId).one('scenario_answer', answerId).destroy()
      let allScenarioAnswers
      if (questionType.match(/field_list$/)) {
        allScenarioAnswers = await indexScenarioAnswers({ scenarioId, participantId })
      }
      return { id: answerId, reindexedAnswers: allScenarioAnswers }
    } catch {
      return {}
    }
  }
)

export const ausSectionDestroyAPI = createAsyncThunk(
  'externalConfigs/scenarioAnswers/section_destroy',
  async ({ scenarioId, payload }) => {
    const { data } = await JSONAPIClient.request(
      `/api/v2/aus/scenarios/${scenarioId}/scenario_answers/section_destroy`,
      'POST',
      {},
      { scenarioId, attributes: payload }
    )
    return { sectionId: payload.sectionId, answers: data }
  }
)
