import { screenShareVisitors } from 'v2/actionTypes'

const initialState = { watchUrl: null }

export default function (state = initialState, action) {
  switch (action.type) {
    case screenShareVisitors.set:
      return action.payload
    default:
      return state
  }
}
