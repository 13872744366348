/* eslint-disable import/no-cycle */
import api, { getRequest } from 'utils/api'
import Route from 'Services/Route'
import toastr from 'utils/toastr'
import { toastrErrors } from 'utils/error_utils'
import { find, indexBy, select } from 'underscore'
import moment from 'moment'
import {
  getLoanAppStagedRecord,
  getExistingLoanAppTasks,
} from 'v2/selectors/loan_apps'
import { getLoanFileId } from 'v2/selectors/loan_files'
import { createAction } from 'utils/reducer_utils'

import AT from '../../actionTypes'

import {
  create,
  show,
  update,
  destroy,
  syncResponses,
  submit,
} from '../../BackendRequest/LoanApp'

import {
  setLoanAppTemplateSections,
  setCurrentLoanAppTemplateSection,
} from './template_sections'

import { setLoanAppTemplateQuestions } from './template_questions'

import {
  setLoanAppResponses,
  validateAllResponses,
} from './responses'

import { setLoanFile } from '../loan_files'

const addLoanApp = createAction(AT.ADD_LOAN_APP)

function _ingestLoanAppData(dispatch, data) {
  const { loanAppTemplateQuestions, loanAppTemplateSections, loanAppResponses } = data
  const responses = loanAppTemplateQuestions.map(q => {
    return (find(loanAppResponses, res => res.templateQuestionId === q.id) || { templateQuestionId: q.id })
  })

  const currentSection = find(loanAppTemplateSections, sec => !sec.hidden)

  // If there is no loanApp.id that means we are dealing with a detached, blank loan ap (see `fetchBlankLoanApp`).
  // In that case, do not load it into `loanApps.records`.
  if (data.loanApp.id) {
    dispatch(setLoanApps([data.loanApp]))
  }

  dispatch(setStagedLoanApp(data.loanApp))
  dispatch(setLoanAppResponses(responses))
  dispatch(setLoanAppTemplateQuestions(loanAppTemplateQuestions))
  dispatch(setLoanAppTemplateSections(loanAppTemplateSections))
  dispatch(setCurrentLoanAppTemplateSection(currentSection))
  dispatch(validateAllResponses())
}

export function validateResponses() {
  return (dispatch) => {
    dispatch(validateAllResponses())
  }
}

export function setLoanApps(loanApps) {
  return { type: AT.SET_LOAN_APPS, payload: loanApps }
}

export function addLoanApps(loanApps) {
  return { type: AT.ADD_LOAN_APPS, payload: loanApps }
}

export function editLoanApp(updatedLoanApp) {
  return { type: AT.EDIT_LOAN_APP, payload: updatedLoanApp }
}

export function removeLoanApp(loanApp) {
  return { type: AT.DESTROY_LOAN_APP, payload: loanApp }
}

// Unlike `fetchLoanApp` which relies on an existing loan file (`loanFileId`), this method fetches
// a blank, detached loan app from the backend for a given `templateId`.
export function fetchBlankLoanApp({ templateId, eConsentEnabled }) {
  return (dispatch, getState) => {
    // Wrapping this first dispatch in a promise gives 'just enough' delay for components to update their local state
    // before we clobber all of the store data in the success callback.
    new Promise((resolve) => {
      dispatch(toggleLoanAppLoading(true))
      resolve()
    }).then(() => {
      // Clear out all related state trees except for `templateId` in the staged loan app. This enables
      // us to swap between loan app templates cleanly.
      dispatch(setStagedLoanApp({ templateId }))
      dispatch(setLoanApps([]))
      dispatch(setLoanAppResponses([]))
      dispatch(setLoanAppTemplateQuestions([]))
      dispatch(setLoanAppTemplateSections([]))
    })

    if (!templateId) {
      dispatch(toggleLoanAppLoading(false))
      return Promise.resolve()
    }

    const url = Route.api.loanApp.loanApp.new({ templateId })
    // override X-Key-Inflection header to turn off OliveBranch middleware. OliveBranch transforms even deeply-nested
    // keys, but unfortunately, existing client-side loan app code relies on server-side camel casing which does
    // NOT transform deeply nested keys.
    return api(getState, { 'X-Key-Inflection': '' })
      .get(url)
      .then(({ data }) => {
        // Manually append Submit section to loan app data
        data.loanAppTemplateSections = [
          ...data.loanAppTemplateSections,
          {
            id: eConsentEnabled ? 'submit' : 'consent_submit',
            name: eConsentEnabled ? 'submit' : 'consent_submit',
            description: 'Submit Application on behalf of borrower.',
            label: eConsentEnabled ? 'Submit Application' : 'Consent & Submit Application',
          },
        ]

        _ingestLoanAppData(dispatch, data)
        dispatch(toggleLoanAppLoading(false))
      })
      .catch(error => {
        const { errors } = error?.response?.data || {}
        if (errors) {
          toastrErrors(errors)
        }

        // Implicitly removes the `templateId` which was set above. Otherwise, all
        // state trees should already be cleared.
        dispatch(setStagedLoanApp({}))
        dispatch(toggleLoanAppLoading(false))
      })
  }
}

export function fetchLoanApp(id, loanFileId) {
  return (dispatch) => {
    dispatch(toggleLoanAppLoading(true))
    show({ id, loanFileId })
      .done((data) => {
        _ingestLoanAppData(dispatch, data)
      })
      .fail((data) => {
        toastrErrors(data.responseJSON.errors)
      })
      .always(() => dispatch(toggleLoanAppLoading(false)))
  }
}

export function createLoanApp(loanApp) {
  return (dispatch, getState) => {
    dispatch(toggleCreatingLoanApp(true))
    const loanFileId = getState().v2LoanFiles.loanFile.id

    create(loanApp, loanFileId)
      .done((data) => {
        toastr.success('Loan application added successfully')
        dispatch(addLoanApp(data.loanApp))
        dispatch(endLoanAppCreate())
      })
      .fail((data) => {
        toastrErrors(data.responseJSON.errors)
        dispatch(toggleLoanAppCreateModal(false))
      })
      .then(() => {
        dispatch(toggleCreatingLoanApp(false))
      })
  }
}

export function processInterviewLoanApp() {
  return async (dispatch, getState) => {
    const loanFileId = getLoanFileId(getState())
    const stagedLoanApp = getLoanAppStagedRecord(getState())

    // NOTE: we need to COPY the value here so don't use destructuring!
    // eslint-disable-next-line prefer-destructuring
    const shouldSubmitAfterCreate = stagedLoanApp.shouldSubmitAfterCreate

    try {
      // If the user selected a starting task template as part of the New Client flow, we may now have an existing
      // loan app with the same template ID. In that case, we want to re-use that loan app record instead of
      // creating a new one.
      const existingLoanAppTasks = getExistingLoanAppTasks(getState())
      const matchingLoanAppTask = existingLoanAppTasks.find(loanAppRecord => loanAppRecord.templateId === stagedLoanApp.templateId) // eslint-disable-line max-len

      if (matchingLoanAppTask) {
        // Replace the staged record so that `syncResponsesToBackend` and `handleLoanAppSubmit` use the existing ID
        await dispatch(setStagedLoanApp(matchingLoanAppTask))
      } else {
        // Create a new loan app record in the backend
        const newLoanAppPayload = {
          templateId: stagedLoanApp.templateId,
          dueDate: moment().add(5, 'days').format('YYYY-MM-DD'),
        }
        const data = await create(newLoanAppPayload, loanFileId)
        await dispatch(addLoanApp(data.loanApp))
        await dispatch(setStagedLoanApp(data.loanApp))
      }

      await dispatch(syncResponsesToBackend())

      if (shouldSubmitAfterCreate) {
        await dispatch(handleLoanAppSubmit(true))
      }
    } catch (error) {
      if (error.responseJSON && error.responseJSON.errors) {
        toastrErrors(error.responseJSON.errors)
      }
      throw error
    }
  }
}

export function updateLoanApp(loanApp, updates) {
  return (dispatch) => {
    update(loanApp, updates)
      .done((data) => {
        toastr.success('Loan application updated successfully')
        dispatch({ type: AT.EDIT_LOAN_APP, payload: data.loanApp })
        dispatch(endLoanAppCreate())
      })
      .fail((data) => {
        toastrErrors(data.responseJSON.errors)
        dispatch(toggleLoanAppCreateModal(false))
      })
  }
}

export function destroyLoanApp(loanApp) {
  return (dispatch) => {
    destroy(loanApp)
      .done((data) => {
        toastr.success('Loan application removed successfully')
        dispatch(removeLoanApp(data.loanApp))
      })
      .fail((data) => {
        toastrErrors(data.responseJSON.errors)
        dispatch(toggleLoanAppCreateModal(false))
      })
  }
}

export function syncResponsesToBackend() {
  return (dispatch, getState) => {
    const currentTime = Date.now()
    const {
      loanApps: {
        stagedRecord: loanApp,
        uiFlags: { loanAppSyncingResponse },
      },
    } = getState().v2
    const { records } = getState().v2.loanAppResponses
    let { dirtyRecords } = getState().v2.loanAppResponses
    dirtyRecords = indexBy(dirtyRecords, dr => dr.templateQuestionId)

    // if already a process is syncing then don't send a new request
    if (loanAppSyncingResponse) return
    // Set that data is being synced to backend.
    const filteredRecords = select(records, (rec) => {
      const dirtyRecord = dirtyRecords[rec.templateQuestionId]
      if (!dirtyRecord) return false
      if (dirtyRecord.updatedAt < dirtyRecord.syncedAt) return false
      return rec
    })

    if (!filteredRecords.length) {
      return
    }

    dispatch(setSyncingResponses())
    // reset the processing flag if still processing after 10 sec.
    const requestTimeout = setTimeout(() => {
      dispatch(resetSyncingResponses())
    }, 10000)

    syncResponses(loanApp, filteredRecords)
      .done((res) => {
        dispatch(resetSyncingResponses())
        clearTimeout(requestTimeout)
        if (res.success && res.show_modal) {
          dispatch(toggleLenderTridModal(true))
        } else {
          toastr.success('Saved')
        }

        filteredRecords.map(rec => dispatch(setSyncedResponse(rec, currentTime)))
      })
      .fail((data) => {
        // incase of error we are not resetting the processing, this will cause request to be paused for 10 seconds
        // if there was a client error then reload the page.
        // Client error occurs when current user looses access to a loan file
        // Or is signed out
        // Or the loan app is destroyed.
        // In this cases there is no point in trying to sync the request every second.
        if (data.status >= 400 && data.status < 500) {
          toastr.error('Something went wrong while saving the information! Please try again in some time!')
          setTimeout(() => { window.location.reload() }, 2000)
        }
        toastrErrors(data.responseJSON.errors)
      })
  }
}

function setSyncingResponses() {
  return {
    type: AT.LOAN_APP_SYNCING_RESPONSE,
    payload: true,
  }
}

function resetSyncingResponses() {
  return {
    type: AT.LOAN_APP_SYNCING_RESPONSE,
    payload: false,
  }
}

function setSyncedResponse(rec, syncedAt) {
  return {
    type: AT.LOAN_APP_RESPONSE_SET_SYNC_TIME,
    payload: { templateQuestionId: rec.templateQuestionId, syncedAt },
  }
}

export function endLoanAppCreate() {
  return (dispatch) => {
    dispatch(toggleLoanAppCreateModal(false))
    dispatch(setStagedLoanApp({}))
  }
}

export function updateStagedLoanApp(updates) {
  return { type: AT.UPDATE_STAGED_LOAN_APP, payload: updates }
}

export function setStagedLoanApp(loanApp) {
  return { type: AT.SET_STAGED_LOAN_APP, payload: loanApp }
}

export function toggleLoanAppLoading(bool) {
  return { type: AT.LOAN_APP_LOADING, payload: bool }
}

export function toggleLoanAppEditModal(payload) {
  return { type: AT.SHOW_EDIT_LOAN_APP_MODAL, payload: payload }
}

export function setLoanAppErrorCounts(payload) {
  return { type: AT.SET_LOAN_APP_ERROR_COUNT, payload: payload }
}

export function setDisableLoanAppSubmitButton(bool) {
  return { type: AT.TOGGLE_LOAN_APP_SUBMIT_BUTTON, payload: bool }
}

export function startLoanAppSubmit(bool = false) {
  return (dispatch) => {
    dispatch(syncResponsesToBackend())

    if (bool) dispatch(toggleLoanAppSubmitModal(bool))
  }
}

export function scrollToBottom() {
  return () => {
    $('html, body').animate({ scrollTop: $(document).height() })
  }
}

export function reviewErrors() {
  return () => {
    const element = document.getElementsByClassName('question-card error')[0]

    // scroll to first error element
    if (element) {
      const bodyRect = document.body.getBoundingClientRect()
      const elRect = element.getBoundingClientRect()

      $('html, body').animate({ scrollTop: Math.abs(elRect.top - bodyRect.top - 200) })
    }
  }
}

export function endLoanAppSubmit() {
  return (dispatch) => {
    dispatch(toggleLoanAppSubmitModal(false))
  }
}

export function handleLoanAppSubmit(submitSilently = false) {
  return (dispatch, getState) => {
    const {
      pageInfo: { userInfo },
      v2: { loanApps: { stagedRecord: loanApp } },
    } = getState()
    const { loanFileId } = loanApp
    const { type, slug } = userInfo

    dispatch(setDisableLoanAppSubmitButton(true))

    submit(loanApp)
      .done((res) => {
        Promise.all([
          dispatch(setStagedLoanApp(res.loanApp)),
          dispatch(endLoanAppSubmit()),
        ]).then(() => {
          if (!submitSilently) {
            toastr.success('Loan application submitted successfully')
          }
        })

        // if a lender is submitting a loan app - do not show celebration screen
        if (!submitSilently && type === 'lender') {
          const redirectUrl = Route.lenders.loanFile.show(
            {
              slug,
              id: loanFileId,
            }
          )

          window.location.href = redirectUrl
        }
      })
      .fail(() => {
        toastr.error('There was some issue while submitting the application. Please try again later')
        dispatch(setDisableLoanAppSubmitButton(false))
      })
  }
}

export function toggleLoanAppSubmitModal(payload) {
  return { type: AT.SHOW_FINISH_SUBMISSION_LOAN_APP_MODAL, payload: payload }
}

export function toggleLenderTridModal(payload = false) {
  return { type: AT.SHOW_LENDER_TRID_MODAL, payload: payload }
}

export function toggleLoanAppCreateModal(payload) {
  return { type: AT.SHOW_CREATE_LOAN_APP_MODAL, payload: payload }
}

export function toggleLoanAppDestroyModal(payload) {
  return { type: AT.SHOW_DESTROY_LOAN_APP_MODAL, payload: payload }
}

export function toggleLoanAppLoadingText(payload) {
  return { type: AT.TOGGLE_LOAN_APP_LOADING_TEXT, payload: payload }
}

export function toggleCreatingLoanApp(payload) {
  return { type: AT.CREATING_NEW_LOAN_APP, payload: payload }
}

export const handleSyncingLoanAppWithLendingQb = (loanApplication, syncOption) => {
  return async (dispatch) => {
    dispatch(toggleLoanAppLoading(true))
    await dispatch(syncLoanAppWithLendingQb(loanApplication, syncOption))
    dispatch(toggleLoanAppLoading(false))
  }
}

export const handleResyncingLoanAppWithLendingQb = (loanApplication) => {
  return async (dispatch) => {
    dispatch(toggleLoanAppLoading(true))
    await dispatch(resyncLoanAppWithLendingQb(loanApplication))
    dispatch(toggleLoanAppLoading(false))
  }
}

export const fetchTeamLoanAppTemplates = () => {
  return async (dispatch, getState) => {
    const url = Route.api.loanApp.templates.index()
    const res = await getRequest({ getState, url })

    if (res) {
      const { loanAppTemplates } = res.data
      dispatch(setLoanApps(loanAppTemplates))
    }
  }
}
// private functions

const syncLoanAppWithLendingQb = (loanApplication, syncOption) => {
  return async (dispatch, getState) => {
    const { loanFileId, id: loanAppId, isV2 = false } = loanApplication
    const url = Route.api.lendingQb.syncLoanApp.create({ loanFileId, loanAppId })

    try {
      const res = await api(getState).post(url, { syncOption, v2: isV2 })
      const { data: { loanFile, loanApps } } = res
      dispatch(setLoanFile(loanFile))
      dispatch(setLoanApps(loanApps))
      toastr.success('Syncing with LendingQB...')
    } catch (err) {
      const { errors } = err.response?.data
      if (errors) toastr.error(errors)
    }
  }
}

const resyncLoanAppWithLendingQb = (loanApplication) => {
  return async (dispatch, getState) => {
    const { loanFileId, id: loanAppId, isV2 = false } = loanApplication
    const url = Route.api.lendingQb.syncLoanApp.resync({ loanFileId, loanAppId })

    try {
      const res = await api(getState).put(url, { v2: isV2 })
      const { data: { loanFile, loanApps } } = res
      dispatch(setLoanFile(loanFile))
      dispatch(setLoanApps(loanApps))
      toastr.success('Re-syncing with LendingQB...')
    } catch (err) {
      toastr.error('Something went wrong')
    }
  }
}
