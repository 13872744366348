import JSONAPIClient from '../../JSONAPIClient'

JSONAPIClient.define('request', {
  task_id: '',
  status: '',
  type: '',
  order_number: '',
  loan_number: '',
  first_name: '',
  last_name: '',
  ssn: '',
  employment_type: '',
  employer_name: '',
  employer_address: '',
  employer_city: '',
  employer_state: '',
  employer_postal_code: '',
  employer_phone: '',
  tax_preparer_company_name: '',
  tax_preparer_city: '',
  tax_preparer_state: '',
  tax_preparer_postal_code: '',
  tax_preparer_phone: '',
}, { collectionPath: 'voei/requests' })
