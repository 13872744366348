import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'

export const createTaskAPI = createAsyncThunk(
  'loan_app_task/create',
  async (payload) => {
    const response = await JSONAPIClient.create('loan_app_task', payload)
    return response.data
  }
)

export const updateTaskAPI = createAsyncThunk(
  'loan_app_task/update',
  async ({ taskId, payload: { participantId, ...payload } }) => {
    const response = await JSONAPIClient.update(
      'loan_app_task', { id: taskId, ...payload }, {}, { participantId }
    ) // resource, payload, queryParams, meta
    return response.data
  }
)

export const destroyTaskAPI = createAsyncThunk(
  'loan_app_task/destroy',
  async ({ taskId }) => {
    try {
      const response = await JSONAPIClient.destroy('loan_app_task', taskId)
      return response.data
    } catch {
      return {}
    }
  }
)
