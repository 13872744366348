import AT from 'actionTypes/'
import _ from 'underscore'


export function addNotification(notification, timeout = 3000) {
  return ({
    type: AT.ADD_NOTIFICATION,
    notification: {
      title: notification.title,
      messages: notification.messages,
      id: _.uniqueId(),
      timeout: timeout,
      type: notification.type,
    },
  })
}

export function addSuccessNotification(notification, timeout) {
  if (typeof notification === 'string') {
    return addNotification({ title: notification, type: 'success' }, timeout)
  } else {
    return addNotification(_.extend({}, notification, { type: 'success' }), timeout)
  }
}

export function addErrorNotification(notification, timeout) {
  if (typeof notification === 'string') {
    return addNotification({ title: notification, type: 'danger' }, timeout)
  } else {
    return addNotification(_.extend({}, notification, { type: 'danger' }), timeout)
  }
}

export function removeNotification(notificationId) {
  return ({
    type: AT.REMOVE_NOTIFICATION,
    notificationId: notificationId,
  })
}
