import api from 'utils/api'
import Route from 'Services/Route'
import toastr from 'utils/toastr'
import { toastrErrors } from 'utils/error_utils'
import { createAction } from 'utils/reducer_utils'
import { generatePath } from 'react-router-dom'
import AT, { creditExports } from '../actionTypes'

// Public Actions
export function setCreditTasks(creditTasks) {
  return { type: AT.SET_CREDIT_TASKS, payload: creditTasks }
}

export function setStagedCreditTask(creditTask) {
  return { type: AT.SET_STAGED_CREDIT_TASK, payload: creditTask }
}

export function retryCreditTaskExport(creditExport) {
  return { type: creditExports.retry, payload: creditExport }
}

export function setCreditTaskExport(creditExport) {
  return { type: creditExports.fetch, payload: creditExport }
}

export function createCreditTask() {
  return async (dispatch, getState) => {
    const canCreate = await beforeCreateValidations(getState(), dispatch)
    if (!canCreate) return false

    const {
      id: loanFileId,
      slug,
    } = getState().v2LoanFiles.loanFile

    const url = Route.api.loanFile.credits.tasks.create({ loanFileId })
    const req = api(getState).post(url)

    req.then((res) => {
      const { creditTask } = res.data
      const { id } = creditTask

      const { loanFile: { creditTasks: { show } } } = Route.frontendRoutes

      const creditTasksPath = generatePath(show, { lenderId: slug, loanFileId, creditTaskId: id })

      window.location.href = creditTasksPath
    }).catch((error) => {
      const { errors } = error?.response?.data || {}

      console.info(errors)
      if (errors) toastrErrors(errors)
    })
  }
}

export function deleteCreditTask(lfId, id) {
  return (dispatch, getState) => {
    dispatch(setCreditTaskDeleting(true))
    const loanFileId = lfId || getState().v2LoanFiles.loanFile.id

    // eslint-disable-next-line maxwell/no-generic-id-in-api-routes
    const url = Route.api.loanFile.credits.tasks.delete({ loanFileId, id })
    const req = api(getState).delete(url)

    return req.then(() => {
      const { slug } = getState().pageInfo.userInfo
      const redirectUrl = Route.lenders.loanFile.show(
        {
          slug,
          id: loanFileId,
        }
      )
      dispatch(setCreditTaskDeleting(false))
      window.location.href = `${redirectUrl}?tasks=true`
    }).catch((error) => {
      const { errors } = error?.response?.data || {}

      console.info(errors)
      dispatch(setCreditTaskDeleting(false))
      if (errors) toastrErrors(errors)
    })
  }
}

export function updateCreditTask(lfId, id, values) {
  return (dispatch, getState) => {
    dispatch(setCreditTaskLoading(true))
    const loanFileId = lfId || getState().v2LoanFiles.loanFile.id

    // eslint-disable-next-line maxwell/no-generic-id-in-api-routes
    const url = Route.api.loanFile.credits.tasks.update({ loanFileId, id })
    const req = api(getState).put(url, values)
    const PENDING_MSG = 'Request submitted successfully, the credit report would be available in a few minutes!'
    const SUBMITTED_MSG = 'Credit report successfully submitted!'

    return req.then((res) => {
      const { creditTask: { status } } = res.data
      const { slug } = getState().pageInfo.userInfo
      toastr.success(status === 'pending' ? PENDING_MSG : SUBMITTED_MSG)
      const route = Route.lenders.loanFile.show({ slug, id: loanFileId })
      window.location.href = `${route}?tasks=true`
    }).catch((error) => {
      const { errors } = error?.response?.data || {}

      if (errors) toastrErrors(errors)
      dispatch(setCreditTaskLoading(false))
      return { error }
    })
  }
}

export function getCreditExportStatus(creditExportId) {
  return (dispatch, getState) => {
    const { sync } = Route.api.credit.exports

    const url = sync({ creditExportId })
    const req = api(getState).get(url)

    return req.then(({ data: creditExport }) => {
      dispatch(setCreditTaskExport({ creditExport }))
    }).catch((error) => {
      const { errors } = error?.response?.data || {}

      console.info(errors)
      if (errors) toastrErrors(errors)
      dispatch(setCreditTaskLoading(false))
    })
  }
}

export function retryCreditExportStatus(creditExportId) {
  return (dispatch, getState) => {
    const { retry } = Route.api.credit.exports

    const url = retry({ creditExportId })
    const req = api(getState).put(url)

    return req.then(({ data: creditExport }) => {
      dispatch(retryCreditTaskExport({ creditExport }))
    }).catch((error) => {
      const { errors } = error?.response?.data || {}

      console.info(errors)
      if (errors) toastrErrors(errors)
      dispatch(setCreditTaskLoading(false))
    })
  }
}

export function showCreditTask(lfId, id) {
  return (dispatch, getState) => {
    const loanFileId = lfId || getState().v2LoanFiles.loanFile.id

    // eslint-disable-next-line maxwell/no-generic-id-in-api-routes
    const url = Route.api.loanFile.credits.tasks.show({ loanFileId, id })
    const req = api(getState).get(url)

    return req.then(({ data: { creditTask } }) => {
      dispatch(setStagedCreditTask(creditTask))
    })
  }
}

export function updateLenderCreditDetail(lfId, id, values) {
  return (dispatch, getState) => {
    dispatch(setCreditTaskLoading(true))

    const loanFileId = lfId || getState().v2LoanFiles.loanFile.id

    // eslint-disable-next-line maxwell/no-generic-id-in-api-routes
    const url = Route.api.loanFile.credits.lenderCreditDetail.update({ loanFileId, id })
    const req = api(getState).put(url, values)

    return req.then(() => {
      dispatch(nextCreditStepPosition())
      dispatch(setCreditTaskLoading(false))
    }).catch((error) => {
      const { errors } = error?.response?.data || {}
      dispatch(setCreditTaskLoading(false))
      console.info(errors)
      if (errors) toastrErrors(errors)
    })
  }
}

export function toggleCreditReportModal(creditTask) {
  return (dispatch) => {
    Promise.all([
      dispatch(toggleCreditReport(true)),
      dispatch(setStagedCreditTask(creditTask)),
    ])
  }
}

export function toggleCreditReport(bool = false) {
  return { type: AT.TOGGLE_CREDIT_REPORT_MODAL, payload: bool }
}

export function validateLendingQbLoanNumber() {
  return (dispatch, getState) => {
    const { loanFile } = getState().v2LoanFiles
    const { id: loanFileId, lqbLoanFileNumber } = loanFile
    // eslint-disable-next-line maxwell/no-generic-id-in-api-routes
    const url = Route.api.lendingQb.validateLoanNumber.show({ loanFileId, id: lqbLoanFileNumber })
    const req = api(getState).get(url)
    return req.then(() => {
      return true
    }).catch((error) => {
      const { errors } = error?.response?.data || {}
      console.info(errors)
      if (errors) toastrErrors(errors)
      return false
    })
  }
}
const setCreditTaskLoading = createAction(AT.SET_CREDIT_TASK_LOADING)
const setCreditTaskDeleting = createAction(AT.SET_CREDIT_TASK_DELETING)
const nextCreditStepPosition = createAction(AT.NEXT_CREDIT_TASK_POSITION)

function beforeCreateValidations(state, dispatch) {
  const {
    id: loanFileId,
    lqbLoanFileNumber,
    featureFlags: { lqbCreditEnabled },
  } = state.v2LoanFiles.loanFile

  if (!loanFileId) {
    toastr.error('The page is still loading. Please try again.')
    return false
  }

  if (lqbCreditEnabled) {
    if (!lqbLoanFileNumber) {
      toastr.error('Loan file must be synced as a lead or loan to LendingQB before credit can be pulled in.')
      return false
    }

    return dispatch(validateLendingQbLoanNumber())
  }
  return true
}
