import { combineReducers } from 'redux'

import records from './records'
import uiFlags from './uiFlags'
import stagedRecord from './stagedRecord'
import loanAppTemplates from './loanAppTemplates'

export default combineReducers({
  uiFlags,
  records,
  stagedRecord,
  loanAppTemplates,
})
