import JSONAPIClient from '../../JSONAPIClient'

JSONAPIClient.define(
  'scenario_answer',
  {
    aus_scenario_id: '',
    loan_file_participant_id: '',
    status: '',
    app_id: '',
    index: [],
    question_display_name: '',
    question_id: '',
    question_name: '',
    question_options: '',
    question_type: '',
    response: '',
    section_id: '',
    section_name: '',
    scenario: {
      jsonApi: 'hasOne',
      type: 'scenario',
    },
  },
  { collectionPath: 'scenario_answers' }
)
