import AT from 'actionTypes/'

export function toggleModal(bool = false, type = 'sources') {
  return {
    type: AT.TOGGLE_MODAL,
    payload: {
      show: bool,
      type: type,
    },
  }
}
