import api from '../api'

class HTTP {
  constructor(state) {
    this.state = state
  }

  get = (url, params) => {
    return api(this.state).get(url, params)
  }

  post = (url, params) => {
    return api(this.state).post(url, params)
  }

  put = (url, params) => {
    return api(this.state).put(url, params)
  }

  delete = (url, params) => {
    return api(this.state).delete(url, params)
  }
}

export default HTTP
