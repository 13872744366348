import AT from '../actionTypes/'

const initialState = {
  templates: [],
}

export default function v2LoanFileTemplates(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case AT.SET_LOAN_FILE_TEMPLATES:
      return { ...state, templates: payload }
    default:
      return state
  }
}
