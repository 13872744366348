import AT from '../actionTypes/'

const initialState = {
  selected: { error: null, loading: false, embedUrl: null },
  previewModal: false,
}

export default function attachments(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case AT.SET_SELECTED_ATTACHMENT:
      return { ...state, selected: payload }
    case AT.TOGGLE_ATTACHMENT_PREVIEW_MODAL:
      return { ...state, previewModal: payload }

    // TODO : Remove this nested junk
    // @puneet I know, I know!
    case AT.SET_ATTACHMENT_PREVIEW_ERROR:
      return {
        ...state,
        selected: {
          ...state.selected,
          error: payload,
        },
      }
    case AT.SET_ATTACHMENT_PREVIEW_EMBED_URL:
      return {
        ...state,
        selected: {
          ...state.selected,
          embedUrl: payload,
        },
      }

    case AT.SET_ATTACHMENT_PREVIEW_LOADING:
      return {
        ...state,
        selected: {
          ...state.selected,
          loading: payload,
        },
      }
    default:
      return state
  }
}
