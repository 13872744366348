import AT from 'actionTypes/'

const initialState = {
  eventNotifications: [],
};

export default function eventNotifications(state = initialState, action) {
  switch (action.type) {
    case AT.SET_EVENT_NOTIFICATIONS: {
      return { ...state, eventNotifications: action.payload }
    }

    default: {
      return state
    }
  }
}
