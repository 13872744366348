import AT from 'actionTypes/'
import api from 'utils/api'
import Route from 'Services/Route'
import { toastrErrors } from 'utils/error_utils'

export function setSelectedPeriod(period) {
  return { type: AT.SET_SELECTED_PERIOD, payload: period }
}

export function fetchPeriod(documentGroupId) {
  return (dispatch, getState) => {
    const url = Route.api.filethis.periods.show({ id: documentGroupId })

    const req = api(getState).get(url)

    req.then((res) => {
      const { period } = res.data

      dispatch(setSelectedPeriod(period))
    }).catch((error) => {
      const { errors } = error?.response?.data || {}

      console.info(errors)

      if (errors) toastrErrors(errors)
    })
  }
}
