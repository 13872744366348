import { findIndex } from 'underscore'
import { replace, remove } from 'Services/ImmutatingHelpers'
import { combineReducers } from 'redux'
import AT from '../actionTypes'

function stagedRecord(state = {}, action) {
  switch (action.type) {
    case AT.SHOW_SIGNABLE_DOCUMENT_CREATE_MODAL: {
      return { id: action.signableDocumentId, showCreateModal: true }
    }
    case AT.HIDE_SIGNABLE_DOCUMENT_CREATE_MODAL: {
      return {}
    }
    case AT.SHOW_SIGNABLE_DOCUMENT_EDIT_MODAL: {
      return { id: action.signableDocumentId, showEditModal: true }
    }
    case AT.HIDE_SIGNABLE_DOCUMENT_EDIT_MODAL: {
      return {}
    }
    case AT.SHOW_SIGNABLE_DOCUMENT_RESEND_MODAL: {
      return { id: action.signableDocumentId, showResendModal: true }
    }
    case AT.HIDE_SIGNABLE_DOCUMENT_RESEND_MODAL: {
      return {}
    }
    case AT.SHOW_SIGNABLE_DOCUMENT_DESTROY_MODAL: {
      return { id: action.signableDocumentId, showDestroyModal: true }
    }
    case AT.HIDE_SIGNABLE_DOCUMENT_DESTROY_MODAL: {
      return {}
    }
    default:
      return state
  }
}

function records(state = [], action) {
  switch (action.type) {
    case AT.SET_SIGNABLE_DOCUMENTS: {
      return action.signableDocuments
    }
    case AT.UPDATE_SIGNABLE_DOCUMENT: {
      const index = findIndex(state, sd => sd.id === action.signableDocument.id)
      return replace(state, index, action.signableDocument)
    }
    case AT.DESTROY_SIGNABLE_DOCUMENT: {
      const index = findIndex(state, sd => sd.id === action.signableDocument.id)
      return remove(state, index)
    }
    default:
      return state
  }
}

const reducers = {
  stagedRecord,
  records,
}

export default combineReducers(reducers)
