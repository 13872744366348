import { createSelector } from 'reselect'

// getters

export const getLoanFile = state => state.v2LoanFiles.loanFile
export const getLoanFileId = state => state.v2LoanFiles.loanFile.id
export const getRedirectUrl = state => state.v2LoanFiles.redirectUrl

// Provides a convenience mechanism to access loan files by status, since they are currently broken up in the state tree
// (e.g. v2LoanFiles.activeLoanFiles, v2LoanFiles.closedLoanFiles, etc.).
//
// state: Redux state tree
// props:
//  - status (String, required) - which set of loan files to return
const getLoanFilesByStatus = (state, { status }) => {
  return state.v2LoanFiles[`${status}LoanFiles`]
}

// Computes sum of `loanAmounts` for a given set of `loanFiles`. Undefined or null loanAmounts will be treated as 0.
// Returns - Number
export const getLoanVolume = createSelector(
  [getLoanFilesByStatus],
  (loanFiles) => {
    return loanFiles.reduce((total, loanFile) => { return total + (loanFile.loanAmount || 0) }, 0)
  }
)

export const getITPAcceptedAt = state => getLoanFile(state).itpAcceptedAt
export const getBorrower = state => getLoanFile(state).borrower
