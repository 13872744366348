import AT from '../../actionTypes/'

const initialState = {}

export default function mockCommunication(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case AT.SET_MOCK_COMMUNICATION: {
      return { ...state, ...payload }
    }
    case AT.REMOVE_MOCK_COMMUNICATION: {
      return { }
    }
    default:
      return state
  }
}
