import JSONAPIClient from '../JSONAPIClient'

JSONAPIClient.define('signable_document', {
  id: '',
  display_title: '',
  document_id: '',
  due_date: '',
  overdue: '',
  sign_url: '',
  signer_info: '',
  status: '',
  title: '',
})
