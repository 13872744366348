import JSONAPIClient from '../JSONAPIClient'

JSONAPIClient.define('task', {
  id: '',
  document_type: '',
  los_type: '',
  upload_behavior: '',
  organization_display_name: '',
  organization_id: '',
})
