import JSONAPIClient from '../JSONAPIClient'

JSONAPIClient.define('quick_apply', {
  phone: '',
  last_four_ssn: '',
  zipcode: '',
  status: '',
  id: '',
  otp_code: '',
  client_id: '',
  resend_otp: false,
})
