import { pipelineUsers as actionTypes } from 'v2/actionTypes'

const initialState = {
  eligibleLenders: [],
  isFetching: {},
  loading: false,
  updating: false,
}

export default function uiFlags(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case actionTypes.setEligibleLenders: {
      return { ...state, eligibleLenders: payload }
    }
    case actionTypes.loading: {
      return { ...state, loading: payload }
    }
    case actionTypes.setPipelineUsersFetching: {
      const { pipelineId, isFetching } = payload
      return { ...state, isFetching: { ...state.isFetching, [pipelineId]: isFetching } }
    }
    case actionTypes.updatingRecord:
      return { ...state, updating: payload }
    default:
      return state
  }
}
