import { employmentIncomeVerificationTasks as actionTypes } from 'v2/actionTypes'

const initialState = {
  showCreateModal: false,
  showUpdateModal: false,
  toggleAdvancedOptions: false,
  loading: false,
}

export default function uiFlags(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case actionTypes.showCreateModal:
      return { ...state, showCreateModal: payload }
    case actionTypes.showUpdateModal:
      return { ...state, showUpdateModal: payload }
    case actionTypes.toggleAdvancedOptions:
      return { ...state, toggleAdvancedOptions: !state.toggleAdvancedOptions }
    case actionTypes.loading: {
      return { ...state, loading: payload }
    }
    default:
      return state
  }
}
