import AT from 'actionTypes/'

const initialState = [];

export default function downloads(state = initialState, action) {
  switch (action.type) {
    case AT.SET_DOWNLOADS:
      return action.downloads
    case AT.ADD_DOWNLOAD:
      return [action.download, ...state]
    default:
      return state
  }
}
