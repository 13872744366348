import AT from '../actionTypes'

const initialState = {
  documents: [],
  selectedDocumentIds: [],
  selectedDocumentId: [],
  bulkIsViewable: false,
  singleIsViewable: false,
  currentPdfTronDocument: '',
  uiFlags: {
    loading: true,
    updating: false,
    changed: false,
    saving: false,
    pdfTronLoaded: false,
    merging: false,
    showCrop: false,
    openedDocumentGroups: [],
    croppingBounds: {},
    croppingSelection: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
    currentPageNumber: 0,
    currentPageZoom: 0,
    showPdfTronDeleteForm: false,
    showPdfTronMergeModal: false,
    showPdfTronReorderForm: false,
    isLoadingAttachment: true,
  },
  documentSortData: {
    currentDocumentSortBy: 'fileName',
    reverse: false,
  },
  metadata: {},
}

export default function v2Documents(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case AT.SET_V2_DOCUMENTS: {
      return { ...state, documents: payload }
    }
    case AT.SET_V2_DOCUMENT: {
      return { ...state, document: payload }
    }
    case AT.SET_PDF_TRON_CURRENT_DOCUMENT: {
      return { ...state, currentPdfTronDocument: payload }
    }
    case AT.SET_PDF_TRON_CORE_CONTROLS: {
      return { ...state, CoreControls: payload }
    }
    case AT.SET_PDF_TRON_VIEWER_INSTANCE: {
      return { ...state, ViewerInstance: payload }
    }
    case AT.TOGGLE_ALL_DOCUMENTS: {
      return { ...state, selectedDocumentIds: payload }
    }
    case AT.TOGGLE_DOCUMENT: {
      return { ...state, selectedDocumentIds: payload }
    }
    case AT.SET_SELECTED_DOCUMENT_ID: {
      return { ...state, selectedDocumentId: payload }
    }
    case AT.TOGGLE_DELETE_BULK_FILE_MODAL: {
      return { ...state, bulkIsViewable: payload }
    }
    case AT.TOGGLE_DELETE_SINGLE_FILE_MODAL: {
      return { ...state, singleIsViewable: payload }
    }
    case AT.SET_LOADING_DOCUMENTS: {
      return { ...state, uiFlags: { ...state.uiFlags, loading: payload } }
    }
    case AT.UPDATING_DOCUMENT: {
      return { ...state, uiFlags: { ...state.uiFlags, updating: payload } }
    }
    case AT.TOGGLE_DOCUMENT_CHANGED: {
      return { ...state, uiFlags: { ...state.uiFlags, changed: payload } }
    }
    case AT.TOGGLE_DOCUMENT_SAVING: {
      return { ...state, uiFlags: { ...state.uiFlags, saving: payload } }
    }
    case AT.TOGGLE_PDF_TRON_LOADED: {
      return { ...state, uiFlags: { ...state.uiFlags, pdfTronLoaded: payload } }
    }
    case AT.SET_OPENED_DOCUMENT_GROUPS: {
      return { ...state, uiFlags: { ...state.uiFlags, openedDocumentGroups: payload } }
    }
    case AT.TOGGLE_PDF_TRON_MERGING: {
      return { ...state, uiFlags: { ...state.uiFlags, merging: payload } }
    }
    case AT.TOGGLE_SHOW_CROP: {
      return { ...state, uiFlags: { ...state.uiFlags, showCrop: payload } }
    }
    case AT.SET_DOCUMENT_CROPPING_BOUNDS: {
      return { ...state, uiFlags: { ...state.uiFlags, croppingBounds: payload } }
    }
    case AT.SET_DOCUMENT_CROPPING_SELECTION: {
      return { ...state, uiFlags: { ...state.uiFlags, croppingSelection: payload } }
    }
    case AT.SET_DOCUMENT_CURRENT_PAGE_NUMBER: {
      return { ...state, uiFlags: { ...state.uiFlags, currentPageNumber: payload } }
    }
    case AT.SET_DOCUMENT_CURRENT_PAGE_ZOOM: {
      return { ...state, uiFlags: { ...state.uiFlags, currentPageZoom: payload } }
    }
    case AT.TOGGLE_PDF_TRON_REORDER_FORM: {
      return { ...state, uiFlags: { ...state.uiFlags, showPdfTronReorderForm: payload } }
    }
    case AT.TOGGLE_PDF_TRON_DELETE_FORM: {
      return { ...state, uiFlags: { ...state.uiFlags, showPdfTronDeleteForm: payload } }
    }
    case AT.TOGGLE_PDF_TRON_MERGE_MODAL: {
      return { ...state, uiFlags: { ...state.uiFlags, showPdfTronMergeModal: payload } }
    }
    case AT.SET_DOCUMENT_SORT_DATA: {
      return { ...state, documentSortData: payload }
    }
    default: {
      return state
    }
  }
}
