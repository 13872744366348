import _ from 'underscore'

export function isStatus(connection, status) {
  return connection.status === status
}


export function staticOnly(parts) {
  if (parts.length < 0) false

  return staticTextParts(parts).length === parts.length
}

export function staticTextParts(parts) {
  return _.where(parts, { kind: 'static-text' })
}

export function uniqParts(parts) {
  return _.uniq(parts, (part) => {
    return part.title
  })
}

// var uniqueList = _.uniq(list, function(item, key, a) {
//     return item.a;
// });

export function replaceText(part) {
  return part.text.replace('FileThis', 'Maxwell')
}

const OPEN_STATES = [
  'created',
  'waiting',
  'connecting',
  'uploading',
  'question',
  'answered',
  'manual',
  'incorrect',
  'error',
  'disabled',
]

export function isConnectionOpen(state) {
  return _.contains(OPEN_STATES, state)
}

// if part data is submitted (not static text), add them to parts array
// We filter out undefined parts (side effect of serialize) and
//  format serialized parts and values for submission.
//  This allows us to handle a single or multiple fields.
export function formattedParts(ids, values) {
  // All ids and values are weird objects after being serialized
  //  They log as arrays with keys? but are objects??? huh
  if (typeof ids === 'object') ids = Object.values(ids)
  if (typeof values === 'object') values = Object.values(values)

  let parts = _.zip(ids, values)


  if (typeof ids === 'undefined' || typeof values === 'undefined') parts = []

  const filteredParts = _filteredParts(parts)

  return filteredParts.map((part) => {
    return { id: part[0], value: part[1] }
  })
}

function _filteredParts(parts) {
  return parts.filter((part) => {
    return (typeof part[0] !== 'undefined' || typeof part[1] !== 'undefined')
  })
}

export function filterInteractions(interactions) {
  return _.filter(interactions, (interaction) => {
    switch (interaction.status) {
      case 404:
      case 400:
        return
      default:
        return interaction
    }
  })
}
