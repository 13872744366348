import AT from '../../actionTypes/'

const initialState = {}

export default function uiFlags(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case AT.SHOW_CREATE_LOAN_APP_TEMPLATE_QUESTION_MODAL:
      return { ...state, showCreateModal: payload }
    case AT.SHOW_EDIT_LOAN_APP_TEMPLATE_QUESTION_MODAL:
      return { ...state, showEditModal: payload }
    case AT.SHOW_DESTROY_LOAN_APP_TEMPLATE_QUESTION_MODAL:
      return { ...state, showDestroyModal: payload }
    default:
      return state
  }
}
