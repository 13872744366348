import AT from 'actionTypes/'
import _ from 'underscore';

const initialState = [];

export default function selectedVaultDocuments(state = initialState, action) {
  switch (action.type) {
    case AT.ADD_SELECTED_VAULT_DOCUMENT:
      return [...state, action.documentId]
    case AT.REMOVE_SELECTED_VAULT_DOCUMENT: {
      const index = _.indexOf(state, action.documentId)
      return [
        ...state.slice(0, index),
        ...state.slice(index + 1),
      ];
    }
    case AT.SET_VAULT_DOCUMENTS:
      return action.documentIds;
    default:
      return state
  }
}
