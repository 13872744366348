export const getPageInfo = state => state.pageInfo

export const getBrandInfo = state => getPageInfo(state).brandInfo
export const getBrandName = state => getBrandInfo(state).name
export const getBrandApplicationName = state => getBrandInfo(state).applicationName
export const getFormInfo = state => getPageInfo(state).formInfo
export const getLatestLoanAppId = state => getPageInfo(state).loan_app_id
export const getLoanFileId = state => getPageInfo(state).loan_file_id
export const getUnreadMessagesCount = state => getPageInfo(state).unread_messages_count
export const getURLs = state => getPageInfo(state).urls
export const getUserInfo = state => getPageInfo(state).userInfo
export const getAusInfo = state => getPageInfo(state).ausInfo
export const getCoborrowerInfo = state => getPageInfo(state).coborrowerInfo

export const getCreditAuthLanguage = state => getBrandInfo(state).creditAuthorizationConsentLanguage
export const getESignLanguage = state => getBrandInfo(state).electronicSignatureConsentLanguage
export const getCustomConsentLanguage = state => getBrandInfo(state).customConsentLanguage
export const getVerbalAuthLanguage = state => getBrandInfo(state).customVerbalCreditAuth

export const getAuthenticityToken = state => getFormInfo(state).authenticityToken
export const getAPIToken = state => getUserInfo(state).apiToken

export const getDisplayFirstName = state => getUserInfo(state).displayFirstName
export const getDisplayName = state => getUserInfo(state).displayName
export const getLenderSlug = state => getUserInfo(state).slug
export const getOrganizationId = state => getUserInfo(state).organizationId
export const getOrganizationName = state => getUserInfo(state).organizationName
export const getProfileImageSource = state => getUserInfo(state).profileImageSrc
export const getRole = state => getUserInfo(state).role
export const getUserType = state => getUserInfo(state).type
export const getSelectedTeamId = state => getUserInfo(state).selectedTeamId
export const getUserId = state => getUserInfo(state).id
export const getUserLocale = state => getUserInfo(state).locale
export const getIsMaxwellManagedDocusign = state => getUserInfo(state).docusignConnected

export const getLoanAppUrl = state => getURLs(state).loanApps
