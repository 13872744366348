import { preApprovalLetterTasks as ActionTypes } from '../../actionTypes/'

const initialState = []

export default function stagedRecord(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case ActionTypes.setStagedLetters:
      return payload
    case ActionTypes.addStagedLetter:
      return [payload, ...state]
    default:
      return state
  }
}
