export default class FeatureFlagBasedDecisionService {
  // Expects loan file feature flags
  constructor(featureFlags) {
    this.featureFlags = featureFlags
  }

  verificationEnabled = () => {
    return this.assetVerificationEnabled()
      || this.employmentIncomeVerificationEnabled()
  }

  assetVerificationEnabled = () => {
    const {
      plaidVOAEnabled,
    } = this.featureFlags
    return !!plaidVOAEnabled
  }

  employmentIncomeVerificationEnabled = () => {
    const {
      worknumberEnabled,
    } = this.featureFlags
    return !!worknumberEnabled
  }

  docusignEnabled = () => {
    const {
      docusignConnected,
    } = this.featureFlags
    return !!docusignConnected
  }

  signableDocumentEnabled = () => {
    const {
      esignaturesEnabled,
    } = this.featureFlags
    return !!esignaturesEnabled || this.docusignEnabled()
  }

  insurefetchEnabled = () => {
    const {
      insurefetchEnabled,
    } = this.featureFlags
    return !!insurefetchEnabled
  }
}
