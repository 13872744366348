export const metadataFromLendingQBDocuments = (lqbDocs) => {
  return {
    failedLqbDocumentCount: lendingQBDocumentCountByStatus(lqbDocs, 'failed'),
    sendingLqbDocumentCount: lendingQBDocumentCountByStatus(lqbDocs, 'sending'),
    sentLqbDocumentCount: lendingQBDocumentCountByStatus(lqbDocs, 'sent'),
  }
}
// Returns documents that are still sending to LendingQB
export const lendingQbPollableDocuments = (lqbDocs) => {
  return lqbDocs.filter((lqbDoc) => {
    return (
      lqbDoc.lqbStatus === 'sending'
    )
  })
}

const lendingQBDocumentCountByStatus = (lqbDocs, status) => {
  return lqbDocs.filter(lqbDoc => lqbDoc.lqbStatus === status).length
}
