import Route from 'Services/Route'
import { underscored } from 'underscore.string'
import { map } from 'underscore'


export function create(loanAppTemplateSection, templateId) {
  const url = Route.api.loanApp.templateSections.create()
  const res = {}
  map(loanAppTemplateSection, (value, key) => {
    res[underscored(key)] = value
  })

  return (
    $.ajax(
      {
        method: 'POST',
        data: {
          loan_app_template_section: res,
          template_id: templateId,
        },
        url: url,
      }
    )
  )
}


export function update(loanAppTemplateSection, changes) {
  const url = Route.api.loanApp.templateSections.update(loanAppTemplateSection)
  const res = {}
  map(changes, (value, key) => {
    res[underscored(key)] = value
  })

  return (
    $.ajax(
      {
        method: 'PUT',
        data: { loan_app_template_section: res, template_id: loanAppTemplateSection.templateId },
        url: url,
      }
    )
  )
}
