import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'
import { showTeamAPI, updateTeamAPI } from './asyncActions'

export const teamSlice = createSlice({
  name: 'team',
  initialState: baseInitialState,
  reducers: baseReducers,
  extraReducers: {
    [showTeamAPI.pending](state) {
      state.uiFlags.isFetching = true
    },
    [showTeamAPI.fulfilled](state, action) {
      state.stagedRecord = action.payload.data
      state.uiFlags.isFetching = false
    },
    [showTeamAPI.rejected](state) {
      state.uiFlags.isFetching = false
    },
    [updateTeamAPI.pending](state) {
      state.uiFlags.isUpdating = true
    },
    [updateTeamAPI.fulfilled](state, action) {
      state.stagedRecord = action.payload.data
      state.uiFlags.isUpdating = false
    },
    [updateTeamAPI.rejected](state) {
      state.uiFlags.isUpdating = false
    },
  },
})

export default teamSlice.reducer
