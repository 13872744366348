import AT from 'actionTypes/'
import _ from 'underscore';

const initialState = {};

export default function documentPreview(state = initialState, action) {
  switch (action.type) {
    case AT.SET_FILE_PREVIEW_SHOW:
      return _.extend({}, state, action.props, { show: true, loadDocumentPreviewComponent: true })
    case AT.RESET_FILE_PREVIEW_SHOW:
      return _.extend({}, state, { show: false })
    case AT.SET_FILE_PREVIEW_CURRENT_DOCUMENT:
      return _.extend({}, state, { currentDocumentId: action.value })
    default:
      return state
  }
}
