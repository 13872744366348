import JSONAPIClient from '../../../JSONAPIClient'

JSONAPIClient.define(
  'category',
  {
    parent_id: '',
    slug: '',
    title: '',
    description: '',
    icon: '',
    items: {
      jsonApi: 'hasMany',
      type: 'items',
    },
  },
  { collectionPath: 'bi/reports/categories' },
)
