import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from '../../../apiClient'

export const showLPA = createAsyncThunk(
  'externalConfigs/LPA/show',
  async (filter) => {
    try {
      const { data } = await JSONAPIClient.request(
        '/api/v2/external_configs/lpa',
        'GET',
        { filter },
      )
      return data
    } catch {
      return {}
    }
  }
)

export const createLPA = createAsyncThunk(
  'externalConfigs/LPA/create',
  async (payload) => {
    const { data } = await JSONAPIClient.request(
      '/api/v2/external_configs/lpa',
      'POST',
      {},
      {
        type: 'lpas',
        attributes: payload,
      },
    )
    return data
  }
)

export const destroyLPA = createAsyncThunk(
  'externalConfigs/LPA/destroy',
  async (id) => {
    const { data } = await JSONAPIClient.request(
      `/api/v2/external_configs/lpa/${id}`,
      'DELETE',
    )
    return data
  }
)
