import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'

import {
  createFavoriteQuestion,
  destroyFavoriteQuestion,
  indexFavoriteQuestion,
} from './asyncActions'

const biFavoriteQuestionSlice = createSlice({
  name: 'bi_favorite_question',
  initialState: baseInitialState,
  reducers: baseReducers,
  extraReducers: {
    [indexFavoriteQuestion.fulfilled](state, action) {
      state.records = action.payload
    },
    [createFavoriteQuestion.fulfilled](state, action) {
      state.records.unshift(action.payload)
    },
    [destroyFavoriteQuestion.fulfilled](state, action) {
      const id = action.meta.arg
      state.records = state.records.filter((r) => r.id !== id)
    },
  },
})

export default biFavoriteQuestionSlice
