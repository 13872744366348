import toastr from 'utils/toastr'

import Route from 'Services/Route'
import api from 'utils/api'
import { createAction } from 'utils/reducer_utils'
import { toastrErrors } from 'utils/error_utils'
import AT from '../actionTypes'
import { show, update } from '../BackendRequest/Api/Organizations/EmailSignatures'

export const fetchEmailSignatureTemplate = (organizationId) => {
  return async (dispatch, getState) => {
    const organizationEmailSignatureTemplate = await show(getState, organizationId)
    dispatch(setCurrentAndLastDeployedEmailSignatureTemplates(organizationEmailSignatureTemplate))
  }
}

export const updateEmailSignatureTemplate = (organizationId, params) => {
  return async (dispatch, getState) => {
    const organizationEmailSignatureTemplate = await update(getState, organizationId, params)
    dispatch(setCurrentAndLastDeployedEmailSignatureTemplates(organizationEmailSignatureTemplate))
    dispatch(toggleEmailSignatureEditModal(false))
    toastr.success('Successfully updated your preferences')
  }
}

export const deployEmailSignatureTemplate = (organizationId) => {
  return async (dispatch, getState) => {
    const url = Route.api.organizations.emailSignatures.deploy.create({ organizationId })

    try {
      await api(getState).post(url)
    } catch (error) {
      const { data = {} } = error.response || {}
      const { errors } = data

      if (errors) {
        return toastrErrors(errors)
      }
    }
  }
}

export const toggleEmailSignatureEnabled = () => {
  return (dispatch, getState) => {
    const {
      emailSignatureTemplates: {
        record: {
          currentTemplate: {
            id, organizationId, enabled,
          },
        },
      },
    } = getState().v2

    const params = {
      id,
      enabled: !enabled,
    }

    dispatch(updateEmailSignatureTemplate(organizationId, params))
  }
}

export const toggleOnEmailSignatureEditModal = () => {
  return (dispatch) => {
    dispatch(toggleEmailSignatureEditModal(true))
  }
}

export const toggleOffEmailSignatureEditModal = () => {
  return (dispatch) => {
    dispatch(toggleEmailSignatureEditModal(false))
  }
}

export const setCurrentAndLastDeployedEmailSignatureTemplates = (emailSignature) => {
  if (!emailSignature) return
  return (dispatch) => {
    const { currentTemplate, deployedTemplate } = emailSignature

    Promise.all([
      dispatch(setCurrentOrganizationEmailSignatureTemplate(currentTemplate)),
      dispatch(setDeployedOrganizationEmailSignatureTemplate(deployedTemplate)),
    ])
  }
}

const toggleEmailSignatureEditModal = createAction(AT.TOGGLE_EMAIL_SIGNATURE_EDIT_MODAL)
const setCurrentOrganizationEmailSignatureTemplate = createAction(AT.SET_CURRENT_EMAIL_SIGNATURE_TEMPLATE)
const setDeployedOrganizationEmailSignatureTemplate = createAction(AT.SET_DEPLOYED_EMAIL_SIGNATURE_TEMPLATE)
