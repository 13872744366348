import { combineReducers } from 'redux'
import records from './records'
import uiFlags from './uiFlags'
import mockCommunication from './mockCommunication'

export default combineReducers({
  records,
  mockCommunication,
  uiFlags,
})
