import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'
import { popToast } from 'v2/actions/system_notifications'

export const createScenario = createAsyncThunk(
  'externalConfigs/aus/scenarios/create',
  async (payload, thunkAPI) => {
    try {
      const response = await JSONAPIClient.request(
        '/api/v2/aus/scenarios',
        'POST',
        {},
        {
          type: 'scenarios',
          attributes: payload,
        },
      )
      return response.data
    } catch (e) {
      thunkAPI.dispatch(popToast({ notice: 'there was an error creating your scenario' }))
      return e.response.error
    }
  }
)

export const indexScenarios = createAsyncThunk(
  'externalConfigs/aus/scenarios/index',
  async (filter, thunkAPI) => {
    try {
      const response = await JSONAPIClient.all('scenario').get({ filter })
      return response.data
    } catch (e) {
      thunkAPI.dispatch(popToast({ notice: 'there was an error getting the scenarios for this loan file' }))
      return e.response.error
    }
  }
)

export const showScenario = createAsyncThunk(
  'externalConfigs/aus/scenarios/show',
  async (id, thunkAPI) => {
    try {
      const response = await JSONAPIClient.one('scenario', id).get()
      return response.data
    } catch (e) {
      thunkAPI.dispatch(popToast({ notice: 'there was an error getting this scenario' }))
      return e.response.error
    }
  }
)

export const destroyScenario = createAsyncThunk(
  'externalConfigs/aus/scenarios/destroy',
  async (id, thunkAPI) => {
    try {
      await JSONAPIClient.one('scenario', id).destroy()
      return { id }
    } catch (e) {
      thunkAPI.dispatch(popToast({ notice: 'there was an error deleting this scenario' }))
      return e.response.error
    }
  }
)
