import { mpaySettings as actionTypes } from 'v2/actionTypes'

const initialState = {
  loading: false,
  activation: false,
  submitting: false,
  showSubmitModal: false,
  submittingPaymentPurposes: false,
  submittingEmail: false,
}

export default function uiFlags(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case actionTypes.loading:
      return { ...state, loading: payload }
    case actionTypes.activation:
      return { ...state, activation: payload }
    case actionTypes.submitting:
      return { ...state, submitting: payload }
    case actionTypes.showSubmitModal:
      return { ...state, showSubmitModal: payload }
    case actionTypes.submittingPaymentPurposes:
      return { ...state, submittingPaymentPurposes: payload }
    case actionTypes.submittingEmail:
      return { ...state, submittingEmail: payload }
    default:
      return state
  }
}
