import { combineReducers } from 'redux'
import pricingShareSlice from './pricing_share'
import quickPricerApplySlice from './loan_app'

const quickPricer = combineReducers({
  pricingShares: pricingShareSlice.reducer,
  quickPricerApply: quickPricerApplySlice.reducer,
})

export default quickPricer
