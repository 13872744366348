import { replace, push } from 'Services/ImmutatingHelpers'
import { findIndex } from 'underscore'
import AT from '../../actionTypes/'

export default function dirtyRecords(state = [], action) {
  const { type, payload } = action

  switch (type) {
    case AT.SET_LOAN_APP_RESPONSE_ERRORS: {
      return payload
    }
    case AT.UPDATE_LOAN_APP_RESPONSE_ERRORS: {
      const index = findIndex(state, error => error.templateQuestionId === payload.templateQuestionId)
      if (index >= 0) {
        return replace(state, index, payload)
      } else {
        return push(state, payload)
      }
    }
    default:
      return state
  }
}
