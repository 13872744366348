import Route from 'Services/Route'
import api from 'utils/api'
import toastr from 'utils/toastr'
import { some } from 'lodash'
import { createActionsFromActionTypes } from 'utils/reducer_utils'
import { toastrErrors } from 'utils/error_utils'
import { preApprovalLetterTemplates as actionTypes } from '../../actionTypes'

export const preApprovalLetterTemplatesReducerActions = createActionsFromActionTypes(actionTypes)
const ApiRoutes = Route.api.organizations.preApprovalLetterTemplates
const PageRoutes = Route.organizations.preApprovalLetterTemplates

export function index({ organizationId }) {
  return async (dispatch, getState) => {
    const url = ApiRoutes.index({ organizationId })
    const res = await api(getState).get(url)
    dispatch(preApprovalLetterTemplatesReducerActions.set(res.data.templates))
  }
}

export function teamIndexIfNeeded(props) {
  return async (dispatch, getState) => {
    if (getState().v2.preApprovalLetterTemplates.records.length)
      return
    dispatch(teamIndex(props))
  }
}
export function teamIndex({ teamId }) {
  return async (dispatch, getState) => {
    const url = Route.api.teams.preApprovalLetterTemplates.index({ teamId })
    const res = await api(getState).get(url)
    await dispatch(preApprovalLetterTemplatesReducerActions.set(res.data.templates))
  }
}

export function startCreationFlow() {
  return (dispatch) => {
    dispatch(preApprovalLetterTemplatesReducerActions.setStagedRecord({ content: {} }))
    dispatch(preApprovalLetterTemplatesReducerActions.showCreateModal(true))
  }
}

export function endCreationFlow() {
  return (dispatch) => {
    dispatch(preApprovalLetterTemplatesReducerActions.showCreateModal(false))
  }
}

export function show({ organizationId, id }) {
  return async (dispatch, getState) => {
    const url = ApiRoutes.show({ organizationId, id })
    const res = await api(getState).get(url)
    dispatch(preApprovalLetterTemplatesReducerActions.setStagedRecord(res.data))
  }
}

export function create() {
  return async (dispatch, getState) => {
    const {
      preApprovalLetterTemplates: { stagedRecord: preApprovalLetterTemplate },
      organizations: { stagedRecord: organization },
    } = getState().v2

    const url = ApiRoutes.create({ organizationId: organization.id })
    dispatch(preApprovalLetterTemplatesReducerActions.creatingRecord(true))
    const res = await api(getState).post(url, { template: preApprovalLetterTemplate })
    dispatch(preApprovalLetterTemplatesReducerActions.creatingRecord(false))
    dispatch(preApprovalLetterTemplatesReducerActions.create(res.data))
    dispatch(endCreationFlow)
    toastr.success('Successfully created new template!')
    window.location.href = PageRoutes.show(res.data)
  }
}

export function onContentChange({ target: { name, value } }) {
  return async (dispatch, getState) => {
    const updates = { [name]: value }
    const { stagedRecord: { content } } = getState().v2.preApprovalLetterTemplates
    dispatch(preApprovalLetterTemplatesReducerActions.updateStagedRecord({ content: { ...content, ...updates } }))
  }
}

export function onStagedRecordChange({ target: { name, value } }) {
  return async (dispatch) => {
    const updates = { [name]: value }
    dispatch(preApprovalLetterTemplatesReducerActions.updateStagedRecord(updates))
  }
}

export function update() {
  return async (dispatch, getState) => {
    const { stagedRecord } = getState().v2.preApprovalLetterTemplates
    const url = ApiRoutes.update(stagedRecord)
    dispatch(preApprovalLetterTemplatesReducerActions.updatingRecord(true))
    const res = await api(getState).put(url, { template: stagedRecord })
    dispatch(preApprovalLetterTemplatesReducerActions.updatingRecord(false))
    dispatch(preApprovalLetterTemplatesReducerActions.setStagedRecord(res.data))
    toastr.success('Successfully saved the changes!')
  }
}

export function updateTitle({ title }) {
  return async (dispatch, getState) => {
    const { stagedRecord } = getState().v2.preApprovalLetterTemplates
    stagedRecord.content.title = title
    Promise.all([
      dispatch(preApprovalLetterTemplatesReducerActions.setStagedRecord(stagedRecord)),
      dispatch(update()),
      dispatch(hideTemplateRenameForm()),
    ])
  }
}

export function toggleLocked({ organizationId, id, locked }) {
  return async (dispatch, getState) => {
    const url = ApiRoutes.show({ organizationId, id })
    const res = await api(getState).get(url)
    res.data.locked = locked
    Promise.all([
      dispatch(preApprovalLetterTemplatesReducerActions.setStagedRecord(res.data)),
      dispatch(update()),
    ])
  }
}

export function showTemplateRenameForm() {
  return dispatch => dispatch(preApprovalLetterTemplatesReducerActions.showTemplateRenameForm(true))
}

export function hideTemplateRenameForm() {
  return dispatch => dispatch(preApprovalLetterTemplatesReducerActions.showTemplateRenameForm(false))
}

export function saveAndPreview() {
  return async (dispatch, getState) => {
    const { stagedRecord: preApprovalTemplate } = getState().v2.preApprovalLetterTemplates
    const href = Route.organizations.preApprovalLetterTemplates.preview(preApprovalTemplate)
    await dispatch(update())
    window.open(href, '_blank')
  }
}

export function deployAll() {
  return async (dispatch, getState) => {
    const organization = getState().v2.organizations.stagedRecord
    const needDeployment = some(getState().v2.preApprovalLetterTemplates.records, 'needDeployment')
    if (!needDeployment) return
    const url = ApiRoutes.deployAll({ organizationId: organization.id })
    dispatch(preApprovalLetterTemplatesReducerActions.deploying(true))
    const res = await api(getState).post(url)
    dispatch(preApprovalLetterTemplatesReducerActions.deploying(false))
    dispatch(preApprovalLetterTemplatesReducerActions.set(res.data.templates))
    toastr.success('Successfully submitted the changes!')
  }
}

export function destroy({ organizationId, id }) {
  return async (dispatch, getState) => {
    const url = ApiRoutes.destroy({ organizationId, id })
    try {
      const res = await api(getState).delete(url)
      dispatch(preApprovalLetterTemplatesReducerActions.set(res.data.templates))
      toastr.success('Successfully deleted template!')
    } catch (error) {
      alertError(error)
    }
  }
}

function alertError(error) {
  if (error.response && error.response.data.errors) {
    toastrErrors(error.response.data.errors)
  } else {
    toastrErrors('Something went wrong! We are looking into it!')
  }
}
