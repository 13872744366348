import AT from 'actionTypes/'
import api from 'utils/api'
import Route from 'Services/Route'
import { toastrErrors } from 'utils/error_utils'
import { setSelectedConnection } from 'actions/connections'

export function setStep(step) {
  return { type: AT.SET_STEP, payload: step }
}

// Create Account Connections dispatches account creation, connection creation and interactions.
export function createAccountConnections(params) {
  return (dispatch, getState) => {
    const { userInfo } = getState().pageInfo

    const url = Route.api.filethis.accountConnections.create()

    const req = api(getState).post(url, params)

    req.then((res) => {
      const { connection } = res.data

      dispatch(setSelectedConnection(connection))
      // Set loading step instead of redirecting back to the task list
      dispatch(setStep('loading'))
    }).catch((error) => {
      const { errors } = error?.response?.data || {}
      console.info(errors)

      if (errors) toastrErrors(errors)

      // If error, go back to sources select step
      dispatch(setStep('sources'))
    })
  }
}
