import AT, { borrowerNewLoanModal } from 'v2/actionTypes'
import { createActionsFromActionTypes } from 'utils/reducer_utils'

export function toggleOverlaySpinner(show, message) {
  if (!show) {
    message = null
  }
  return { type: AT.TOGGLE_OVERLAY_SPINNER, payload: { show, message } }
}

export const borrowerNewLoanModalActions = createActionsFromActionTypes(borrowerNewLoanModal)
