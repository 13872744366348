import AT from '../../actionTypes/'

const initialState = []

export default function records(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case AT.ADD_TASK_TEMPLATE:
      return [...state, payload]
    case AT.EDIT_TASK_TEMPLATE:
      return state.map((template) => {
        if (template.id === payload.id)
          return payload
        else
          return template
      })
    case AT.REMOVE_TASK_TEMPLATE: {
      return (
        state.filter(s => s.id != payload.id)
      )
    }
    default:
      return state
  }
}
