import { combineReducers } from 'redux'
import categories from './categories'
import items from './items'
import filters from './filters'

export default combineReducers({
  categories: categories.reducer,
  items: items.reducer,
  filters: filters.reducer,
})
