// Check for template
export const isTemplate = (type) => {
  return type === 'lender'
    && window
    && window.location.pathname.includes('templates')
}

// Check for lockable and locked status
export const isLocked = (question) => {
  return question
    && question.lockable
    && question.locked
}

// Check for Locked and not template or lender
export const isLockable = (question, type) => {
  return isLocked(question) && !isTemplate(type)
}

export const findQuestionByName = (questions, name) => {
  return questions.find(question => question.name === name || question.name === `ulad_${name}`)
}

export const findResponseByQuestionId = (responses, questionId) => {
  return responses.find(response => response.templateQuestionId === questionId)
}

export const getLoanAppResponseFromQuestion = (questionName, questions, responses) => {
  const question = findQuestionByName(questions, questionName) || { id: null }
  const response = findResponseByQuestionId(responses, question.id)
  return response ? response.value : null
}
