import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'
import { popToast } from 'v2/actions/system_notifications'

export const indexAnswerAPI = createAsyncThunk(
  'answer/index',
  async ({ loanAppId, participantId }) => {
    const data = await indexAnswers({ loanAppId, participantId })
    return data
  }
)

const indexAnswers = async ({ loanAppId, participantId, page = 1 }) => {
  const request = JSONAPIClient
    .one('loan_app', loanAppId)
    .all('answer')
    .get({ filter: { loanFileParticipantId: participantId }, page: { number: page } })
  const { data, meta: { pageCount } } = await request

  if (pageCount > page) {
    return data.concat(await indexAnswers({ loanAppId, participantId, page: page + 1 }))
  } else {
    return data
  }
}

export const sectionDestroyAPI = createAsyncThunk(
  'answer/section_destroy',
  async ({ loanAppId, payload }) => {
    const { data } = await JSONAPIClient.request(
      `/api/v2/loan_apps/${loanAppId}/answers/section_destroy`, 'POST', {}, payload
    )
    return { sectionId: payload.sectionId, answers: data }
  }
)

export const createAnswerAPI = createAsyncThunk(
  'answer/create',
  async ({ loanAppId, payload }) => {
    try {
      const response = await JSONAPIClient.one('loan_app', loanAppId).all('answer').post(payload)
      return response.data
    } catch {
      return {}
    }
  }
)

export const updateAnswerAPI = createAsyncThunk(
  'answer/update',
  async ({ loanAppId, answerId, payload }) => {
    try {
      const response = await JSONAPIClient.one('loan_app', loanAppId).one('answer', answerId).patch(payload)
      return response.data
    } catch {
      return {}
    }
  }
)

export const destroyAnswerAPI = createAsyncThunk(
  'answer/destroy',
  async ({ loanAppId, answer, participantId }) => {
    try {
      const { id: answerId, questionType } = answer
      await JSONAPIClient.one('loan_app', loanAppId).one('answer', answerId).destroy()
      let allAnswers
      if (questionType.match(/field_list$/)) {
        allAnswers = await indexAnswers({ loanAppId, participantId })
      }
      return { id: answerId, reindexedAnswers: allAnswers }
    } catch {
      return {}
    }
  }
)

export const destroyBulkAnswerAPI = createAsyncThunk(
  'answer/bulk_destroy',
  async ({ loanAppId, answers }, thunkAPI) => {
    try {
      const answerIds = answers.map(a => a.id)
      await JSONAPIClient.request(
        `/api/v2/loan_apps/${loanAppId}/answers/bulk_destroy`, 'POST', {}, { answerIds }
      )
      return { answerIds }
    } catch (error) {
      thunkAPI.dispatch(popToast({ error: 'There was an error deleting the responses.' }))
      throw error
    }
  }
)
