import { findQuestionByName, findResponseByQuestionId } from 'utils/loan_app_utils'

export default class BaseLoanAppQuestionResponseGetter {
  constructor(responses, questions, name) {
    this.responses = responses
    this.questions = questions
    this.isULAD = name && /^ulad_/.test(name)
    this.name = name && name.replace(/^ulad_/, '')
  }

  perform = () => {
    return this.filterResponseByQuestionName()
  }

  dependentQuestion = () => {
    const name = this.questionName()
    const { questions } = this
    return findQuestionByName(questions, name)
  }

  filterResponseByQuestionName = () => {
    const { responses } = this
    const question = this.dependentQuestion()
    if (!question) return
    const response = findResponseByQuestionId(responses, question.id)
    return response.value
  }
}
