import AT from '../../actionTypes/'

const initialState = {}

export default function uiFlags(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case AT.TOGGLE_MASTER_LOAN_FILE_TEMPLATE_CHECKBOX:
      return { ...state, checkedCheckbox: payload }
    case AT.TOGGLE_MASTER_LOAN_FILE_TEMPLATE_CLONE_MODAL:
      return { ...state, showCloneModal: payload }
    case AT.TOGGLE_MASTER_LOAN_FILE_TEMPLATE_DESTROY_MODAL:
      return { ...state, showDestroyModal: payload }
    case AT.TOGGLE_MASTER_LOAN_FILE_TEMPLATE_NEW_MODAL:
      return { ...state, showNewModal: payload }
    default:
      return state
  }
}
