import { combineReducers } from 'redux'

import eConsentSetting from './eConsentSetting'
import loanFiles from './pipelines/loanFiles'
import mpay from './mpay'
import organization from './organization'
import paymentsHistory from './paymentsHistory'
import pipeline from './pipeline'
import pipelines from './pipelines'
import pipelineUsers from './pipelines/users'
import profile from './profile'
import users from './users'

export default combineReducers({
  eConsentSetting,
  loanFiles,
  mpay,
  organization,
  paymentsHistory,
  pipeline,
  pipelines,
  pipelineUsers,
  profile,
  users,
})
