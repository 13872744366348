import AT from '../../actionTypes/'

const initialState = []

export default function records(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case AT.SET_LOAN_APP_TEMPLATES:
      return payload
    case AT.ADD_LOAN_APP_TEMPLATE:
      return [...state, payload]
    case AT.EDIT_LOAN_APP_TEMPLATE:
      return state.map((edit) => {
        if (edit.id === payload.id)
          return payload
        else
          return edit
      })
    case AT.DESTROY_LOAN_APP_TEMPLATE: {
      return (
        state.filter(s => s.id != payload.id)
      )
    }
    default:
      return state
  }
}
