import { pipelineLoanFiles as actionTypes } from 'v2/actionTypes'

const initialState = {}

export default function records(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case actionTypes.set: {
      const {
        pipelineId,
        loanFiles,
      } = payload

      const newState = state
      newState[pipelineId] = loanFiles

      return newState
    }
    default:
      return state
  }
}
