import AT from '../actionTypes/'

const initialState = {
  selectedTaskTemplates: [],
};

export default function documentGroups(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case AT.SET_SELECTED_TASK_TEMPLATES: {
      return { ...state, selectedTaskTemplates: payload }
    }
    default:
      return state
  }
}
