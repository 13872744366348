import JSONAPIClient from '../JSONAPIClient'

JSONAPIClient.define('template', {
  aus_template: '',
  custom: '',
  name: '',
  title: '',
  description: '',
  optional_section_ids: '',
  hidden_section_ids: '',
  landing_link: '',
  owner_id: '',
  owner_name: '',
  parent_id: '',
  questions: {
    jsonApi: 'hasMany',
    type: 'questions',
  },
  require_all_sections: '',
  sections: {
    jsonApi: 'hasMany',
    type: 'sections',
  },
  slug: '',
  updated_at: '',
  trid6_hidden: '',
})
