import { plaidLinkToken as actionTypes } from '../../actionTypes'

// {itemId: { linkToken: 'token' }}
export default function stagedRecord(state = {}, action) {
  const { type, payload } = action

  switch (type) {
    case actionTypes.set: {
      return { ...state, ...payload }
    }
    default: {
      return state
    }
  }
}
