import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'
import {
  createExternalCreditConfigAPI,
  indexExternalCreditConfigsAPI,
  updateExternalCreditConfigAPI,
  deleteExternalCreditConfigAPI,
} from './asyncActions'

export const externalCreditConfigSlice = createSlice({
  name: 'externalCreditConfig',
  initialState: baseInitialState,
  reducers: baseReducers,
  extraReducers: {
    [createExternalCreditConfigAPI.pending](state) {
      state.uiFlags.isCreating = true
    },
    [createExternalCreditConfigAPI.fulfilled](state, action) {
      state.records = [...state.records, action.payload]
      state.uiFlags.isCreating = false
    },
    [createExternalCreditConfigAPI.rejected](state) {
      state.uiFlags.isCreating = false
    },
    [indexExternalCreditConfigsAPI.pending](state) {
      state.uiFlags.isFetching = true
    },
    [indexExternalCreditConfigsAPI.fulfilled](state, action) {
      state.records = action.payload
      state.uiFlags.isFetching = false
    },
    [indexExternalCreditConfigsAPI.rejected](state) {
      state.uiFlags.isFetching = false
    },
    [updateExternalCreditConfigAPI.pending](state) {
      state.uiFlags.isUpdating = true
    },
    [updateExternalCreditConfigAPI.fulfilled](state, action) {
      state.records = state.records.map((record) => {
        if (record.id === action.payload.id) {
          return action.payload
        }
        return record
      })
      state.uiFlags.isUpdating = false
    },
    [updateExternalCreditConfigAPI.rejected](state) {
      state.uiFlags.isUpdating = false
    },
    [deleteExternalCreditConfigAPI.pending](state) {
      state.uiFlags.isDeleting = true
    },
    [deleteExternalCreditConfigAPI.fulfilled](state, action) {
      state.records = state.records.filter(
        (record) => record.id !== action.payload
      )
      state.uiFlags.isDeleting = false
    },
    [deleteExternalCreditConfigAPI.rejected](state) {
      state.uiFlags.isDeleting = false
    },
  },
})

export default externalCreditConfigSlice.reducer
