import Route from 'Services/Route'
import toastr from 'utils/toastr'
import api, {
  postRequest,
  getRequest,
  putRequest,
} from 'utils/api'
import { getLoanFile } from 'v2/selectors/loan_files'
import {
  getEmploymentIncomeVerificationTask,
  getEmploymentIncomeVerificationTasks,
} from 'v2/selectors/employment_income_verification_tasks'
import { createActionsFromActionTypes } from 'utils/reducer_utils'
import { sanitizeInput } from 'modules/forms'
import { EMPLOYMENT_INCOME_VERIFICATION_TASK_TYPE_TO_TITLE_MAP } from 'v2/constants/EmploymentIncomeVerificationTasks'
import * as Sentry from '@sentry/react'
import { isEmpty } from 'lodash'
import { fetchLoanFile } from 'v2/actions'
import { toastrErrors } from 'utils/error_utils'
import FeatureFlagBasedDecisionService from 'Services/FeatureFlagBasedDecisionService'
import { employmentIncomeVerificationTasks as employmentIncomeVerificationTasksActionTypes } from '../actionTypes'

const ApiRoutes = Route.api.loanFile.employmentIncomeVerificationTask

export const employmentIncomeVerificationTasksReducerActions = createActionsFromActionTypes(
  employmentIncomeVerificationTasksActionTypes,
)

export const fetchResearchedEmploymentIncomeVerficiationTask = (props) => {
  return async (dispatch, getState) => {
    const url = Route.api.loanFile.employmentIncomeVerificationTask.researchedRequests.show(props)
    const res = await getRequest({ getState, url })

    if (res) {
      const { employmentIncomeVerificationsTask } = res.data

      dispatch(employmentIncomeVerificationTasksReducerActions.setStagedRecord(employmentIncomeVerificationsTask))
      dispatch(employmentIncomeVerificationTasksReducerActions.create(employmentIncomeVerificationsTask))
    }
  }
}

export const handleUpdatingResearchedEmploymentIncomeVerficiationTask = (values) => {
  return async (dispatch, getState) => {
    const employmentIncomeVerificationTask = getEmploymentIncomeVerificationTask(getState())
    const { loanFileId } = employmentIncomeVerificationTask

    dispatch(employmentIncomeVerificationTasksReducerActions.loading(true))

    await dispatch(updateResearchedEmploymentIncomeVerficiationTask(values))
    await dispatch(fetchLoanFile(loanFileId))

    dispatch(employmentIncomeVerificationTasksReducerActions.loading(false))
    dispatch(employmentIncomeVerificationTasksReducerActions.nextResearchedPosition(1))
  }
}

export const toggleInstantEmploymentIncomeVerificationTaskModal = (payload) => {
  return (dispatch, getState) => {

    const { id: loanFileId = null } = getLoanFile(getState())
    if (!loanFileId) toastr.error('Loading loanfile data, please try again.')
    else dispatch(employmentIncomeVerificationTasksReducerActions.showCreateModal(payload))
  }
}

export const toggleResearchEmploymentIncomeVerificationTaskModal = (payload) => {
  return (dispatch) => {
    dispatch(employmentIncomeVerificationTasksReducerActions.showUpdateModal(payload))
  }
}

export const toggleInstantVerificationAdvancedOptions = () => {
  return (dispatch) => {
    dispatch(employmentIncomeVerificationTasksReducerActions.toggleAdvancedOptions())
  }
}

// Initiate Request for Instant Verification
export const handleSubmitInstantEmploymentIncomeVerificationTask = (values) => async (dispatch, getState) => {
  dispatch(toggleLoading(true))
  const formattedInstantVerificationTaskParams = formatInstantEmploymentIncomeVerificationTaskParams(values)
  const existingVerificationTask = getEmploymentIncomeVerificationTask(getState())

  if (isEmpty(existingVerificationTask)) {
    // Creates a new task and sends instant verification
    await dispatch(createEmploymentIncomeVerificationTask(values))
    await dispatch(sendEmploymentIncomeInstantVerification(formattedInstantVerificationTaskParams))
  } else {
    // Task is already present (ie staged record is set when clicking a continue button) - only send the instant request
    await dispatch(sendEmploymentIncomeInstantVerification(formattedInstantVerificationTaskParams))
  }

  dispatch(toggleLoading(false))
}

// Initiate Request for Research Verification
export const handleSubmitResearchedVerificationTask = (values) => async (dispatch) => {
  dispatch(toggleLoading(true))
  const formattedResearchVerificationTaskParams = formatResearchedVerificationTaskParams(values)
  await dispatch(sendEmploymentIncomeResearchedVerification(formattedResearchVerificationTaskParams))
  dispatch(toggleLoading(false))
}

export const showInstantEmploymentIncomeVerificationModal = (taskId) => {
  return (dispatch, getState) => {
    const employmentIncomeVerificationTasks = getEmploymentIncomeVerificationTasks(getState())
    const employmentIncomeVerificationTask = employmentIncomeVerificationTasks.find(task => task.id === taskId)

    dispatch(employmentIncomeVerificationTasksReducerActions.setStagedRecord(employmentIncomeVerificationTask))
    dispatch(toggleInstantEmploymentIncomeVerificationTaskModal(true))
  }
}

export const showResearchEmploymentIncomeVerificationModal = (taskId) => {
  return (dispatch, getState) => {
    const { featureFlags } = getLoanFile(getState())
    const featureFlagService = new FeatureFlagBasedDecisionService(featureFlags)
    if (!featureFlagService.docusignEnabled()) {
      toastr.error(
        `You need to enable Docusign to perform researched verifications.
         Please contact your administrator!`,
        '',
        { timeOut: 5000 }
      )
      return
    }
    const employmentIncomeVerificationTasks = getEmploymentIncomeVerificationTasks(getState())
    const employmentIncomeVerificationTask = employmentIncomeVerificationTasks.find(task => task.id === taskId)

    dispatch(employmentIncomeVerificationTasksReducerActions.setStagedRecord(employmentIncomeVerificationTask))
    dispatch(toggleResearchEmploymentIncomeVerificationTaskModal(true))
  }
}

// private functions

const updateResearchedEmploymentIncomeVerficiationTask = (values) => {
  return async (dispatch, getState) => {
    const formattedUpdateVerificationTaskParams = formatResearchedVerificationTaskParams(values)
    const employmentIncomeVerificationTask = getEmploymentIncomeVerificationTask(getState())
    const { id: taskId, latestRequestId: requestId, loanFileId } = employmentIncomeVerificationTask
    const url = ApiRoutes.researchedRequests.update({ loanFileId, taskId, requestId })
    const res = await putRequest({ getState, url, params: formattedUpdateVerificationTaskParams })

    if (res) {
      const { employmentIncomeVerificationsTask } = res.data

      dispatch(employmentIncomeVerificationTasksReducerActions.setStagedRecord(employmentIncomeVerificationsTask))
      dispatch(employmentIncomeVerificationTasksReducerActions.create(employmentIncomeVerificationsTask))
    }
  }
}

const createEmploymentIncomeVerificationTask = (values) => {
  return async (dispatch, getState) => {
    const formattedCreateVerificationTaskParams = formatEmploymentIncomeVerificationTaskParams(values)
    const loanFile = getLoanFile(getState())
    const { id: loanFileId } = loanFile
    const url = Route.api.loanFile.employmentIncomeVerificationTask.create({ loanFileId })

    const res = await postRequest({ getState, url, params: formattedCreateVerificationTaskParams })

    if (res) {
      const { employmentIncomeVerificationsTask } = res.data
      const { title } = employmentIncomeVerificationsTask

      dispatch(employmentIncomeVerificationTasksReducerActions.setStagedRecord(employmentIncomeVerificationsTask))
      dispatch(employmentIncomeVerificationTasksReducerActions.create(employmentIncomeVerificationsTask))

      toastr.options.timeOut = '3000'
      toastr.success(`${title} Task added successfully! Sending instant verification request!`)
    }
  }
}

const sendEmploymentIncomeInstantVerification = (formattedVerificationTaskParams) => {
  return async (dispatch, getState) => {
    const loanFile = getLoanFile(getState())
    const task = getEmploymentIncomeVerificationTask(getState())
    const { id: loanFileId } = loanFile
    const { id: taskId } = task

    const url = ApiRoutes.instantRequest({
      loanFileId,
      taskId,
    })

    const res = await postRequest({ getState, url, params: formattedVerificationTaskParams })

    if (res) {
      dispatch(processEmploymentIncomeInstantVerificationResponse(res))
    }
  }
}

const sendEmploymentIncomeResearchedVerification = (formattedVerificationTaskParams) => {
  return async (dispatch, getState) => {
    const loanFile = getLoanFile(getState())
    const task = getEmploymentIncomeVerificationTask(getState())
    const { id: loanFileId } = loanFile
    const { id: taskId } = task

    const url = ApiRoutes.researchedRequests.create({
      loanFileId,
      taskId,
    })

    const res = await postRequest({ getState, url, params: formattedVerificationTaskParams })

    if (res) {
      dispatch(processEmploymentIncomeResearchVerificationResponse(res))
    }
  }
}

// format params for creating employment/income verfication task
const formatEmploymentIncomeVerificationTaskParams = (values) => {
  const { verificationType } = values
  return {
    task: {
      verificationType,
      title: EMPLOYMENT_INCOME_VERIFICATION_TASK_TYPE_TO_TITLE_MAP[verificationType],
    },
  }
}

// format params for sending the instant research request
const formatInstantEmploymentIncomeVerificationTaskParams = (values) => {
  const { ssn } = values

  return {
    request: {
      ...values,
      ssn: sanitizeInput(ssn),
    },
  }
}

// format params for sending the manual research request
const formatResearchedVerificationTaskParams = (values) => {
  const {
    loanNumber,
    firstName,
    lastName,
    ssn,
    dueDate,
    employmentType,
    employerName,
    employerAddress,
    employerPhone,
    taxPreparerCompanyName,
    taxPreparerCity,
    taxPreparerState,
    taxPreparerPostalCode,
    taxPreparerPhone,
    verificationType,
  } = values

  const { address, city, state, zipCode } = employerAddress

  const baseParams = {
    loanNumber,
    firstName,
    lastName,
    ssn: sanitizeInput(ssn),
    dueDate,
    employerName,
    employerAddress: address,
    employerCity: city,
    employerState: state,
    employerPostalCode: zipCode,
    employerPhone: sanitizeInput(employerPhone),
  }

  let additionalParams = {}

  if (employmentType === 'self_employed' && verificationType === 'voe') {
    additionalParams = {
      taxPreparerCompanyName,
      taxPreparerCity,
      taxPreparerState,
      taxPreparerPostalCode,
      taxPreparerPhone: sanitizeInput(taxPreparerPhone),
    }
  }

  let employmentTypeParams = {}

  if (verificationType === 'voe') {
    employmentTypeParams = { employmentType }
  }

  return {
    request: {
      ...baseParams,
      ...additionalParams,
      ...employmentTypeParams,
    },
  }
}

const toggleLoading = (bool) => {
  return (dispatch) => {
    dispatch(employmentIncomeVerificationTasksReducerActions.loading(bool))
  }
}

// methods for handling instant verification response object

const processEmploymentIncomeInstantVerificationResponse = (res) => {
  return (dispatch) => {
    const { employmentIncomeVerificationsTask } = res.data
    const { status } = employmentIncomeVerificationsTask

    dispatch(employmentIncomeVerificationTasksReducerActions.update(employmentIncomeVerificationsTask))

    switch (status) {
      case 'instant_verification_completed':
        return dispatch(processCompleteEmploymentIncomeVerification(employmentIncomeVerificationsTask))
      case 'instant_verification_not_found':
        return dispatch(processInstantVerificationNotFound())
      case 'instant_verification_failed':
        return dispatch(processFailedEmploymentIncomeVerification(employmentIncomeVerificationsTask))
      default:
        return dispatch(processErrorEmploymentIncomeVerification())
    }
  }
}

const processCompleteEmploymentIncomeVerification = (task) => {
  return (dispatch) => {
    dispatch(toggleInstantEmploymentIncomeVerificationTaskModal(false))
    dispatch(employmentIncomeVerificationTasksReducerActions.setStagedRecord({}))

    const { title } = task
    toastr.success(`Worknumber ${title} report fetched successfully!`)
  }
}

const processInstantVerificationNotFound = () => {
  return (dispatch) => {
    toastr.options.timeOut = '5000'
    toastr.error('Instant verification request failed. Please provide additional information for manual verification')

    // Close instant form - show research form
    dispatch(toggleInstantEmploymentIncomeVerificationTaskModal(false))
    dispatch(toggleResearchEmploymentIncomeVerificationTaskModal(true))
  }
}

const processFailedEmploymentIncomeVerification = (task) => {
  return (dispatch) => {
    dispatch(toggleInstantEmploymentIncomeVerificationTaskModal(false))
    dispatch(employmentIncomeVerificationTasksReducerActions.setStagedRecord({}))

    toastr.options.timeOut = '3000'
    const { errorMessage = 'Sorry, we were unable to process your instant verification request' } = task
    toastr.error(errorMessage)
  }
}

const processErrorEmploymentIncomeVerification = () => {
  return (dispatch) => {
    dispatch(toggleInstantEmploymentIncomeVerificationTaskModal(false))
    dispatch(employmentIncomeVerificationTasksReducerActions.setStagedRecord({}))

    const message = 'Invalid task status provided for employment and income verification response'
    Sentry.withScope(scope => {
      scope.setTag('type', 'EMPLOYMENT_INCOME_VERIFICATION_TASK')
      Sentry.captureMessage(message)
    })
  }
}

// methods for handling manual verification response object

const processEmploymentIncomeResearchVerificationResponse = (res) => {
  return (dispatch) => {
    const { employmentIncomeVerificationsTask } = res.data

    dispatch(employmentIncomeVerificationTasksReducerActions.update(employmentIncomeVerificationsTask))
    dispatch(toggleResearchEmploymentIncomeVerificationTaskModal(false))
    dispatch(employmentIncomeVerificationTasksReducerActions.setStagedRecord({}))

    toastr.options.timeOut = '3000'
    toastr.success("Researched verification requested. Collecting borrower's authorization")
  }
}

export function destroy({ loanFileId, taskId }) {
  return async (dispatch, getState) => {
    const url = ApiRoutes.destroy({ loanFileId, taskId })
    try {
      await api(getState).delete(url)
      dispatch(employmentIncomeVerificationTasksReducerActions.destroy({ id: taskId }))
      toastr.success('Successfully deleted task!')
    } catch (error) {
      alertError(error)
    }
  }
}

function alertError(error) {
  if (error.response && error.response.data.errors) {
    toastrErrors(error.response.data.errors)
  } else {
    toastrErrors('Something went wrong! We are looking into it!')
  }
}
