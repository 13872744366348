import { lenderProfile as actionTypes } from 'v2/actionTypes'

const initialState = {
  loading: false,
  updating: false,
  editing: false,
  verifyingOtp: false,
  showConfirmModal: false,
  showUpdateModal: false,
}

export default function uiFlags(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case actionTypes.loading:
      return { ...state, loading: payload }
    case actionTypes.updatingRecord:
      return { ...state, updating: payload }
    case actionTypes.editing:
      return { ...state, editing: payload }
    case actionTypes.showConfirmModal:
      return { ...state, showConfirmModal: payload }
    case actionTypes.showUpdateModal:
      return { ...state, showUpdateModal: payload }
    case actionTypes.toggleTwoFactorUpdating:
      return { ...state, verifyingOtp: payload }
    default:
      return state
  }
}
