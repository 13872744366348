import { quickPricerScenarios } from 'v2/actionTypes'

const initialState = {
  isFetching: false,
  isCreating: false,
  isCreatingBorrower: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case quickPricerScenarios.loading:
      return { ...state, ...action.payload }

    default:
      return state
  }
}
