import { insuranceTasks as ActionTypes } from '../../actionTypes/'

const initialState = {}

export default function currentRecord(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case ActionTypes.setCurrentRecord:
      return payload
    default:
      return state
  }
}
