import AT from '../../actionTypes/'

const initialState = {}

export default function uiFlags(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case AT.SHOW_EDIT_LOAN_APP_MODAL:
      return { ...state, showEditModal: payload }
    case AT.SHOW_CREATE_LOAN_APP_MODAL:
      return { ...state, showCreateModal: payload }
    case AT.SHOW_DESTROY_LOAN_APP_MODAL:
      return { ...state, showDestroyModal: payload }
    case AT.SHOW_FINISH_SUBMISSION_LOAN_APP_MODAL:
      return { ...state, showFinishModal: payload }
    case AT.LOAN_APP_LOADING:
      return { ...state, loading: payload }
    case AT.CREATING_NEW_LOAN_APP:
      return { ...state, creatingNewLoanApp: payload }
    case AT.SHOW_LENDER_TRID_MODAL:
      return { ...state, showLenderTridModal: payload }
    case AT.LOAN_APP_SYNCING_RESPONSE:
      return { ...state, loanAppSyncingResponse: payload }
    default:
      return state
  }
}
