import api from 'utils/api'
import Route from 'Services/Route'
import { toastrErrors } from 'utils/error_utils'

import { toggleDocumentIds } from 'v2/actions/documents'

import {
  lendingQbPollableDocuments,
  metadataFromLendingQBDocuments,
} from 'utils/lending_qb_document_utils'
import { getLoanFile } from 'v2/selectors/loan_files'

import AT from '../../actionTypes'
import { fetchAttachments } from '../s3attachments'


export function setLendingQbDocuments(documents) {
  return { type: AT.SET_LENDING_QB_DOCUMENTS, payload: documents }
}

export function setLendingQbDocument(document) {
  return { type: AT.SET_LENDING_QB_DOCUMENT, payload: document }
}

export function setLendingQbDocumentsMetadata(metadata) {
  return { type: AT.SET_LENDING_QB_DOCUMENTS_METADATA, payload: metadata }
}

export function syncingWithLendingQB(bool) {
  return { type: AT.SYNCING_WITH_LENDING_QB, payload: bool }
}

export const sendDocumentsToLendingQb = (loanFileId, documentIds) => {
  return (dispatch, getState) => {
    dispatch(syncingWithLendingQB(true))
    const url = Route.api.lendingQb.sendDocuments({ loanFileId })
    const data = { attachmentIds: documentIds }
    const req = api(getState).post(url, data)
    req.then(() => {
      dispatch(fetchAttachments({ loanFileId, isOnVaultTab: true }))
    }).catch((error) => {
      const { errors } = error?.response?.data || {}
      console.info(errors)

      if (errors) toastrErrors(errors)
    })
  }
}

export function fetchLendingQbDocuments(loanFileId) {
  return (dispatch, getState) => {
    // Select loan file ID from state or loan file ID

    // eslint-disable-next-line
    const url = Route.api.loanFiles.attachments.index({ loanFileId })
    const req = api(getState).get(url)

    req.then((res) => {
      const documents = res.data.attachments

      // Poll if LendingQB docs
      const lendingQBDocumentsSending = lendingQbPollableDocuments(documents)

      let isPolling

      if (lendingQBDocumentsSending.length > 0 && isPolling == null) {
        // Start polling for download changes
        setTimeout(() => {
          dispatch(fetchLendingQbDocuments(loanFileId))
        }, 5000)
        isPolling = true
      } else {
        isPolling = false
        // fetch documents when there are no more incomplete lendingQB documents
        dispatch(fetchAttachments({ loanFileId }))

        // clear selected ids after successful poll
        dispatch(toggleDocumentIds(null))

        // Turn of spinners for sync with LendingQB
        dispatch(syncingWithLendingQB(false))
      }

      const metadata = metadataFromLendingQBDocuments(documents)
      dispatch(setLendingQbDocumentsMetadata(metadata))
    }).catch((error) => {
      const { errors } = error?.response?.data || {}

      console.info(errors)
      if (errors) toastrErrors(errors)
    })
  }
}
