import _ from 'underscore'
import AT from '../actionTypes'

const initialState = {
  attachments: [],
  percentage: 0,
  loading: false,
  fileUploadModal: false,
  saving: false,
}

export default function v2FileSelector(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case AT.SET_ATTACHMENT_LOADING:
      return {
        ...state,
        loading: payload,
      }

    case AT.SET_ATTACHMENT_SAVING:
      return {
        ...state,
        saving: payload,
      }

    case AT.TOGGLE_FILE_UPLOAD_MODAL: {
      return {
        ...state,
        fileUploadModal: payload,
      }
    }

    case AT.TOGGLE_ATTACHMENT_MODAL: {
      return {
        ...state,
        attachmentModal: payload,
      }
    }

    case AT.ADD_SELECTED_ATTACMENT:
      return {
        ...state,
        attachments: [
          ...state.attachments,
          payload,
        ],
      }

    case AT.REMOVE_SELECTED_ATTACMENT: {
      const index = _.indexOf(state.attachments, payload)
      return {
        ...state,
        attachments: [
          ...state.attachments.slice(0, index),
          ...state.attachments.slice(index + 1),
        ],
      }
    }

    case AT.RESET_ATTACHMENTS: {
      return {
        ...state,
        attachments: [],
        loading: false,
        saving: false,
      }
    }

    default:
      return state
  }
}
