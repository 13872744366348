import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'

export const indexFavoriteQuestion = createAsyncThunk(
  'bi/favoriteQuestion/index',
  async () => {
    const { data } = await JSONAPIClient.all('bi_favorite_question').get({ sort: '-created_at' })
    return data
  }
)

export const createFavoriteQuestion = createAsyncThunk(
  'bi/favoriteQuestion/create',
  async (payload) => {
    const { data } = await JSONAPIClient.create('bi_favorite_question', payload)
    return data
  }
)

export const destroyFavoriteQuestion = createAsyncThunk(
  'bi/favoriteQuestion/destroy',
  async (id) => {
    const { data } = await JSONAPIClient.destroy('bi_favorite_question', id)
    return data
  }
)
