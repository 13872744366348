import api from 'utils/api'
import Route from 'Services/Route'
import { createActionsFromActionTypes } from 'utils/reducer_utils'
import { toastrErrors } from 'utils/error_utils'
import { plaidLinkToken as actionTypes } from '../actionTypes'

const reducerActions = createActionsFromActionTypes(actionTypes)

export function fetchPlaidLinkToken({ products, itemId, useTeamSettings }) {
  return async (dispatch, getState) => {
    const url = Route.api.plaid.linkToken()
    try {
      const res = await api(getState).post(url, { products, itemId, useTeamSettings })
      if (products) {
        dispatch(reducerActions.setStagedRecord(res.data))
      } else if (itemId) {
        dispatch(reducerActions.set({ [itemId]: res.data }))
      }
    } catch (error) {
      toastrErrors('Something went wrong! We are looking into it!')
    }
  }
}

export function fetchPlaidAccounts(token, onSuccess, onError) {
  return async (dispatch, getState) => {
    const url = Route.api.plaid.balances()
    try {
      const res = await api(getState).post(url, { token })
      const { accounts } = res.data
      onSuccess(accounts)
    } catch (error) {
      onError()
      toastrErrors('Something went wrong! We are looking into it!')
    }
  }
}
