import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'

export const indexParticipantAPI = createAsyncThunk(
  'participant/index',
  async ({ loanFileId }) => {
    const response = await JSONAPIClient
      .one('loan_file', loanFileId)
      .all('participants')
      .get({ filter: { loanFileId, role: ['borrower', 'coborrower'] } })

    // Filter out NBO participants
    return response.data.filter(p => !p.metadata?.nbo)
  }
)

export const getBorrowerParticipantAPI = createAsyncThunk(
  'participant/borrower/index',
  async ({ loanFileId }) => {
    const response = await JSONAPIClient
      .one('loan_file', loanFileId)
      .all('participants')
      .get({ filter: { loanFileId, role: ['borrower'] } })

    // Filter out NBO participants
    return response.data.filter(p => !p.metadata?.nbo)
  }
)

export const createParticipantAPI = createAsyncThunk(
  'participant/create',
  async (payload) => {
    const { meta, ...attributes } = payload
    const { data } = await JSONAPIClient.create('participant', attributes, {}, meta)
    return data
  }
)

export const updateParticipantAPI = createAsyncThunk(
  'participant/update',
  async (payload, thunkAPI) => {
    try {
      const { meta, ...attributes } = payload
      const { data } = await JSONAPIClient.update('participant', attributes, {}, meta)
      return data
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data)
    }
  }
)

export const destroyParticipantAPI = createAsyncThunk(
  'participant/destroy',
  async (participantId) => {
    await JSONAPIClient.one('participant', participantId).destroy()
    return { id: participantId }
  }
)
