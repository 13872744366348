import { preApprovalLetterTemplates as actionTypes } from '../../actionTypes/'

const initialState = []

export default function records(state = initialState, action) {
  const {
    type,
    payload,
  } = action

  switch (type) {
    case actionTypes.set: {
      return payload
    }
    case actionTypes.create: {
      return [...state, payload]
    }
    default:
      return state
  }
}
