import JSONAPIClient from '../JSONAPIClient'

JSONAPIClient.define('disclosures_task', {
  status: '',
  vendor: '',
  vendor_package_id: '',
  title: '',
  subtitle: '',
  note: '',
  document_id: '',
  due_date: '',
  disclosures_task_items: {
    jsonApi: 'hasMany',
    type: 'disclosures_task_items',
  },
})
