import { find } from 'underscore'

const perform = (loanAppTemplateQuestions, question, value, updateResponse) => {
  handleMortgageTypeIsRefinance(
    loanAppTemplateQuestions,
    question,
    value,
    updateResponse
  )

  handleBorrowerCurrentAddressRentOrOwnUpdated(
    loanAppTemplateQuestions,
    question,
    value,
    updateResponse,
  )
}

const handleMortgageTypeIsRefinance = (loanAppTemplateQuestions, question, value, updateResponse) => {

  const { name } = question
  if (name === 'mortgage_type' && value === 'refinance') {
    let tempQuestion = find(loanAppTemplateQuestions.records, rec => rec.name === 'current_stay')
    if (tempQuestion) {
      updateResponse(tempQuestion, 'own_home')
    }
    tempQuestion = find(loanAppTemplateQuestions.records, rec => rec.name === 'borrower_selling_home')
    if (tempQuestion) {
      updateResponse(tempQuestion, 'no')
    }
  }
}

const handleBorrowerCurrentAddressRentOrOwnUpdated = (loanAppTemplateQuestions, question, value, updateResponse) => {
  const { name } = question

  if (name === 'borrower_current_address_rent_or_own') {
    let currentStay
    if (value === 'own') {
      currentStay = 'own_home'
    } else {
      currentStay = 'rent'
    }
    let tempQuestion = find(loanAppTemplateQuestions.records, rec => rec.name === 'current_stay')
    if (tempQuestion) {
      updateResponse(tempQuestion, currentStay)
    }
    tempQuestion = find(loanAppTemplateQuestions.records, rec => rec.name === 'borrower_selling_home')
    if (currentStay === 'own_home' && tempQuestion) {
      updateResponse(tempQuestion, 'no')
    }
  }
}

export default { perform }
