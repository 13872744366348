import JSONAPIClient from 'apiV2/apiClient/JSONAPIClient'

JSONAPIClient.define(
  'item', {
    category_id: '',
    slug: '',
    title: '',
    description: '',
    metabase_url: '',
    demo: '',
    demo_info: {},
    premium_access: '',
    configured: '',
    premium_bundle: '',
  },
  { collectionPath: 'bi/reports/items' },
)
