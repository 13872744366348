import { assetVerificationTasks as ActionTypes } from '../../actionTypes'

export default function currentRecord(state = {}, action) {
  const { type, payload } = action
  switch (type) {
    case ActionTypes.setCurrentRecord:
      return payload
    default:
      return state
  }
}
