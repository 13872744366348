import api from 'utils/api'
import Route from 'Services/Route'
import toastr from 'utils/toastr'
import { toastrErrors } from 'utils/error_utils'

import { updateOrganization } from './organizations'

import AT from '../actionTypes/'

export function fetchMasterLoanFileTemplates() {
  return (dispatch, getState) => {
    const url = Route.api.masterLoanFileTemplates.index()

    const req = api(getState).get(url)

    req.then((res) => {
      const { templates } = res.data
      dispatch(setMasterLoanFileTemplates(templates))
    }).catch((error) => {
      const { errors } = error?.response?.data || {}

      console.info(errors)
      if (errors) toastrErrors(errors)
    })
  }
}

export function fetchLoanFileTemplates(teamId = null) {
  return (dispatch, getState) => {

    const url = Route.api.loanFileTemplates.index()

    const req = api(getState).get(url, { params: { team_id: teamId } })

    req.then((res) => {
      const { templates } = res.data
      dispatch(setLoanFileTemplates(templates))
    }).catch((error) => {
      const { errors } = error?.response?.data || {}

      console.info(errors)
      if (errors) toastrErrors(errors)
    })
  }
}

export function cloneMasterLoanFileTemplate() {
  return (dispatch, getState) => {
    const { stagedRecord } = getState().v2.masterLoanFileTemplates

    const url = Route.api.masterLoanFileTemplates.clone(stagedRecord)

    const req = api(getState).post(url)

    req.then((res) => {
      const { loanFileTemplate } = res.data

      toastr.success(`Successfully cloned ${loanFileTemplate.displayTitle}`)

      dispatch(addMasterLoanFileTemplate(loanFileTemplate))
      dispatch(toggleMasterLoanFileTemplateCloneModal(false))
      dispatch(setStagedMasterLoanFileTemplate({}))
    }).catch((error) => {
      const { errors } = error?.response?.data || {}

      if (errors) toastrErrors(errors)
    })
  }
}

export function destroyMasterLoanFileTemplate() {
  return (dispatch, getState) => {
    const { stagedRecord } = getState().v2.masterLoanFileTemplates

    const url = Route.api.masterLoanFileTemplates.destroy(stagedRecord)
    const req = api(getState).delete(url)

    req.then((res) => {
      const { loanFileTemplate } = res.data

      toastr.success(`Successfully removed ${loanFileTemplate.displayTitle}`)

      dispatch(removeMasterLoanFileTemplate(loanFileTemplate))
      dispatch(toggleMasterLoanFileTemplateDestroyModal(false))
      dispatch(setStagedMasterLoanFileTemplate({}))
    }).catch((error) => {
      const { errors } = error?.response?.data || {}

      if (errors) toastrErrors(errors)
    })
  }
}

export function createMasterLoanFileTemplate() {
  return (dispatch, getState) => {
    const { stagedRecord } = getState().v2.masterLoanFileTemplates

    const url = Route.api.masterLoanFileTemplates.create()

    const req = api(getState).post(url, stagedRecord)

    req.then((res) => {
      const { loanFileTemplate } = res.data

      toastr.success(`Successfully added ${loanFileTemplate.displayTitle}`)

      dispatch(addMasterLoanFileTemplate(loanFileTemplate))
      dispatch(toggleMasterLoanFileTemplateNewModal(false))
      dispatch(setStagedMasterLoanFileTemplate({}))
    }).catch((error) => {
      const { errors } = error?.response?.data || {}

      if (errors) toastrErrors(errors)
    })
  }
}

export function toggleMasterLoanFileTemplateLock() {
  return (dispatch, getState) => {
    const { stagedRecord } = getState().v2.masterLoanFileTemplates

    const url = Route.api.masterLoanFileTemplates.lock()

    const req = api(getState).post(url, stagedRecord)

    req.then((res) => {
      const { templates, template } = res.data

      const sampleMasterTemplate = templates[0]

      const masterLockedStatus = sampleMasterTemplate.masterLocked ? 'locked' : 'unlocked'

      toastr.success(`Successfully ${masterLockedStatus} ${template.displayTitle}`)

      dispatch(setMasterLoanFileTemplates(templates))
      setStagedMasterLoanFileTemplate({})
    }).catch((error) => {
      const { errors } = error?.response?.data || {}

      if (errors) toastrErrors(errors)
    })
  }
}


export function toggleMasterLoanFileTeamTemplateLock() {
  return (dispatch, getState) => {
    const url = Route.api.masterLoanFileTemplates.teamLock()

    const req = api(getState).post(url)

    req.then((res) => {
      const { organization } = res.data

      toastr.success('Successfully updated team loan file templates')

      dispatch(updateOrganization(organization))
      dispatch(setStagedMasterLoanFileTemplate({}))
    }).catch((error) => {
      const { errors } = error?.response?.data || {}

      if (errors) toastrErrors(errors)
    })
  }
}

export function addMasterLoanFileTemplate(payload) {
  return { type: AT.ADD_MASTER_LOAN_FILE_TEMPLATE, payload: payload }
}

export function setStagedMasterLoanFileTemplate(loanFileTemplate) {
  return { type: AT.SET_STAGED_MASTER_LOAN_FILE_TEMPLATE, payload: loanFileTemplate }
}

export function updateStagedMasterLoanFileTemplate(updates) {
  return { type: AT.UPDATE_STAGED_MASTER_LOAN_FILE_TEMPLATE, payload: updates }
}

export function removeMasterLoanFileTemplate(loanFileTemplate) {
  return { type: AT.REMOVE_MASTER_LOAN_FILE_TEMPLATE, payload: loanFileTemplate }
}

export function setLoanFileTemplates(payload) {
  return { type: AT.SET_LOAN_FILE_TEMPLATES, payload: payload }
}

export function setMasterLoanFileTemplates(payload) {
  return { type: AT.SET_MASTER_LOAN_FILE_TEMPLATES, payload: payload }
}

export function toggleMasterLoanFileTemplateCloneModal(payload = false) {
  return { type: AT.TOGGLE_MASTER_LOAN_FILE_TEMPLATE_CLONE_MODAL, payload: payload }
}

export function toggleMasterLoanFileTemplateNewModal(payload = false) {
  return { type: AT.TOGGLE_MASTER_LOAN_FILE_TEMPLATE_NEW_MODAL, payload: payload }
}

export function toggleMasterLoanFileTemplateDestroyModal(payload = false) {
  return { type: AT.TOGGLE_MASTER_LOAN_FILE_TEMPLATE_DESTROY_MODAL, payload: payload }
}
