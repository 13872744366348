import JSONAPIClient from '../JSONAPIClient'

JSONAPIClient.define('loan_app_task', {
  app_id: '',
  due_date: '',
  loan_file_id: '',
  note: '',
  template_id: '',
  title: '',
  display_due_date: '',
  due_date_highlight_class: '',
  type: '',
  status: '',
})
