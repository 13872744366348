import api from 'utils/api'
import Route from 'Services/Route'
import URLSearchParams from '@ungap/url-search-params'

import { createAction } from 'utils/reducer_utils'
import { getUserId, getUserInfo } from 'v2/selectors/page_info'

import AT from 'v2/actionTypes'

import { setOnboardingStep } from './lender_onboarding'
import { popToast } from './system_notifications'

export const setTwoFactorCode = createAction(AT.SET_TWO_FACTOR_CODE)
export const setTwoFactorEnabled = createAction(AT.SET_TWO_FACTOR_ENABLED)
export const setTwoFactorPhone = createAction(AT.SET_TWO_FACTOR_PHONE)
export const addFlashAlert = createAction(AT.ADD_FLASH_ALERT)
export const toggleMobilePhoneVerified = createAction(AT.TOGGLE_MOBILE_PHONE_VERIFIED)
export const toggleTwoFactorCodeRequired = createAction(AT.TOGGLE_TWO_FACTOR_CODE_REQUIRED)
export const toggleUpdatePhone = createAction(AT.TOGGLE_UPDATE_PHONE)

const toggleTwoFactorUpdating = createAction(AT.TOGGLE_TWO_FACTOR_UPDATING)
const toggleTwoFactorCancelling = createAction(AT.TOGGLE_TWO_FACTOR_CANCELLING)

export const toggleTwoFactorAuth = (bool) => async (dispatch, getState) => {
  try {
    const userId = getUserId(getState())
    const url = Route.api.twoFactorAuths.toggle()
    const params = {
      userId,
      twoFactorEnabled: bool,
    }

    dispatch(toggleTwoFactorCodeRequired(false))

    const res = await api(getState).post(url, params)

    const {
      verify,
      notice,
    } = res.data

    dispatch(popToast({ notice }))
    dispatch(addFlashAlert({ notice }))
    dispatch(setTwoFactorEnabled(bool))
    if (verify) {
      dispatch(toggleUpdatePhone(true))
    }

  } catch (error) {
    const { errors } = error?.response?.data || {}
    if (errors) {
      dispatch(popToast({ error: errors }))
      dispatch(addFlashAlert({ error: errors }))
    }
  }
}

export const requestTwoFactorCode = (phone) => async (dispatch, getState) => {
  try {
    const {
      type,
      id: userId,
    } = getUserInfo(getState())

    const url = Route.api.twoFactorAuths.send_otp()
    const formattedPhone = phone.replace(/\D/g, '')

    const params = {
      userId,
      user: { phone: formattedPhone },
    }

    dispatch(setTwoFactorCode(null))
    dispatch(toggleTwoFactorUpdating(true))

    const res = await api(getState).post(url, params)

    const { notice } = res.data
    const flashAlert = { notice }
    dispatch(addFlashAlert(flashAlert))
    dispatch(popToast({ notice }))
    dispatch(setTwoFactorPhone(phone))
    dispatch(toggleMobilePhoneVerified(false))
    dispatch(toggleUpdatePhone(false))
    dispatch(toggleTwoFactorCodeRequired(true))
    dispatch(toggleTwoFactorUpdating(false))
    if (type === 'lender') {
      dispatch(setOnboardingStep('verifyOtp'))
    }

  } catch (error) {
    const { errors } = error?.response?.data || {}
    if (errors) {
      const flashAlert = { error: errors }
      dispatch(addFlashAlert(flashAlert))
      dispatch(popToast({ error: errors }))
    }
    dispatch(toggleTwoFactorUpdating(false))
  }
}

export const reRequestTwoFactorCode = () => async (dispatch, getState) => {
  try {
    const userId = getUserId(getState())
    const url = Route.api.twoFactorAuths.resend_otp()
    const params = { userId }

    dispatch(setTwoFactorCode(null))
    dispatch(toggleTwoFactorUpdating(true))

    const res = await api(getState).post(url, params)

    const { notice } = res.data
    const flashAlert = { notice }
    dispatch(addFlashAlert(flashAlert))
    dispatch(popToast({ notice }))
    dispatch(toggleTwoFactorUpdating(false))
  } catch (e) {
    const { errors } = e.response.data
    if (errors) {
      const flashAlert = { error: errors }
      dispatch(addFlashAlert(flashAlert))
      dispatch(popToast({ error: errors }))
    }
    dispatch(toggleTwoFactorUpdating(false))
  }
}

export const confirmTwoFactorCode = (code, { redirect, loanAppId }) => async (dispatch, getState) => {
  try {
    const {
      type,
      id: userId,
    } = getUserInfo(getState())
    const url = Route.api.twoFactorAuths.confirm_otp()
    const params = {
      userId,
      user: { otp_attempt: code },
    }

    dispatch(toggleTwoFactorUpdating(true))

    const res = await api(getState).post(url, params)

    const { notice, alert } = res.data
    const flashAlert = { notice, alert }

    dispatch(addFlashAlert(flashAlert))
    dispatch(popToast({ notice, alert }))

    if (notice) {
      if (type === 'lender') {
        dispatch(setOnboardingStep('profile'))
      } else if (redirect) {
        redirectUser({ userId, loanAppId })
      } else {
        dispatch(toggleTwoFactorCodeRequired(false))
        dispatch(toggleMobilePhoneVerified(true))
        dispatch(toggleTwoFactorUpdating(false))
      }
    } else {
      dispatch(toggleTwoFactorUpdating(false))
    }
  } catch (error) {
    const { errors } = error?.response?.data || {}
    if (errors) {
      const flashAlert = { error: errors }
      dispatch(addFlashAlert(flashAlert))
      dispatch(popToast({ error: errors }))
    }
    dispatch(toggleTwoFactorUpdating(false))
  }
}

export const cancelUpdatePhone = (loanAppId) => (dispatch, getState) => {
  const {
    type,
    id: userId,
  } = getUserInfo(getState())
  dispatch(toggleTwoFactorCancelling(true))
  if (type === 'lender') {
    dispatch(setOnboardingStep('Profile'))
    dispatch(toggleTwoFactorUpdating(false))
  } else {
    redirectUser({ userId, loanAppId })
  }
  dispatch(toggleTwoFactorUpdating(false))
}

export const cancelVerificationCode = () => (dispatch, getState) => {
  const { type } = getUserInfo(getState())
  dispatch(toggleTwoFactorCodeRequired(false))
  dispatch(toggleUpdatePhone(true))
  if (type === 'lender') {
    dispatch(setOnboardingStep('updatePhone'))
  }
}

const redirectUser = ({ userId, loanAppId }) => {
  const urlSearchParams = new URLSearchParams(window.location.search)
  if (urlSearchParams.get('loan_app') === 'true') {
    window.location.href = Route.borrowers.loanApps.show({ borrowerId: userId, id: loanAppId })
  } else {
    window.location.href = Route.borrowers.root({ id: userId })
  }
}
