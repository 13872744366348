const initialState = {
  selectedReassigningFromTeam: undefined,
  selectedReassignFile: undefined,
  selectedReassignToTeam: undefined,
}

export default function stagedRecord(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case 'SET_REASSIGNING_TEAM':
      return {
        ...state,
        selectedReassignFile: payload.selectedReassignFile,
        selectedReassigningFromTeam: payload.selectedReassigningFromTeam,
      }
    case 'SET_REASSIGN_TO_TEAM':
      return { ...state, selectedReassignToTeam: payload }
    default:
      return state
  }
}
