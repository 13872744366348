import { createAsyncThunk } from '@reduxjs/toolkit'
import { getPageInfo } from 'v2/selectors/page_info'
import { indexScenarioAnswersAPI } from 'apiV2/store/externalConfigs/aus/scenarioAnswers/asyncActions'
import JSONAPIClient from 'apiV2/apiClient'
import { indexSectionAPI } from '../section/asyncActions'
import { indexAnswerAPI } from '../answer/asyncActions'
import { indexParticipantAPI } from '../participant/asyncActions'
import { indexTaskItemAPI } from '../taskItem/asyncActions'

export const showLoanAppAPI = createAsyncThunk(
  'loanApp/show',
  async ({ loanAppId, fetchTemplate = true }, thunkAPI) => {
    const { ausInfo } = getPageInfo(thunkAPI.getState())
    let response = {}

    if (ausInfo) {
      response = await JSONAPIClient.one('aus/loan_app', loanAppId).get()
    } else {
      response = await JSONAPIClient.one('loan_app', loanAppId).get()
    }

    if (fetchTemplate) {
      const {
        data: {
          templateId,
          loanFileId,
          primaryParticipantId,
          currentParticipantId,
        },
      } = response
      const participantId = currentParticipantId || primaryParticipantId

      await Promise.all([
        thunkAPI.dispatch(indexParticipantAPI({ loanFileId })),
        thunkAPI.dispatch(indexSectionAPI({ templateId })),
        thunkAPI.dispatch(indexTaskItemAPI({ loanAppId, participantId, include: 'section_reviews' })),
        ausInfo
          ? thunkAPI.dispatch(indexScenarioAnswersAPI({ scenarioId: ausInfo.scenarioId, participantId }))
          : thunkAPI.dispatch(indexAnswerAPI({ loanAppId, participantId })),
      ])
      return response.data
    }
    return response.data
  }
)

export const updateLoanAppAPI = createAsyncThunk(
  'loanApp/update',
  async ({ loanAppId, payload }) => {
    const response = await JSONAPIClient.update('loan_app', { id: loanAppId, ...payload })
    return response.data
  }
)

export const destroyLoanAppAPI = createAsyncThunk(
  'loanApp/destroy',
  async (loanAppId) => {
    await JSONAPIClient.one('loan_app', loanAppId).destroy()
    return { id: loanAppId }
  }
)
