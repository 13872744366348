import AT, { lossLoanNumber } from '../../actionTypes'

const initialState = { syncingWithLOSS: false, showLossLoanNumberEditModal: false }

export default function uiFlags(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case AT.SYNCING_WITH_LOSS: {
      return { ...state, syncingWithLOSS: payload }
    }
    case lossLoanNumber.validateLossLoanNumber: {
      return { ...state, validatingLossLoanNumber: payload }
    }
    case lossLoanNumber.toggleLossLoanNumberEditModal: {
      return { ...state, showLossLoanNumberEditModal: payload }
    }
    default: {
      return state
    }
  }
}
