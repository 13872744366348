import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'
import { indexTaskItemAPI, showTaskItemAPI, updateTaskItemAPI } from './asyncActions'

const taskItemSlice = createSlice({
  name: 'loan_app_task_item',
  initialState: baseInitialState,
  reducers: { ...baseReducers },
  extraReducers: {
    [indexTaskItemAPI.pending](state) {
      state.uiFlags.isFetching = true
    },
    [indexTaskItemAPI.fulfilled](state, action) {
      state.uiFlags.isFetching = false
      state.records = action.payload
      if (action.payload.length === 1) {
        [state.stagedRecord] = action.payload
      } else if (action.meta.arg.participantId && action.payload.length > 1) {
        // stage coborrower task item
        state.stagedRecord = action.payload.find(({ participantId }) => participantId === action.meta.arg.participantId)
      }
    },
    [showTaskItemAPI.pending](state) {
      state.uiFlags.isFetching = true
    },
    [showTaskItemAPI.fulfilled](state, action) {
      state.stagedRecord = action.payload
      state.uiFlags.isFetching = false
    },
    [showTaskItemAPI.rejected](state) {
      state.uiFlags.isFetching = false
    },
    [indexTaskItemAPI.rejected](state) {
      state.uiFlags.isFetching = false
    },
    [updateTaskItemAPI.pending](state) {
      state.uiFlags.isUpdating = true
    },
    [updateTaskItemAPI.fulfilled](state, action) {
      state.uiFlags.isUpdating = false
      state.stagedRecord = action.payload
    },
    [updateTaskItemAPI.rejected](state) {
      state.uiFlags.isUpdating = false
    },
  },
})

export default taskItemSlice
