import { combineReducers } from 'redux'
import uiFlags from './uiFlags'
import stagedRecord from './stagedRecord'
import currentRecord from './currentRecord'

export default combineReducers({
  uiFlags,
  stagedRecord,
  currentRecord,
})
