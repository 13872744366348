import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'
import {
  createLoanNoteAPI,
  showLoanNoteAPI,
  indexLoanNoteAPI,
  destroyLoanNoteAPI,
  destroyLoanNoteFromSidAPI,
} from './asyncActions'

const loanNotesSlice = createSlice({
  name: 'loanNote',
  initialState: baseInitialState,
  reducers: baseReducers,
  extraReducers: {
    [indexLoanNoteAPI.pending](state) {
      state.uiFlags.isFetching = true
    },
    [indexLoanNoteAPI.fulfilled](state, action) {
      state.uiFlags.isFetching = false
      state.records = action.payload
    },
    [indexLoanNoteAPI.rejected](state) {
      state.uiFlags.isFetching = false
    },
    [showLoanNoteAPI.pending](state) {
      state.uiFlags.isFetchingItem = true
    },
    [showLoanNoteAPI.fulfilled](state, action) {
      const { id } = action.payload
      const index = state.records.findIndex(item => String(item.id) === String(id))
      if (index > -1) {
        state.records[index] = action.payload
      } else {
        state.records.push(action.payload)
      }
      state.uiFlags.isFetchingItem = false
    },
    [showLoanNoteAPI.rejected](state) {
      state.uiFlags.isFetchingItem = false
    },
    [createLoanNoteAPI.pending](state) {
      state.uiFlags.isCreating = true
    },
    [createLoanNoteAPI.fulfilled](state, action) {
      state.records.push(action.payload)
      state.uiFlags.isCreating = false
    },
    [createLoanNoteAPI.rejected](state) {
      state.uiFlags.isCreating = false
    },
    [destroyLoanNoteAPI.pending](state) {
      state.uiFlags.isDeleting = true
    },
    [destroyLoanNoteAPI.fulfilled](state, action) {
      const { id } = action.payload
      state.records = state.records.filter(r => String(r.id) !== String(id))
      state.uiFlags.isDeleting = false
    },
    [destroyLoanNoteAPI.rejected](state) {
      state.uiFlags.isDeleting = false
    },
    [destroyLoanNoteFromSidAPI.pending](state) {
      state.uiFlags.isDeleting = true
    },
    [destroyLoanNoteFromSidAPI.fulfilled](state) {
      state.uiFlags.isDeleting = false
    },
    [destroyLoanNoteFromSidAPI.rejected](state) {
      state.uiFlags.isDeleting = false
    },
  },
})

export default loanNotesSlice
export const loanFileReducer = loanNotesSlice.reducer
