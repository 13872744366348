import JSONAPIClient from '../JSONAPIClient'

JSONAPIClient.define('consent', {
  loan_file_id: '',
  user_id: '',
  name: '',
  email: '',
  ip_address: '',
  created_at: '',
  consent_type: '',
})
