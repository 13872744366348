import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'

export const createAUSSettingAPI = createAsyncThunk(
  'aus/setting/create',
  async ({ loanFileId, creditTaskId, payload }) => {
    const { data } = await JSONAPIClient.request(
      '/api/v2/aus/settings',
      'POST',
      { loanFileId, creditTaskId },
      {
        type: 'settings',
        attributes: {
          ...payload,
          loanFileId,
          creditTaskId,
        },
      },
    )
    return data
  }
)

export const showAUSSettingAPI = createAsyncThunk(
  'aus/setting/show',
  async ({ id }) => {
    try {
      const response = await JSONAPIClient.request(
        '/api/v2/aus/settings/:id',
        'GET',
        { id },
      )
      return response.data
    } catch {
      return {}
    }
  }
)

export const deleteAUSSettingAPI = createAsyncThunk(
  'aus/setting/delete',
  async ({ id }) => {
    await JSONAPIClient.request(
      `/api/v2/aus/settings/${id}`,
      'DELETE',
    )
    return id
  }
)

export const updateAUSCreditLiabilityAPI = createAsyncThunk(
  'aus/credit_liabilities/update',
  async ({ ausCreditLiabilityId, ausScenarioAnswerId }) => {
    const { data } = await JSONAPIClient.request(
      `/api/v2/aus/credit_liabilities/${ausCreditLiabilityId}`,
      'PATCH',
      { ausCreditLiabilityId },
      {
        id: ausCreditLiabilityId,
        type: 'credit_liabilities',
        attributes: { ausScenarioAnswerId: ausScenarioAnswerId },
      },
    )
    return data
  }
)
