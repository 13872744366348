import { sortBy, isString, find } from 'underscore'
import { capitalize } from 'underscore.string'

export function sortList(collection, key, sortCriteria = defaultSortCriteria) {
  const sortedLoanFiles = sortBy(collection, (obj) => {
    return sortCriteria(obj, key)
  })

  return loanFilePositionKey(sortedLoanFiles, collection)
}

export function sortCollection(collection, currentSortKey, sortCriteria = defaultSortCriteria) {
  return sortBy(collection, (obj) => {
    return sortCriteria(obj, currentSortKey)
  })
}

export function findCurrSortMetaDataFromSortKey(sortMetaData, currentSortKey) {
  return find(sortMetaData, (obj) => {
    return obj.sortKey === currentSortKey ? obj : null
  })
}

// Private
function defaultSortCriteria(obj, key) {
  if (isString(obj[key])) {
    return capitalize(obj[key])
  } else {
    return obj[key]
  }
}

// Returns array of positions to where loanFile is located in active/closed/archived loanfiles array
function loanFilePositionKey(sortedLoanFiles = [], originalLoanFiles = []) {
  return sortedLoanFiles.map((loanFile) => {
    return originalLoanFiles.findIndex(i => i.id === loanFile.id)
  })
}
