import JSONAPIClient from '../../JSONAPIClient'

JSONAPIClient.define(
  'scenario',
  {
    id: '',
    loan_file_id: '',
    title: '',
    scenario_type: '',
    app_id: '',
    base_scenario_id: '',
  },
  { collectionPath: 'aus/scenarios' }
)
