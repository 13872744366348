import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'

export const showLenderAPI = createAsyncThunk(
  'lender/show',
  async ({ lenderId, shareToken = null }) => {
    const response = await JSONAPIClient.one('lender', lenderId).get({ shareToken })

    return response.data
  }
)
