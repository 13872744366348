import AT from '../actionTypes'

export function toggleTaskTemplatePicker(payload) {
  return { type: AT.TOGGLE_TASK_TEMPLATE_PICKER_MODAL, payload: payload }
}

export function toggleTaskTypePicker(payload) {
  return { type: AT.TOGGLE_TASK_TYPE_PICKER_MODAL, payload: payload }
}


export function showVerificationTaskPicker() {
  return (dispatch) => {
    dispatch(toggleTaskTypePicker(false))
    dispatch(toggleVerificationTaskPicker(true))
  }
}

export function hideVerificationTaskPicker() {
  return (dispatch) => {
    dispatch(toggleTaskTypePicker(false))
    dispatch(toggleVerificationTaskPicker(false))
  }
}
export function toggleVerificationTaskPicker(payload) {
  return { type: AT.TOGGLE_VERIFICATION_TASK_PICKER_MODAL, payload: payload }
}
