import { employmentIncomeVerificationTasks as actionTypes } from '../../actionTypes'

export default function position(state = 0, action) {
  const { type, payload } = action

  switch (type) {
    case actionTypes.nextResearchedPosition: {
      return payload
    }
    default: {
      return state
    }
  }
}
