import JSONAPIClient from '../../JSONAPIClient'

JSONAPIClient.define('credit', {
  credit_vendor_id: '',
  internal_account_identifier: '',
  owner_id: '',
  owner_type: '',
  password: '',
  username: '',
  nickname: '',
})
