import toastr from 'utils/toastr'
import { toastrErrors } from 'utils/error_utils'
import AT from '../../actionTypes/'
import { create, update } from '../../BackendRequest/LoanAppTemplateSection'
import { toggleOverlaySpinner } from '../globalUI'

export function setLoanAppTemplateSections(loanAppTemplateSections) {
  return { type: AT.SET_LOAN_APP_TEMPLATE_SECTIONS, payload: loanAppTemplateSections }
}

function editLoanAppTemplateSection(loanAppTemplateSection) {
  return { type: AT.EDIT_LOAN_APP_TEMPLATE_SECTION, payload: loanAppTemplateSection }
}

export function createLoanAppTemplateSection() {
  return (dispatch, getState) => {
    dispatch(toggleOverlaySpinner(true, 'Creating section...'))
    const loanAppTemplateSection = getState().v2.loanAppTemplateSections.stagedRecord
    const template = getState().v2.loanAppTemplates.stagedRecord
    // TODO : Use request
    create(loanAppTemplateSection, template.id)
      .done((data) => {
        toastr.success('Loan application template added successfully')
        dispatch({ type: AT.ADD_LOAN_APP_TEMPLATE_SECTION, payload: data.loanAppTemplateSection })
        dispatch(endLoanAppTemplateSectionCreate())
        if (template.id !== data.loanAppTemplateSection.templateId) {
          window.location.reload()
        } else {
          dispatch(toggleOverlaySpinner(false))
          dispatch(setCurrentLoanAppTemplateSection(data.loanAppTemplateSection))
        }
      })
      .fail((data) => {
        dispatch(toggleOverlaySpinner(false))
        toastrErrors(data.responseJSON.errors)
      })
  }
}

export function toggleShowSection(section) {
  return (dispatch, getState) => {
    const changes = { hidden: !section.hidden }
    const template = getState().v2.loanAppTemplates.stagedRecord
    dispatch(editLoanAppTemplateSection({ ...section, ...changes }))
    dispatch(toggleOverlaySpinner(true, 'Updating section...'))
    update(section, changes)
      .done((data) => {
        toastr.success('Section updated successfully!')
        if (template.id !== data.loanAppTemplateSection.templateId) {
          window.location.reload()
        } else {
          dispatch(editLoanAppTemplateSection(data.loanAppTemplateSection))
          dispatch(toggleOverlaySpinner(false))
        }
      })
      .fail((data) => {
        toastrErrors(data.responseJSON.errors)
        dispatch(editLoanAppTemplateSection(section))
        dispatch(toggleOverlaySpinner(false))
      })
  }
}

export function startLoanAppTemplateSectionCreate() {
  return (dispatch) => {
    dispatch(setStagedLoanAppTemplateSection({}))
    dispatch(toggleLoanAppTemplateSectionCreateModal(true))
  }
}

export function endLoanAppTemplateSectionCreate() {
  return (dispatch) => {
    dispatch(toggleLoanAppTemplateSectionCreateModal(false))
    dispatch(setStagedLoanAppTemplateSection({}))
  }
}

export function updateStagedLoanAppTemplateSection(updates) {
  return { type: AT.UPDATE_STAGED_LOAN_APP_TEMPLATE_SECTION, payload: updates }
}


export function setStagedLoanAppTemplateSection(loanAppTemplateSection) {
  return { type: AT.SET_STAGED_LOAN_APP_TEMPLATE_SECTION, payload: loanAppTemplateSection }
}

export function setCurrentLoanAppTemplateSection(loanAppTemplateSection) {
  return { type: AT.SET_CURRENT_LOAN_APP_TEMPLATE_SECTION, payload: loanAppTemplateSection }
}


function toggleLoanAppTemplateSectionCreateModal(payload) {
  return { type: AT.SHOW_CREATE_LOAN_APP_TEMPLATE_SECTION_MODAL, payload: payload }
}
