const initialState = {
  activeTeams: [],
  filteredActiveTeams: [],
  filteredDestinationTeams: [],
  selectedTeams: [],
  selectedTeamsLoanFiles: {},
  filteredSelectedTeamsLoanFiles: {},
}

export default function records(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case 'SET_ACTIVE_TEAMS':
      return {
        ...state,
        activeTeams: payload,
        filteredActiveTeams: payload,
        filteredDestinationTeams: payload,
        selectedTeams: [],
        selectedTeamsLoanFiles: {},
        filteredSelectedTeamsLoanFiles: {},
      }
    case 'SET_FILTERED_ACTIVE_TEAMS':
      return {
        ...state,
        filteredActiveTeams: payload,
      }
    case 'SET_FILTERED_DESTINATION_TEAMS': {
      return {
        ...state,
        filteredDestinationTeams: payload,
      }
    }
    case 'SET_SELECTED_TEAMS_AND_LOANS':
      return {
        ...state,
        selectedTeams: payload.selectedTeams,
        selectedTeamsLoanFiles: payload.selectedTeamsLoanFiles,
        filteredSelectedTeamsLoanFiles: payload.filteredSelectedTeamsLoanFiles,
      }
    case 'SET_FILTERED_SELECTED_TEAM_LOAN_FILES':
      return {
        ...state,
        filteredSelectedTeamsLoanFiles: payload,
      }
    default:
      return state
  }
}
