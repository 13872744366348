import { paymentTasks as actionTypes } from 'v2/actionTypes'

const initialState = {
  showPaymentIntentModal: false,
  isFetchingPaymentIntent: false,
  isUpdatingPaymentIntent: false,
  isPaymentCompleted: false,
}

export default function uiFlags(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case actionTypes.setPaymentIntentLoading:
      return { ...initialState, isFetchingPaymentIntent: payload }
    case actionTypes.togglePaymentIntentModal:
      return { ...state, showPaymentIntentModal: payload }
    case actionTypes.setPaymentIntentUpdating:
      return { ...state, isUpdatingPaymentIntent: payload }
    case actionTypes.setPaymentCompleted:
      return { ...state, isPaymentCompleted: payload }
    default:
      return state
  }
}
