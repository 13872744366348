import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'
import {
  createParticipantAPI,
  destroyParticipantAPI,
  indexParticipantAPI,
  updateParticipantAPI,
  getBorrowerParticipantAPI,
} from './asyncActions'

const participantsSlice = createSlice({
  name: 'participant',
  initialState: baseInitialState,
  reducers: baseReducers,
  extraReducers: {
    [indexParticipantAPI.pending](state) {
      state.uiFlags.isFetching = true
    },
    [indexParticipantAPI.fulfilled](state, action) {
      state.uiFlags.isFetching = false
      state.records = action.payload
    },
    [indexParticipantAPI.rejected](state) {
      state.uiFlags.isFetching = false
    },
    [getBorrowerParticipantAPI.pending](state) {
      state.uiFlags.isFetching = true
    },
    [getBorrowerParticipantAPI.fulfilled](state, action) {
      state.uiFlags.isFetching = false
      state.records = action.payload
    },
    [getBorrowerParticipantAPI.rejected](state) {
      state.uiFlags.isFetching = false
    },
    [createParticipantAPI.pending](state) {
      state.uiFlags.isCreating = true
    },
    [createParticipantAPI.fulfilled](state, action) {
      state.uiFlags.isCreating = false
      state.records.push(action.payload)
    },
    [createParticipantAPI.rejected](state) {
      state.uiFlags.isCreating = false
    },
    [updateParticipantAPI.pending](state) {
      state.uiFlags.isUpdating = true
    },
    [updateParticipantAPI.fulfilled](state, action) {
      state.uiFlags.isUpdating = false
      state.records = state.records.map(record => (record.id === action.payload.id ? action.payload : record))
    },
    [updateParticipantAPI.rejected](state) {
      state.uiFlags.isUpdating = false
    },
    [destroyParticipantAPI.pending](state) {
      state.uiFlags.isDeleting = true
    },
    [destroyParticipantAPI.fulfilled](state, action) {
      state.uiFlags.isDeleting = false
      const { payload: { id: participantId } } = action
      state.records = state.records.filter(p => p.id !== participantId)
    },
    [destroyParticipantAPI.rejected](state) {
      state.uiFlags.isDeleting = false
    },
  },
})

export default participantsSlice
