import AT from '../../actionTypes/'

const initialState = {
  loadingText: 'Loading...',
}

export default function uiFlags(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case AT.TOGGLE_TEXT_MESSAGE_CHANGE_CONFIRMATION_MODAL:
      return { ...state, showTextMessagesChangeConfirmationModal: payload }
    case AT.TOGGLE_TEXT_MESSAGE_CHANGES:
      return { ...state, submittingTextMessageChanges: payload }
    default:
      return state
  }
}
