import { invert } from 'lodash'
import { splitName } from '../utils/string'
import { getAllLoanAppTemplateQuestionRecords, getLoanAppResponseValueByQuestionName } from '../v2/selectors/loan_apps'
import { findQuestionByName } from '../utils/loan_app_utils'
import { updateResponse } from '../v2/actions/loan_app/responses'
import { setLoanFileStagedRecordAttributes } from '../v2/actions/loan_files'

// In the New Client workflow, this service object provides 2-way sync between certain fields
// in the 'Client Info' form and corresponding fields in the loan app.
export default class ClientInfoAndLoanAppSyncService {
  // Mapping of LoanFile field => Loan App question name
  static get fieldMapping() {
    return { /* eslint-disable key-spacing */
      loanType:        'mortgage_type',
      borrowerName:    'borrower_full_name',
      borrowerPhone:   'borrower_phone_number',
      hasCoborrower:   'has_coborower',
      coborrowerEmail: 'coborrower_email',
      coborrowerName:  'coborrower_full_name',
    }
  }

  // dispatch - Redux dispatch function
  // state - Redux state tree (from `getState()`)
  constructor(dispatch, state) {
    this.dispatch = dispatch
    this.state = state
  }

  // Prepopulates all of the pertinent loan app questions in one go.
  prepopulateLoanApp() {
    const { stagedRecord } = this.state.v2LoanFiles

    // We get all questions instead of just visible ones since the value of some like 'Has Coborrower'
    // will determine the visbility of others.
    const questions = getAllLoanAppTemplateQuestionRecords(this.state)

    Object
      .values(this.constructor.fieldMapping)
      .map(questionName => findQuestionByName(questions, questionName))
      .filter(question => question) // Remove missing questions (some custom loan apps may not have every question)
      .forEach(question => {
        this.dispatch(
          updateResponse(question, { target: { value: this.valueForLoanAppQuestion(question, stagedRecord) } })
        )
      })
  }

  // Sets loan file attribute based on response to `questionName` in loan app
  syncResponseValueToLoanFile(questionName) {
    const reverseMapping = invert(this.constructor.fieldMapping)
    const loanFileFieldName = reverseMapping[questionName] || reverseMapping[`ulad_${questionName}`]

    const responseValue = getLoanAppResponseValueByQuestionName(this.state, { questionName })

    const loanFileValue = this.valueForLoanFile(loanFileFieldName, responseValue)
    this.dispatch(setLoanFileStagedRecordAttributes({ [loanFileFieldName]: loanFileValue }))
  }

  // Translates a value from the Client Info data (`stagedRecord`) into the corresponding value for populating
  // a particular `loanAppQuestion`
  valueForLoanAppQuestion = (loanAppQuestion, stagedRecord) => {
    switch (loanAppQuestion.name) {
      case 'mortgage_type':
        return stagedRecord.loanType === 'refinance' ? 'refinance' : 'purchase'
      case 'ulad_mortgage_type':
        return stagedRecord.loanType === 'refinance' ? 'Refinance' : 'Purchase'
      case 'borrower_full_name':
      case 'ulad_borrower_full_name':
        return loanAppQuestion.questionType === 'text'
          ? stagedRecord.borrowerName
          : splitName(stagedRecord.borrowerName)
      case 'borrower_phone_number':
      case 'ulad_borrower_phone_number':
        return { cell: stagedRecord.borrowerPhone }
      case 'has_coborower':
        return stagedRecord.hasCoborrower ? 'yes' : 'no'
      case 'coborrower_email':
      case 'ulad_coborrower_email':
        return stagedRecord.coborrowerEmail || ''
      case 'coborrower_full_name':
      case 'ulad_coborrower_full_name':
        return loanAppQuestion.questionType === 'text'
          ? stagedRecord.coborrowerName
          : splitName(stagedRecord.coborrowerName)
      default:
        console.error('Attempted to get value for unknown loan app question', loanAppQuestion)
        return null
    }
  }

  // Translates a loan app response (`responseValue`) into the corresponding value for the given
  // Client Info `fieldName`
  valueForLoanFile = (fieldName, responseValue) => {
    switch (fieldName) {
      case 'loanType':
        return /refinance/i.test(responseValue || '') ? 'refinance' : 'new_purchase'
      case 'borrowerName':
      case 'coborrowerName':
        if (!responseValue) {
          return ''
        }
        return (typeof responseValue === 'object')
          ? [responseValue.first, responseValue.last].join(' ').trim()
          : responseValue
      case 'borrowerPhone':
        return ((responseValue || {}).cell || '').replace(/\D/g, '')
      case 'hasCoborrower':
        return responseValue === 'yes'
      case 'coborrowerEmail':
        return responseValue || ''
      default:
        console.error('Attempted to get value for unknown loan file field', fieldName)
        return null
    }
  }
}
