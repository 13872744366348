import { replace } from 'Services/ImmutatingHelpers'
import { teams as actionTypes } from '../../actionTypes'

export default function records(state = [], action) {
  const { type, payload } = action

  switch (type) {
    case actionTypes.set: {
      return payload
    }
    case actionTypes.create: {
      return [...state, payload]
    }
    case actionTypes.update: {
      const index = state.findIndex(record => record.id === payload.id)
      return replace(state, index, payload)
    }
    default:
      return state
  }
}
