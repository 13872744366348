import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'

export const indexLoanNoteAPI = createAsyncThunk(
  'loanNote/index',
  async ({ loanFileId }) => {
    const response = await JSONAPIClient.one('loan_file', loanFileId).all('loan_notes').get({ include: ['lender'] })
    return response.data
  }
)

export const destroyLoanNoteFromSidAPI = createAsyncThunk(
  'loanNote/destroy/sid',
  async ({ loanFileId, twilioMessageSid }, thunkApi) => {
    const response = await JSONAPIClient
      .one('loan_file', loanFileId)
      .all('loan_notes')
      .get({ filter: { twilio_message_sid: twilioMessageSid } })

    if (response.data.length) {
      const [loanNote] = response.data
      thunkApi.dispatch(destroyLoanNoteAPI({ loanFileId, loanNoteId: loanNote.id }))
    }
  }
)

export const showLoanNoteAPI = createAsyncThunk(
  'loanNote/show',
  async ({ loanFileId, loanNoteId }) => {
    const response = await JSONAPIClient
      .one('loan_file', loanFileId)
      .one('loan_note', loanNoteId)
      .get({ include: ['lender'] })
    return response.data
  }
)

export const createLoanNoteAPI = createAsyncThunk(
  'loanNote/create',
  async ({ loanFileId, payload }) => {
    const response = await JSONAPIClient
      .one('loan_file', loanFileId)
      .all('loan_note')
      .post(payload, { include: ['lender'] })
    return response.data
  }
)

export const destroyLoanNoteAPI = createAsyncThunk(
  'loanNote/destroy',
  async ({ loanFileId, loanNoteId }) => {
    await JSONAPIClient.one('loan_file', loanFileId).one('loan_note', loanNoteId).destroy()
    return { id: loanNoteId }
  }
)
