import { duCaseFiles } from '../../actionTypes'

const initialState = { isCreating: false }


export default function (state = initialState, action) {
  switch (action.type) {

    case duCaseFiles.setCreatingCaseFile:
      return {
        ...state,
        isCreating: action.payload,
      }

    default:
      return state
  }
}
