import AT, { borrowerNewLoanModal } from 'v2/actionTypes'

const initialState = {
  overlaySpinner: { message: null, show: false },
  showBorrowerNewLoanModal: false,
}

export default function globalUI(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case AT.TOGGLE_OVERLAY_SPINNER: {
      const overlaySpinner = { message: payload.message, show: payload.show }
      return { ...state, overlaySpinner }
    }
    case borrowerNewLoanModal.show: {
      return { ...state, showBorrowerNewLoanModal: payload }
    }
    default:
      return state
  }
}
