import AT from '../../actionTypes'

const initialState = { loadingText: 'Loading...' }

export default function uiFlags(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case AT.SHOW_CREATE_LOAN_APP_TEMPLATE_MODAL:
      return { ...state, showCreateModal: payload }
    case AT.SHOW_UPDATE_LOAN_APP_TEMPLATE_MODAL:
      return { ...state, showUpdateModal: payload }
    case AT.SHOW_DESTROY_LOAN_APP_TEMPLATE_MODAL:
      return { ...state, showDestroyModal: payload }
    case AT.LOAN_APP_TEMPLATE_LOADING:
      return { ...state, loading: payload }
    case AT.TOGGLE_LOAN_APP_LOADING_TEXT:
      return { ...state, loadingText: payload }
    case AT.TOGGLE_CREATE_LOAN_APP_TEMPLATE_MODAL_SUBMITTING:
      return { ...state, createModalSubmitting: payload }
    case AT.TOGGLE_UPDATE_LOAN_APP_TEMPLATE_MODAL_SUBMITTING:
      return { ...state, updateModalSubmitting: payload }
    case AT.TOGGLE_DESTROY_LOAN_APP_TEMPLATE_MODAL_SUBMITTING:
      return { ...state, destroyModalSubmitting: payload }
    case AT.SHOW_CLONE_LOAN_APP_TEMPLATE_MODAL:
      return { ...state, showCloneModal: payload }
    case AT.TOGGLE_CLONE_LOAN_APP_TEMPLATE_MODAL_SUBMITTING:
      return { ...state, cloneModalSubmitting: payload }
    default:
      return state
  }
}
