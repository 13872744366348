import AT from '../../actionTypes/'

const initialState = {
  showTaskNotificationEmailModal: false,
  loading: false,
  sending: false,
}

export default function uiFlags(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case AT.TOGGLE_TASK_NOTIFICATION_EMAIL_MODAL:
      return { ...state, showTaskNotificationEmailModal: payload }
    case AT.SET_TASK_NOTIFICATIONS_LOADING:
      return { ...state, loading: payload }
    case AT.SET_TASK_NOTIFICATIONS_SENDING:
      return { ...state, sending: payload }
    default:
      return state
  }
}
