import toastr from 'utils/toastr'
import { popToast } from 'v2/actions/system_notifications'
import { toastrErrors } from 'utils/error_utils'
import api from 'utils/api'
import Route from 'Services/Route'
import AT from '../actionTypes'
import { fetchCommunications } from './communications'

export function setSignableDocuments(signableDocuments) {
  return { type: AT.SET_SIGNABLE_DOCUMENTS, signableDocuments }
}

export function updateSignableDocument(signableDocument, updates, updateType) {
  return (dispatch, getState) => {
    const { loanFile } = getState().v2LoanFiles
    const data = { signable_document: updates }

    const url = Route.api.signableDocuments.update(
      { id: loanFile.id, documentId: signableDocument.id },
    )

    const req = api(getState).put(url, data)

    req.then((res) => {
      const sd = res.data.signableDocument
      const successMessage = updateType === 'resend'
        ? 'Signature request resent'
        : `Successfully updated ${sd.document_key}`
      toastr.success(successMessage)
      dispatch(popToast({ notice: successMessage }))
      dispatch({ type: AT.UPDATE_SIGNABLE_DOCUMENT, signableDocument: sd })
      dispatch(hideSignableDocumentEditModal())
      dispatch(fetchCommunications(loanFile.id))
    }).catch((error) => {
      const { errors } = error?.response?.data || {}

      console.info(errors)
      if (errors) {
        toastrErrors(errors)
        if (typeof errors === 'string') {
          dispatch(popToast({ error: errors }))
        } else {
          dispatch(popToast({ error: 'There was an error updating the document' }))
        }
      }
    })
  }
}

export function destroySignableDocument(signableDocument) {
  return (dispatch, getState) => {
    const { loanFile } = getState().v2LoanFiles

    const url = Route.api.signableDocuments.destroy(
      { id: loanFile.id, documentId: signableDocument.id },
    )

    const req = api(getState).delete(url)

    req.then((res) => {
      const sd = res.data.signableDocument

      toastr.success(`Successfully deleted ${signableDocument.document_key}`)
      dispatch({ type: AT.DESTROY_SIGNABLE_DOCUMENT, signableDocument: sd })
      dispatch(hideSignableDocumentDestroyModal())
    }).catch((error) => {
      const { errors } = error?.response?.data || {}

      console.info(errors)
      if (errors) {
        toastrErrors(errors)

        if (typeof errors === 'string') {
          dispatch(popToast({ error: errors }))
        } else {
          dispatch(popToast({ error: 'There was an error deleting the document' }))
        }
      }
    })
  }
}

export function showSignableDocumentCreateModal() {
  return { type: AT.SHOW_SIGNABLE_DOCUMENT_CREATE_MODAL }
}

export function hideSignableDocumentCreateModal() {
  return { type: AT.HIDE_SIGNABLE_DOCUMENT_CREATE_MODAL }
}

export function showSignableDocumentEditModal(signableDocument) {
  return { type: AT.SHOW_SIGNABLE_DOCUMENT_EDIT_MODAL, signableDocumentId: signableDocument.id }
}

export function hideSignableDocumentEditModal() {
  return { type: AT.HIDE_SIGNABLE_DOCUMENT_EDIT_MODAL }
}

export function showSignableDocumentResendModal(signableDocument) {
  return { type: AT.SHOW_SIGNABLE_DOCUMENT_RESEND_MODAL, signableDocumentId: signableDocument.id }
}

export function hideSignableDocumentResendModal() {
  return { type: AT.HIDE_SIGNABLE_DOCUMENT_RESEND_MODAL }
}

export function showSignableDocumentDestroyModal(signableDocument) {
  return { type: AT.SHOW_SIGNABLE_DOCUMENT_DESTROY_MODAL, signableDocumentId: signableDocument.id }
}

export function hideSignableDocumentDestroyModal() {
  return { type: AT.HIDE_SIGNABLE_DOCUMENT_DESTROY_MODAL }
}
