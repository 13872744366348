import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'
import { indexSectionAPI, updateSectionAPI } from './asyncActions'

const sectionSlice = createSlice({
  name: 'section',
  initialState: baseInitialState,
  reducers: {
    ...baseReducers,
    setFinalReview(state, action) {
      state.uiFlags.finalReview = action.payload
    },
  },
  extraReducers: {
    [indexSectionAPI.pending](state, action) {
      const { meta: { arg: { background } } } = action
      if (!background) state.uiFlags.isFetching = true
    },
    [indexSectionAPI.fulfilled](state, action) {
      state.uiFlags.isFetching = false
      state.records = action.payload
    },
    [indexSectionAPI.rejected](state) {
      state.uiFlags.isFetching = false
    },
    [updateSectionAPI.pending](state) {
      state.uiFlags.isUpdating = true
    },
    [updateSectionAPI.fulfilled](state) {
      state.uiFlags.isUpdating = false
    },
    [updateSectionAPI](state) {
      state.uiFlags.isUpdating = false
    },
  },
})

export default sectionSlice
