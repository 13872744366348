/* eslint-disable maxwell/no-generic-id-in-api-routes */
import api from 'utils/api'
import toastr from 'utils/toastr'

import Route from 'Services/Route'
import { fuzzySearchLoanFiles } from 'utils/loan_file_utils'
import { createAction } from 'utils/reducer_utils'
import { toastrErrors } from 'utils/error_utils'
import { getLoanFile, getRedirectUrl } from 'v2/selectors/loan_files'
import { getUserInfo, getLenderSlug } from 'v2/selectors/page_info'
import { getLoanAppStagedRecord } from 'v2/selectors/loan_apps'
import { fetchIncompleteTasks, fetchCompleteTasks } from 'v2/actions'
import { popToast } from './system_notifications'

import AT from '../actionTypes'
import { fetchCommunications } from './communications'
import { updateLoanFileTaskTemplate } from './document_group'
import { processInterviewLoanApp, setStagedLoanApp } from './loan_app/loan_app'
import ClientInfoAndLoanAppSyncService from '../../Services/ClientInfoAndLoanAppSyncService'

export const setFilteredLoanFiles = createAction(AT.SET_FILTERED_LOAN_FILES)
export const setActiveLoanFiles = createAction(AT.SET_ACTIVE_LOAN_FILES)
export const setClosedLoanFiles = createAction(AT.SET_CLOSED_LOAN_FILES)
export const setArchivedLoanFiles = createAction(AT.SET_ARCHIVED_LOAN_FILES)
export const setActiveLoanFileTab = createAction(AT.SET_ACTIVE_LOAN_FILE_TAB)
export const setLoanFile = createAction(AT.SET_LOAN_FILE)
export const updateLoanFileLocally = createAction(AT.UPDATE_LOAN_FILE)
export const setLoanFileStagedRecord = createAction(AT.SET_LOAN_FILE_STAGED_RECORD)
export const setLoanFileStagedRecordAttributes = createAction(AT.SET_LOAN_FILE_STAGED_RECORD_ATTRIBUTES)
export const setNewClientStep = createAction(AT.SET_NEW_CLIENT_STEP)
export const toggleCreatingNewLoanFile = createAction(AT.CREATING_NEW_LOAN_FILE)
export const setCurrentSortBy = createAction(AT.SET_CURRENT_SORT_BY)
export const setDefaultSortBy = createAction(AT.SET_DEFAULT_SORT_BY)
export const toggleNewClientSubmitting = createAction(AT.NEW_CLIENT_SUBMITTING)
export const sendLoanFileStatusEmailSuccess = createAction(AT.SEND_LOAN_FILE_STATUS_EMAIL_SUCCESS)

export function toggleNewClientModal(bool = false) {
  return { type: AT.TOGGLE_NEW_CLIENT_MODAL, payload: bool }
}

export function toggleEditLoanFileModal(payload) {
  return { type: AT.TOGGLE_EDIT_LOAN_FILE_MODAL, payload: payload }
}

export function toggleLoadingActiveLoanFiles(loading = false) {
  return { type: AT.LOADING_ACTIVE_LOAN_FILES, payload: loading }
}

export function toggleLoadingLoanFile(loading = false) {
  return { type: AT.LOADING_LOAN_FILE, payload: loading }
}

export function toggleLoadingClosedLoanFiles(loading = false) {
  return { type: AT.LOADING_CLOSED_LOAN_FILES, payload: loading }
}

export function toggleLoadingArchivedLoanFiles(loading = false) {
  return { type: AT.LOADING_ARCHIVED_LOAN_FILES, payload: loading }
}

export const handleSendLoanFileStatusEmail = (loanFileId, emailCopy) => (dispatch, getState) => {
  dispatch(toggleNewClientSubmitting(true))
  dispatch(sendLoanFileStatusEmail(loanFileId, emailCopy)).then(() => {
    window.location = getRedirectUrl(getState())
  })
}

export function sendLoanFileStatusEmail(loanFileId, emailCopy) {
  return async (dispatch, getState) => {
    const loanFile = getLoanFile(getState())
    const lId = loanFileId || loanFile.id

    const url = Route.api.loanFile.statusEmail({ loanFileId: lId })

    const params = { email_copy: emailCopy }
    try {
      const response = await api(getState).post(url, params)
      const { redirectUrl } = response.data
      dispatch(sendLoanFileStatusEmailSuccess(redirectUrl))
    } catch (error) {
      console.info(error)
      if (error) toastrErrors(error)
    }
  }
}

export function createLoanFile(data) {
  return (dispatch, getState) => {
    dispatch(toggleCreatingNewLoanFile(true))

    const url = Route.api.loanFiles.create()
    const req = api(getState).post(url, data)
    return req.then(res => {
      const { loanFile } = res.data
      dispatch(setLoanFile(loanFile))

      dispatch(toggleCreatingNewLoanFile(false))
    }).catch(error => {
      console.error(error)
      const { errors } = error?.response?.data || {}
      if (errors) toastrErrors(errors)
      dispatch(toggleCreatingNewLoanFile(false))
      throw error
    })
  }
}

const READER_MAPPING = {
  active: 'activeLoanFiles',
  closed: 'closedLoanFiles',
  archived: 'archivedLoanFiles',
}

export function fetchLoanFilesByStatus(teamId, status, override = true) {
  return async (dispatch, getState) => {
    const loanFiles = getState().v2LoanFiles[READER_MAPPING[status]]
    const fetchable = override || !loanFiles.length

    switch (status) {
      case 'closed':
        if (fetchable) dispatch(resetClosedLoanFiles(teamId))
        break

      case 'archived':
        if (fetchable) dispatch(resetArchivedLoanFiles(teamId))
        break

      default:
        if (fetchable) await dispatch(resetActiveLoanFiles(teamId))
        break
    }
  }
}

function resetActiveLoanFiles(teamId) {
  return async (dispatch) => {
    dispatch(fetchActiveLoanFiles(teamId)).then(() => {
      dispatch(filterLoanFiles(''))
    })
  }
}

function resetArchivedLoanFiles(teamId) {
  return (dispatch) => {
    dispatch(
      fetchArchivedLoanFiles(teamId)
    ).then(() => {
      dispatch(filterLoanFiles(''))
    })
  }
}

function resetClosedLoanFiles(teamId) {
  return (dispatch) => {
    dispatch(fetchClosedLoanFiles(teamId)).then(() => {
      dispatch(filterLoanFiles(''))
    })
  }
}

// Filter loan files by search term
export function filterLoanFiles(searchTerm, fuzzySearchOptions) {
  return (dispatch, getState) => {
    const { v2LoanFiles: { activeLoanFileTab } } = getState()
    const isSearchTerm = (typeof searchTerm !== 'undefined' && searchTerm.length > 0)

    const loanFiles = getState().v2LoanFiles[READER_MAPPING[activeLoanFileTab]]
    const userInfo = getUserInfo(getState())
    let payload
    if (isSearchTerm) {
      const fuzzySearchResults = fuzzySearchLoanFiles(loanFiles, searchTerm, fuzzySearchOptions)
      const fuzzedLoanFiles = fuzzySearchResults.map(({ item, matches }) => {
        return { ...item, matchData: matches }
      })

      payload = {
        userInfo,
        loanFiles: fuzzedLoanFiles,
        preSorted: true,
      }
    } else {
      payload = { userInfo, loanFiles }
    }
    dispatch(setFilteredLoanFiles(payload))
  }
}

export function fetchArchivedLoanFiles(teamId = null) {
  return async (dispatch, getState) => {
    dispatch(toggleLoadingArchivedLoanFiles(true))

    const params = { params: { team_id: teamId } }
    const userInfo = getUserInfo(getState())

    try {
      const url = Route.api.loanFiles.archived()
      const res = await api(getState).get(url, params)
      const { data: { loanFiles } } = res

      dispatch(setArchivedLoanFiles({ loanFiles, userInfo }))
      dispatch(toggleLoadingArchivedLoanFiles(false))
    } catch (e) {
      console.error(e)
    }
  }
}

export function fetchClosedLoanFiles(teamId = null) {
  return async (dispatch, getState) => {
    dispatch(toggleLoadingClosedLoanFiles(true))

    const params = { params: { team_id: teamId } }
    const url = Route.api.loanFiles.closed()
    const userInfo = getUserInfo(getState())

    try {
      const res = await api(getState).get(url, params)
      const { loanFiles } = res.data

      dispatch(setClosedLoanFiles({ loanFiles, userInfo }))
      dispatch(toggleLoadingClosedLoanFiles(false))
    } catch (e) {
      console.error(e)
    }
  }
}

export function fetchActiveLoanFiles(teamId = null) {
  return async (dispatch, getState) => {
    dispatch(toggleLoadingActiveLoanFiles(true))

    const params = { params: { team_id: teamId } }
    const url = Route.api.loanFiles.active()

    try {
      const res = await api(getState).get(url, params)
      const { loanFiles } = res.data
      const userInfo = getUserInfo(getState())

      dispatch(setActiveLoanFiles({ loanFiles, userInfo }))
      dispatch(toggleLoadingActiveLoanFiles(false))
    } catch (e) {
      console.error(e)
    }
  }
}

export function updateLoanFileStatus(params) {
  return (dispatch, getState) => {
    const id = getState().v2LoanFiles.loanFile.id || params.loanFileId
    // something about status and email copy
    const data = {
      email_copy: params.emailCopy,
      borrower_email_copy: params.borrowerEmailCopy,
      status: params.status,
      bcc_email_address: params.bccEmailAddress,
    }

    const url = Route.api.loanFile.status({ id })
    const req = api(getState).put(url, data)

    req.then((res) => {
      const { loanFile } = res.data

      dispatch(setLoanFile(loanFile))
      const message = `The loan file status has been updated to ${loanFile.displayStatus}`
      toastr.success(message)
      // Reload new communication
      dispatch(fetchCommunications(id))
    }).catch((error) => {
      const { errors } = error?.response?.data || {}

      console.info(errors)
      if (errors) toastrErrors(errors)
    })
  }
}

export function fetchLoanFile(id) {
  return async (dispatch, getState) => {
    dispatch(toggleLoadingLoanFile(true))
    const url = Route.api.loanFiles.show({ id: id })
    const req = api(getState).get(url)

    return req.then((res) => {
      const { loanFile } = res.data
      dispatch(setLoanFile(loanFile))
      dispatch(toggleLoadingLoanFile(false))
    }).catch((error) => {
      console.info(error)
      const { errors } = error?.response?.data || {}
      if (errors) toastrErrors(errors)
    })
  }
}

export function updateLoanFile(loanFileId, values) {
  return (dispatch, getState) => {
    const url = Route.api.loanFiles.update({ loanFileId })
    const req = api(getState).put(url, values)

    return req
      .then((res) => {
        const { loanFile } = res.data
        dispatch(setLoanFile(loanFile))
        const message = 'The loan file has been updated'
        toastr.success(message)
        dispatch(popToast({ notice: message }))
        dispatch(toggleEditLoanFileModal(false))
        dispatch(fetchCompleteTasks(loanFileId))
        dispatch(fetchIncompleteTasks(loanFileId))
        window.location.reload()
      })
      .catch((error) => {
        console.error(error)

        const errors = error?.response?.data || null

        if (errors) {
          toastrErrors(errors)
          const popToastError = typeof errors === 'string' ? errors : 'There was an error updating the loan file'
          dispatch(popToast({ error: popToastError }))
        }
      })
  }
}

export function removeRealtorFromLoanFile(loanFile, agent) {
  return (dispatch, getState) => {
    const url = Route.api.loanFileRealtor.destroy(
      {
        loanFileId: loanFile.id,
        realtorId: agent.id,
      }
    )

    const req = api(getState).delete(url)

    req.then((res) => {
      dispatch(setLoanFile(res.data.loanFile))
      const message = 'Realtor removed from loan file'
      toastr.success(message)
    }).catch((error) => {
      console.info(error)

      const errors = error?.response?.data || null

      if (errors) toastrErrors(errors)
    })
  }
}

export function processNewClientSavePoint() {
  return async (dispatch, getState) => {
    const state = getState()

    // Transform the data from the temporary `stagedRecord` structure we've built up to the finalized one that gets
    // sent to the backend. The final loan file will live in `loanFile` and `stagedRecord` will be cleared.
    const { stagedRecord } = state.v2LoanFiles

    const {
      borrowerFirstName,
      borrowerMiddleName,
      borrowerLastName,
      borrowerSuffix,
      borrowerEmail,
      borrowerPhone,
      loanAmount,
      loanType,
      realtors,
      hasCoborrower,
      coborrowerFirstName,
      coborrowerMiddleName,
      coborrowerLastName,
      coborrowerSuffix,
      coborrowerEmail,
      coborrowerJoint,
      coborrowerMarried,
      coborrowerNonoccupant,
      coborrowerNotifications,
      coborrowerWithoutAccount,
      sharedEmail,
      teamId,
      templateId,
    } = stagedRecord
    const data = {
      user: {
        first_name: borrowerFirstName,
        middle_name: borrowerMiddleName,
        last_name: borrowerLastName,
        suffix: borrowerSuffix,
        email: borrowerEmail,
        phone: borrowerPhone,
      },
      loan_file: {
        loan_amount: loanAmount,
        loan_type: loanType,
        team_id: teamId,
      },
    }

    data.template_id = templateId

    if (realtors && realtors.length > 0) {
      data.realtor = realtors.map(({ name, email }) => {
        return { name, email }
      })
    }
    if (hasCoborrower) {
      data.coborrower = {
        first_name: coborrowerFirstName,
        middle_name: coborrowerMiddleName,
        last_name: coborrowerLastName,
        suffix: coborrowerSuffix,
        email: coborrowerEmail,

        // For these checkbox-based values, legacy backend code expects "on" or nil
        coborrower_without_account: coborrowerWithoutAccount || null,
        nonoccupant: coborrowerNonoccupant || null,
        notifications: coborrowerNotifications || null,
        joint: coborrowerJoint || coborrowerMarried || null,
        sharedEmail: sharedEmail || null,
        married: coborrowerMarried || null,
      }
    }

    const documentGroups = state.v2.documentGroups /* eslint-disable-line */
    dispatch(toggleNewClientSubmitting(true))
    try {
      await dispatch(createLoanFile(data))
      await dispatch(updateLoanFileTaskTemplate(documentGroups.selectedTaskTemplates))

      const { templateId: stagedLoanAppTemplate } = getLoanAppStagedRecord(getState())
      if (stagedLoanAppTemplate) {
        await dispatch(processInterviewLoanApp())
      }

      dispatch(setNewClientStep('reviewEmail'))

      // Clear the stagedRecords only after the next step is shown to avoid re-validation and emptying out of the
      // UI fields
      dispatch(setLoanFileStagedRecord({}))
      dispatch(setStagedLoanApp({}))

      dispatch(toggleNewClientSubmitting(false))
    } catch (_) {
      dispatch(toggleNewClientSubmitting(false))
    }
  }
}

export function prepopulateLoanAppQuestionsFromNewClientInfoForm() {
  return (dispatch, getState) => {
    const clientInfoLoanAppSyncService = new ClientInfoAndLoanAppSyncService(dispatch, getState())
    clientInfoLoanAppSyncService.prepopulateLoanApp()
  }
}

export function syncLoanAppResponseToNewClientInfo(questionName) {
  return (dispatch, getState) => {
    const clientInfoLoanAppSyncService = new ClientInfoAndLoanAppSyncService(dispatch, getState())
    clientInfoLoanAppSyncService.syncResponseValueToLoanFile(questionName)
  }
}

export const setPersistDashboardSort = ({ sortKey, sortDirection, filters }) => async (dispatch, getState) => {
  const url = Route.api.lenders.lenderSettings.create({ lenderId: getLenderSlug(getState()) })
  try {
    await api(getState).post(url, { dashboard_sort: { sort_key: sortKey, sortDirection, filters } })
  } catch (error) {
    // not handling this error state
  }
}

export function sendBorrowerPasswordReset({ email }) {
  return async (dispatch, getState) => {
    const url = Route.api.passwords.recover()
    try {
      await api(getState).post(url, { email, type: 'User' })
      toastr.success('A password reset email has been successfully sent to the borrower.')
    } catch (error) {
      if (error.response?.status === 429) {
        toastr.error('Password reset link has already been sent to the borrower. Please wait 5 minutes.')
      } else {
        toastr.error(
          'The password reset email could not be sent. If this issue persists, please contact support for assistance.'
        )
      }
    }
  }
}
