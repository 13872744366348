import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'
import { showLoanOfficerTeam } from './asyncActions'

export const teamSlice = createSlice({
  name: 'loanOfficerTeam',
  initialState: baseInitialState,
  reducers: baseReducers,
  extraReducers: {
    [showLoanOfficerTeam.pending](state) {
      state.uiFlags.isFetching = true
    },
    [showLoanOfficerTeam.fulfilled](state, action) {
      state.stagedRecord = action.payload.data
      state.uiFlags.isFetching = false
    },
    [showLoanOfficerTeam.rejected](state) {
      state.uiFlags.isFetching = false
    },
  },
})

export default teamSlice.reducer
