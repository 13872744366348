import JSONAPIClient from '../JSONAPIClient'

JSONAPIClient.define(
  'setting',
  {
    loan_file_id: '',
    credit_task_id: '',
  },
  { collectionPath: 'aus/settings' }
)
