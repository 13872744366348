import { paymentsHistory as actionTypes } from 'v2/actionTypes'

const initialState = {
  loading: false,
  showRefundModal: false,
  updating: false,
}

export default function uiFlags(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case actionTypes.loading:
      return { ...state, loading: payload }
    case actionTypes.showRefundModal:
      return { ...state, showRefundModal: payload }
    case actionTypes.updatingRecord:
      return { ...state, updating: payload }
    default:
      return state
  }
}
