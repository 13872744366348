import Route from 'Services/Route'
import { underscored } from 'underscore.string'
import { map } from 'underscore'

export function create(loanApp, loanFileId) {
  const url = Route.api.loanApp.loanApp.create({ loan_file_id: loanFileId })
  const res = {}

  map(loanApp, (value, key) => {
    res[underscored(key)] = value
  })

  return (
    $.ajax(
      {
        method: 'POST',
        data: { loan_app: res },
        url: url,
      }
    )
  )
}

export function update(loanApp, updates) {
  const url = Route.api.loanApp.loanApp.update(loanApp)
  const res = {}
  map(updates, (value, key) => {
    res[underscored(key)] = value
  })

  return (
    $.ajax(
      {
        method: 'PUT',
        data: { loan_app: res },
        url: url,
      }
    )
  )
}

export function show({ id, loanFileId }) {
  const url = Route.api.loanApp.loanApp.show({ id: id })
  return (
    $.ajax(
      {
        method: 'GET',
        data: { loan_file_id: loanFileId },
        url: url,
      }
    )
  )
}

export function destroy(loanApp) {
  const url = Route.api.loanApp.loanApp.destroy(
    loanApp,
    { loan_file_id: loanApp.loanFileId },
  )

  return ($.ajax({ method: 'DELETE', url: url }))
}

export function submit(loanApp) {
  const url = Route.api.loanApp.loanApp.submit(
    loanApp,
    { loan_file_id: loanApp.loanFileId },
  )

  return ($.ajax({ method: 'POST', url: url }))
}

export function syncResponses(loanApp, loanAppResponses) {
  const url = Route.api.loanApp.loanApp.syncResponses(loanApp)
  return (
    $.ajax(
      {
        method: 'POST',
        data: {
          loan_file_id: loanApp.loanFileId,
          loan_app_id: loanApp.id,
          loan_app_responses: loanAppResponses,
        },
        url: url,
      }
    )
  )
}
