import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'
import { popToast } from 'v2/actions'
import { updateTemplateAPI } from '../template/asyncActions'
import { indexSectionAPI } from '../section/asyncActions'

export const indexQuestionAPI = createAsyncThunk(
  'question/index',
  async ({ templateId, sectionId = null }) => {
    let response
    if (sectionId) {
      response = await JSONAPIClient.one('template', templateId).one('section', sectionId).all('question').get()
    } else {
      response = await JSONAPIClient.one('template', templateId).all('question').get()
    }
    return response.data
  }
)

export const createQuestionAPI = createAsyncThunk(
  'question/create',
  async ({ template, sectionId, payload }, thunkAPI) => {
    try {
      const { id: templateId, optionalSectionIds } = template
      const { questionOptional, ...questionPayload } = payload

      const response = await JSONAPIClient.one('template', templateId)
        .one('section', sectionId)
        .all('question')
        .post(questionPayload, { include: ['sections', 'sections.parent'] })

      if (questionOptional) {
        const { data: { sections: [singlegroup] } } = response
        const { parent: { id: questionGroupId } } = singlegroup
        const templatePayload = { optionalSectionIds: [...optionalSectionIds, Number(questionGroupId)] }
        await thunkAPI.dispatch(updateTemplateAPI({ templateId, payload: templatePayload }))
      }
      await thunkAPI.dispatch(indexSectionAPI({ templateId, background: true }))
      await thunkAPI.dispatch(indexQuestionAPI({ templateId, background: true }))
    } catch (e) {
      return e?.response?.errors
    }
  }
)

export const updateQuestionAPI = createAsyncThunk(
  'question/update',
  async ({ questionId, template, sectionId, payload }, thunkAPI) => {
    try {
      const { id: templateId, optionalSectionIds } = template
      const { questionOptional, ...questionPayload } = payload

      const response = await JSONAPIClient
        .one('template', templateId)
        .one('section', sectionId)
        .one('question', questionId)
        .patch({ id: questionId, ...questionPayload }, { include: ['sections', 'sections.parent'] })

      if (typeof questionOptional === 'boolean') {
        const { data: { sections: [singlegroup] } } = response
        const { parent: { id: questionGroupId } } = singlegroup
        const newOptionalSectionIds = questionOptional
          ? [...optionalSectionIds, Number(questionGroupId)]
          : optionalSectionIds.filter(value => value !== Number(questionGroupId))

        const templatePayload = { optionalSectionIds: newOptionalSectionIds }
        await thunkAPI.dispatch(updateTemplateAPI({ templateId, payload: templatePayload }))
      }
      await thunkAPI.dispatch(indexQuestionAPI({ templateId, background: true }))
      return response.data
    } catch (e) {
      return e?.response?.errors
    }
  }
)

export const deleteQuestionAPI = createAsyncThunk(
  'question/delete',
  async ({ templateId, sectionId, questionId }, thunkAPI) => {
    await JSONAPIClient
      .one('template', templateId)
      .one('section', sectionId)
      .one('question', questionId)
      .destroy()

    await thunkAPI.dispatch(indexSectionAPI({ templateId, background: true }))
    await thunkAPI.dispatch(indexQuestionAPI({ templateId, background: true }))
    thunkAPI.dispatch(popToast({ notice: 'You successfully deleted a question' }))
  }
)
