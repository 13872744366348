import { omit, isEmpty, values, isObject } from 'underscore'

const CURRENT_EMPLOYMENT_KEYS = [
  'current_employer_position',
  'current_employer_name',
  'current_employer_start_date',
]

const PREVIOUS_EMPLOYMENT_KEYS = [
  'previous_employer_position',
  'previous_employer_name',
  'previous_employer_start_date',
  'previous_employer_end_date',
]

const ULAD_QUESTIONS_MAP = {
  borrower_phone_number: 'ulad_borrower_phone_number',
  borrower_dob: 'ulad_borrower_dob',
  borrower_full_name: 'ulad_borrower_full_name',
  borrower_ssn: 'ulad_borrower_ssn',
  borrower_current_address: 'ulad_borrower_current_address',
  current_employer_position: 'ulad_borrower_current_employer_position',
  current_employer_name: 'ulad_borrower_current_employer_name',
  current_employer_start_date: 'ulad_borrower_current_employer_start_date',
  previous_employer_position: 'ulad_borrower_previous_employer_position',
  previous_employer_name: 'ulad_borrower_previous_employer_name',
  previous_employer_start_date: 'ulad_borrower_previous_employer_start_date',
  previous_employer_end_date: 'ulad_borrower_previous_employer_end_date',
  borrower_montly_expenses: 'ulad_borrower_liabilities_accounts',
}

const uladQuestionsMapper = (name) => {
  return ULAD_QUESTIONS_MAP[name] || name
}

const isValueEmpty = (value) => {
  if (isObject(value)) {
    return isObjectKeysEmpty(value)
  }
  return isEmpty(value)
}

const isObjectKeysEmpty = (obj) => {
  const hasValue = values(obj).reduce((acc, value) => acc || !isEmpty(value), false)
  return !hasValue
}

const removeCurrentEmploymentKeys = (responses, questionToIdMapping) => {
  const currentEmploymentKeyIDs = CURRENT_EMPLOYMENT_KEYS.map((key) => questionToIdMapping[key])
  return omit(responses, currentEmploymentKeyIDs)
}

const removePreviousEmploymentKeys = (responses, questionToIdMapping) => {
  const previousEmploymentKeys = PREVIOUS_EMPLOYMENT_KEYS.map((key) => questionToIdMapping[key])
  return omit(responses, previousEmploymentKeys)
}


export const perform = ({
  loanAppResponses,
  loanAppTemplateQuestions,
  isULAD,
  responseValues,
}) => {
  const responseKeys = Object.keys(responseValues)
  if (!responseKeys.length) {
    return {}
  }

  const questionToIdMapping = {}
  let responsesWithQuestionId = {}

  let omitCurrentEmploymentKeys = false
  let omitPreviousEmploymentKeys = false
  responseKeys.forEach(responseKey => {
    const filterQuestion = isULAD ? uladQuestionsMapper(responseKey) : responseKey
    const [question] = loanAppTemplateQuestions.records.filter(q => q.name === filterQuestion)
    if (question) {
      const [response] = loanAppResponses.records.filter(r => r.templateQuestionId === question.id)

      const isResponseEmpty = response && isValueEmpty(response.value)

      if (isResponseEmpty) {
        responsesWithQuestionId[question.id] = responseValues[responseKey]
        questionToIdMapping[question.name] = question.id
      } else if (CURRENT_EMPLOYMENT_KEYS.includes(question.name)) {
        omitCurrentEmploymentKeys = true
      } else if (PREVIOUS_EMPLOYMENT_KEYS.includes(question.name)) {
        omitPreviousEmploymentKeys = true
      }
    }
  })

  if (omitCurrentEmploymentKeys) {
    responsesWithQuestionId = removeCurrentEmploymentKeys(responsesWithQuestionId, questionToIdMapping)
  }

  if (omitPreviousEmploymentKeys) {
    responsesWithQuestionId = removePreviousEmploymentKeys(responsesWithQuestionId, questionToIdMapping)
  }

  return responsesWithQuestionId
}
