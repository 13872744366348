import AT from '../../actionTypes/'

export default function position(state = 0, action) {
  const { type, payload } = action

  switch (type) {
    case AT.NEXT_CREDIT_TASK_POSITION: {
      return state + 1
    }
    default: {
      return state
    }
  }
}
