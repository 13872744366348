import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'

export const createQuickPricerApplyAPI = createAsyncThunk(
  'quickPricerApply/create',
  async (params) => {
    const response = await JSONAPIClient.axios.post('/api/v2/quick_pricer/loan_apps.json', params)
    return response.data
  }
)
