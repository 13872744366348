import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'

export const showLoanFileAPI = createAsyncThunk(
  'loanFile/show',
  async (loanFileId) => {
    const response = await JSONAPIClient.one('loan_file', loanFileId).get()
    return response.data
  }
)

export const indexLoanFileAPI = createAsyncThunk(
  'loanFile/index',
  async () => {
    const response = await JSONAPIClient.all('loan_files').get()
    return response.data
  }
)

export const updateLoanFileAPI = createAsyncThunk(
  'loanFile/update',
  async ({ id, payload }) => {
    const { data } = await JSONAPIClient.update('loan_file', { id, ...payload })
    return data
  }
)

export const createLoanFileAPI = createAsyncThunk(
  'loanFile/create',
  async ({ templateId, lenderSlug, ...payload }) => {
    const { data } = await JSONAPIClient.create('loan_file', payload, {}, { templateId, lenderSlug })
    return data
  }
)
