import cloneDeep from 'lodash/cloneDeep'
import AT, { creditExports } from '../../actionTypes'

export default function records(state = [], action) {
  const { type, payload } = action

  switch (type) {
    case AT.SET_CREDIT_TASKS: {
      return payload
    }
    case creditExports.fetch:
    case creditExports.retry: {
      const { creditExport } = payload
      const { taskId } = creditExport
      const taskIndex = state.findIndex((task) => task.id === taskId)

      const newState = cloneDeep(state)
      newState[taskIndex] = { ...newState[taskIndex], creditExport }
      return newState
    }
    default: {
      return state
    }
  }
}
