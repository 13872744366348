import { combineReducers } from 'redux'

import stagedRecord from './stagedRecord'
import uiFlags from './uiFlags'
import record from './record'

export default combineReducers({
  stagedRecord,
  uiFlags,
  record,
})
