import { createAction } from 'utils/reducer_utils'

import AT from 'v2/actionTypes'

export const popToast = createAction(AT.POP_TOAST)
export const resetToasts = createAction(AT.RESET_TOASTS)
export const popAlert = createAction(AT.POP_ALERT)
export const resetAlerts = createAction(AT.RESET_ALERTS)

export const dismissToast = (index) => async (dispatch, getState) => {
  const { v2: { systemNotifications: { toasts } } } = getState()
  toasts.splice(index, 1)
  dispatch(resetToasts(toasts))
}

export const dismissAlert = (index) => async (dispatch, getState) => {
  const { v2: { systemNotifications: { alerts } } } = getState()
  const newAlerts = [...alerts]
  newAlerts.splice(index, 1)
  dispatch(resetAlerts(newAlerts))
}

export const errorHandler = (e, message) => (dispatch) => {
  if (Array.isArray(e.errors)) {
    return e.errors.map((err) => {
      return dispatch(popToast({ error: err }))
    })
  }

  if (e.response) {
    if (e.response.data) {
      if (Array.isArray(e.response.data.errors)) {
        return e.response.data.errors.map((err) => {
          return dispatch(popToast({ error: err }))
        })
      }
      if (e.response.data.error) {
        return dispatch(popToast({ error: e.response.data.error }))
      }
    } else if (typeof e.response === 'string') {
      return dispatch(popToast({ error: e.response }))
    }
  }

  if (e.message) {
    return dispatch(popToast({ error: e.message }))
  }

  return dispatch(popToast({ error: message }))
}

export const alertHandler = (message) => (dispatch) => {
  return dispatch(popAlert({ error: message }))
}
