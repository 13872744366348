import JSONAPIClient from '../../JSONAPIClient'

JSONAPIClient.define('voei_task', {
  completed: '',
  created_at: '',
  due_date: '',
  employee_alternate_id: '',
  employee_salary_key: '',
  employer_code: '',
  error_code: '',
  error_message: '',
  first_name: '',
  last_name: '',
  latest_request_id: '',
  loan_number: '',
  loan_file_id: '',
  loan_file: {
    jsonApi: 'hasOne',
    type: 'loan_file',
  },
  note: '',
  ssn: '',
  status: '',
  title: '',
  updated_at: '',
  verification_type: '',
})
