import { combineReducers } from 'redux'
import records from './records'
import dirtyRecords from './dirtyRecords'
import errors from './errors'

export default combineReducers({
  records,
  dirtyRecords,
  errors,
})
