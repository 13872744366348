import AT from '../../actionTypes/'

const initialState = {}

export default function currentRecord(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case AT.SET_CURRENT_TEXT_MESSSAGE_SETTINGS:
      return payload
    default:
      return state
  }
}
