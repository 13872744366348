import Route from 'Services/Route'
import api from 'utils/api'
import { pluralize } from 'utils/string'
import toastr from 'utils/toastr'
import { toastrErrors } from 'utils/error_utils'
import { replaceByKey } from 'Services/ImmutatingHelpers'
import { pollLendingQBStatus, setDocument, setDocuments, setLoadingDocument } from '../documents'

export const fetchAttachments = ({ loanFileId, attachmentId, isOnVaultTab }) => async (dispatch, getState) => {
  dispatch(setLoadingDocument(true))
  try {
    const url = Route.api.loanFiles.attachments.index({ loanFileId })
    const { data: { attachments = [] } } = await api(getState).get(url)
    if (attachmentId) {
      const [selectedAttachment] = attachments.filter(attachment => attachment.id === attachmentId)
      dispatch(setDocument(selectedAttachment))
    }
    dispatch(setDocuments(attachments))
    if (isOnVaultTab) {
      dispatch(pollLendingQBStatus({ lendingQBDocuments: attachments, isOnVaultTab, loanFileId }))
    }
  } finally {
    dispatch(setLoadingDocument(false))
  }
}

export const destroyAttachments = ({ loanFileId, attachmentIds = [] }) => async (dispatch, getState) => {
  const url = Route.api.loanFiles.attachments.bulkDelete({ loanFileId })
  try {
    const { data: { attachments } } = await api(getState).delete(url, { data: { attachmentIds } })
    const messageSubject = pluralize(attachmentIds.length, 'Attachment', 'Attachments')
    dispatch(setDocuments(attachments))
    toastr.success(`${messageSubject} successfully removed`)
  } catch (error) {
    const { errors } = error?.response?.data || {}
    if (errors) toastrErrors(errors)
  }
}

export const updateAttachmentName = ({ loanFileId, attachmentId, data }) => async (dispatch, getState) => {
  const url = Route.api.loanFiles.attachments.update({ loanFileId, attachmentId })

  try {
    await api(getState).patch(url, { blob: data })
  } catch (error) {
    const { errors } = error?.response?.data || {}
    if (errors) toastrErrors(errors)
  }
}

export function updateAttachmentStatus(attachment, status, params = { reason: null, los_type: null }) {
  return async (dispatch, getState) => {
    try {
      const { loanFileId, id: attachmentId } = attachment
      const url = Route.api.loanFiles.attachments[status]({ loanFileId, attachmentId })
      const { data: { attachment: updatedAttachment } } = await api(getState).post(url, params)
      const { documents } = getState().v2Documents
      const newDocs = replaceByKey(documents, 'id', updatedAttachment)
      dispatch(setDocuments(newDocs))
      dispatch(setDocument(updatedAttachment))

      const message = `Document marked as ${status}`
      toastr.success(message)
    } catch (error) {
      const message = `Failed to mark document as ${status}. Please try again.`

      toastrErrors(message)
    }
  }
}
