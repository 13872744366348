import { createAsyncThunk } from '@reduxjs/toolkit'
import { errorHandler, popToast } from 'v2/actions/system_notifications'
import JSONAPIClient from 'apiV2/apiClient'

export const createAuthAPI = createAsyncThunk(
  'quickApplyAuth/create',
  async ({ loanAppId, payload, errorMessage }, thunkAPI) => {
    try {
      const response = await JSONAPIClient.one('loan_app', loanAppId).all('quick_apply').post(payload)
      return response.data
    } catch (e) {
      thunkAPI.dispatch(popToast({ warning: errorMessage }))
      return e.response.data
    }
  }
)

export const updateAuthAPI = createAsyncThunk(
  'quickApplyAuth/update',
  async ({ loanAppId, payload, errorMessage }, thunkAPI) => {
    try {
      let attempts = +localStorage.getItem('updateAuthAttempts')
      attempts += 1
      if (attempts > 3) {
        thunkAPI.dispatch(popToast({ error: errorMessage }))
        localStorage.removeItem('updateAuthAttempts')
        return { status: 'redirect' }
      }
      localStorage.setItem('updateAuthAttempts', attempts)

      const response = await JSONAPIClient.one('loan_app', loanAppId).all('quick_apply')
        .patch(payload, { enable_2fa: payload.enable2FA })
      const { resendOtp } = payload
      if (resendOtp) thunkAPI.dispatch(popToast({ notice: 'Code successfully sent' }))
      return response.data
    } catch (e) {
      if (e.response?.status === 404) {
        thunkAPI.dispatch(popToast({ warning: errorMessage }))
        return { status: 'redirect' }
      }
      thunkAPI.dispatch(errorHandler(e))
      return e.response.data
    }
  }
)
