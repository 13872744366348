import AT from 'v2/actionTypes/'
import { getRequest } from 'utils/api'
import Route from 'Services/Route'
import { createAction } from 'utils/reducer_utils'
import {
  getFinicityConnectUrl,
  getFinicityConnectFixUrl,
} from 'v2/selectors/file_fetch'
import * as Sentry from '@sentry/react'
import { toastrErrors } from 'utils/error_utils'
import toastr from 'utils/toastr'

import { postRequest } from '../../utils/api'

const ApiRoutes = Route.api.loanFile.finicity

const setFinicityConnectUrl = createAction(AT.SET_FINICITY_CONNECT_URL)
const fetchingConnectUrl = createAction(AT.SET_FINICITY_CONNECT_URL_LOADING)
const setFinicityConnectFixUrl = createAction(AT.SET_FINICITY_CONNECT_FIX_URL)
const fetchingConnectFixUrl = createAction(AT.SET_FINICITY_CONNECT_FIX_URL_LOADING)
const setFinicityPendingStatus = createAction(AT.SET_FINICITY_PENDING_STATUS)
const setImportStatementsStatus = createAction(AT.SET_IMPORT_STATEMENTS_STATUS)
const setFinicityIframeVisible = createAction(AT.SET_FINICITY_IFRAME_VISIBLE)

const raiseError = true

export const handleFetchFinicityConnectUrl = ({ loanFileId, taskId }) => async (dispatch) => {
  dispatch(fetchingConnectUrl(true))
  await dispatch(fetchFinicityConnectUrl({ loanFileId, taskId }))
  await dispatch(loadFinicityConnect())
}

export const fetchFinicityConnectUrl = ({ loanFileId, taskId }) => async (dispatch, getState) => {
  try {
    const url = ApiRoutes.connectUrl({ loanFileId, taskId })
    const res = await getRequest({ getState, url, raiseError })
    dispatch(fetchingConnectUrl(false))
    if (res) {
      const { data: { connectUrl } } = res
      dispatch(setFinicityConnectUrl(connectUrl))
    }
  } catch (e) {
    toastrErrors('There was an error loading import statements. Please reload and try again.')
    dispatch(fetchingConnectUrl(false))
    Sentry.withScope(scope => {
      scope.setTag('type', 'FINICITY_LOGGER')
      Sentry.captureException(e)
    })
  }
}

const loadFinicityConnect = () => (dispatch, getState) => {
  const url = getFinicityConnectUrl(getState())
  if (url) {
    dispatch(setFinicityIframeVisible(true))
    window.finicityConnect.launch(url, {
      selector: '#connect-container',
      error: function () {
        dispatch(fetchingConnectUrl(false))
        dispatch(setFinicityIframeVisible(false))
      },
      loaded: function () {
        dispatch(fetchingConnectUrl(false))
      },
      success: function () {
        dispatch(setFinicityPendingStatus(true))
        dispatch(setFinicityIframeVisible(false))
      },
      cancel: () => {
        dispatch(fetchingConnectUrl(false))
        dispatch(setFinicityIframeVisible(false))
      },
    })
  }
}

export const handleFetchFinicityConnectFixUrl = ({
  loanFileId,
  taskId,
  customerInstitutionLoginId,
}) => async (dispatch) => {
  dispatch(fetchingConnectFixUrl(true))
  await dispatch(fetchFinicityConnectFixUrl({ loanFileId, taskId, customerInstitutionLoginId }))
  await dispatch(loadFinicityConnectFix())
}

export const fetchFinicityConnectFixUrl = ({
  loanFileId,
  taskId,
  customerInstitutionLoginId,
}) => async (dispatch, getState) => {
  try {
    const url = ApiRoutes.connectFixUrl({ loanFileId, taskId, customerInstitutionLoginId })
    const res = await getRequest({ getState, url, raiseError })
    if (res) {
      const { data: { connectUrl } } = res
      dispatch(setFinicityConnectFixUrl(connectUrl))
    }
  } catch (e) {
    toastrErrors('There was an error importing statements. Please try again.')
    Sentry.withScope(scope => {
      scope.setTag('type', 'FINICITY_LOGGER')
      Sentry.captureException(e)
    })
  }
}

const loadFinicityConnectFix = () => (dispatch, getState) => {
  const url = getFinicityConnectFixUrl(getState())
  if (url) {
    dispatch(setFinicityIframeVisible(true))
    window.finicityConnect.launch(url, {
      selector: '#connect-container',
      error: function () {
        dispatch(fetchingConnectFixUrl(false))
        dispatch(setFinicityIframeVisible(false))
      },
      loaded: function () {
        dispatch(fetchingConnectFixUrl(false))
      },
      success: function () {
        dispatch(setFinicityPendingStatus(true))
        dispatch(setFinicityIframeVisible(false))
      },
      cancel: () => {
        dispatch(fetchingConnectFixUrl(false))
        dispatch(setFinicityIframeVisible(false))
      },
    })
  }
}

export const importStatementsFromAlreadyLinkedAccounts = ({ loanFileId, taskId }) => async (dispatch, getState) => {
  dispatch(setImportStatementsStatus(true))
  try {
    const url = ApiRoutes.importStatements({ loanFileId, taskId })
    await postRequest({ getState, url, params: {} })
    toastr.success('Importing statements. Please wait a minute and then refresh your browser.')
    dispatch(setImportStatementsStatus(false))
  } catch (e) {
    dispatch(setImportStatementsStatus(false))
    toastrErrors('There was an error importing statements. Please try again.')
    Sentry.withScope(scope => {
      scope.setTag('type', 'FINICITY_LOGGER')
      Sentry.captureException(e)
    })
  }
}
