import { contains } from 'underscore'
import { createSelector } from 'reselect'
import LoanAppElementDisplayLogic from '../../Services/LoanAppElementDisplayLogic'
import { getLoanAppResponseFromQuestion } from '../../utils/loan_app_utils'

export const getAllLoanAppTemplateQuestionRecords = state => state.v2.loanAppTemplateQuestions.records
export const getLoanAppStagedRecord = state => state.v2.loanApps.stagedRecord
export const getLoanAppUiFlags = state => state.v2.loanApps.uiFlags
export const getExistingLoanAppTasks = state => state.v2.loanApps.records

const getAllLoanAppTemplateSectionRecords = state => state.v2.loanAppTemplateSections.records
const getLoanAppResponsesStateTree = state => state.v2.loanAppResponses
const getAllLoanAppResponseRecords = state => getLoanAppResponsesStateTree(state).records
const getLoanAppResponsesErrorsCollection = state => getLoanAppResponsesStateTree(state).errors


// Apply LoanAppELementDisplayLogic to filter the sections in the current loan app template
// Returns Array
export const getVisibleLoanAppSectionRecords = createSelector(
  [getAllLoanAppTemplateSectionRecords, getAllLoanAppTemplateQuestionRecords, getLoanAppResponsesStateTree],
  (allSections, allQuestions, loanAppResponses) => {
    const propsForDisplayLogic = {
      loanAppTemplateSections: { records: allSections },
      loanAppTemplateQuestions: { records: allQuestions },
      loanAppResponses,
    }
    // eslint-disable-next-line
    return allSections.filter(section => LoanAppElementDisplayLogic.perform({ element: section, ...propsForDisplayLogic }))
  }
)

// Apply LoanAppELementDisplayLogic to filter the questions in the current loan app template
// Returns Array
export const getVisibleLoanAppQuestionRecords = createSelector(
  [getAllLoanAppTemplateSectionRecords, getAllLoanAppTemplateQuestionRecords, getLoanAppResponsesStateTree],
  (allSections, allQuestions, loanAppResponses) => {
    const propsForDisplayLogic = {
      loanAppTemplateSections: { records: allSections },
      loanAppTemplateQuestions: { records: allQuestions },
      loanAppResponses,
    }
    // eslint-disable-next-line
    return allQuestions.filter(question => LoanAppElementDisplayLogic.perform({ element: question, ...propsForDisplayLogic }))
  }
)

const getAllLoanAppVisibleQuestionIds = createSelector(
  [getVisibleLoanAppQuestionRecords],
  questions => questions.map(q => q.id)
)

const filterCollectionWithErrors = createSelector(
  [getLoanAppResponsesErrorsCollection],
  errorsCollection => errorsCollection.filter(errorRecord => errorRecord.errors && errorRecord.errors.length > 0)
)

// Filters out error objects that either don't contain any actual errors or aren't part of the visible set of questions
export const getLoanAppVisibleErrors = createSelector(
  [filterCollectionWithErrors, getAllLoanAppVisibleQuestionIds],
  (errors, questionIds) => errors.filter(error => contains(questionIds, error.templateQuestionId))
)

const getQuestionName = (_state, { questionName }) => questionName

// Returns the current response value for questions matching prop `questionName`
export const getLoanAppResponseValueByQuestionName = createSelector(
  [getAllLoanAppTemplateQuestionRecords, getAllLoanAppResponseRecords, getQuestionName],
  (allQuestionRecords, allResponseRecords, questionName) => {
    return getLoanAppResponseFromQuestion(questionName, allQuestionRecords, allResponseRecords)
  }
)
