import Route from 'Services/Route'
import { underscored } from 'underscore.string'
import { map } from 'underscore'


export function updateAllQuestions(apiToken, loanAppTemplateId, type, toggle) {
  const url = Route
    .api
    .loanApp
    .templates[type](loanAppTemplateId, { toggle: toggle })

  return (
    $.ajax(
      {
        method: 'POST',
        url: url,
        headers: {
          Authorization: `Token ${apiToken}`,
          Accept: 'application/vnd.himaxwell.com; version=1,application/json',
          'X-Internal': 'true',
          'Content-Type': 'application/json',
        },
      },
    )
  )
}

export function create(loanAppTemplateQuestion, templateId, templateSectionId) {
  const url = Route.api.loanApp.templateQuestions.create()
  const res = {}
  map(loanAppTemplateQuestion, (value, key) => {
    res[underscored(key)] = value
  })

  return (
    $.ajax(
      {
        method: 'POST',
        data: {
          loan_app_template_question: res,
          template_id: templateId,
          template_section_id: templateSectionId,
        },
        url: url,
      }
    )
  )
}

export function update(loanAppTemplateQuestion, changes) {
  const url = Route.api.loanApp.templateQuestions.update(loanAppTemplateQuestion)
  const res = {}
  map(changes, (value, key) => {
    res[underscored(key)] = value
  })

  return (
    $.ajax(
      {
        method: 'PUT',
        data: {
          loan_app_template_question: res,
          template_id: loanAppTemplateQuestion.templateId,
          template_section_id: loanAppTemplateQuestion.templateSectionId,
        },
        url: url,
      }
    )
  )
}

export function deleteQuestion(loanAppTemplateQuestion) {
  const url = Route.api.loanApp.templateQuestions.delete(loanAppTemplateQuestion)

  return (
    $.ajax(
      {
        method: 'DELETE',
        data: {
          template_id: loanAppTemplateQuestion.templateId,
          template_section_id: loanAppTemplateQuestion.templateSectionId,
        },
        url: url,
      }
    )
  )
}
