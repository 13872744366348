import AT from '../../actionTypes/'

const initialState = {
  currentTemplate: {
    enabled: false,
  },
  deployedTemplate: {
    enabled: false,
  },
}

export default function record(state = initialState, action) {
  const {
    type,
    payload,
  } = action

  switch (type) {
    case AT.SET_CURRENT_EMAIL_SIGNATURE_TEMPLATE: {
      return { ...state, currentTemplate: payload }
    }
    case AT.SET_DEPLOYED_EMAIL_SIGNATURE_TEMPLATE: {
      return { ...state, deployedTemplate: payload }
    }
    default:
      return state
  }
}
