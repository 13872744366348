import JSONAPIClient from '../../JSONAPIClient'

JSONAPIClient.define('voa_task', {
  loan_file_id: '',
  due_date: '',
  title: '',
  status: '',
  note: '',
  status_title: '',
  creator_id: '',
  created_at: '',
  plaid_details_items: '',
  user_id: '',
  loan_file: {
    jsonApi: 'hasOne',
    type: 'loan_file',
  },
})
