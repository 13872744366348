export const CLOSING_STATUS = {
  IN_PROGRESS: 0,
  SIGNING_COMPLETE: 1,
  CLOSED: 2,
  CANCELED: 3,
}

export const TASK_ITEM_STATUS = {
  NOT_READY: 0,
  READY: 1,
  COMPLETED: 2,
  NOT_APPLICABLE: 3,
  OPTED_OUT: 4,
}
