import AT from '../actionTypes'

const initialState = {
  showTaskTemplatePickerModal: false,
  showTaskTypePickerModal: false,
}

function taskTypePicker(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case AT.TOGGLE_TASK_TEMPLATE_PICKER_MODAL:
      return { ...state, showTaskTemplatePickerModal: payload }
    case AT.TOGGLE_TASK_TYPE_PICKER_MODAL:
      return { ...state, showTaskTypePickerModal: payload }
    case AT.TOGGLE_VERIFICATION_TASK_PICKER_MODAL:
      return { ...state, showVerificationTaskPickerModal: payload }
    default:
      return state
  }
}

export default taskTypePicker
