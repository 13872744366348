import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'
import { popToast } from 'v2/actions/system_notifications'

export const createConsentAPI = createAsyncThunk(
  'consent/create',
  async (payload, thunkAPI) => {
    let response
    try {
      response = await JSONAPIClient.create('consent', payload)
    } catch (e) {
      const consentType = () => {
        switch (payload.consentType) {
          case 1: return 'verbal consent'
          case 2: return 'HELOC consent'
          default: return 'consent'
        }
      }
      thunkAPI.dispatch(popToast({ error: `There was an error creating ${consentType()} authorization` }))
    }
    return response.data
  }
)

export const indexConsentAPI = createAsyncThunk(
  'consent/index',
  async ({ userId, loanFileId }) => {
    const response = await JSONAPIClient
      .all('consents')
      .get({ filter: { userId: userId, loanFileId: loanFileId } })
    return response.data
  }
)
