import { pipelineUsers as actionTypes } from 'v2/actionTypes'

// Eligible Lenders are stored as an object where key will be teamId
// { teamId1: [eligibleLender11, eligibleLender12], teamId2: [eligibleLender21, eligibleLender22] }

const initialState = {}

export default function records(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case actionTypes.set: {
      const { pipelineId, pipelineUsers } = payload
      return { ...state, [pipelineId]: pipelineUsers }
    }
    case actionTypes.destroy: {
      const { pipelineId, pipelineRoleId } = payload
      const pipelineUsers = state[pipelineId].filter(pipelineUser => pipelineUser.id !== pipelineRoleId)
      return { ...state, [pipelineId]: pipelineUsers }
    }
    default:
      return state
  }
}
