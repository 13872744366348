import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'
import { showLPA } from './asyncActions'

export const lpaSlice = createSlice({
  name: 'lpa',
  initialState: baseInitialState,
  reducers: baseReducers,
  extraReducers: {
    [showLPA.pending](state) {
      state.uiFlags.isFetching = true
    },
    [showLPA.fulfilled](state, action) {
      state.stagedRecord = action.payload
      state.uiFlags.isFetching = false
    },
    [showLPA.rejected](state) {
      state.uiFlags.isFetching = false
    },
  },
})

export default lpaSlice.reducer
