import JSONAPIClient from '../JSONAPIClient'

JSONAPIClient.define('document', {
  loan_file_id: '',
  document_group_id: '',
  reason: '',
  file_name: '',
  status: '',
  blob_url: '',
  lender_uploaded_document: '',
  borrower_uploaded_document: '',
  employer: '',
  account: '',
  system_generated: '',
  created_at: '',
  document_key: '',
  borrower_id: '',
  rejected: '',
  attachment_id: '',
})
