import api from 'utils/api'
import toastr from 'utils/toastr'
import { find } from 'underscore'
import { popToast } from 'v2/actions/system_notifications'

import Route from 'Services/Route'
import { createActionsFromActionTypes } from 'utils/reducer_utils'
import {
  getDisclosuresUiFlags,
  findDisclosureTask,
} from 'v2/selectors/disclosures'

import { getLoanFile } from 'v2/selectors/loan_files'

import {
  disclosuresTasks as disclosuresTasksActionTypes,
  disclosuresTaskItems as disclosuresTaskItemsActionTypes,
  disclosureSelfService as disclosureSelfServiceActionTypes,
} from '../../actionTypes'

export const disclosuresTasksReducerActions = createActionsFromActionTypes(disclosuresTasksActionTypes)
export const disclosuresTaskItemsReducerActions = createActionsFromActionTypes(
  disclosuresTaskItemsActionTypes
)
export const disclosureSelfServiceActions = createActionsFromActionTypes(disclosureSelfServiceActionTypes)

export function destroyDisclosuresTask({ taskId }) {
  return async (dispatch, getState) => {
    const loanFile = getLoanFile(getState())

    const { id: loanFileId } = loanFile

    const url = Route.api.disclosuresTasks.destroy({ loanFileId, taskId })

    const res = await api(getState).delete(url)
    toastr.success('Task deleted')
    const { disclosuresTasks } = res.data
    dispatch(disclosuresTasksReducerActions.set(disclosuresTasks))
  }
}

export function fetchSsoToken({ taskId }) {
  return async (dispatch, getState) => {
    const loanFile = getLoanFile(getState())
    const disclosuresTask = findDisclosureTask(getState(), { taskId })

    const uiFlags = getDisclosuresUiFlags(getState())
    const { docutechDocLinkURL } = uiFlags

    const disclosuresTaskItem = find(
      disclosuresTask.taskItems,
      (record) => record.signUrl === docutechDocLinkURL
    )

    const { id: taskItemId } = disclosuresTaskItem
    const { id: loanFileId } = loanFile

    const url = Route.api.disclosuresTasks.ssoToken({ loanFileId, taskId, taskItemId })

    const res = await api(getState).post(url)
    const { data: { token } } = res
    dispatch(disclosuresTasksReducerActions.setSsoToken(token))
  }
}

const getDisclosureTaskUrl = ({ loanFileId, taskId, itemId }) => {
  return `/api/v1/loan_files/${loanFileId}/disclosures_tasks/${taskId}/disclosures_task_items/${itemId}`
}

export function createBorrowerAndAttachToLoan({ loanFileId, taskId, itemId, firstName, lastName, suffix }) {
  return async (dispatch, getState) => {
    dispatch(disclosureSelfServiceActions.loading(true))
    try {
      const response = await api(getState).post(
        `${getDisclosureTaskUrl({ loanFileId, taskId, itemId })}/create_borrower_participant`,
        { user: { first_name: firstName, last_name: lastName, suffix } }
      )
      const data = response?.data
      dispatch(disclosureSelfServiceActions.set(data))
      dispatch(disclosureSelfServiceActions.success())
    } catch (error) {
      dispatch(
        popToast({ error: error?.response?.data?.message || 'There was an error resolving your disclosures issues' })
      )
    }
    dispatch(disclosureSelfServiceActions.loading(false))
  }
}
export function updateBorrowerEmail({ loanFileId, taskId, itemId, borrowerId }) {
  return async (dispatch, getState) => {
    dispatch(disclosureSelfServiceActions.loading(true))
    try {
      const response = await api(getState).post(
        `${getDisclosureTaskUrl({ loanFileId, taskId, itemId })}/update_borrower_participant`,
        { user: { user_id: borrowerId } }
      )
      const data = response?.data
      dispatch(disclosureSelfServiceActions.set(data))
      dispatch(disclosureSelfServiceActions.success())
    } catch (error) {
      dispatch(
        popToast({ error: error?.response?.data?.message || 'There was an error resolving your disclosures issues' })
      )
    }
    dispatch(disclosureSelfServiceActions.loading(false))
  }
}
export function addBorrowerToLoanFile({ loanFileId, taskId, itemId, borrowerId }) {
  return async (dispatch, getState) => {
    dispatch(disclosureSelfServiceActions.loading(true))
    try {
      const response = await api(getState).post(
        `${getDisclosureTaskUrl({ loanFileId, taskId, itemId })}/add_borrower_participant`,
        { user: { user_id: borrowerId } }
      )
      const data = response?.data
      dispatch(disclosureSelfServiceActions.set(data))
      dispatch(disclosureSelfServiceActions.success())
    } catch (error) {
      dispatch(
        popToast({ error: error?.response?.data?.message || 'There was an error resolving your disclosures issues' })
      )
    }
    dispatch(disclosureSelfServiceActions.loading(false))
  }
}
