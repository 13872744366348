import JSONAPIClient from '../JSONAPIClient'

JSONAPIClient.define('lender', {
  avatar_src: '',
  email: '',
  firstname: '',
  mobile_phone: '',
  name: '',
  office_phone: '',
  office_phone_ext: '',
  role: '',
  slug: '',
  title: '',
  ela_enabled_org: '',
  has_active_team: '',
})
