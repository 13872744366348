import { mpaySettings as actionTypes } from 'v2/actionTypes'

const initialState = { lastSubmitted: '' }

export default function record(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case actionTypes.set: {
      return {
        lastSubmitted: payload.mpaySetting.updatedAt,
        paymentPurposes: payload.mpaySetting.paymentPurposes,
        payoutSummaryEmailAddress: payload.mpaySetting.payoutSummaryEmailAddress,
      }
    }
    default:
      return state
  }
}
