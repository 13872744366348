import { createSlice } from '@reduxjs/toolkit'

const initialGetstreamState = {}

const getstreamReducerSlice = createSlice({
  name: 'getstreamReducer',
  initialState: initialGetstreamState,
  reducers: {
    updateGetstreamReducer(state, action) {
      const { payload } = action
      return {
        ...state,
        [payload.feedType]: { socketFeed: payload.socketFeed ? payload.socketFeed : state.socketFeed },
      }
    },
  },
})

export default getstreamReducerSlice
