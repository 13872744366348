import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'

export const showLoanOfficerTeam = createAsyncThunk(
  'loanOfficerTeam/show',
  async (id) => {
    const response = await JSONAPIClient.one('team', id).get()
    return response
  }
)
