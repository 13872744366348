import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'
import { createLoanFileAPI, showLoanFileAPI, indexLoanFileAPI, updateLoanFileAPI } from './asyncActions'

const loanFileSlice = createSlice({
  name: 'loanFile',
  initialState: baseInitialState,
  reducers: baseReducers,
  extraReducers: {
    [showLoanFileAPI.pending](state) {
      state.uiFlags.isFetching = true
    },
    [showLoanFileAPI.fulfilled](state, action) {
      state.uiFlags.isFetching = false
      state.records.push(action.payload)
      state.stagedRecord = action.payload
    },
    [showLoanFileAPI.rejected](state) {
      state.uiFlags.isFetching = false
    },
    [indexLoanFileAPI.pending](state) {
      state.uiFlags.isFetching = true
    },
    [indexLoanFileAPI.fulfilled](state, action) {
      state.uiFlags.isFetching = false
      state.records = action.payload
    },
    [indexLoanFileAPI.rejected](state) {
      state.uiFlags.isFetching = false
    },
    [createLoanFileAPI.pending](state) {
      state.uiFlags.isCreating = true
    },
    [createLoanFileAPI.fulfilled](state, action) {
      state.stagedRecord = action.payload
      state.uiFlags.isCreating = false
    },
    [createLoanFileAPI.rejected](state) {
      state.uiFlags.isCreating = false
    },
    [updateLoanFileAPI.pending](state) {
      state.uiFlags.isFetching = true
    },
    [updateLoanFileAPI.fulfilled](state, action) {
      state.uiFlags.isFetching = false
      const { id } = action.payload
      state.records = state.records.map(rec => (rec.id === id ? action.payload : rec))
      state.stagedRecord = action.payload
    },
    [updateLoanFileAPI.rejected](state) {
      state.uiFlags.isFetching = false
    },
  },
})

export default loanFileSlice
export const loanFileReducer = loanFileSlice.reducer
