import _ from 'underscore'

import AT from 'actionTypes/'
import Route from 'Services/Route'
import api from 'utils/api'
import { createAction } from 'utils/reducer_utils'
import { sourceTitleSearch, getFuzzySearchSources } from 'utils/filefetch_utils'

export const setFilteredSources = createAction(AT.FILTER_SOURCES)
export const setFilteredSourcesByTask = createAction(AT.FILTER_SOURCE_BY_TASK_TITLE)

export function filterSources(searchTerm, connNames) {
  return (dispatch, getState) => {
    const { sources: { taskSources } } = getState()
    const isSearchTerm = (!!searchTerm && searchTerm.length > 0)
    const connectionNames = connNames || []
    let payload
    if (isSearchTerm) {
      const fuzzySearchResults = getFuzzySearchSources(taskSources, searchTerm)
      const fuzzedSources = fuzzySearchResults.map(({ item }) => item)
      payload = fuzzedSources
    } else {
      payload = taskSources
    }
    const filteredSources = payload.filter((item) => !connectionNames.includes(item.name))
    dispatch(setFilteredSources(filteredSources))
  }
}

const FAKE_BANK = 'Fake Bank'

export function filterSourcesByTaskTitle(value, connNames) {
  return (dispatch, getState) => {
    const { sources: { sources, fakeSource, fakeSourceEnabled } } = getState()

    const filteredSources = sourceTitleSearch(sources, value, connNames)
    const hasFakeConn = connNames && connNames.includes(FAKE_BANK)
    if (fakeSourceEnabled && !hasFakeConn) {
      filteredSources.unshift(fakeSource)
    }
    dispatch(setFilteredSourcesByTask(filteredSources))
  }
}

export function setDisplaySources(sources) {
  const popularSources = _.where(sources, { isPopular: true })

  return { type: AT.SET_DISPLAY_SOURCES, payload: popularSources }
}

export const setSources = createAction(AT.SET_SOURCES)
export const setSelectedSource = createAction(AT.SET_SELECTED_SOURCE)

export function fetchSources(filethisTaskTitle) {
  return (dispatch, getState) => {
    const { connectionNames } = getState().pageInfo.userInfo || []

    const url = Route.api.filethis.sources.index()
    const req = api(getState).get(url)

    req.then((res) => {
      const { sources, fakeSourceEnabled } = res.data

      dispatch(setSources({ sources, fakeSourceEnabled }))

      if (!filethisTaskTitle) dispatch(setDisplaySources(sources))
      dispatch(filterSourcesByTaskTitle(filethisTaskTitle, connectionNames))
    }).catch((error) => {
      console.error(error)
    })
  }
}
