import JSONAPIClient from '../../JSONAPIClient'

JSONAPIClient.define('letter_of_explanation', {
  stream_id: '',
  lender_name: '',
  borrower_name: '',
  lender_note: '',
  prompt: '',
  loan_file_id: '',
}, { collectionPath: 'completions/letter_of_explanations' })
