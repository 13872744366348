import AT from 'actionTypes/'

const initialState = {
  interactions: [],
}

export default function interactions(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case AT.SET_INTERACTIONS: {
      return { ...state, interactions: payload }
    }

    case AT.SET_INTERACTION_FAILURE_STATE: {
      return { ...state, failureState: payload }
    }

    default: {
      return state
    }
  }
}
