/* eslint-disable eqeqeq */
import AT from '../../actionTypes/'

export default function records(state = [], action) {
  const { type, payload } = action

  switch (type) {
    case AT.SET_MASTER_LOAN_FILE_TEMPLATES:
      return payload
    case AT.ADD_MASTER_LOAN_FILE_TEMPLATE:
      return [...state, payload]
    case AT.EDIT_MASTER_LOAN_FILE_TEMPLATE:
      return state.map((template) => {
        if (template.id === payload.id)
          return payload
        else
          return template
      })
    case AT.REMOVE_MASTER_LOAN_FILE_TEMPLATE:
      return state.filter(s => s.id != payload.id)
    default:
      return state
  }
}
