import { pipelineLoanFiles as actionTypes } from 'v2/actionTypes'

const initialState = {
  eligiblePipelines: [],
  loading: {},
  reassigning: false,
  showDeleteModal: false,
  showUpdateModal: false,
  targetPipeline: false,
  updating: false,
  pipelinesUpdatingInBackground: [],
  loanFilesDeletingInBackground: [],
}

export default function uiFlags(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case actionTypes.loading: {
      const {
        pipelineId,
        value,
      } = payload

      const newLoading = state.loading
      newLoading[pipelineId] = value

      return { ...state, loading: newLoading }
    }
    case actionTypes.reassign:
      return { ...state, reassigning: payload }
    case actionTypes.setEligiblePipelines:
      return { ...state, eligiblePipelines: payload }
    case actionTypes.setTargetPipeline:
      return { ...state, targetPipeline: payload }
    case actionTypes.showDeleteModal:
      return { ...state, showDeleteModal: payload }
    case actionTypes.showUpdateModal:
      return { ...state, showUpdateModal: payload }
    case actionTypes.updatingRecord:
      return { ...state, updating: payload }
    case actionTypes.pipelinesUpdatingInBackground:
      return { ...state, pipelinesUpdatingInBackground: payload }
    case actionTypes.loanFilesDeletingInBackground:
      return { ...state, loanFilesDeletingInBackground: payload }
    default:
      return state
  }
}
