import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'

export const indexCreditVendors = createAsyncThunk(
  'credit/vendors/index',
  async () => {
    try {
      const { data } = await JSONAPIClient.request(
        '/api/v2/credit/vendors',
        'GET',
      )
      return data
    } catch (error) {
      throw error
    }
  }
)

export const createCreditLiabilityAPI = createAsyncThunk(
  'credit/liabilities/create',
  async ({ ausSettingId, creditTaskId, payload }) => {
    const { data } = await JSONAPIClient.request(
      '/api/v2/aus/credit_liabilities',
      'POST',
      { ausSettingId, creditTaskId },
      {
        type: 'credit_liabilities',
        attributes: {
          ...payload,
          ausSettingId,
          creditTaskId,
        },
      },
    )
    return data
  }
)

export const createLiabilityImportAPI = createAsyncThunk(
  'aus/liability_import/create',
  async ({ ausSettingId, creditTaskId, payload }) => {
    const { data } = await JSONAPIClient.request(
      '/api/v2/aus/liability_imports',
      'POST',
      { ausSettingId, creditTaskId },
      {
        type: 'liability_imports',
        attributes: {
          ...payload,
          ausSettingId,
          creditTaskId,
        },
      },
    )
    return data
  }
)
