import _ from 'underscore';

export function sanitizeInput(value) {
  if (value === undefined) return value

  return value.replace(/[^\w\s]/gi, '')
}

export function serialize(form) {
  const formData = $(form).serializeArray();

  const obj = {};

  // Iterate over serialized form array
  _.each(formData, (elem) => {
    // Ignore if no value or undefined
    if (elem.value.length || elem.value !== undefined || elem.value === '') {
      // Set form element name to object key, and form value to object value
      obj[elem.name] = sanitizeInput(elem.value);
    }
  });

  return obj;
}
