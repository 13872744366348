import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'
import { popToast } from 'v2/actions/system_notifications'

export const indexFilters = createAsyncThunk(
  'bi/reports/filters/index',
  async (params = {}, thunkAPI) => {
    try {
      const { data } = await JSONAPIClient.all('filter').get(params)
      return data
    } catch (e) {
      thunkAPI.dispatch(popToast({ error: 'There was an error fetching filters' }))
      return e.response.error
    }
  },
)

export const createFilter = createAsyncThunk(
  'bi/reports/filters/create',
  async (payload, thunkAPI) => {
    try {
      const { data } = await JSONAPIClient.create('filter', payload)
      return data
    } catch (e) {
      thunkAPI.dispatch(popToast({ error: 'There was an error creating a filter' }))
      return e.response.error
    }
  },
)

export const destroyFilter = createAsyncThunk(
  'bi/reports/filters/destroy',
  async (id) => {
    const { data } = await JSONAPIClient.destroy('filter', id)
    return data
  },
)
