import AT from 'v2/actionTypes/'
import {
  create,
  update,
  generateNewLetter,
  show as showTask,
  saveAndPreview as backendSaveAndPreview,
  fetchLetters,
} from 'v2/BackendRequest/Api/LoanFiles/PreApprovalLetterTasks'
import Routes from 'Services/Route'
import api from 'utils/api'
import toastr from 'utils/toastr'
import { createActionsFromActionTypes } from 'utils/reducer_utils'
import { preApprovalLetterTasks } from '../../actionTypes'
import { createAction } from '../../../utils/reducer_utils'
import { teamIndexIfNeeded, preApprovalLetterTemplatesReducerActions } from './templates'

export const preApprovalLetterTasksReducerActions = createActionsFromActionTypes(preApprovalLetterTasks)
export const togglePreApprovalTaskTemplateModal = createAction(AT.TOGGLE_PRE_APPROVAL_LETTER_TEMPLATE_MODAL)

export function index({ loanFileId }) {
  return async (dispatch, getState) => {
    const url = Routes.index({ loanFileId })
    const res = await api(getState).get(url)
    dispatch(preApprovalLetterTasksReducerActions.set(res.data.templates))
  }
}

export function show({ loanFileId, id }) {
  return async (dispatch, getState) => {
    const preApprovalLetterTask = await showTask(getState, loanFileId, id)
    dispatch(preApprovalLetterTasksReducerActions.setStagedRecord(preApprovalLetterTask))
  }
}

export function destroy({ loanFileId, id }) {
  return async (dispatch, getState) => {
    const url = Routes.api.loanFile.preApprovalLetters.tasks.delete({ loanFileId, id })
    await api(getState).delete(url)
    const { slug } = getState().pageInfo.userInfo
    const redirectUrl = Routes.lenders.loanFile.show({
      slug,
      id: loanFileId,
    })

    window.location.href = `${redirectUrl}?tasks=true`
  }
}

export function startPreApprovalTaskFlow({ teamId }) {
  return async dispatch => {
    await dispatch(teamIndexIfNeeded({ teamId }))
    dispatch(togglePreApprovalTaskTemplateModal(true))
  }
}

export function completePreApprovalTaskFlow(loanFileId, id, params) {
  return async (dispatch, getState) => {
    dispatch(preApprovalLetterTasksReducerActions.updatingRecord(true))
    await update(getState, loanFileId, id, params)
    dispatch(preApprovalLetterTasksReducerActions.updatingRecord(false))
    const { slug } = getState().pageInfo.userInfo
    toastr.success('Successfully generated letter!')
    const route = Routes.lenders.loanFile.show({
      slug,
      id: loanFileId,
    })

    window.location.href = `${route}?tasks=true`
  }
}

export function saveAndPreview(loanFileId, id, params) {
  return async (dispatch, getState) => {
    const url = await backendSaveAndPreview(getState, loanFileId, id, params)
    window.open(url, '_blank')
  }
}

export function realtorGenerateNewLetter(loanFileId, id, params) {
  return async (dispatch, getState) => {
    dispatch(preApprovalLetterTasksReducerActions.updatingRecord(true))
    const newLetter = await generateNewLetter(getState, loanFileId, id, params)
    dispatch(preApprovalLetterTasksReducerActions.updatingRecord(false))
    dispatch(preApprovalLetterTasksReducerActions.addStagedLetter(newLetter))
  }
}

export function fetchPreApprovalLetters({ loanFileId, id }) {
  return async (dispatch, getState) => {
    const letters = await fetchLetters(getState, loanFileId, id)
    dispatch(preApprovalLetterTasksReducerActions.setStagedLetters(letters))
  }
}

export function setSelectedPreApprovalLetterTemplate({ loanFileId, id, realtorId }) {
  return async (dispatch, getState) => {
    dispatch(preApprovalLetterTemplatesReducerActions.creatingRecord(true))
    const preApprovalLetterTask = await create(getState, loanFileId, id, realtorId)
    const { id: taskId, slug } = preApprovalLetterTask
    window.location = Routes.lenders.loanFile.preApprovalLetters.show({ slug, loanFileId, taskId })
  }
}

export function resetPreApprovalTaskFlow() {
  return dispatch => {
    dispatch(togglePreApprovalTaskTemplateModal(false))
  }
}
