import Route from 'Services/Route'
import { getRequest, putRequest } from 'utils/api'
import toastr from 'utils/toastr'
import { createActionsFromActionTypes } from 'utils/reducer_utils'
import { getTeam } from 'v2/selectors/teams'
import { teams as teamActionTypes } from '../../actionTypes'

export const teamReducerActions = createActionsFromActionTypes(
  teamActionTypes,
)

export const fetchTeam = (teamId) => {
  return async (dispatch, getState) => {
    const url = Route.api.teams.show({ teamId: teamId })
    const res = await getRequest({ getState, url })

    if (res) {
      const { team } = res.data
      dispatch(teamReducerActions.setStagedRecord(team))
    }
  }
}

export const handleUpdatingTeamMessage = (values) => {
  return async (dispatch) => {
    await dispatch(teamReducerActions.loading(true))
    const res = await dispatch(updateTeam(values))
    await dispatch(teamReducerActions.loading(false))

    if (res.status === 200) {
      toastr.options.timeOut = '3000'
      toastr.success('Updated team message!')
    }
  }
}

export const handleTogglingTeamMortgageCalculator = () => {
  return async (dispatch, getState) => {
    const { settings: { mortgageCalculator } } = getTeam(getState())
    const res = await dispatch(updateTeam({ team: { mortgageCalculator: !mortgageCalculator } }))

    if (res.status === 200) {
      toastr.options.timeOut = '3000'
      toastr.success('Updated mortgage calculator settings!')
    }
  }
}

export const handleUpdatingTaskReplyTo = (values) => {
  return async (dispatch) => {
    const res = await dispatch(updateTeam(values))

    if (res.status === 200) {
      toastr.options.timeOut = '3000'
      toastr.success('Updated task email settings!')
    }
  }
}


export const handleUpdatingTaskReplyToCc = (values) => {
  return async (dispatch) => {
    const res = await dispatch(updateTeam(values))

    if (res.status === 200) {
      toastr.options.timeOut = '3000'
      toastr.success('Updated task email settings!')
    }
  }
}

export const handleUpdatingDefaultLoanAppTemplate = (values) => {
  return async (dispatch) => {
    const res = await dispatch(updateTeam(values))

    if (res.status === 200) {
      toastr.options.timeOut = '3000'
      toastr.success('Updated default loan app template!')
    }
  }
}

// private functions

const updateTeam = (values) => {
  return async (dispatch, getState) => {
    const { id: teamId } = getTeam(getState())
    const url = Route.api.teams.update({ teamId })
    const res = await putRequest({ getState, url, params: values })

    if (res) {
      const { team } = res.data
      dispatch(teamReducerActions.setStagedRecord(team))
      return res
    }
  }
}
