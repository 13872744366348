import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'
import { createAuthAPI, updateAuthAPI } from './asyncActions'

const quickApplyAuthSlice = createSlice({
  name: 'quickApplyAuth',
  initialState: baseInitialState,
  reducers: baseReducers,
  extraReducers: {
    [createAuthAPI.pending](state) {
      state.uiFlags.isCreating = true
    },
    [createAuthAPI.fulfilled](state, action) {
      state.uiFlags.isCreating = false
      state.stagedRecord = action.payload
    },
    [createAuthAPI.rejected](state, action) {
      state.uiFlags = false
      state.error = action.payload
    },
    [updateAuthAPI.pending](state, action) {
      const { meta: { arg: { payload: { resendOtp } } } } = action
      state.uiFlags.isResending = !!resendOtp
    },
    [updateAuthAPI.fulfilled](state) {
      state.uiFlags.isResending = false
    },
    [updateAuthAPI.rejected](state) {
      state.uiFlags = false
    },
  },
})

export default quickApplyAuthSlice
