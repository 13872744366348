import AT from 'actionTypes/'

import _ from 'underscore';
import { remove, push } from 'Services/ImmutatingHelpers'

export default function documentGroupTemplateDisplayOrder(state = [], action) {
  switch (action.type) {
    case AT.MOVE_DOWN_DOCUMENT_GROUP_TEMPLATE: {
      const newState = _.clone(state)
      const index = newState.indexOf(action.id)
      // No reason for this logic to be here...
      if (index < (newState.length - 1)) {
        const temp = newState[index]
        newState[index] = newState[index + 1]
        newState[index + 1] = temp
        return newState
      } else
        return state
    }
    case AT.MOVE_UP_DOCUMENT_GROUP_TEMPLATE: {
      const newState = _.clone(state)
      const index = newState.indexOf(action.id)
      if (index > 0) {
        const temp = newState[index]
        newState[index] = newState[index - 1]
        newState[index - 1] = temp
        return newState
      } else
        return state
    }

    case AT.ADD_ID_TO_DOCUMENT_GROUP_TEMPLATE_ORDER_LIST:
      return push(state, action.id)

    case AT.REMOVE_ID_FROM_DOCUMENT_GROUP_TEMPLATE_ORDER_LIST: {
      return remove(state, state.indexOf(action.id))
    }

    default:
      return state
  }
}
