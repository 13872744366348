import AT from 'actionTypes/'
import api from 'utils/api'

import Route from 'Services/Route'

import { toastrErrors } from 'utils/error_utils'
import { getLoanFileId, getBorrower } from 'v2/selectors/loan_files'
import { errorHandler } from 'v2/actions/system_notifications'
import { setStep } from './account_connections'
import { setSelectedConnection } from './connections'
import { toggleModal } from './modal'
import { toggleSpinner } from './spinner'

import {
  filterInteractions,
  isStatus,
} from '../modules/filethis'

export function setInteractions(interactions) {
  return { type: AT.SET_INTERACTIONS, payload: interactions }
}

export function setInteractionFailuresState(failureState) {
  return { type: AT.SET_INTERACTION_FAILURE_STATE, payload: failureState }
}

export function submitInteractions(data) {
  return (dispatch, getState) => {
    const url = Route.api.filethis.interactions.create()

    const req = api(getState).post(url, data)

    req.then(() => {
      dispatch(setStep('answer'))
    }).catch((error) => {
      const { errors } = error?.response?.data || {}

      console.error(errors)
      if (errors) toastrErrors(errors)

      // Toggle global and interactions modals to false.
      toggleModal(false, 'global')
      toggleModal(false, 'interactions')
    })
  }
}

export function fetchInteractions(connectionId = null) {
  return async (dispatch, getState) => {
    const data = { params: { connection_id: connectionId } }
    const url = Route.api.filethis.interactions.index()
    try {
      const res = await api(getState).get(url, data)
      const { interactions, failureState, connection } = res.data
      // Turn spinner on
      if (!getState().spinner.isLoading) dispatch(toggleSpinner(true))

      if (interactions && interactions.length > 0) {
        dispatch(setInteractionsStep({ interactions, failureState }))
      } else {
        dispatch(setConnectionStep(connection))
      }

      dispatch(setSelectedConnection(connection))
      dispatch(toggleSpinner(false))
    } catch (error) {
      dispatch(errorHandler(error, 'There was an error deleting the task, please try again'))
      // If error, go back to sources select step
      setStep('sources')
    }
  }
}

function setInteractionsStep({ interactions, failureState = null }) {
  return dispatch => {
    const filteredInteractions = filterInteractions(interactions)

    dispatch(setInteractions(filteredInteractions))
    dispatch(setInteractionFailuresState(failureState))
    dispatch(setStep('interactions'))
  }
}

function setConnectionStep(connection) {
  return (dispatch, getState) => {
    const { statements } = connection
    const complete = isStatus(connection, 'complete')
    const uploading = isStatus(connection, 'uploading')
    const answer = isStatus(connection, 'answer')
    const failed = isStatus(connection, 'failed')
    const importReady = (complete || uploading) && statements.length > 0

    if (complete && !statements.length) {
      const loanFileId = getLoanFileId(getState())
      const borrowerId = getBorrower(getState())
      const tasksUrl = Route.borrowers.loanFile.show({ borrowerId, id: loanFileId })
      window.location.href = tasksUrl
    }
    if (importReady) dispatch(setStep('import'))
    if (answer) dispatch(setStep('answer'))
    if (failed) dispatch(setStep('failed'))
  }
}
