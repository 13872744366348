import JSONAPIClient from '../JSONAPIClient'

JSONAPIClient.define('question', {
  button_text: '',
  custom: '',
  default_value: '',
  description: '',
  display_logic: '',
  display_name: '',
  hint: '',
  label: '',
  name: '',
  note: '',
  post_note: '',
  options: '',
  question_type: '',
  readonly: '',
  section_ids: '',
  sections: {
    jsonApi: 'hasMany',
    type: 'sections',
  },
  validation: '',
})
