import AT from 'v2/actionTypes/'

const initialState = {
  loading: false,
  connectFixLoading: false,
  pending: false,
  importingStatements: false,
  finicityIframeVisible: false,
}

export default function uiFlags(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case AT.SET_FINICITY_CONNECT_URL_LOADING: {
      return { ...state, loading: payload }
    }
    case AT.SET_FINICITY_CONNECT_FIX_URL_LOADING: {
      return { ...state, connectFixLoading: payload }
    }
    case AT.SET_FINICITY_PENDING_STATUS: {
      return { ...state, pending: payload }
    }
    case AT.SET_IMPORT_STATEMENTS_STATUS: {
      return { ...state, importingStatements: payload }
    }
    case AT.SET_FINICITY_IFRAME_VISIBLE: {
      return { ...state, finicityIframeVisible: payload }
    }
    default:
      return state
  }
}
