import api from 'utils/api'
import Route from 'Services/Route'
import { toastrErrors } from 'utils/error_utils'

export function show(state, organizationId) {
  const url = Route.api.organizations.emailSignatures.show({ organizationId })
  const req = api(state).get(url)

  return req.then(parseResponse).catch((error) => {
    const errors = error?.response?.data || null
    console.error(errors)
    if (errors) toastrErrors(errors)
  })
}

export function update(state, organizationId, params) {
  const body = {
    organizationId,
    id: params.id,
  }

  const url = Route.api.organizations.emailSignatures.update(body, params)
  const req = api(state).put(url, params)

  return req.then(parseResponse).catch((error) => {
    const errors = error?.response?.data || null
    console.error(errors)
    if (errors) toastrErrors(errors)
    return
  })
}

const parseResponse = (res) => {
  const {
    organizationEmailSignatureTemplate,
    lastDeployedOrganizationEmailSignatureTemplate,
  } = res.data

  return {
    currentTemplate: organizationEmailSignatureTemplate,
    deployedTemplate: lastDeployedOrganizationEmailSignatureTemplate,
  }
}

