import AT from '../../actionTypes/'

const initialState = {
  disabled: false,
  isSubmitting: false,
}

export default function stagedRecord(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case AT.SET_STAGED_LOAN_APP:
      return payload
    case AT.UPDATE_STAGED_LOAN_APP:
      return { ...state, ...payload }
    case AT.SET_LOAN_APP_ERROR_COUNT:
      return { ...state, errorCounts: payload }
    case AT.SET_LOAN_APP_DISABLED:
      return { ...state, disabled: payload }
    case AT.TOGGLE_LOAN_APP_SUBMIT_BUTTON:
      return { ...state, isSubmitting: payload }
    default:
      return state
  }
}
