/* eslint-disable import/no-cycle */
import api from 'utils/api'
import moment from 'moment'
import toastr from 'utils/toastr'
import { each, any, find } from 'underscore'
import Route from 'Services/Route'
import { toastrErrors } from 'utils/error_utils'
import { createAction } from 'utils/reducer_utils'
import {
  assetVerificationTask,
  creditTask,
  preApprovalTask,
  paymentTask,
  employmentIncomeVerificationTask,
  isInvalidTimePeriod,
  filterIncompleteTasks,
  filterCompleteTasks,
  getProcessedNote,
} from 'utils/task_utils'
import { isReceived } from 'utils/document_utils'
import { getLoanFileId } from 'v2/selectors/loan_files'
import { getPageInfo, getUserId } from 'v2/selectors/page_info'
import { getV2CompleteTasks } from 'v2/selectors/tasks'

import { setSignableDocuments } from './signable_documents'
import { addLoanApps } from './loan_app/loan_app'
import {
  destroy as deleteEmploymentIncomeVerificationTask,
  employmentIncomeVerificationTasksReducerActions,
} from './employment_income_verification_tasks'
import { destroy as deletePreApprovalTask, preApprovalLetterTasksReducerActions } from './pre_approval_letters/tasks'
import * as assetVerificationTaskActions from './asset_verification_tasks'
import { disclosuresTasksReducerActions } from './disclosures/tasks'
import { setCreditTasks, deleteCreditTask } from './credit_tasks'
import { setPaymentTasks, destroyPaymentTask } from './payment_tasks'
import AT from '../actionTypes'
import { fetchCommunications } from './communications'

export function setSelectedTask(task) {
  return { type: AT.SET_SELECTED_TASK, payload: task }
}

export function setCompleteTasks(tasks) {
  return { type: AT.SET_COMPLETE_TASKS, payload: tasks }
}

export function setIncompleteTasks(tasks) {
  return { type: AT.SET_INCOMPLETE_TASKS, payload: tasks }
}

export function setIncompleteTaskLoading(payload) {
  return { type: AT.INCOMPLETE_TASK_LOADING, payload: payload }
}

function setNotePreview(payload) {
  return { type: AT.SET_NOTE_PREVIEW, payload: payload }
}

export function updateNotePreview() {
  return (dispatch, getState) => {
    const { v2LoanFiles, v2Tasks } = getState()
    const { loanFile } = v2LoanFiles
    const { selectedTask } = v2Tasks
    const { title, timePeriod, note } = selectedTask

    const selectedTemplate = selectedDocumentGroupTemplate(title, loanFile.documentGroupTemplates)
    const payload = getProcessedNote(note, timePeriod, selectedTemplate.timePeriodSelectorType)

    dispatch(setNotePreview(payload))
  }
}

const toggleCompleteTaskModal = createAction(AT.TOGGLE_COMPLETE_TASK_MODAL)
const toggleCompleteTaskModalProcessing = createAction(AT.TOGGLE_COMPLETE_TASK_MODAL_PROCESSING)

export function setCompleteTaskLoading(payload) {
  return { type: AT.COMPLETE_TASK_LOADING, payload: payload }
}

export function toggleEditTaskModal(isViewable = false) {
  return { type: AT.TOGGLE_EDIT_TASK_MODAL, payload: isViewable }
}

export function removeAccount(tasks, task) {
  return {
    type: AT.REMOVE_ACCOUNT,
    payload: { tasks: tasks, task: task },
  }
}

export function handleConnectAccountSuccess(publicToken, metadata) {
  return (dispatch, getState) => {
    const { selectedTask } = getState().v2Tasks
    const { userInfo, formInfo } = getState().pageInfo
    const { slug } = userInfo
    const { authenticityToken } = formInfo
    dispatch(toggleConnectAccountModal({}, false))
    post(Route.borrowers.connections.base({ slug }), {
      public_token: publicToken,
      document_group_id: selectedTask.id,
      authenticity_token: authenticityToken,
      'institution[name]': metadata.institution.name,
    })
  }
}

export function toggleConnectAccountModal(task, isViewable = false) {
  return dispatch => {
    const selectedTask = isViewable ? task : {}
    dispatch({ type: AT.TOGGLE_CONNECT_ACCOUNT_MODAL, payload: isViewable })
    dispatch(setSelectedTask(selectedTask))
  }
}

export function toggleCreateTaskModal(loanFile, isViewable = false) {
  return dispatch => {
    const selectedTask = isViewable
      ? {
        dueDate: moment()
          .add(7, 'days')
          .format('YYYY-MM-DD'),
        requiredFor: loanFile.defaultRequiredFor,
      }
      : {}
    dispatch({ type: AT.TOGGLE_CREATE_TASK_MODAL, payload: isViewable })
    dispatch(setSelectedTask(selectedTask))
  }
}

export function updateSelectedTask(update) {
  return { type: AT.UPDATE_SELECTED_TASK, payload: update }
}

export function toggleFileUploadModal(isViewable = false) {
  return { type: AT.TOGGLE_FILE_UPLOAD_MODAL, payload: isViewable }
}

export function toggleDeleteTaskModal(isViewable = false) {
  return { type: AT.TOGGLE_DELETE_TASK_MODAL, payload: isViewable }
}

export function fetchTasks(id) {
  return (dispatch, getState) => {
    const url = Route.api.tasks.index({ loanFileId: id || getState().v2LoanFiles.loanFile.id })

    const req = api(getState).get(url)

    req.then(res => {
      const { tasks } = res.data
      dispatch(setIncompleteTasks(filterIncompleteTasks(tasks)))
      dispatch(setCompleteTasks(filterCompleteTasks(tasks)))
    })
  }
}

// TODO : we could return all tasks and filter them on the client
//   but it may be more extensible if we just make 2 api calls,
//   incase we need to return the responses separately in the future
export function fetchIncompleteTasks(id, onlyDocumentGroups = false) {
  return (dispatch, getState) => {
    dispatch(setIncompleteTaskLoading(true))

    const url = Route.api.tasks.incomplete({ loanFileId: id || getState().v2LoanFiles.loanFile.id })

    const req = api(getState).get(url)

    req
      .then(res => {
        const {
          assetVerificationTasks,
          creditTasks,
          disclosuresTasks,
          closingsTasks,
          employmentIncomeVerificationsTasks,
          loanApps,
          paymentTasks,
          preApprovalTasks,
          signableDocuments,
          tasks,
          v2LoanAppTasks,
        } = res.data
        const incompleteTasks = filterIncompleteTasks(tasks)
        dispatch(setIncompleteTasks(incompleteTasks))

        if (!onlyDocumentGroups) {
          dispatch(setSignableDocuments(signableDocuments))
          dispatch(addLoanApps(v2LoanAppTasks.concat(loanApps)))
          dispatch(employmentIncomeVerificationTasksReducerActions.set(employmentIncomeVerificationsTasks))
          dispatch({ type: AT.SET_CLOSINGS_TASK, payload: closingsTasks })
          dispatch(setCreditTasks(creditTasks))
          dispatch(setPaymentTasks(paymentTasks))
          dispatch(preApprovalLetterTasksReducerActions.set(preApprovalTasks))
          dispatch(assetVerificationTaskActions.reducerActions.set(assetVerificationTasks))
          dispatch(disclosuresTasksReducerActions.set(disclosuresTasks))
        }
        dispatch(setIncompleteTaskLoading(false))
      })
      .catch(error => {
        console.error(error)
        dispatch(setIncompleteTaskLoading(false))
        const { errors } = error?.response?.data || {}
        if (errors) toastrErrors(errors)
      })
  }
}

export function fetchCompleteTasks(id) {
  return (dispatch, getState) => {
    const url = Route.api.tasks.complete({ loanFileId: id || getState().v2LoanFiles.loanFile.id })

    dispatch(setCompleteTaskLoading(true))
    const req = api(getState).get(url)

    req
      .then(res => {
        const { data: { tasks, v2LoanAppTasks } } = res

        dispatch(setCompleteTasks(tasks))
        dispatch(addLoanApps(v2LoanAppTasks))
        dispatch(setCompleteTaskLoading(false))
      })
      .catch(error => {
        dispatch(setCompleteTaskLoading(false))
        const { errors } = error?.response?.data || {}

        console.error(errors)
        if (errors) toastrErrors(errors)
      })
  }
}

export function toggleTaskComplete(task) {
  return dispatch => {
    const { isCompleted, documents } = task
    const hasReceivedDocuments = any(documents, (doc) => isReceived(doc))

    if (isCompleted) {
      dispatch(setTaskAsIncomplete(task))
    } else if (hasReceivedDocuments) {
      dispatch(setSelectedTask(task))
      dispatch(toggleCompleteTaskModal(true))
    } else {
      dispatch(completeTask(task))
    }
  }
}

export function setTaskAsIncomplete(task) {
  return dispatch => {
    return dispatch(setDocumentGroupAsIncomplete(task))
  }
}

export function completeTask(task, documentIds = [], losType = null) {
  return dispatch => {
    if (assetVerificationTask(task)) {
      return dispatch(assetVerificationTaskActions.complete(task.id))
    } else {
      return dispatch(completeDocumentGroup(task, documentIds, losType))
    }
  }
}

export function cancelTaskComplete() {
  return dispatch => {
    dispatch(setSelectedTask({}))
    dispatch(toggleCompleteTaskModal(false))
    dispatch(toggleCompleteTaskModalProcessing(false))
  }
}

export function completeDocumentGroup(task, documentIds, losType = null) {
  return (dispatch, getState) => {
    dispatch(toggleCompleteTaskModalProcessing(true))

    const loanFileId = getLoanFileId(getState())
    const { id: taskId } = task
    const params = { documents: documentIds, los_type: losType }
    const url = Route.api.task.complete({ loanFileId, taskId })
    const req = api(getState).post(url, params)

    req
      .then(res => {
        const { data: { tasks } } = res
        toastr.success('Task marked complete')

        dispatch(fetchIncompleteTasks(loanFileId))
        dispatch(setCompleteTasks(tasks))
        dispatch(setSelectedTask({}))
        dispatch(toggleCompleteTaskModal(false))
        dispatch(toggleCompleteTaskModalProcessing(false))
      })
      .catch(error => {
        const { errors } = error?.response?.data || {}

        dispatch(toggleCompleteTaskModalProcessing(false))

        console.error(errors)
        if (errors) toastrErrors(errors)
      })
  }
}

export function setDocumentGroupAsIncomplete(task) {
  return (dispatch, getState) => {
    const loanFileId = getLoanFileId(getState())
    const completeTasks = getV2CompleteTasks(getState())
    const { id: taskId } = task
    const url = Route.api.task.incomplete({ loanFileId, taskId })
    const req = api(getState).post(url)

    req
      .then(res => {
        const incompleteTasks = res.data.tasks
        const newCompleteTasks = completeTasks.filter(t => t.id !== taskId)

        toastr.success('Task marked incomplete')

        dispatch(setIncompleteTasks(incompleteTasks))
        dispatch(setCompleteTasks(newCompleteTasks))
      })
      .catch(error => {
        const { errors } = error?.response?.data || {}

        console.error(errors)
        if (errors) toastrErrors(errors)
      })
  }
}

export function toggleCreatingNewTask(bool) {
  return { type: AT.CREATING_NEW_TASK, payload: bool }
}

export const selectedDocumentGroupTemplate = (title, documentGroupTemplates) => {
  let template = {}
  each(documentGroupTemplates, documentGroupTemplatesForCategory => {
    const res = find(
      documentGroupTemplatesForCategory,
      documentGroupTemplate => documentGroupTemplate.title === title,
    )
    if (res) {
      template = res
    }
  })
  return template
}

export function createTask() {
  return (dispatch, getState) => {
    // What do we need serialized in order to update a task?
    // title, category, due_date, note, subtitle, period, required_for
    const { selectedTask, notePreview } = getState().v2Tasks
    const { showNotePreview, notePreview: notePreviewValue } = notePreview

    if (isInvalidTimePeriod(selectedTask.timePeriod)) {
      toastrErrors('Please enter a valid number of months')
      return
    }

    dispatch(toggleCreatingNewTask(true))

    const data = { ...selectedTask }
    const loanFileId = getLoanFileId(getState())
    const { action, controller } = getPageInfo(getState())
    data.user_id = getUserId(getState())
    data.id = loanFileId
    data.required_for = selectedTask.requiredFor
    data.due_date = selectedTask.dueDate
    delete data.dueDate
    data.send_notifications = !(controller === 'loan_files' && action === 'new')
    if (showNotePreview) {
      data.note = notePreviewValue
    }

    const url = Route.api.tasks.create({ loanFileId })

    const req = api(getState).post(url, data)

    req
      .then(res => {
        const { task } = res.data

        dispatch({ type: AT.CREATE_TASK, payload: task })
        dispatch(toggleCreateTaskModal())
        dispatch(fetchCommunications(loanFileId))
        dispatch(fetchIncompleteTasks(loanFileId))
        toastr.success(`Successfully added ${task.title}`)
        dispatch(toggleCreatingNewTask(false))
      })
      .catch(error => {
        const { errors } = error?.response?.data || {}

        console.error(errors)
        if (errors) toastrErrors(errors)
        dispatch(toggleCreatingNewTask(false))
      })
  }
}

export function updateTask() {
  return (dispatch, getState) => {
    const { selectedTask } = getState().v2Tasks
    const loanFileId = getLoanFileId(getState())

    if (isInvalidTimePeriod(selectedTask.timePeriod)) {
      toastrErrors('Please enter a valid number of months')
      return
    }

    const data = {
      timePeriod: selectedTask.timePeriod,
      title: selectedTask.title,
      note: selectedTask.note,
      required_for: selectedTask.requiredFor,
      subtitle: selectedTask.subtitle,
    }

    data.user_id = getState().pageInfo.userInfo.id
    data.id = loanFileId
    data.due_date = selectedTask.dueDate

    const url = Route.api.tasks.update({
      loanFileId,
      taskId: selectedTask.id,
    })

    const req = api(getState).put(url, data)

    req
      .then(res => {
        const { tasks } = res.data
        const completedTasks = filterCompleteTasks(tasks)

        dispatch(setCompleteTasks(completedTasks))
        dispatch(fetchIncompleteTasks(loanFileId))
        // close modal after tasks have been updated
        dispatch(toggleEditTaskModal(false))
        dispatch(setSelectedTask({}))
        dispatch(fetchCommunications(loanFileId))
        toastr.success(`Successfully updated ${selectedTask.title}`)
      })
      .catch(error => {
        const { errors } = error?.response?.data || {}

        console.error(errors)
        if (errors) toastrErrors(errors)
      })
  }
}

export function deleteTask(taskId = null) {
  return (dispatch, getState) => {
    const { selectedTask } = getState().v2Tasks
    const id = taskId || selectedTask.id
    const loanFileId = getState().v2LoanFiles.loanFile.id

    if (assetVerificationTask(selectedTask)) {
      return dispatch(assetVerificationTaskActions.destroy({ loanFileId, id }))
    } else if (creditTask(selectedTask)) {
      return dispatch(deleteCreditTask(loanFileId, id))
    } else if (preApprovalTask(selectedTask)) {
      return dispatch(deletePreApprovalTask({ loanFileId, id }))
    } else if (paymentTask(selectedTask)) {
      return dispatch(destroyPaymentTask({ loanFileId, taskId: id }))
    } else if (employmentIncomeVerificationTask(selectedTask)) {
      return dispatch(deleteEmploymentIncomeVerificationTask({ loanFileId, taskId: id }))
    } else {
      return dispatch(deleteStandardTask(id))
    }
  }
}

function deleteStandardTask(taskId = null) {
  return (dispatch, getState) => {
    const { selectedTask } = getState().v2Tasks
    taskId = taskId || selectedTask.id
    const loanFileId = getLoanFileId(getState())
    const url = Route.api.tasks.destroy({ loanFileId, taskId })
    const req = api(getState).delete(url)

    req
      .then(res => {
        const { tasks } = res.data

        const completedTasks = filterCompleteTasks(tasks)

        dispatch(setCompleteTasks(completedTasks))
        dispatch(fetchIncompleteTasks(loanFileId))
        dispatch(toggleDeleteTaskModal(false))

        toastr.success(`Successfully removed ${selectedTask.title}`)
      })
      .catch(error => {
        const { errors } = error?.response?.data || {}

        console.error(errors)
        if (errors) toastrErrors(errors)
      })
  }
}

export function importStatements(connection, selectedStatementIds) {
  return (dispatch, getState) => {
    const url = Route.api.filethis.connections.importStatements(connection)

    const data = { statement_ids: selectedStatementIds }
    const req = api(getState).post(url, data)

    req
      .then(() => {
        window.location = connection.redirectUrl
      })
      .catch(error => {
        const { errors } = error?.response?.data || {}

        console.error(errors)

        if (errors) toastrErrors(errors)
      })
  }
}

// Not puting, much thought as this is temprory solution.
function post(path, params) {
  const form = document.createElement('form')
  form.setAttribute('method', 'post')
  form.setAttribute('action', path)

  each(params, (value, key) => {
    const hiddenField = document.createElement('input')
    hiddenField.setAttribute('type', 'hidden')
    hiddenField.setAttribute('name', key)
    hiddenField.setAttribute('value', params[key])
    form.appendChild(hiddenField)
  })
  document.body.appendChild(form)
  form.submit()
}

export function addTaskToList(newTask) {
  return (dispatch, getState) => {
    const { incompleteTasks } = getState().v2Tasks

    const updatedTasksList = incompleteTasks.concat(newTask)
    dispatch(setIncompleteTasks(filterIncompleteTasks(updatedTasksList)))
  }
}

export function updateTasksList(updatedTask) {
  return (dispatch, getState) => {
    const { incompleteTasks, completeTasks } = getState().v2Tasks

    const updatedTasksList = [...incompleteTasks, ...completeTasks].map((task) => {
      return Number(task.id) === Number(updatedTask.id) && task.type === updatedTask.type
        ? updatedTask
        : task
    })

    dispatch(setIncompleteTasks(filterIncompleteTasks(updatedTasksList)))
    dispatch(setCompleteTasks(filterCompleteTasks(updatedTasksList)))
  }
}

export function deleteTaskFromList(taskId) {
  return (dispatch, getState) => {
    const { incompleteTasks, completeTasks } = getState().v2Tasks

    const updatedTasksList = [...incompleteTasks, ...completeTasks].filter((task) => {
      return Number(task.id) !== Number(taskId)
    })

    dispatch(setIncompleteTasks(filterIncompleteTasks(updatedTasksList)))
    dispatch(setCompleteTasks(filterCompleteTasks(updatedTasksList)))
  }
}
