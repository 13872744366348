import _ from 'underscore'
import AT from '../../actionTypes'

const initialState = []


const addPrefillResponsesToDirtyRecords = (state, payload) => {
  const newResponses = []
  const newState = [...state]
  Object.keys(payload).forEach(questionId => {
    const questionIndex = _.findIndex(newState, dirtyRecord => dirtyRecord.templateQuestionId === questionId)
    if (questionIndex > -1) {
      newState[questionIndex] = { ...newState[questionIndex], updatedAt: Date.now() }
    } else {
      newResponses.push({ updatedAt: Date.now(), templateQuestionId: parseInt(questionId, 10) })
    }
  })
  return [...newState, ...newResponses]
}

export default function dirtyRecords(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case AT.UPDATE_PREFILL_RESPONSES:
      return addPrefillResponsesToDirtyRecords(state, payload)
    case AT.UPDATE_LOAN_APP_RESPONSE_VALUE: {
      let found = false
      let res = state.map((rec) => {
        if (rec.templateQuestionId === payload.templateQuestionId) {
          found = true
          return { ...rec, updatedAt: Date.now() }
        } else {
          return rec
        }
      })
      if (!found) {
        res = [...res, { templateQuestionId: payload.templateQuestionId, updatedAt: Date.now() }]
      }
      return res
    }
    case AT.LOAN_APP_RESPONSE_SET_SYNC_TIME: {
      let found = false
      let res = state.map((rec) => {
        if (rec.templateQuestionId === payload.templateQuestionId) {
          found = true
          return { ...rec, syncedAt: payload.syncedAt }
        } else {
          return rec
        }
      })
      if (!found) {
        res = [...res, { templateQuestionId: payload.templateQuestionId, syncedAt: payload.syncedAt }]
      }
      return res
    }
    default:
      return state
  }
}
