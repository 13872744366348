import { createSlice } from '@reduxjs/toolkit'
import { sortBy } from 'underscore'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'

import { indexFilters, createFilter, destroyFilter } from './asyncActions'

const filterSlice = createSlice({
  name: 'filter',
  initialState: baseInitialState,
  reducers: baseReducers,
  extraReducers: {
    [indexFilters.fulfilled](state, action) {
      state.records = action.payload
    },
    [createFilter.fulfilled](state, action) {
      const index = state.records.findIndex((record) => {
        return record.title === action.payload.title
      })
      if (index !== -1) {
        state.records[index] = action.payload
      } else {
        state.records.push(action.payload)
        state.records = sortBy(state.records, (record) => record.title)
      }
    },
    [destroyFilter.fulfilled](state, action) {
      const id = action.meta.arg
      state.records = state.records.filter((r) => r.id !== id)
    },
  },
})

export default filterSlice
