import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'
import { statementImportCreateAPI, sendReconnectRequest } from './asyncActions'

export const statementImportSlice = createSlice({
  name: 'statement_import',
  initialState: baseInitialState,
  reducers: baseReducers,
  extraReducers: {
    [statementImportCreateAPI.pending](state) {
      state.uiFlags.isCreating = true
    },
    [statementImportCreateAPI.fulfilled](state, action) {
      state.stagedRecord = action.payload
      state.uiFlags.isCreating = false
    },
    [statementImportCreateAPI.rejected](state) {
      state.uiFlags.isCreating = false
    },
    [sendReconnectRequest.pending](state) {
      state.uiFlags.isUpdating = true
    },
    [sendReconnectRequest.fulfilled](state, action) {
      state.stagedRecord = action.payload
      state.uiFlags.isUpdating = false
    },
    [sendReconnectRequest.rejected](state) {
      state.uiFlags.isUpdating = false
    },
  },
})

export default statementImportSlice
