import { createActionsFromActionTypes } from 'utils/reducer_utils'
import Route from 'Services/Route'
import toastr from 'utils/toastr'

import AT, { insuranceTasks as actionTypes } from '../actionTypes'
import api from '../../utils/api'
import { fetchCommunications } from './communications'
import * as QuoteRequestsApi from '../BackendRequest/Api/LoanFiles/Tasks/QuoteRequests'

const reducerActions = createActionsFromActionTypes(actionTypes)

const ApiRoutes = Route.api.loanFile.insuranceTasks

export function showCreateModal() {
  return dispatch => dispatch(reducerActions.showCreateModal(true))
}

export function hideCreateModal() {
  return dispatch => dispatch(reducerActions.showCreateModal(false))
}

export const show = (props) => {
  return async (dispatch, getState) => {
    const url = ApiRoutes.show(props)
    const res = await api(getState).get(url)
    dispatch(reducerActions.setCurrentRecord(res.data.insuranceTask))
  }
}

export function create(values) {
  return async (dispatch, getState) => {
    const {
      v2LoanFiles: { loanFile: { id: loanFileId } },
    } = getState()

    const url = ApiRoutes.create({ loanFileId })
    const res = await api(getState).post(url, { insurance_task: values })
    const { insuranceTask } = res.data
    dispatch({ type: AT.CREATE_TASK, payload: insuranceTask })
    dispatch(fetchCommunications(loanFileId))
    toastr.success('Successfully created task!')
    dispatch(hideCreateModal())
  }
}

export function createQuoteRequest(params) {
  return async (dispatch, getState) => {
    const { v2: { insuranceTasks: { currentRecord } } } = getState()
    const insuranceTask = await QuoteRequestsApi.create(getState, currentRecord, params)

    if (!insuranceTask) return

    dispatch(reducerActions.setCurrentRecord(insuranceTask))
    toastr.success('Your insurance quote request has been sent and will be updated shortly.')

    setTimeout(() => {
      window.location.href = Route.borrowers.maxwellRoot()
    }, 1000)
  }
}
