import { duCaseFiles } from '../../actionTypes'

const initialState = []

export default function (state = initialState, action) {
  switch (action.type) {

    case duCaseFiles.set:
      return action.payload

    default:
      return state
  }
}
