import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'
import {
  showUnderwritingDecisionAPI,
  createUnderwritingDecisionAPI,
  indexUnderwritingDecisionsAPI,
  updateUnderwritingDecisionAPI,
} from './asyncActions'

export const ausUnderwritingDecisionsSlice = createSlice({
  name: 'ausUnderwritingDecisions',
  initialState: baseInitialState,
  reducers: baseReducers,
  extraReducers: {
    [createUnderwritingDecisionAPI.pending](state) {
      state.uiFlags.isCreating = true
    },
    [createUnderwritingDecisionAPI.fulfilled](state, action) {
      state.records = [...state.records, action.payload]
      state.uiFlags.isCreating = false
    },
    [createUnderwritingDecisionAPI.rejected](state) {
      state.uiFlags.isCreating = false
    },
    [indexUnderwritingDecisionsAPI.pending](state) {
      state.uiFlags.isFetching = true
    },
    [indexUnderwritingDecisionsAPI.fulfilled](state, action) {
      action.payload.forEach(record => {
        const { id } = record
        const recordIndex = state.records.findIndex(
          ({ id: decisionId }) => String(decisionId) === String(id)
        )
        if (recordIndex !== -1) {
          state.records[recordIndex] = record
        } else {
          state.records.push(record)
        }
      })
      state.uiFlags.isFetching = false
    },
    [indexUnderwritingDecisionsAPI.rejected](state) {
      state.uiFlags.isFetching = false
    },
    [showUnderwritingDecisionAPI.pending](state) {
      state.uiFlags.isFetchingItem = true
    },
    [showUnderwritingDecisionAPI.fulfilled](state, action) {
      state.stagedRecord = action.payload
      const { id } = action.payload

      const recordIndex = state.records.findIndex(
        ({ id: decisionId }) => String(decisionId) === String(id)
      )
      if (recordIndex !== -1) {
        state.records[recordIndex] = action.payload
      } else {
        state.records.push(action.payload)
      }

      state.uiFlags.isFetchingItem = false
    },
    [showUnderwritingDecisionAPI.rejected](state) {
      state.uiFlags.isFetchingItem = false
    },
    [updateUnderwritingDecisionAPI.pending](state) {
      state.uiFlags.isUpdating = true
    },
    [updateUnderwritingDecisionAPI.fulfilled](state, action) {
      const { id } = action.payload

      const recordIndex = state.records.findIndex(
        ({ id: decisionId }) => String(decisionId) === String(id)
      )
      if (recordIndex !== -1) {
        state.records[recordIndex] = action.payload
      }

      state.uiFlags.isUpdating = false
    },
    [updateUnderwritingDecisionAPI.rejected](state) {
      state.uiFlags.isUpdating = false
    },
  },
})

export default ausUnderwritingDecisionsSlice.reducer
