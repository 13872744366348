import { combineReducers } from 'redux'
import records from './records'
import uiFlags from './uiFlags'
import stagedRecord from './stagedRecord'

export default combineReducers({
  records,
  uiFlags,
  stagedRecord,
})
