import api from 'utils/api'
import Route from 'Services/Route'

import { toastrErrors } from 'utils/error_utils'
import AT from '../actionTypes/'


export function setDownloads(downloads) {
  return { type: AT.SET_V2_DOWNLOADS, payload: downloads }
}

export function setDownloadLoading(bool = false) {
  return { type: AT.SET_V2_DOWNLOAD_LOADING, payload: bool }
}

export function addDownload(download) {
  return { type: AT.ADD_V2_DOWNLOAD, payload: download }
}

export function setDownloadMetadata(payload) {
  return { type: AT.SET_V2_DOWNLOAD_METADATA, payload: payload }
}

function completedDownloadCount(downloads) {
  if (typeof downloads === 'undefined' || !downloads.length) return 0

  return downloads.filter(download => download.completed).length
}

function pendingDownloadCount(downloads) {
  if (typeof downloads === 'undefined' || !downloads.length) return 0

  return completedDownloadCount(downloads) - downloads.filter(download => download.processing).length
}

export function fetchDownloads(loanFileId = null) {
  return (dispatch, getState) => {
    if (!getState().v2Downloads.downloads.length)
      dispatch(setDownloadLoading(true))

    const id = loanFileId || getState().v2LoanFiles.loanFile.id
    const url = Route.api.downloads.index({ id })
    const req = api(getState).get(url)

    req.then((res) => {
      const { downloads } = res.data

      const metadata = {
        pendingDownloadCount: pendingDownloadCount(downloads),
        completedDownloadCount: completedDownloadCount(downloads),
      }

      const isIncompleteDownloads = downloads.filter(download => download.processing).length > 0
      let isPolling

      if (isIncompleteDownloads && isPolling == null) {
        // Start polling for download changes
        setTimeout(() => {
          dispatch(fetchDownloads(loanFileId))
        }, 2500)
        isPolling = true
      } else {
        isPolling = false
      }

      dispatch(setDownloadMetadata(metadata))
      dispatch(setDownloads(downloads))
      dispatch(setDownloadLoading(false))
    }).catch((error) => {
      dispatch(setDownloadLoading(false))
      const { errors } = error?.response?.data || {}

      console.info(errors)
      if (errors) toastrErrors(errors)
    })
  }
}
