import toastr from 'utils/toastr'

import api from 'utils/api'
import { toastrErrors } from 'utils/error_utils'
import { createAction } from 'utils/reducer_utils'
import Route from 'Services/Route'
import { getStagedLoanAppTemplate } from 'v2/selectors/loan_apps/templates'
import { getUserInfo } from 'v2/selectors/page_info'

import AT from '../../actionTypes'
import {
  clone,
  create,
  index,
  show,
  update,
} from '../../BackendRequest/LoanAppTemplate'

import {
  setLoanAppTemplateSections,
  setCurrentLoanAppTemplateSection,
} from './template_sections'

import { setLoanAppTemplateQuestions } from './template_questions'
import { setLoanAppResponses } from './responses'

export function fetchLoanAppTemplates(templateType) {
  return (dispatch) => {
    dispatch(toggleLoanAppTemplateLoading(true))

    index(templateType)
      .done((data) => {
        dispatch(setLoanAppTemplates(data.loanAppTemplates))
        dispatch(toggleLoanAppTemplateLoading(false))
      })
      .fail((data) => {
        dispatch(toggleLoanAppTemplateLoading(false))
        toastrErrors(data.responseJSON.errors)
      })
  }
}

export function fetchLoanAppTemplate(id) {
  return (dispatch) => {
    dispatch(toggleLoanAppTemplateLoading(true))
    show({ id })
      .done((data) => {
        const responses = data.loanAppTemplateQuestions.map((q) => {
          return { templateQuestionId: q.id }
        })
        const { loanAppTemplateSections } = data
        const currentSection = loanAppTemplateSections[0]
        // const hash = window.location.hash.slice(1)
        // currentSection = find(loanAppTemplateSections, sec => sec.name === hash) || currentSection

        dispatch(setLoanAppTemplates([data.loanAppTemplate]))
        dispatch(setStagedLoanAppTemplate(data.loanAppTemplate))
        dispatch(setLoanAppTemplateSections(loanAppTemplateSections))
        dispatch(setCurrentLoanAppTemplateSection(currentSection))
        dispatch(setLoanAppTemplateQuestions(data.loanAppTemplateQuestions))
        dispatch(setLoanAppResponses(responses))
        dispatch(toggleLoanAppTemplateLoading(false))
      })
      .fail((data) => {
        dispatch(toggleLoanAppTemplateLoading(false))
        toastrErrors(data.responseJSON.errors)
      })

  }
}

export function createLoanAppTemplate(templateType) {
  return (dispatch, getState) => {
    const loanAppTemplate = getStagedLoanAppTemplate(getState())
    const { slug } = getUserInfo(getState())

    dispatch(toggleCreateLoanAppModalSubmitting(true))
    create(loanAppTemplate, templateType)
      .done((data) => {
        toastr.success('Loan application template added successfully')
        dispatch({ type: AT.ADD_LOAN_APP_TEMPLATE, payload: data.loanAppTemplate })
        dispatch(endLoanAppTemplateCreate())
        dispatch(toggleCreateLoanAppModalSubmitting(false))
        window.location.href = Route
          .lenders
          .loanApp
          .templates
          .show({ ...data.loanAppTemplate, slug })
      })
      .fail((data) => {
        toastrErrors(data.responseJSON.errors)
        dispatch(toggleCreateLoanAppModalSubmitting(false))
      })
  }
}

export function updateLoanAppTemplateChildLock(loanAppTemplate, childTemplateLocked) {
  return (dispatch) => {
    const { id } = loanAppTemplate
    dispatch(editLoanAppTemplate({ ...loanAppTemplate, childTemplateLocked }))
    update({ id, childTemplateLocked })
      .done((data) => {
        toastr.success('Loan application updated successfully!')
        dispatch(editLoanAppTemplate(data.loanAppTemplate))
      })
      .fail((data) => {
        dispatch(editLoanAppTemplate(loanAppTemplate))
        toastrErrors(data.responseJSON.errors)
      })
  }
}

export function updateLoanAppVisibleToTeams(loanAppTemplate, visibleToTeams) {
  return (dispatch) => {
    const { id } = loanAppTemplate
    dispatch(editLoanAppTemplate({ ...loanAppTemplate, visibleToTeams }))
    update({ id, visibleToTeams })
      .done((data) => {
        toastr.success('Loan application updated successfully!')
        dispatch(editLoanAppTemplate(data.loanAppTemplate))
      })
      .fail((data) => {
        dispatch(editLoanAppTemplate(loanAppTemplate))
        toastrErrors(data.responseJSON.errors)
      })
  }
}

export function showLoanAppVisibleToTeamsError() {
  return () => {
    toastrErrors('At least one loan application must be visible for teams.')
  }
}

export function updateLoanAppTemplate() {
  return (dispatch, getState) => {
    const loanAppTemplate = getStagedLoanAppTemplate(getState())
    dispatch(toggleUpdateLoanAppModalSubmitting(true))
    update(loanAppTemplate)
      .done((data) => {
        toastr.success('Loan application template updated successfully')
        dispatch(editLoanAppTemplate(data.loanAppTemplate))
        dispatch(endLoanAppTemplateUpdate())
        dispatch(toggleUpdateLoanAppModalSubmitting(false))
      })
      .fail((data) => {
        toastrErrors(data.responseJSON.errors)
        dispatch(toggleUpdateLoanAppModalSubmitting(false))
      })
  }
}

export function startLoanAppTemplateCreate() {
  return (dispatch) => {
    dispatch(setStagedLoanAppTemplate({}))
    dispatch(toggleLoanAppTemplateCreateModal(true))
  }
}

export function endLoanAppTemplateCreate() {
  return (dispatch) => {
    dispatch(toggleLoanAppTemplateCreateModal(false))
    dispatch(setStagedLoanAppTemplate({}))
  }
}

export function startLoanAppTemplateUpdate(loanAppTemplate) {
  return (dispatch) => {
    dispatch(setStagedLoanAppTemplate({ ...loanAppTemplate }))
    dispatch(toggleLoanAppTemplateUpdateModal(true))
  }
}

export function endLoanAppTemplateUpdate() {
  return (dispatch) => {
    dispatch(toggleLoanAppTemplateUpdateModal(false))
    dispatch(setStagedLoanAppTemplate({}))
  }
}

export function updateStagedLoanAppTemplate({ target: { name, value } }) {
  const updates = { [name]: value }
  return { type: AT.UPDATE_STAGED_LOAN_APP_TEMPLATE, payload: updates }
}

export function cloneLoanAppTemplate(templateType) {
  return (dispatch, getState) => {
    const loanAppTemplate = getStagedLoanAppTemplate(getState())
    const { slug } = getUserInfo(getState())

    dispatch(toggleCloneLoanAppModalSubmitting(true))
    clone(loanAppTemplate, templateType)
      .done((data) => {
        toastr.success('Loan application template cloned successfully')
        dispatch({ type: AT.ADD_LOAN_APP_TEMPLATE, payload: data.loanAppTemplate })
        dispatch(endLoanAppTemplateClone())
        dispatch(toggleCloneLoanAppModalSubmitting(false))
        window.location.href = Route
          .lenders
          .loanApp
          .templates
          .show({ ...data.loanAppTemplate, slug })
      })
      .fail((data) => {
        toastrErrors(data.responseJSON.errors)
        dispatch(toggleCloneLoanAppModalSubmitting(false))
      })
  }
}

export function startLoanAppTemplateClone(template) {
  return (dispatch) => {
    dispatch(setStagedLoanAppTemplate({ ...template, title: '' }))
    dispatch(toggleLoanAppTemplateCloneModal(true))
  }
}

export function endLoanAppTemplateClone() {
  return (dispatch) => {
    dispatch(toggleLoanAppTemplateCloneModal(false))
    dispatch(setStagedLoanAppTemplate({}))
  }
}

export function startLoanAppTemplateDestroy(template) {
  return (dispatch) => {
    dispatch(setStagedLoanAppTemplate({ ...template }))
    dispatch(toggleLoanAppTemplateDestroyModal(true))
  }
}

export function submitLoanAppTemplateDestroy() {
  return (dispatch, getState) => {
    const { id: templateId } = getStagedLoanAppTemplate(getState())
    dispatch(toggleDestroyLoanAppModalSubmitting(true))

    const url = Route.api.loanApp.templates.destroy({ templateId })
    const req = api(getState).delete(url)

    req.then(({ data }) => {
      const { loanAppTemplate } = data
      toastr.success('Loan application template destroyed successfully')
      dispatch(destroyLoanAppTemplate(loanAppTemplate))
      dispatch(endLoanAppTemplateDestroy())
    }).catch((error) => {
      const { errors } = error?.response?.data || {}
      console.error(errors)
      if (errors) toastrErrors(errors)
    })
  }
}

export function endLoanAppTemplateDestroy() {
  return (dispatch) => {
    dispatch(toggleLoanAppTemplateDestroyModal(false))
    dispatch(toggleDestroyLoanAppModalSubmitting(false))
    dispatch(setStagedLoanAppTemplate({}))
  }
}

const toggleLoanAppTemplateUpdateModal = createAction(AT.SHOW_UPDATE_LOAN_APP_TEMPLATE_MODAL)
const toggleCreateLoanAppModalSubmitting = createAction(AT.TOGGLE_CREATE_LOAN_APP_TEMPLATE_MODAL_SUBMITTING)
const setStagedLoanAppTemplate = createAction(AT.SET_STAGED_LOAN_APP_TEMPLATE)
const toggleLoanAppTemplateLoading = createAction(AT.LOAN_APP_TEMPLATE_LOADING)
const toggleLoanAppTemplateCreateModal = createAction(AT.SHOW_CREATE_LOAN_APP_TEMPLATE_MODAL)
const editLoanAppTemplate = createAction(AT.EDIT_LOAN_APP_TEMPLATE)
const toggleCloneLoanAppModalSubmitting = createAction(AT.TOGGLE_CLONE_LOAN_APP_TEMPLATE_MODAL_SUBMITTING)
const toggleLoanAppTemplateCloneModal = createAction(AT.SHOW_CLONE_LOAN_APP_TEMPLATE_MODAL)
const toggleDestroyLoanAppModalSubmitting = createAction(AT.TOGGLE_DESTROY_LOAN_APP_TEMPLATE_MODAL_SUBMITTING)
const toggleLoanAppTemplateDestroyModal = createAction(AT.SHOW_DESTROY_LOAN_APP_TEMPLATE_MODAL)
const destroyLoanAppTemplate = createAction(AT.DESTROY_LOAN_APP_TEMPLATE)
const setLoanAppTemplates = createAction(AT.SET_LOAN_APP_TEMPLATES)
const toggleUpdateLoanAppModalSubmitting = createAction(AT.TOGGLE_UPDATE_LOAN_APP_TEMPLATE_MODAL_SUBMITTING)
