import { combineReducers } from 'redux'
import answerSlice from './answer'
import lenderSlice from './lender'
import loanAppSlice from './loanApp'
import participantsSlice from './participant'
import questionSlice from './question'
import sectionSlice from './section'
import taskSlice from './task'
import taskItemSlice from './taskItem'
import templateSlice from './template'
import userSlice from './user'

const loanApplicationsV2 = combineReducers({
  answers: answerSlice.reducer,
  lender: lenderSlice.reducer,
  loanApp: loanAppSlice.reducer,
  participants: participantsSlice.reducer,
  questions: questionSlice.reducer,
  sections: sectionSlice.reducer,
  tasks: taskSlice.reducer,
  taskItems: taskItemSlice.reducer,
  templates: templateSlice.reducer,
  user: userSlice.reducer,
})

export default loanApplicationsV2
