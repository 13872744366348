import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'
import { getBIQuestionsPageNumber } from './selectors'

export const fetchNextQuestionsPage = createAsyncThunk(
  'bi/questions/index',
  async (_params, thunkAPI) => {
    let pageNumber = getBIQuestionsPageNumber(thunkAPI.getState())
    pageNumber += 1
    const { data, meta: { recordCount } } = await JSONAPIClient.all('bi_question').get({
      fields: { bi_questions: ['text', 'status', 'updated_at'] },
      page: { number: pageNumber },
      sort: '-updated_at',
    })
    return { data, pageNumber, recordCount }
  },
)

export const showQuestion = createAsyncThunk(
  'bi/questions/show',
  async (id) => {
    const { data } = await JSONAPIClient.one('bi_question', id).get()
    return data
  },
)

export const createQuestion = createAsyncThunk(
  'bi/questions/create',
  async (payload) => {
    const { data } = await JSONAPIClient.create('bi_question', payload)
    return data
  },
)
