import { quickPricerScenarios } from 'v2/actionTypes'

const initialState = {}

export default function (state = initialState, action) {
  switch (action.type) {
    case quickPricerScenarios.setStagedRecord: {
      const { id, searchId } = action.payload
      return {
        ...state,
        [id]: { searchId },
      }
    }
    default:
      return state
  }
}
