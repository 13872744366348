import AT from 'actionTypes/'

const initialState = {
  step: '',
}

export default function step(state = initialState, action) {
  switch (action.type) {
    case AT.SET_STEP: {
      return { ...state, step: action.payload }
    }
    default: {
      return state
    }
  }
}
