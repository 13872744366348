import api from 'utils/api'
import toastr from 'utils/toastr'
import { createAction } from 'utils/reducer_utils'
import { toastrErrors } from 'utils/error_utils'
import { sortTeams } from 'utils/team_utils'
import { push, remove, replace } from 'Services/ImmutatingHelpers'
import Route from 'Services/Route'
import { index as teamsIndex } from '../../BackendRequest/Organization/AllTeams'
import { index as loanFilesIndex } from '../../BackendRequest/Team/ActiveLoanFiles'

export const setActiveTeams = createAction('SET_ACTIVE_TEAMS', sortTeams)
export const setFilteredActiveTeams = createAction('SET_FILTERED_ACTIVE_TEAMS', sortTeams)
export const setFilteredDestinationTeams = createAction('SET_FILTERED_DESTINATION_TEAMS', sortTeams)
export const setSelectedTeamsAndLoans = createAction('SET_SELECTED_TEAMS_AND_LOANS')
export const setFilteredSelectedTeamsLoanFiles = createAction('SET_FILTERED_SELECTED_TEAM_LOAN_FILES')
export const setReassigningTeam = createAction('SET_REASSIGNING_TEAM')
export const setReassignToTeam = createAction('SET_REASSIGN_TO_TEAM')
export const toggleReassignLoanModal = createAction('TOGGLE_REASSIGN_LOAN_MODAL')
export const toggleReassignLoanModalSaving = createAction('TOGGLE_REASSIGN_LOAN_MODAL_SAVING')

export function setFilteredTeamLoanFiles(filteredLoanFiles, teamId) {
  return (dispatch, getState) => {
    const loanManager = getState().v2.loanManager.records
    const { filteredSelectedTeamsLoanFiles } = loanManager
    const newFilteredSelectedTeamsLoanFiles = replace(filteredSelectedTeamsLoanFiles, teamId, filteredLoanFiles)
    dispatch(setFilteredSelectedTeamsLoanFiles(newFilteredSelectedTeamsLoanFiles))
  }
}

export function selectLoanFileToReassign({ loanFileId, teamId }) {
  return (dispatch) => {
    Promise.all([
      dispatch(setReassigningTeam({
        selectedReassignFile: loanFileId,
        selectedReassigningFromTeam: teamId,
      })),
      dispatch(toggleReassignLoanModal(true)),
    ])
  }
}

export function fetchActiveTeams(orgId) {
  return async (dispatch, getState) => {
    const activeTeams = await teamsIndex(getState, orgId)
    if (!activeTeams) return
    dispatch(setActiveTeams(activeTeams))
  }
}

export function toggleSelectedTeam(teamId = null) {
  return (dispatch, getState) => {
    const loanManager = getState().v2.loanManager.records
    const {
      selectedTeams,
      selectedTeamsLoanFiles,
      filteredSelectedTeamsLoanFiles,
    } = loanManager
    const existingIndex = selectedTeams.indexOf(teamId)

    // If team was already opened, clear the data
    if (existingIndex !== -1) {
      const newSelectedTeams = remove(selectedTeams, existingIndex)
      const newSelectedTeamLoanFiles = remove(selectedTeamsLoanFiles, teamId)
      const newFilteredSelectedTeamsLoanFiles = remove(filteredSelectedTeamsLoanFiles, teamId)

      const newTeamsAndLoans = {
        selectedTeams: newSelectedTeams,
        selectedTeamsLoanFiles: newSelectedTeamLoanFiles,
        filteredSelectedTeamsLoanFiles: newFilteredSelectedTeamsLoanFiles,
      }
      dispatch(setSelectedTeamsAndLoans(newTeamsAndLoans))
    } else {
      const newSelectedTeams = push(selectedTeams, teamId)
      const newTeamsAndLoans = {
        ...loanManager,
        selectedTeams: newSelectedTeams,
      }

      Promise.all([
        dispatch(setSelectedTeamsAndLoans(newTeamsAndLoans)),
        dispatch(fetchActiveLoanFilesForTeam(teamId)),
      ])
    }
  }
}

export function fetchActiveLoanFilesForTeam(teamId = null) {
  return async (dispatch, getState) => {
    const { records } = getState().v2.loanManager
    const {
      selectedTeamsLoanFiles,
      filteredSelectedTeamsLoanFiles,
    } = records

    const loanFiles = await loanFilesIndex(getState, teamId)
    if (!loanFiles) return

    const newSelectedTeamLoanFiles = replace(selectedTeamsLoanFiles, teamId, loanFiles)
    const newFilteredSelectedTeamsLoanFiles = replace(filteredSelectedTeamsLoanFiles, teamId, loanFiles)
    const newData = {
      ...records,
      selectedTeamsLoanFiles: newSelectedTeamLoanFiles,
      filteredSelectedTeamsLoanFiles: newFilteredSelectedTeamsLoanFiles,
    }
    dispatch(setSelectedTeamsAndLoans(newData))
  }
}

export function moveLoanFileToTeam(loanFileId = null, teamId = null) {
  return (dispatch, getState) => {
    dispatch(toggleReassignLoanModalSaving(true))

    const url = Route.api.loanFiles.reassignments({ loanFileId })
    const data = { teamId }
    const req = api(getState).post(url, data)

    req.then((res) => {
      const { teams } = res.data
      Promise.all([
        dispatch(setActiveTeams(teams)),
        dispatch(resetLoanManagerModal()),
      ])
      toastr.success('Successfully moved loan file')
    }).catch((error) => {
      const { errors } = error?.response?.data || {}
      console.info(errors)
      if (errors) toastrErrors(errors)
    })
  }
}

export function resetLoanManagerModal() {
  return (dispatch) => {
    Promise.all([
      dispatch(toggleReassignLoanModal(false)),
      dispatch(setReassignToTeam(undefined)),
      dispatch(setReassigningTeam({
        selectedReassignFile: undefined,
        selectedReassigningFromTeam: undefined,
      })),
      dispatch(setSelectedTeamsAndLoans({
        selectedTeams: [],
        selectedTeamsLoanFiles: {},
        filteredSelectedTeamsLoanFiles: {},
      })),
      dispatch(toggleReassignLoanModalSaving(false)),
    ])
  }
}
