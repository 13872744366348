import Route from 'Services/Route'
import toastr from 'utils/toastr'
import api from 'utils/api'

import { createAction } from 'utils/reducer_utils'
import { toastrErrors } from 'utils/error_utils'

import AT from '../actionTypes/'

export const setStagedEmailTemplate = createAction('SET_STAGED_EMAIL_TEMPLATE')
export const updateStagedEmailTemplate = createAction('UPDATE_STAGED_EMAIL_TEMPLATE')
export const setBorrowerEmailTemplates = createAction('SET_BORROWER_EMAIL_TEMPLATES')
export const setLenderEmailTemplates = createAction('SET_LENDER_EMAIL_TEMPLATES')
export const setRealtorEmailTemplates = createAction('SET_REALTOR_EMAIL_TEMPLATES')

export function toggleEmailTemplateModal(payload = false) {
  return { type: AT.TOGGLE_EMAIL_TEMPLATE_MODAL, payload: payload }
}

export function toggleEmailTemplateReviewModal(payload = false) {
  return { type: AT.TOGGLE_EMAIL_TEMPLATE_REVIEW_MODAL, payload: payload }
}

export function fetchEmailTemplates({ organizationId }) {
  return async (dispatch, getState) => {
    const url = Route.api.masterLoanFileTemplates.emailTemplates.index({ organizationId })

    const req = api(getState).get(url)

    return req.then((res) => {
      dispatch(setEmailTemplates(res.data))
    })
  }
}

export function updateEmailTemplate() {
  return (dispatch, getState) => {
    const { stagedRecord } = getState().v2.emailTemplates
    const { stagedRecord: organization } = getState().v2.organizations
    const url = Route.api.masterLoanFileTemplates.emailTemplates.update({
      ...stagedRecord,
      organizationId: organization.id,
    })
    const {
      id,
      content,
      type,
      status,
    } = stagedRecord

    const values = {
      id,
      content,
      type,
      status,
    }

    const req = api(getState).put(url, values)

    req.then((res) => {
      const {
        emailTemplate,
      } = res.data

      dispatch(setStagedEmailTemplate(emailTemplate))
      dispatch(setEmailTemplates(res.data))

      toastr.success(`Successfully updated ${emailTemplate.displayName}`)
    }).catch((error) => {
      const errors = error?.response?.data || null

      console.info(errors)
      if (errors) toastrErrors(errors)
    })
  }
}

export function toggleEmailTemplateLock() {
  return (dispatch, getState) => {
    const { stagedRecord } = getState().v2.emailTemplates
    const { stagedRecord: organization } = getState().v2.organizations

    const {
      id,
      type,
      locked,
    } = stagedRecord

    const values = {
      id,
      type,
      locked,
    }

    const url = Route.api.masterLoanFileTemplates.emailTemplates.lock({
      ...stagedRecord,
      organizationId: organization.id,
    })
    const req = api(getState).put(url, values)

    req.then((res) => {
      const {
        template,
      } = res.data

      const lockedText = template.locked ? 'locked' : 'unlocked'
      toastr.success(`Team template editing is ${lockedText}`)

      dispatch(setEmailTemplates(res.data))
    }).catch((error) => {
      console.info(error)

      const errors = error?.response?.data || null

      if (errors) toastrErrors(errors)
    })
  }
}

export function toggleAllLocked(locked) {
  return (dispatch, getState) => {
    const url = Route.api.masterLoanFileTemplates.emailTemplates.lockAll()

    const values = { locked: locked }

    const req = api(getState).post(url, values)

    req.then((res) => {
      dispatch(setEmailTemplates(res.data))

      toastr.success('Successfully toggled lock status for all email templates')
    }).catch((error) => {
      console.info(error)

      const errors = error?.response?.data || null

      if (errors) toastrErrors(errors)
    })
  }
}

export function deployEmailTemplates(organizationId) {
  return async (dispatch, getState) => {
    const url = Route.api.organizations.emailTemplates.deploy.create({ organizationId })
    try {
      await api(getState).post(url)
    } catch (error) {
      const { data = {} } = error.response || {}
      const { errors } = data

      if (errors) {
        return toastrErrors(errors)
      }
    }
  }
}

export function setEmailTemplates({borrowerEmailTemplates, lenderEmailTemplates, realtorEmailTemplates}) {
  return (dispatch) => {
    dispatch(setBorrowerEmailTemplates(borrowerEmailTemplates))
    dispatch(setLenderEmailTemplates(lenderEmailTemplates))
    dispatch(setRealtorEmailTemplates(realtorEmailTemplates))
  }
}
