import JSONAPIClient from '../JSONAPIClient'

JSONAPIClient.define('section', {
  button_text: '',
  description: '',
  display_logic: '',
  name: '',
  note: '',
  parent_id: '',
  parent: {
    jsonApi: 'hasOne',
    type: 'sections',
  },
  questions: {
    jsonApi: 'hasMany',
    type: 'questions',
  },
  question_ids: '',
  questions_count: '',
  section_type: '',
  title: '',
  validation: '',
  validation_message: '',
  accessible_by: '',
  position: '',
  custom: '',
})
