import api from 'utils/api'
import Route from 'Services/Route'

import { toastrErrors } from 'utils/error_utils'
import { setUserInfo } from './page_info'

import AT from '../actionTypes'

export function setSelectedTeam(id) {
  return { type: AT.SET_SELECTED_TEAM, payload: id }
}

export function setUpdatingSelectedTeam(flag = false) {
  return { type: AT.UPDATING_SELECTED_TEAM, payload: flag }
}
export function setOptionsForTeamSelect(options) {
  return { type: AT.SET_OPTIONS_FOR_TEAM_SELECT, payload: options }
}

export function fetchOptionsForTeamSelect(teamId = null) {
  return (dispatch, getState) => {
    const url = Route.api.team.optionsForSelect({ teamId })
    const req = api(getState).get(url)

    req.then((res) => {
      const { optionsForTeamSelect } = res.data

      dispatch(setOptionsForTeamSelect(optionsForTeamSelect))
      dispatch(setUpdatingSelectedTeam(false))
    }).catch((error) => {
      dispatch(setUpdatingSelectedTeam(false))
      const { errors } = error?.response?.data || {}

      console.info(errors)
      if (errors) toastrErrors(errors)
    })
  }
}

export function updateSelectedTeam(teamId) {
  return (dispatch, getState) => {
    dispatch(setUpdatingSelectedTeam(true))

    const url = Route.api.teams.updateSelectedTeam({ teamId: teamId })
    const req = api(getState).put(url)

    req.then((res) => {
      const { data: { lender } } = res
      dispatch(setUserInfo(lender))
      dispatch(setSelectedTeam(teamId))
      dispatch(fetchOptionsForTeamSelect())
    }).catch((error) => {
      dispatch(setUpdatingSelectedTeam(false))
      const { errors } = error?.response?.data || {}

      console.info(errors)
      if (errors) toastrErrors(errors)
    })
  }
}
