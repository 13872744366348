import api from 'utils/api'
import Route from 'Services/Route'
import { toastrErrors } from 'utils/error_utils'

export function show(state, organizationId) {
  const url = Route.api.organization.show({ organizationId })
  const req = api(state).get(url)

  return req.then((res) => {
    const { organization } = res.data
    return organization
  }).catch((error) => {
    const { errors } = error?.response?.data || {}

    console.info(errors)
    if (errors) toastrErrors(errors)
  })
}
