import api from 'utils/api'

import Route from 'Services/Route'
import toastr from 'utils/toastr'
import {
  attachmentName,
  isAllowListedFileExtension,
} from 'utils/file_utils'
import { getLoanFile } from 'v2/selectors/loan_files'
import { getAttachments } from 'v2/selectors/box_files'
import { getV2SelectedTask } from 'v2/selectors/tasks'
import { getLoanFileId } from 'v2/selectors/page_info'
import { createAction } from 'utils/reducer_utils'
import { pluralize } from 'utils/string'
import { fetchAttachments } from 'v2/actions/s3attachments'
import { isCoborrower } from 'utils/user_type_utils'

import AT from '../actionTypes'
import {
  addCommunication,
  scrollToBottomOfMessenger,
} from './communications'

import { toggleFileUploadModal } from './tasks'

export function addSelectedAttachment(file) {
  return { type: AT.ADD_SELECTED_ATTACMENT, payload: file }
}

export function removeSelectedAttachment(file) {
  return { type: AT.REMOVE_SELECTED_ATTACMENT, payload: file }
}

export function setFileSelectorActiveTask(taskId) {
  return { type: AT.SET_FILE_SELECTOR_ACTIVE_TASK, taskId: taskId }
}

export function resetAttachments() {
  return { type: AT.RESET_ATTACHMENTS }
}

export const toggleAttachmentLoading = createAction(AT.SET_ATTACHMENT_LOADING)
export const toggleAttachmentSaving = createAction(AT.SET_ATTACHMENT_SAVING)
export const updateDocumentOnIncompleteTask = createAction(AT.UPDATE_DOCUMENT_ON_INCOMPLETE_TASK)
export const updateDocumentOnCompleteTask = createAction(AT.UPDATE_DOCUMENT_ON_COMPLETE_TASK)

export const attachDocumentToSelectedTask = (document) => {
  return (dispatch, getState) => {
    const selectedTask = getV2SelectedTask(getState())

    if (selectedTask.status === 'completed') {
      dispatch(updateDocumentOnCompleteTask(document))
    } else {
      dispatch(updateDocumentOnIncompleteTask(document))
    }
  }
}

export function createAttachments(message = '', attachments = []) {
  return async (dispatch, getState) => {
    const { id: loanFileId } = getState().v2LoanFiles.loanFile
    const url = Route.api.messenger.attachments({ loanFileId })

    const data = new FormData()
    data.append('message', message)
    attachments.forEach(attachment => data.append('attachments[]', attachment))
    try {
      const result = await api(getState).post(url, data)
      const { communication } = result.data
      dispatch(addCommunication(communication))
      dispatch(fetchAttachments({ loanFileId }))
    } catch (error) {
      toastr.error('There was an error sending the message. Please try again')
    }
  }
}

export const handleMessageWithAttachments = (message, attachments) => async dispatch => {
  await dispatch(createAttachments(message, attachments))
  scrollToBottomOfMessenger()
}

// function can use some refactoring. Separate the batch upload logic into a separate service file
export const uploadFiles = (files) => async (dispatch, getState) => {
  const { displayTitle, subtitle, requiredFor } = getState().v2Tasks.selectedTask
  const { borrowerName, loanProfile: { coborrowerName } } = getLoanFile(getState())
  const userName = isCoborrower(requiredFor) ? coborrowerName : borrowerName

  // Add spinner
  dispatch(toggleAttachmentLoading(true))

  // Iterate through files and send a separate request to box for each file
  // TODO : Look into box api method for multiple files...
  // we should make a single request to box with a bunch of attachments!
  const fileBatchInfo = {
    numberOfFiles: files.length,
    anotherFileProcessed: function () {
      this.numberOfFiles -= 1
      // All files uploaded then, remove spinner
      if (this.numberOfFiles <= 0) dispatch(toggleAttachmentLoading(false))
    },
  }

  const uploadTime = new Date()
  const promises = files.map((file, index, allFiles) => {
    if (!isAllowListedFileExtension(file)) {
      const supportedExtensions = 'We support doc, docx, pdf, xlsx, jpeg, jpg, png, svg, txt or zip.'

      setTimeout(() => {
        fileBatchInfo.anotherFileProcessed()
        toastr.error(`${file.name} is an unsupported file type. ${supportedExtensions}`, '', { timeOut: 5000 })
      }, 750)

      return
    }

    const fileTaskTitle = displayTitle !== 'Custom Document' ? displayTitle : subtitle.substring(0, 16)
    const fileName = attachmentName({
      attachment: file,
      taskUserName: userName,
      taskTitle: fileTaskTitle,
      uploadTime,
      docIndex: index,
      docCount: allFiles.length,
    })

    const attachment = Object.defineProperty(file, 'name', {
      writable: true,
      value: fileName,
    })
    fileBatchInfo.anotherFileProcessed()
    return dispatch(addSelectedAttachment(attachment))
  })
  await Promise.all(promises)
}

export function createDocumentsWithAttachments(losType) {
  return async (dispatch, getState) => {
    dispatch(toggleAttachmentSaving(true))

    const attachments = getAttachments(getState())
    const attachmentDispatches = []

    attachments.forEach(attachment => {
      attachmentDispatches.push(dispatch(createDocumentWithAttachment(attachment, losType)))
    })

    await Promise.all(attachmentDispatches)

    dispatch(toggleAttachmentSaving(false))
    dispatch(resetAttachments())
    dispatch(toggleFileUploadModal(false))
    toastr.options.timeOut = '3000'
    toastr.success(
      `Successfully uploaded and saved ${attachments.length} ${pluralize(attachments.length, 'document', 'documents')}`
    )
  }
}

export function createDocumentWithAttachment(file, losType) {
  return async (dispatch, getState) => {
    const { id: taskId, category } = getV2SelectedTask(getState())
    const loanFileId = getLoanFileId(getState())
    const url = Route.api.documentGroups.upload({ loanFileId, documentGroupId: taskId })
    const formData = new FormData()

    formData.append('document_key', file.name)
    formData.append('category', category.toLowerCase())
    formData.append('document_file', file, file.name)
    if (losType) formData.append('los_type', losType)

    const res = await api(getState).post(url, formData)
    const { attachment } = res.data
    dispatch(attachDocumentToSelectedTask({ ...attachment, documentGroupId: taskId }))
  }
}
