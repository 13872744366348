import { insuranceTasks } from '../../actionTypes/'

const initialState = {
  showCreateModal: false,
}

export default function uiFlags(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case insuranceTasks.showCreateModal:
      return { ...state, showCreateModal: payload }
    default:
      return state
  }
}
