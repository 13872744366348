import JSONAPIClient from '../JSONAPIClient'

JSONAPIClient.define('user', {
  name: '',
  email: '',
  phone: '',
  otp_required_for_login: '',
  mobile_phone_verified: '',
  locale: '',
})
