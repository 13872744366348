import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'

export const showTeamAPI = createAsyncThunk(
  'team/show',
  async (id) => {
    const response = await JSONAPIClient.one('team', id).get()
    return response
  }
)

export const updateTeamAPI = createAsyncThunk(
  'team/update',
  async ({ id, payload }) => {
    const response = await JSONAPIClient.update('team', { id, ...payload })
    return response
  }
)
