import { createAsyncThunk } from '@reduxjs/toolkit'
import toastr from 'utils/toastr'
import { toastrErrors } from 'utils/error_utils'
import JSONAPIClient from '../../apiClient'

// TO DO:  Update toasts for new lender loan file
export const statementImportCreateAPI = createAsyncThunk(
  'statementImport/create',
  async ({ taskId }) => {
    try {
      const { data } = await JSONAPIClient.all('statement_import').post({ taskId })
      toastr.success('Connection refreshed')
      return data
    } catch (error) {
      if (error) toastrErrors({ error: 'There was an error retreiving new documents' })
      return {}
    }
  }
)

export const sendReconnectRequest = createAsyncThunk(
  'statementImport/create',
  async ({ taskId }) => {
    try {
      const { data } = await JSONAPIClient.all('statement_import').post({ taskId })
      toastr.success('A request to reconnect email has been successfully sent to the borrower.')
      return data
    } catch (error) {
      if (error) toastrErrors({ error: 'There was an error sending the request to reconnect email.' })
      return {}
    }
  }
)
