import React from 'react'
import { findIndex, includes, isNull, isUndefined, sortBy } from 'underscore'
import { format, subMonths, subYears } from 'date-fns'
import { pluralize } from 'utils/string'
import { CLOSING_STATUS, TASK_ITEM_STATUS } from 'v2/constants/ClosingTask'
import { selectedDocumentGroupTemplate } from 'v2/actions/tasks'

export function totalTaskCount(
  tasks = [],
  signableDocuments = [],
  loanApps = [],
  employmentIncomeVerificationTasks = [],
  creditTasks = [],
  preApprovalLetterTasks = [],
  assetVerificationTasks = [],
  disclosuresTasks = [],
  paymentTasks = [],
  v2LoanAppTasks = [],
  closingTasks = [],
) {
  return tasks.length
   + signableDocuments.length
    + loanApps.length
    + employmentIncomeVerificationTasks.length
    + creditTasks.length
    + preApprovalLetterTasks.length
    + assetVerificationTasks.length
    + disclosuresTasks.length
    + paymentTasks.length
    + v2LoanAppTasks.length
    + closingTasks.length
}

const isCompletedClosingTask = (task, userId = null) => {
  const { CANCELED, CLOSED, SIGNING_COMPLETE } = CLOSING_STATUS
  const { COMPLETED } = TASK_ITEM_STATUS

  let isCompletedForBorrower = null
  if (userId) {
    const taskItem = task.taskItems?.find(item => item.userId === userId)
    if (taskItem
      && taskItem.previewStatus === COMPLETED
      && taskItem.esigningStatus === COMPLETED) {
      isCompletedForBorrower = true
    }
  }

  return task.type === 'ClosingsTask'
    && (
      task.status === CANCELED
      || task.status === CLOSED
      || task.status === SIGNING_COMPLETE
      || (userId && isCompletedForBorrower)
    )
}

const isNotReadyClosingTask = (task, userId) => {
  const { NOT_READY } = TASK_ITEM_STATUS
  const taskItem = task.taskItems?.find(item => item.userId === userId)
  return task.type === 'ClosingsTask' && taskItem
    && (taskItem.previewStatus === NOT_READY && taskItem.esigningStatus === NOT_READY)
}

export const calculateIncompleteTaskCount = ({
  assetVerificationTasks,
  creditTasks,
  closingTasks,
  disclosuresTasks,
  employmentIncomeVerificationTasks,
  incompleteTasks,
  loanApps,
  paymentTasks,
  preApprovalLetterTasks,
  signableDocuments,
}) => {
  return totalTaskCount(
    incompleteTasks,
    filterIncompleteTasks(signableDocuments.records),
    filterIncompleteTasks(loanApps.records),
    filterIncompleteTasks(employmentIncomeVerificationTasks.records),
    filterIncompleteTasks(creditTasks.records),
    filterIncompleteTasks(preApprovalLetterTasks.records),
    filterIncompleteTasks(assetVerificationTasks.records),
    filterIncompleteTasks(disclosuresTasks.records),
    filterIncompleteTasks(paymentTasks.records),
    filterIncompleteTasks(closingTasks.records),
  )
}

export const calculateCompletedTaskCount = ({
  completeTasks,
  signableDocuments,
  loanApps,
  employmentIncomeVerificationTasks,
  creditTasks,
  disclosuresTasks,
  closingTasks,
  paymentTasks,
  assetVerificationTasks,
}) => {
  return totalTaskCount(
    completeTasks,
    filterCompleteTasks(signableDocuments.records),
    filterCompleteTasks(closingTasks.records),
    filterCompleteTasks(loanApps.records),
    filterCompleteTasks(employmentIncomeVerificationTasks.records),
    filterCompleteTasks(creditTasks.records),
    filterCompleteTasks(disclosuresTasks.records),
    filterCompleteTasks(paymentTasks.records),
    filterCompleteTasks(assetVerificationTasks.records),
  )
}

export function filterIncompleteTasks(tasks, userId = null) {
  return tasks.filter(
    (task) => !task.isCompleted
      && !task.isRefunded
      && task.status !== 'completed'
      && !isCompletedClosingTask(task, userId),
  )
}

export function filterCompleteTasks(tasks, userId = null) {
  return tasks.filter(
    (task) => task.isCompleted
      || task.isRefunded
      || task.status === 'completed'
      || isCompletedClosingTask(task, userId),
  )
}

export function filterBorrowerViewableTasks(tasks, userId) {
  return tasks.filter((task) => {
    if (isNotReadyClosingTask(task, userId)) return false
    if (task.type === 'DisclosuresTask' || task.type === 'ClosingsTask') {
      return !!task.taskItems?.find(item => item.userId === userId)
    }
    return true
  })
}

export function sortAndFilterIncompleteTasks(tasks, userId = null) {
  return sortIncompleteTasks(filterIncompleteTasks(tasks, userId))
}

export function sortAndFilterCompleteTasks(tasks, userId = null) {
  return sortTaskByCompletionDate(filterCompleteTasks(tasks, userId))
}

export function sortIncompleteTasks(tasks) {
  // not most optimised, as sorting 2 times, but more readable and does not matter for small list.
  // this works because sort_by is stable sort.
  return sortTaskByDueDate(sortById(tasks))
}

export function sortCompleteTasks(tasks) {
  return sortTaskByDueDate(sortById(tasks))
}

export function sortTaskByDueDate(tasks) {
  return sortBy(tasks, (task) => {
    if (task.dueDate) {
      return new Date(task.dueDate)
    }
    return 0 // if dueDate is not present show them at the top
  })
}

export function sortTaskByCompletionDate(tasks) {
  return sortBy(tasks, (task) => {
    if (task.completionDate) {
      return new Date(task.completionDate)
    }
    return 0
  })
}

export function sortTaskByTimestampFloat(tasks) {
  return sortBy(tasks, 'timestampFloat')
}

export function sortById(tasks) {
  return sortBy(tasks, 'id')
}

export const NOTE_MERGE_TAGS = [
  { name: 'CURRENT_YEAR', tag: '{CURRENT_YEAR}' },
  { name: 'YEAR_TIME_PERIOD', tag: '{YEAR_TIME_PERIOD}' },
  { name: 'CUSTOM_TIME_PERIOD', tag: '{CUSTOM_TIME_PERIOD}' },
]

export const getPeriodText = (type, value) => {
  if (type === 'month') {
    const monthValue = parseInt(value, 10)
    return monthValue === 1 ? 'month' : `${monthValue} months`
  } else if (type === 'year') {
    const yearValue = Math.floor(parseInt(value, 10) / 12)
    return yearValue === 1 ? 'year' : `${yearValue} years`
  }
  return []
}

export const getProcessedNote = (note, timePeriod, timePeriodType) => {
  let notePreview = note || ''
  let showNotePreview = false
  const values = {}
  if (timePeriod) {
    const period = parseInt(timePeriod, 10)
    const years = []
    years[0] = format(subMonths(new Date(), period), 'yyyy')
    const endYear = format(subYears(new Date(), 1), 'yyyy')
    if (years[0] !== endYear) years[1] = endYear
    values.YEAR_TIME_PERIOD = years.join(' to ')
    values.CUSTOM_TIME_PERIOD = getPeriodText(timePeriodType, timePeriod)
  }
  values.CURRENT_YEAR = format(new Date(), 'yyyy')

  NOTE_MERGE_TAGS.forEach(mergeTag => {
    const regex = new RegExp(mergeTag.tag, 'g')
    const found = notePreview.match(regex)
    if (mergeTag.name in values) {
      notePreview = notePreview.replace(regex, values[mergeTag.name])
    }
    if (found) showNotePreview = true
  })
  return { showNotePreview, notePreview }
}

export function formattedNote(note, title = null, timePeriod = null, documentGroupTemplates = null) {
  if (!note) return null

  const getNewNote = () => {
    const selectedTemplate = selectedDocumentGroupTemplate(title, documentGroupTemplates)
    const { notePreview } = getProcessedNote(note, timePeriod, selectedTemplate.timePeriodSelectorType)
    return notePreview
  }

  const newNote = title && timePeriod !== null && documentGroupTemplates ? getNewNote() : note
  const splitNotes = newNote.split('\n')
  // eslint-disable-next-line react/no-array-index-key
  return splitNotes.map((string, index) => <p key={index}>{string}</p>)
}

export function getIndexById(objs, id) {
  return findIndex(objs, (obj) => {
    return obj.id === id
  })
}

export function updateTasks(tasks, updatedTask, index) {
  return [
    ...tasks.slice(0, index),
    ...tasks.slice(index + 1),
    updatedTask,
  ]
}

export function removeFilethisConnection(task, connections, index) {
  return {
    ...task,
    filethisConnections: [
      ...connections.slice(0, index),
      ...connections.slice(index + 1),
    ],
  }
}

export function addDocumentsToTask(task, documents, newDocuments) {
  return {
    ...task,
    documents: [
      ...documents,
      ...newDocuments,
    ],
  }
}

export function determineAssignees(taskAssignees, assignees) {
  const options = {
    borrower: assignees.borrower,
    coborrower: assignees.coborrower,
    'borrower & coborrower': assignees['borrower & coborrower'],
  }
  return options[taskAssignees]
}

export function homeInsuranceTask(task) {
  return task.title === 'Homeowners Insurance'
}

export function borrowerViewableSignableDocuments(signableDocuments) {
  return signableDocuments.filter(sd => includes(['sent', 'completed', 'declined'], sd.status))
}

export function assetVerificationTask(task) {
  return task.type === 'AssetVerificationTask'
}

export function creditTask(task) {
  return task.type === 'CreditTask'
}

export function disclosuresTask(task) {
  return task.type === 'DisclosuresTask'
}

export function preApprovalTask(task) {
  return task.type === 'PreApprovalLettersTask'
}

export function paymentTask(task) {
  return task.type === 'PaymentTask'
}

export function employmentIncomeVerificationTask(task) {
  return task.type === 'EmploymentIncomeVerification'
}

export function preApprovalTaskInitiated(task) {
  return task.status === 0
}

export const isSystemGeneratedDocument = doc => !doc.borrowerUploadedDocument && !doc.lenderUploadedDocument

export const getRequiredPeriod = (timePeriod, period) => {
  if (timePeriod) {
    const year = Math.floor(timePeriod / 12)
    const month = timePeriod % 12

    let displayText = month ? `${month} ${pluralize(month, 'month', 'months')}` : ''
    if (year) {
      displayText = `${year} ${pluralize(year, 'year', 'years')} ${displayText}`
    }
    return `Last ${displayText}`
  }
  return period
}

export function filterDefaultTeamMembers(participants) {
  return participants.reduce((acc, member) => {
    if (member.role === 'loan_officer') {
      return [member, ...acc]
    } else if (member.role === 'borrower' || member.role === 'coborrower') {
      return [...acc]
    }
    return [...acc, member]
  }, [])
}

export const isInvalidTimePeriod = timePeriod => {
  const hasTimePeriodValue = !(isUndefined(timePeriod) || isNull(timePeriod))
  const isNotAValidNumber = Number.isNaN(Number(timePeriod)) || Number(timePeriod) < 0
  return hasTimePeriodValue && isNotAValidNumber
}

export const formatDueDate = ({ dueDate, dueDateHighlightClass }) => {
  if (dueDateHighlightClass === 'danger') return 'Overdue'

  return `Due: ${dueDate}`
}
