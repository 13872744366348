import AT from '../../actionTypes/'

const initialState = {
  showEmailSignatureEditModal: false,
  showEmailSignatureConfirmationModal: false,
}

export default function uiFlags(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case AT.TOGGLE_EMAIL_SIGNATURE_EDIT_MODAL:
      return { ...state, showEmailSignatureEditModal: payload }
    case AT.TOGGLE_EMAIL_SIGNATURE_CONFIRMATION_MODAL:
      return { ...state, showEmailSignatureConfirmationModal: payload }
    default:
      return state
  }
}
