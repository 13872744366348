const initialState = {
  showModal: false,
  isSaving: false,
}

export default function uiFlags(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case 'TOGGLE_REASSIGN_LOAN_MODAL':
      return { ...state, showModal: payload }
    case 'TOGGLE_REASSIGN_LOAN_MODAL_SAVING':
      return { ...state, isSaving: payload }
    default:
      return state
  }
}
