import JSONAPIClient from '../JSONAPIClient'

JSONAPIClient.define('insurance_quote', {
  task_id: '',
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  dob: '',
  address: '',
  city: '',
  state: '',
  zip_code: '',
  address_cont: '',
})
