import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'

import {
  createPricingShareAPI,
  showPricingShareAPI,
  updatePricingShareAPI,
} from './asyncActions'

const pricingShareSlice = createSlice({
  name: 'pricingShare',
  initialState: {
    ...baseInitialState,
    toolTips: {},
    settings: {},
  },
  reducers: baseReducers,
  extraReducers: {
    [createPricingShareAPI.pending](state) {
      state.uiFlags.isCreating = true
    },
    [createPricingShareAPI.fulfilled](state) {
      state.uiFlags.isCreating = false
    },
    [createPricingShareAPI.rejected](state) {
      state.uiFlags = false
    },
    [showPricingShareAPI.pending](state) {
      state.uiFlags.isFetchingItem = true
    },
    [showPricingShareAPI.fulfilled](state, action) {
      state.uiFlags.isFetchingItem = false
      state.stagedRecord = action.payload.data
      state.toolTips = action.payload.toolTips
      state.settings = action.payload.settings
    },
    [showPricingShareAPI.rejected](state) {
      state.uiFlags = false
    },
    [updatePricingShareAPI.pending](state) {
      state.uiFlags.isUpdating = true
    },
    [updatePricingShareAPI.fulfilled](state, action) {
      state.uiFlags.isUpdating = false
      state.stagedRecord = { ...state.stagedRecord, ...action.payload }
    },
    [updatePricingShareAPI.rejected](state) {
      state.uiFlags = false
    },
  },
})

export default pricingShareSlice
