import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'
import { createQuestionAPI, deleteQuestionAPI, indexQuestionAPI, updateQuestionAPI } from './asyncActions'

const questionSlice = createSlice({
  name: 'question',
  initialState: baseInitialState,
  reducers: baseReducers,
  extraReducers: {
    [indexQuestionAPI.pending](state, action) {
      // background arg allows uiFlag updates to be skipped
      const { meta: { arg: { background } } } = action
      if (!background) state.uiFlags.isFetching = true
    },
    [indexQuestionAPI.fulfilled](state, action) {
      state.uiFlags.isFetching = false
      state.records = action.payload
    },
    [indexQuestionAPI.rejected](state) {
      state.uiFlags.isFetching = false
    },
    [createQuestionAPI.pending](state) {
      state.uiFlags.isCreating = true
    },
    [createQuestionAPI.fulfilled](state) {
      state.uiFlags.isCreating = false
    },
    [createQuestionAPI.rejected](state) {
      state.uiFlags.isCreating = false
    },
    [updateQuestionAPI.pending](state) {
      state.uiFlags.isUpdating = true
    },
    [updateQuestionAPI.fulfilled](state) {
      state.uiFlags.isUpdating = false
    },
    [updateQuestionAPI.rejected](state) {
      state.uiFlags.isUpdating = false
    },
    [deleteQuestionAPI.pending](state) {
      state.uiFlags.isDeleting = true
    },
    [deleteQuestionAPI.fulfilled](state) {
      state.uiFlags.isDeleting = false
    },
    [deleteQuestionAPI.rejected](state) {
      state.uiFlags.isDeleting = false
    },
  },
})

export default questionSlice
