import AT from '../../actionTypes'

const initialState = {}

export default function uiFlags(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case AT.SET_CLOSINGS_TASK:
      return { ...state, closingTasks: payload }
    default:
      return state
  }
}
