import { map, filter } from 'underscore'
import Fuse from 'fuse.js'
import {
  MORTGAGE_STATEMENT_INSTITUTIONS,
  TAX_INSTITUTIONS,
  TEST_INSTITUTIONS,
  BANK_AND_INVESTMENT_INSTITUTIONS,
  PAYROLL_INSTITUTIONS,
} from 'v2/constants/FilethisDocumentTypeMappings'
import { FILETHIS_INSTITUTION_SEARCH_OPT } from '../constants'


export function viewableStatements(statements, viewAll) {
  const STATEMENT_COUNT = 10

  return viewAll ? statements : statements.slice(0, STATEMENT_COUNT)
}

export function removeableConnection(deliveredStatementsCount, status) {
  return !deliveredStatementsCount && status === 'complete'
}

export function importableDocuments(isImported, statements) {
  return !isImported && statements.length !== 0
}

export function keywordSearch(arr, value, connNames) {
  return filter(arr, (obj) => {
    const alreadyConnectedService = connNames && connNames.includes(obj.name)

    if (!obj.name || !value || alreadyConnectedService) return false

    return Boolean(~obj.name.toLowerCase().indexOf(value.toLowerCase())) // eslint-disable-line
  })
}

export function sourceTitleSearch(arr, value, connNames) {
  const sourceNames = map(_titleMapping(value), name => name.toLowerCase())
  return filter(arr, function (obj) {
    if (!obj.name) return false
    if (connNames && connNames.includes(obj.name)) return false
    return this.keys.indexOf(obj.name.toLowerCase()) > -1
  }, { keys: sourceNames })
}

export function getFuzzySearchSources(arr, searchTerm) {
  const fuse = new Fuse(arr, FILETHIS_INSTITUTION_SEARCH_OPT)

  return fuse.search(searchTerm)
}

function _titleMapping(value) {
  switch (value) {
    case 'w-2': {
      return PAYROLL_INSTITUTIONS
    }
    case '1099': {
      return PAYROLL_INSTITUTIONS
    }
    case 'paystub': {
      return PAYROLL_INSTITUTIONS
    }
    case 'bank-statements': {
      return BANK_AND_INVESTMENT_INSTITUTIONS
    }
    case 'checking-account-statement': {
      return BANK_AND_INVESTMENT_INSTITUTIONS
    }
    case 'savings-account-statement': {
      return BANK_AND_INVESTMENT_INSTITUTIONS
    }
    case 'investment-account-statement': {
      return BANK_AND_INVESTMENT_INSTITUTIONS
    }
    case 'other-account-statement': {
      return BANK_AND_INVESTMENT_INSTITUTIONS
    }
    case 'retirement-account-statement': {
      return BANK_AND_INVESTMENT_INSTITUTIONS
    }
    case 'complete-federal-tax-return': {
      return TAX_INSTITUTIONS
    }
    case 'business-tax-return': {
      return TAX_INSTITUTIONS
    }
    case 'mortgage-statement': {
      return MORTGAGE_STATEMENT_INSTITUTIONS
    }
    case 'test': {
      return TEST_INSTITUTIONS
    }
    default: {
      return []
    }
  }
}
