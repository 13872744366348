import AT from 'actionTypes/'

const initialState = {
  selectedPeriod: null,
};

export default function periods(state = initialState, action) {
  switch (action.type) {
    case AT.SET_SELECTED_PERIOD: {
      return { ...state, selectedPeriod: action.payload }
    }
    default: {
      return state
    }
  }
}
