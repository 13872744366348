import AT from '../../actionTypes/'

const initialState = {}

export default function uiFlags(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case AT.TOGGLE_NEW_LOAN_FILE_TEMPLATE_MODAL:
      return { ...state, showNewLoanFileTemplateModal: payload }
    case AT.SHOW_TASK_TEMPLATE_FORM_MODAL:
      return { ...state, showTaskTemplateModal: payload }
    case AT.SHOW_TASK_TEMPLATE_DESTROY_MODAL:
      return { ...state, showDocumentGroupTemplateDestroyModal: payload }
    case AT.SHOW_TASK_TEMPLATE_CLONE_MODAL:
      return { ...state, showTaskTemplateCloneModal: payload }
    default:
      return state
  }
}
