import JSONAPIClient from '../JSONAPIClient'

JSONAPIClient.define('loan_file_dashboard_stat', {
  borrower_email: '',
  borrower_last_sign_in_at: '',
  borrower_last_invited_at: '',
  borrower_locale: '',
  borrower_name: '',
  coborrower_email: '',
  coborrower_name: '',
  coborrower_locale: '',
  completed_task_count: '',
  external_loan_number: '',
  external_loan_type: '',
  flags: '',
  incomplete_task_count: '',
  loan_file_created_at: '',
  loan_file_id: '',
  loan_file_status: '',
  loan_officer_image_src: '',
  loan_officer_name: '',
  realtor_name: '',
  realtors: '',
  received_documents_count: '',
  team_members: '',
  updated_at: '',
})
