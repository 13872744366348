import Route from 'Services/Route'
import HTTP from './http'

class Encompass {
  constructor(state) {
    this.state = state
    this.http = new HTTP(this.state)
  }

  createLoanFile = (params) => {
    const url = Route.api.loss.create()

    return this.http.post(url, params)
  }
}

export default Encompass
