// Default assumption is that the state is maintained as an object as follows
// {
//   records: [],
//   uiFlags: {
//     isFetching: false,
//     isFetchingItem: false,
//     isCreating: false,
//     isUpdating: false,
//     isDeleting: false,
//   },
//   stagedRecord: {
//   }
// }
// and the immutability is maintained by redux-toolkit
export default {
  set(state, action) {
    state.records = action.payload
  },
  add(state, action) {
    state.records.push(action.payload)
  },
  update(state, action) {
    const { id, data } = action.payload
    const index = state.records.filter(item => item.id === id)
    if (index > -1) {
      state.records[index] = { id, ...data }
    }
  },
  destroy(state, action) {
    const id = action.payload
    state.records = state.records.filter(item => String(item.id) !== String(id))
  },
  setStagedRecord(state, action) {
    state.stagedRecord = action.payload
  },
}
