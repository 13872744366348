import JSONAPIClient from '../../JSONAPIClient'

JSONAPIClient.define(
  'underwriting_decision',
  {
    status: '',
    run_type: '',
    aus_scenario_id: '',
    agency_type: '',
    credit_type: '',
    reference_id: '',
    vendor_type: '',
    username: '',
    password: '',
    submission_count: '',
    case_identifier: '',
    eligibility: '',
    recommendation: '',
    combined_ltv_ratio: '',
    ltv_ratio: '',
    debt_expense_ratio: '',
    housing_expense_ratio: '',
    reserves_amount: '',
    error: '',
    submission_errors: '',
    created_at: '',
    scenario: {
      jsonApi: 'hasOne',
      type: 'scenario',
    },
  },
  { collectionPath: 'underwriting_decisions' }
)
