import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'
import { showLenderAPI } from 'apiV2/store/loanApplications/lender/asyncActions'

export const createPricingShareAPI = createAsyncThunk(
  'pricingShare/create',
  async (payload) => {
    const response = await JSONAPIClient.create('pricing_share', payload)
    return response.data
  }
)

export const updatePricingShareAPI = createAsyncThunk(
  'pricingShare/update',
  async ({ shareId, shareToken = null, payload }) => {
    const response = await JSONAPIClient.update(
      'pricing_share', { id: shareId, ...payload }, { shareToken }
    )
    return response.data
  }
)

export const showPricingShareAPI = createAsyncThunk(
  'pricingShare/show',
  async ({ shareId, shareToken = null }, thunkAPI) => {
    const response = await JSONAPIClient.one('pricing_share', shareId)
      .get({ shareToken })
    const { data: { lenderId, loanOfficerId } } = response
    thunkAPI.dispatch(showLenderAPI({ lenderId: (loanOfficerId || lenderId), shareToken }))

    const { meta: { toolTips, settings }, data } = response
    return { data, toolTips, settings }
  }
)

export const destroyPricingShareAPI = createAsyncThunk(
  'pricingShare/destroy',
  async ({ shareId }) => {
    const response = await JSONAPIClient.destroy(
      'pricing_share', shareId
    )
    return response.data
  }
)
