import { pipelinesSettings as actionTypes } from 'v2/actionTypes'

const initialState = {
  loading: false,
  updating: false,
  panels: {
    users: {},
    loanFiles: {},
  },
  showCreateModal: false,
}

export default function uiFlags(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case actionTypes.loading:
      return { ...state, loading: payload }
    case actionTypes.updating:
      return { ...state, updating: payload }
    case actionTypes.togglePanel: {
      const {
        pipelineId,
        panel,
      } = payload

      const newPanels = state.panels

      // toggle target panel
      newPanels[panel][pipelineId] = !newPanels[panel][pipelineId]

      // close other panels
      const other = panel === 'users' ? 'loanFiles' : 'users'
      newPanels[other][pipelineId] = false

      return { ...state, panels: newPanels }
    }
    case actionTypes.showCreateModal:
      return { ...state, showCreateModal: payload }
    default:
      return state
  }
}
