import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'

import { showItem } from './asyncActions'

const initialState = {
  stagedRecordError: null,
  ...baseInitialState,
}

const itemSlice = createSlice({
  name: 'item',
  initialState: initialState,
  reducers: baseReducers,
  extraReducers: {
    [showItem.pending](state) {
      state.stagedRecordError = null
    },
    [showItem.fulfilled](state, action) {
      state.stagedRecord = action.payload
      state.stagedRecordError = null
    },
    [showItem.rejected](state, action) {
      state.stagedRecordError = action.error
    },
  },
})

export default itemSlice
