import AT from '../actionTypes'

export function setSelectedAttachment(attachment) {
  return {
    type: AT.SET_SELECTED_ATTACHMENT,
    payload: attachment,
  }
}

export function toggleAttachmentPreviewModal(bool = false) {
  return {
    type: AT.TOGGLE_ATTACHMENT_PREVIEW_MODAL,
    payload: bool,
  }
}
