import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'
import { createTaskAPI, updateTaskAPI, destroyTaskAPI } from './asyncActions'

const taskSlice = createSlice({
  name: 'loan_app_task',
  initialState: baseInitialState,
  reducers: {
    ...baseReducers,
    showCreateModal(state, action) {
      state.uiFlags.showCreateModal = action.payload
    },
    showEditModal(state, action) {
      state.uiFlags.showEditModal = action.payload
    },
    showDestroyModal(state, action) {
      state.uiFlags.showDestroyModal = action.payload
    },
  },
  extraReducers: {
    [createTaskAPI.pending](state) {
      state.uiFlags.isCreating = true
    },
    [createTaskAPI.fulfilled](state, action) {
      state.uiFlags.isCreating = false
      state.stagedRecord = action.payload
    },
    [createTaskAPI.rejected](state) {
      state.uiFlags.isCreating = false
    },
    [updateTaskAPI.pending](state) {
      state.uiFlags.isUpdating = true
    },
    [updateTaskAPI.fulfilled](state, action) {
      state.uiFlags.isUpdating = false
      state.stagedRecord = action.payload
    },
    [updateTaskAPI.rejected](state) {
      state.uiFlags.isUpdating = false
    },
    [destroyTaskAPI.pending](state) {
      state.uiFlags.isDeleting = true
    },
    [destroyTaskAPI.fulfilled](state) {
      state.uiFlags.isDeleting = false
      state.stagedRecord = {}
    },
    [destroyTaskAPI.rejected](state) {
      state.uiFlags.isDeleting = false
    },
  },
})

export default taskSlice
