import AT from 'v2/actionTypes'

const initialState = {
  checked: undefined,
  selectedIndex: undefined,
  taskReplyTo: undefined,
  taskReplyToCc: undefined,
}

export default function stagedSettings(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case AT.SET_TASK_NOTIFICATIONS_CHECKED:
      return { ...state, checked: payload }
    case AT.SET_TASK_NOTIFICATIONS_FREQUENCY:
      return { ...state, selectedIndex: payload }
    case AT.SET_LOAN_FILE_TASK_REPLY_TO:
      return { ...state, taskReplyTo: payload }
    case AT.SET_LOAN_FILE_TASK_REPLY_TO_CC:
      return { ...state, taskReplyToCc: payload }
    default:
      return state
  }
}
