import JSONAPIClient from '../JSONAPIClient'

JSONAPIClient.define('answer', {
  app_id: '',
  created_at: '',
  deleted_at: '',
  index: '',
  loan_file_participant_id: '',
  question_id: '',
  question_display_name: '',
  question_name: '',
  question_options: '',
  question_type: '',
  response: '',
  section_id: '',
  skipped: '',
  source_id: '',
  source_type: '',
  submission_section_id: '',
  section_name: '',
  updated_at: '',
})
