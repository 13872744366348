import JSONAPIClient from '../../JSONAPIClient'

JSONAPIClient.define('plaid_details_item', {
  id: '',
  loan_file: {
    jsonApi: 'hasOne',
    type: 'loan_file',
  },
  loan_file_id: '',
  user: '',
  user_id: '',
  plaid_account_metadata: '',
  public_token: '',
  institution_name: '',
  accounts: '',
  status: '',
  error: '',
})
