import AT from '../../actionTypes/'

const initialState = {
  id: 1,
  taskId: 1,
  borrower: {},
  coborrower: {},
}

export default function stagedRecord(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case AT.SET_STAGED_CREDIT_REPORTEE: {
      return { ...initialState, taskId: payload.id, borrower: payload.borrower, coborrower: payload.coborrower }
    }
    default: {
      return state
    }
  }
}
