import { paymentTasks as actionTypes } from 'v2/actionTypes'

const initialState = {
  clientSecret: '',
  selectedTaskId: '',
}

export default function records(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case actionTypes.setPaymentClientSecret:
      return { ...state, clientSecret: payload }
    case actionTypes.setSelectedTaskId:
      return { ...state, selectedTaskId: payload }
    default:
      return state
  }
}
