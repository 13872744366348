import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'
import {
  createUserAccountAPI,
  sendOtpAPI,
  confirmOtpAPI,
  showUserAPI,
  updateUserAPI,
  confirmUserAccountAPI,
} from './asyncActions'

const userSlice = createSlice({
  name: 'user',
  initialState: baseInitialState,
  reducers: baseReducers,
  extraReducers: {
    [createUserAccountAPI.pending](state) {
      state.uiFlags.isCreating = true
    },
    [createUserAccountAPI.fulfilled](state, action) {
      state.uiFlags.isCreating = false
      state.uiFlags.requireOtp = action.meta?.arg?.user?.otp_required_for_login
      state.stagedRecord = action.payload
    },
    [createUserAccountAPI.rejected](state) {
      state.uiFlags.isCreating = false
    },
    [sendOtpAPI.pending](state) {
      state.uiFlags.isFetching = true
    },
    [sendOtpAPI.fulfilled](state) {
      state.uiFlags.isFetching = false
    },
    [sendOtpAPI.rejected](state) {
      state.uiFlags.isFetching = false
    },
    [confirmOtpAPI.pending](state) {
      state.uiFlags.isFetching = true
    },
    [confirmOtpAPI.fulfilled](state) {
      state.uiFlags.isFetching = false
    },
    [confirmOtpAPI.rejected](state) {
      state.uiFlags.isFetching = false
    },
    [showUserAPI.pending](state) {
      state.uiFlags.isFetching = true
    },
    [showUserAPI.fulfilled](state, { payload: { data } }) {
      state.uiFlags.isFetching = false
      state.stagedRecord = data
      state.records = [...state.records.filter(u => u.id !== data.id), data]
    },
    [showUserAPI.rejected](state) {
      state.uiFlags.isFetching = false
    },
    [updateUserAPI.pending](state) {
      state.uiFlags.isFetching = true
    },
    [updateUserAPI.fulfilled](state, action) {
      state.uiFlags.isFetching = false
      state.stagedRecord = action.payload.data
    },
    [updateUserAPI.rejected](state) {
      state.uiFlags.isFetching = false
    },
    [confirmUserAccountAPI.pending](state) {
      state.uiFlags.isCreating = true
    },
    [confirmUserAccountAPI.fulfilled](state) {
      state.uiFlags.isCreating = false
    },
    [confirmUserAccountAPI.rejected](state) {
      state.uiFlags.isCreating = false
    },
  },
})

export default userSlice
