import toastr from 'utils/toastr'
import api from 'utils/api'
import Route from 'Services/Route'

import { toastrErrors } from 'utils/error_utils'
import { createActionsFromActionTypes } from 'utils/reducer_utils'
import { getLoanFileId } from 'v2/selectors/loan_files'
import { setLoanFile } from '../loan_files'
import { lossLoanNumber } from '../../actionTypes'

const reducerActions = createActionsFromActionTypes(lossLoanNumber)

// Public actions
export function validateLossLoanNumber(loanNumber) {
  return (dispatch) => {
    return Promise.all([
      dispatch(reducerActions.validateLossLoanNumber(true)),
      dispatch(validate(loanNumber)),
      dispatch(reducerActions.validateLossLoanNumber(false)),
    ])
  }
}

export function toggleLossLoanNumberEditModal(bool = false) {
  return reducerActions.toggleLossLoanNumberEditModal(bool)
}

// Private actions
function validate(loanNumber, losType) {
  return async (dispatch, getState) => {
    const loanFileId = getLoanFileId(getState())
    const url = Route.api.loss.associateLoan()
    const params = {
      loanFileId: loanFileId,
      loanIdentifier: loanNumber,
      losType: losType,
    }

    try {
      const res = await api(getState).post(url, params)
      const { loanFile } = res.data
      dispatch(reducerActions.toggleLossLoanNumberEditModal(false))
      toastr.success('Loan number has been validated!')
      dispatch(setLoanFile(loanFile))
    } catch (err) {
      const { errors } = err.response.data
      if (errors) toastrErrors(errors)
    }
  }
}
