import { disclosuresTasks as actionTypes } from '../../actionTypes'

export default function records(state = [], action) {
  const { type, payload } = action
  switch (type) {
    case actionTypes.set: {
      return payload
    }
    case actionTypes.create: {
      return [...state, payload]
    }
    default:
      return state
  }
}
