import { combineReducers } from 'redux'
import { replace } from 'Services/ImmutatingHelpers'
import AT from 'v2/actionTypes'

function loading(state = {}, action) {
  const { type, payload } = action

  switch (type) {
    case AT.COMPLETE_TASK_LOADING:
      return { ...state, completeTaskLoading: payload }
    case AT.INCOMPLETE_TASK_LOADING:
      return { ...state, incompleteTaskLoading: payload }
    case AT.CREATING_NEW_TASK:
      return { ...state, creatingNewTask: payload }
    default:
      return state
  }
}

function completeTasks(state = [], action) {
  const { type, payload } = action
  switch (type) {
    case AT.SET_COMPLETE_TASKS: {
      return payload
    }
    case AT.UPDATE_DOCUMENT_ON_COMPLETE_TASK: {
      return updateDocumentOnTask(state, payload)
    }
    default: {
      return state
    }
  }
}

function incompleteTasks(state = [], action) {
  const { type, payload } = action
  switch (type) {
    case AT.SET_INCOMPLETE_TASKS: {
      return payload
    }
    case AT.CREATE_TASK: {
      return [...state, payload]
    }
    case AT.UPDATE_DOCUMENT_ON_INCOMPLETE_TASK: {
      return updateDocumentOnTask(state, payload)
    }
    default: {
      return state
    }
  }
}

function updateDocumentOnTask(state, payload) {
  const taskRecord = state.find(record => record.id === payload.documentGroupId)
  const taskIndex = state.findIndex(record => record.id === payload.documentGroupId)
  const updatedTaskRecord = {
    ...taskRecord,
    documents: [
      ...taskRecord.documents,
      payload,
    ],
  }

  return replace(state, taskIndex, updatedTaskRecord)
}

function selectedTask(state = {}, action) {
  const { type, payload } = action
  switch (type) {
    case AT.SET_SELECTED_TASK: {
      return payload
    }
    case AT.UPDATE_SELECTED_TASK: {
      return { ...{}, ...state, ...payload }
    }
    default: {
      return state
    }
  }
}

function notePreview(state = {}, action) {
  const { type, payload } = action
  switch (type) {
    case AT.SET_NOTE_PREVIEW: {
      return payload
    }
    default: {
      return state
    }
  }
}

function createTaskModal(state = { isViewable: false }, action) {
  const { type, payload } = action
  switch (type) {
    case AT.TOGGLE_CREATE_TASK_MODAL: {
      return { isViewable: payload }
    }
    default: {
      return state
    }
  }
}

function editTaskModal(state = { isViewable: false }, action) {
  const { type, payload } = action
  switch (type) {
    case AT.TOGGLE_EDIT_TASK_MODAL: {
      return { isViewable: payload }
    }
    default: {
      return state
    }
  }
}

function completeTaskModal(state = { isViewable: false, processing: false }, action) {
  const { type, payload } = action
  switch (type) {
    case AT.TOGGLE_COMPLETE_TASK_MODAL: {
      return { isViewable: payload }
    }
    case AT.TOGGLE_COMPLETE_TASK_MODAL_PROCESSING: {
      return { processing: payload }
    }
    default: {
      return state
    }
  }
}

function connectAccountModal(state = { isViewable: false }, action) {
  const { type, payload } = action
  switch (type) {
    case AT.TOGGLE_CONNECT_ACCOUNT_MODAL: {
      return { isViewable: payload }
    }
    default: {
      return state
    }
  }
}

function fileUploadModal(state = { isViewable: false }, action) {
  const { type, payload } = action
  switch (type) {
    case AT.TOGGLE_FILE_UPLOAD_MODAL: {
      return { isViewable: payload }
    }
    default: {
      return state
    }
  }
}

function deleteTaskModal(state = { isViewable: false }, action) {
  const { type, payload } = action
  switch (type) {
    case AT.TOGGLE_DELETE_TASK_MODAL: {
      return { isViewable: payload }
    }
    default: {
      return state
    }
  }
}


export default combineReducers({
  completeTaskModal,
  completeTasks,
  connectAccountModal,
  createTaskModal,
  deleteTaskModal,
  editTaskModal,
  fileUploadModal,
  incompleteTasks,
  loading,
  selectedTask,
  notePreview,
})
