import JSONApi from 'devour-client'
import Cookies from 'js-cookie'
import i18n from 'i18n'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'

const doorKeeperToken = Cookies.get('maxwell_api_v2_token')
const JSONAPIClient = new JSONApi({ apiUrl: '/api/v2', bearer: doorKeeperToken })

const responseToCamelCaseMiddleware = {
  name: 'snake-to-camel',
  res: payload => camelcaseKeys(payload || {}, { deep: true }),
}

const requestToSnakeCaseMiddleware = {
  name: 'camel-to-snake',
  req: payload => {
    payload.req.data = snakecaseKeys(payload.req.data || {}, { deep: true })
    payload.req.params = snakecaseKeys(payload.req.params || {}, { deep: true })
    payload.req.meta = snakecaseKeys(payload.req.meta || {}, { deep: true })
    return payload
  },
}

const requestLanguageHeaderMiddleware = {
  name: 'language-header',
  req: payload => {
    const { language } = i18n
    payload.req.headers['Accept-Language'] = `${language};q=0.9`
    return payload
  },
}

const errorMiddleware = {
  name: 'redirect-unathorized',
  error: (payload) => {
    if (payload.response.status === 401) {
      window.location.href = '/sign_in'
    }
    return payload
  },
}

JSONAPIClient.insertMiddlewareBefore('HTTP_BASIC_AUTH', requestToSnakeCaseMiddleware)
JSONAPIClient.insertMiddlewareAfter('HEADER', requestLanguageHeaderMiddleware)
JSONAPIClient.insertMiddlewareAfter('response', responseToCamelCaseMiddleware)
JSONAPIClient.replaceMiddleware('errors', errorMiddleware)

export default JSONAPIClient
