import AT from 'actionTypes/'

const initialState = {
  sources: { showModal: false },
  connections: { showModal: false },
  interactions: { showModal: false },
  global: { showModal: false },
};

export default function modal(state = initialState, action) {
  switch (action.type) {
    case AT.TOGGLE_MODAL: {
      return { ...state, [action.payload.type]: { showModal: action.payload.show } }
    }
    default: {
      return state
    }
  }
}
