import Route from 'Services/Route'
import { underscored } from 'underscore.string'
import { map } from 'underscore'

// Default to team when no other templateType supplied
export function index(templateType = 'team', teamId = null) {
  const url = Route.api.loanApp.templates.index()

  return (
    $.ajax({
      method: 'GET',
      url: url,
      data: { template_type: templateType, team_id: teamId },
    })
  )
}

export function create(loanAppTemplate, templateType) {
  const url = Route.api.loanApp.templates.create()
  const res = {}
  map(loanAppTemplate, (value, key) => {
    res[underscored(key)] = value
  })

  return (
    $.ajax(
      {
        method: 'POST',
        data: { loan_app_template: res, template_type: templateType },
        url: url,
      }
    )
  )
}

export function clone(loanAppTemplate) {
  const url = Route.api.loanApp.templates.clone(loanAppTemplate)
  const res = { title: loanAppTemplate.title }

  return (
    $.ajax(
      {
        method: 'POST',
        data: { loan_app_template: res },
        url: url,
      }
    )
  )
}

export function update(loanAppTemplate) {
  const url = Route.api.loanApp.templates.update(loanAppTemplate)
  const res = {}
  map(loanAppTemplate, (value, key) => {
    res[underscored(key)] = value
  })
  delete res.id

  return (
    $.ajax(
      {
        method: 'PUT',
        data: { loan_app_template: res },
        url: url,
      }
    )
  )
}

export function show({ id }) {
  const url = Route.api.loanApp.templates.show({ id: id })
  return (
    $.ajax(
      {
        method: 'GET',
        url: url,
      }
    )
  )
}
