import JSONAPIClient from '../JSONAPIClient'

JSONAPIClient.define('bi_question', {
  text: '',
  status: '',
  metabase_table_card_url: '',
  metabase_chart_card_url: '',
  thought: '',
  grade: '',
  suggestion: '',
  critique: '',
  created_at: '',
  updated_at: '',
})
