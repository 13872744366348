import { filter, groupBy } from 'underscore'
import { caseInsensitiveContains } from 'modules/objects'
import {
  LENDER_LQB_DOCUMENT_VAULT_METADATA,
  LENDER_ENCOMPASS_DOCUMENT_VAULT_METADATA,
  LENDER_INTEGRA_DOCUMENT_VAULT_METADATA,
  LENDER_BYTE_DOCUMENT_VAULT_METADATA,
  LENDER_BASIC_DOCUMENT_VAULT_METADATA,
} from 'v2/constants/LenderDocumentVaultMetadata'
import { findCurrSortMetaDataFromSortKey, sortCollection } from 'Services/SortingHelpers'
import { fileExtensionFromDocumentKey } from 'utils/file_utils'
import { PDF_TRON_MERGE_ALLOW_LIST_DOCUMENTS_EXTENSIONS } from 'v2/constants/PdfTron'

export const docuSignUploadComplete = (status) => {
  return status === 'open'
}

export function filterDocumentsByTerm(documents, searchTerm) {
  if (!searchTerm) return documents
  return filter(documents, (obj) => {
    return (
      caseInsensitiveContains(obj.fileName, searchTerm)
      || caseInsensitiveContains(obj.documentType, searchTerm)
      || caseInsensitiveContains(obj.status, searchTerm)
      || caseInsensitiveContains(obj.uploadedOn, searchTerm)
    )
  })
}

export const taskTitleFromDocument = (doc) => {
  return doc.documentGroupTitle || 'Other'
}

export const groupDocumentsByTask = (documents) => {
  return groupBy(documents, (doc) => {
    return taskTitleFromDocument(doc)
  })
}

export const isVisibileInVault = doc => !!doc.displayInVault

export const getDocumentsVisibleInVault = (documents = []) => documents.filter(isVisibileInVault)

export const docIsSent = (doc) => doc.externalDocumentStatus === 'sent'

export const hasVaultInSearchParams = () => window.location.search.includes('vault')
export const determineAppropriateDocVaultMetadata = ({ userInfo, loanFile }) => {

  const { lendingqbConnected, encompassConnected, integraEnabled, byteEnabled } = userInfo
  const { lqbLoanFileNumber, encompassLoanFileSyncId, integraLoanFile, byteLoanFile } = loanFile

  const isLendingQb = lendingqbConnected && lqbLoanFileNumber
  const isEncompass = encompassConnected && encompassLoanFileSyncId
  const isIntegra = integraEnabled && integraLoanFile?.referenceNumber
  const isByte = byteEnabled && byteLoanFile?.loanNumber

  if (isLendingQb) {
    return LENDER_LQB_DOCUMENT_VAULT_METADATA
  } else if (isEncompass) {
    return LENDER_ENCOMPASS_DOCUMENT_VAULT_METADATA
  } else if (isIntegra) {
    return LENDER_INTEGRA_DOCUMENT_VAULT_METADATA
  } else if (isByte) {
    return LENDER_BYTE_DOCUMENT_VAULT_METADATA
  } else {
    return LENDER_BASIC_DOCUMENT_VAULT_METADATA
  }
}

export const sortDocuments = ({ documents, sortOptions = {}, userInfo, loanFile }) => {
  const { sortKey, reverse } = sortOptions

  const documentVaultMetaData = determineAppropriateDocVaultMetadata({ userInfo, loanFile })
  const currentSelectedColumnMetadata = findCurrSortMetaDataFromSortKey(
    documentVaultMetaData,
    sortKey
  )

  const { sortCriteria } = currentSelectedColumnMetadata || {}
  const sortedDocuments = sortCollection(
    documents,
    sortKey,
    sortCriteria,
  )

  return reverse ? sortedDocuments.reverse() : sortedDocuments
}

export const isReceived = (doc) => {
  const { status } = doc
  return status === 'received'
}

export const shouldShowDocTools = (docToolsEnabled, fileName = '') => {
  const extension = fileExtensionFromDocumentKey(fileName).toLowerCase()
  return docToolsEnabled && PDF_TRON_MERGE_ALLOW_LIST_DOCUMENTS_EXTENSIONS.includes(extension)
}

export const isOfficeDocument = (fileName) => {
  const extension = fileExtensionFromDocumentKey(fileName).toLowerCase()
  return (extension === 'doc' || extension === 'docx' || extension === 'xls' || extension === 'xlsx')
}
