import AT from 'actionTypes/'

export default function notifications(state = [], action) {
  switch (action.type) {
    case AT.ADD_NOTIFICATION:
      return [...state, action.notification]
    case AT.REMOVE_NOTIFICATION:
      return state.filter((notification) => {
        return notification.id !== action.notificationId
      })
    default:
      return state
  }
}
