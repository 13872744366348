import { combineReducers } from 'redux'
import records from './records'
import uiFlags from './uiFlags'
import stagedRecord from './stagedRecord'
import stagedLetters from './stagedLetters'

export default combineReducers({
  records,
  uiFlags,
  stagedRecord,
  stagedLetters,
})
