import JSONAPIClient from '../JSONAPIClient'

JSONAPIClient.define('participant', {
  loan_file_id: '',
  role: '',
  participantable_id: '',
  participantable_name: '',
  participantable_name_hash: '',
  participantable_email: '',
  joint_applicant_authorization: '',
  primary: '',
  credit_type: '',
  married: '',
  metadata: '',
})
