// getters

const getTeamObject = state => state.v2.teams

export const getTeam = (state) => {
  return getTeamObject(state).stagedRecord
}

export const getTeamUiFlags = (state) => {
  return getTeamObject(state).uiFlags
}
