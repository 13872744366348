import AT from 'actionTypes/'
import api from 'utils/api'
import Route from 'Services/Route'
import toastr from 'utils/toastr'
import { toastrErrors } from 'utils/error_utils'

import { setIncompleteTasks } from '../v2/actions/tasks'

import {
  updateTasks,
  removeFilethisConnection,
  getIndexById,
} from '../utils/task_utils'

export function setConnections(connections) {
  return { type: AT.SET_CONNECTIONS, payload: connections }
}

export function setSelectedConnection(connection) {
  return { type: AT.SET_SELECTED_CONNECTION, payload: connection }
}

export function removeSelectedConnection() {
  return { type: AT.REMOVE_SELECTED_CONNECTION, payload: {} }
}

export function handleConnectionRemoval(connectionId, taskId) {
  return (dispatch, getState) => {
    const { incompleteTasks } = getState().v2Tasks
    // Should not be able to remove completed task
    const selectedTask = incompleteTasks.filter(task => task.id === taskId)[0]
    const { filethisConnections } = selectedTask

    const connectionIndex = getIndexById(filethisConnections, connectionId)
    const updatedTask = removeFilethisConnection(selectedTask, filethisConnections, connectionIndex)
    const taskIndex = getIndexById(incompleteTasks, taskId)

    const tasks = updateTasks(incompleteTasks, updatedTask, taskIndex)

    dispatch(setIncompleteTasks(tasks))
  }
}

export function fetchConnection(connectionId) {
  return (dispatch, getState) => {
    const url = Route.api.filethis.connections.show({ id: connectionId })

    const req = api(getState).get(url)

    req.then((res) => {
      const { connection } = res.data

      dispatch(setSelectedConnection(connection))
    }).catch((error) => {
      console.log({ error })
      const { errors } = error?.response?.data || {}

      if (errors) toastrErrors(errors)
    })
  }
}

export function fetchConnections() {
  return (dispatch, getState) => {
    const url = Route.api.filethis.connections.index()

    const req = api(getState).get(url)

    req.then((res) => {
      const { connections } = res.data

      dispatch(setConnections(connections))
    }).catch((error) => {
      const { errors } = error?.response?.data || {}

      console.info(errors)

      if (errors) toastrErrors(errors)
    })
  }
}

export function createConnection(params) {
  return (dispatch, getState) => {
    const url = Route.api.filethis.connections.create()

    const req = api(getState).post(url, params)

    req.then((res) => {
      const { connection } = res.data

      dispatch(setSelectedConnection(connection))
    }).catch((error) => {
      const { errors } = error?.response?.data || {}

      console.info(errors)

      if (errors) toastrErrors(errors)
    })
  }
}

export function closeConnection(id, taskId) {
  return (dispatch, getState) => {
    const url = Route.api.filethis.connections.destroy({ id })
    const req = api(getState).delete(url)

    req.then(() => {
      dispatch(handleConnectionRemoval(id, taskId))

      toastr.success('Successfully removed connection to account')
    }).catch((error) => {
      const { errors } = error?.response?.data || {}

      console.info(errors)

      if (errors) toastrErrors(errors)
    })
  }
}
