import toastr from 'utils/toastr'
import { toastrErrors } from 'utils/error_utils'
import Encompass from 'utils/requests/encompass'
import { createAction } from 'utils/reducer_utils'

import { addLoanApps } from 'v2/actions/loan_app/loan_app'
import AT from 'v2/actionTypes/'
import { getUserInfo } from 'v2/selectors/page_info'

export const toggleEncompassResyncModal = createAction(AT.TOGGLE_ENCOMPASS_RESYNC_MODAL)

export function submitToEncompass({ loanApp }) {
  return (dispatch, getState) => {
    const userInfo = getUserInfo(getState())
    const { id: loanAppId, isV2: v2 } = loanApp

    const params = {
      loan_app_id: loanAppId,
      user_id: userInfo.id,
      v2,
    }

    const encompass = new Encompass(getState)
    const req = encompass.createLoanFile(params)

    req.then((res) => {
      const { loanApps } = res.data

      dispatch(addLoanApps(loanApps))

      toastr.success('Successfully synced the file with Encompass')
    }).catch((error) => {
      toastrErrors(error)
    })
  }
}
