import api from 'utils/api'
import Route from 'Services/Route'
import { toastrErrors } from 'utils/error_utils'

export function create(state, insuranceTask, params) {
  const { loanFileId, id: taskId } = insuranceTask
  const url = Route.api.loanFiles.quoteRequests.create({ loanFileId, taskId })
  const req = api(state).post(url, { quote_request: params })

  return req.then((res) => {
    const { insuranceTask: task } = res.data
    return task
  }).catch((error) => {
    const { errors } = error?.response?.data || {}
    console.error(errors)
    if (errors) toastrErrors(errors)
  })
}
