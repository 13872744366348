import BaseLoanAppQuestionResponseGetter from './BaseLoanAppQuestionResponseGetter'
import LoanAppParentQuestionResponseGetter from './LoanAppParentQuestionResponseGetter'

export default class LoanAppDependentQuestionResponseGetter extends BaseLoanAppQuestionResponseGetter {
  questionName = () => {
    const { name } = this

    if (name.includes('_end_date')) {
      return name.split('_end_date')[0].concat('_start_date')
    } else if (name.includes('_start_date')) {
      return name.split('_start_date')[0].concat('_end_date')
    } else if (name === 'approximate_downpayment') {
      return this.fetchCorrectPriceQuestion()
    } else if (['price_of_house_under_contract', 'approximate_price', 'homebuying_process_status'].includes(name)) {
      return 'approximate_downpayment'
    } else if (name === 'borrower_ssn') {
      return 'coborrower_ssn'
    } else if (name === 'coborrower_ssn') {
      return 'borrower_ssn'
    }
    return null
  }

  fetchCorrectPriceQuestion = () => {
    const { responses, questions, name } = this
    const parentQuestionResponse = new LoanAppParentQuestionResponseGetter(responses, questions, name).perform()

    if (parentQuestionResponse === 'under_contract') {
      return 'price_of_house_under_contract'
    } else {
      return 'approximate_price'
    }
  }
}
