import { usersSettings as actionTypes } from 'v2/actionTypes'

const initialState = []

export default function records(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case actionTypes.set: {
      return payload
    }
    default:
      return state
  }
}
