import AT from '../../actionTypes/'

const initialState = {
  syncingWithLendingQB: false,
  validatingLoanNumberWithLendingQb: false,
  showLendingQbNumberEditModal: false,
}

export default function uiFlags(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case AT.SYNCING_WITH_LENDING_QB: {
      return { ...state, syncingWithLendingQB: payload }
    }
    case AT.VALIDATING_LOAN_NUMBER_WITH_LENDING_QB: {
      return { ...state, validatingLoanNumberWithLendingQb: payload }
    }
    case AT.TOGGLE_LENDING_QB_NUMBER_EDIT_MODAL: {
      return { ...state, showLendingQbNumberEditModal: payload }
    }
    default: {
      return state
    }
  }
}
