import api from 'utils/api'
import Route from 'Services/Route'
import toastr from 'utils/toastr'
import { toastrErrors } from 'utils/error_utils'
import { createAction } from 'utils/reducer_utils'

import AT from '../actionTypes/'
import { show } from '../BackendRequest/Organization'

export const fetchOrganization = (id) => {
  return async (dispatch, getState) => {
    const organization = await show(getState, id)
    if (!organization) return
    dispatch(setOrganization(organization))
  }
}

export function toggleTeamsCanCreateLoanAppTemplates() {
  return (dispatch, getState) => {
    const organization = getState().v2.organizations.stagedRecord
    const { teamsCanCreateLoanAppTemplates } = organization
    const url = Route.api.organization.update({ organizationId: organization.id })
    const params = {
      organization: {
        teams_can_create_loan_app_templates: !teamsCanCreateLoanAppTemplates,
      },
    }
    dispatch(setOrganization({ ...organization, teamsCanCreateLoanAppTemplates: !teamsCanCreateLoanAppTemplates }))
    const req = api(getState).put(url, params)

    req.then((res) => {
      dispatch(setOrganization(res.data.organization))
      toastr.success('Successfully toggled lock!')
    }).catch((error) => {
      dispatch(setOrganization(organization))
      const errors = error?.response?.data || null
      console.error(errors)
      if (errors) toastrErrors(errors)
    })
  }
}

export const setOrganization = createAction(AT.SET_ORGANIZATION)
export const updateOrganization = createAction(AT.UPDATE_ORGANIZATION)
