import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'
import { indexConsentAPI, createConsentAPI } from './asyncActions'

export const consentSlice = createSlice({
  name: 'consent',
  initialState: baseInitialState,
  reducers: baseReducers,
  extraReducers: {
    [indexConsentAPI.pending](state) {
      state.uiFlags.isCreating = true
    },
    [indexConsentAPI.fulfilled](state, action) {
      state.uiFlags.isCreating = false
      state.records = action.payload
    },
    [indexConsentAPI.rejected](state) {
      state.uiFlags.isCreating = false
    },
    [createConsentAPI.pending](state) {
      state.uiFlags.isCreating = true
    },
    [createConsentAPI.fulfilled](state, action) {
      state.records = [...state.records, action.payload]
      state.uiFlags.isCreating = false
    },
    [createConsentAPI.rejected](state) {
      state.uiFlags = false
    },
  },
})

export default consentSlice
export const consentReducer = consentSlice.reducer
