import { optimalBlueStates } from '../../actionTypes'

const initialState = { isStateLoadingIncomplete: false }

export default function (state = initialState, action) {
  switch (action.type) {

    case optimalBlueStates.setStateLoadingIncomplete:
      return { isStateLoadingIncomplete: true }

    default:
      return state
  }
}
