import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'

import { fetchNextQuestionsPage, showQuestion, createQuestion } from './asyncActions'

const initialState = {
  ...baseInitialState,
  recordsPageNumber: 0,
  recordsCount: 0,
}

const biQuestionSlice = createSlice({
  name: 'bi_question',
  initialState: initialState,
  reducers: baseReducers,
  extraReducers: {
    [fetchNextQuestionsPage.fulfilled](state, action) {
      const { data, pageNumber, recordCount } = action.payload
      state.recordsPageNumber = pageNumber
      state.recordsCount = recordCount
      state.records = [...state.records, ...data]
    },
    [showQuestion.fulfilled](state, action) {
      state.stagedRecord = action.payload
      state.records = state.records.filter(r => r.id !== action.payload.id)
      state.records.unshift(action.payload)
    },
    [createQuestion.fulfilled](state, action) {
      state.stagedRecord = action.payload
      state.records.unshift(action.payload)
    },
  },
})

export default biQuestionSlice
