import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'

export const indexPresetQuestions = createAsyncThunk(
  'bi/presetQuestions/index',
  async (params = {}) => {
    const { data } = await JSONAPIClient.all('bi_preset_question').get(params)
    return data
  },
)
