import { quickPricerAccounts } from 'v2/actionTypes'

const initialState = []

export default function (state = initialState, action) {
  switch (action.type) {
    case quickPricerAccounts.set:
      return action.payload

    default:
      return state
  }
}
