import { paymentTasks as actionTypes } from 'v2/actionTypes'

const initialState = {
  showCreateModal: false,
  showUpdateModal: false,
  showITPModal: false,
  isITPFormSubmitting: false,
  isITPFetching: false,
  loading: false,
}

export default function uiFlags(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case actionTypes.showCreateModal:
      return { ...state, showCreateModal: payload }
    case actionTypes.showUpdateModal:
      return { ...state, showUpdateModal: payload }
    case actionTypes.toggleITPModal:
      return { ...state, showITPModal: payload }
    case actionTypes.setITPSubmitting:
      return { ...state, isITPFormSubmitting: payload }
    case actionTypes.setITPFetching:
      return { ...state, isITPFetching: payload }
    case actionTypes.loading:
      return { ...state, loading: payload }
    default:
      return state
  }
}
