import AT from '../../actionTypes'

const initialState = []

const updatePrefillResponses = (state, payload) => {
  return state.map((rec) => {
    const [isInPayload] = Object.keys(payload)
      .filter(questionId => rec.templateQuestionId.toString() === questionId)

    if (isInPayload) {
      return { ...rec, value: payload[rec.templateQuestionId.toString()], prefillUsed: true }
    }
    return rec
  })
}

export default function records(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case AT.SET_LOAN_APP_RESPONSES:
      return payload

    case AT.UPDATE_PREFILL_RESPONSES:
      return updatePrefillResponses(state, payload)

    case AT.UPDATE_LOAN_APP_RESPONSE_VALUE:
      return state.map((rec) => {
        if (rec.templateQuestionId === payload.templateQuestionId) {
          return { ...rec, value: payload.value }
        }
        return rec
      })
    default:
      return state
  }
}
