import JSONAPIClient from '../JSONAPIClient'

JSONAPIClient.define('pricing_share', {
  loan_file_id: '',
  lender_id: '',
  loan_officer_id: '',
  scenarios: '',
  applied_at: '',
  viewed_at: '',
  created_at: '',
  confirmation_url: '',
  status: '',
  lender: {
    jsonApi: 'hasOne',
    type: 'lenders',
  },
})
