import { createAction } from 'utils/reducer_utils'
import toastr from 'utils/toastr'

import { index, create } from '../BackendRequest/Api/Organizations/TextMessages'
import AT from '../actionTypes'

export const fetchTextMessageNotificationStatus = (organizationId) => {
  return async (dispatch, getState) => {
    const textMessageSettings = await index(getState, organizationId)
    if (!textMessageSettings) return

    Promise.all([
      dispatch(setCurrentTextMessageSettings(textMessageSettings)),
      dispatch(setStagedTextMessageSettings(textMessageSettings)),
    ])
  }
}

export const toggleTextMessageNotificationStatus = () => {
  return (dispatch, getState) => {
    const {
      textMessageSettings: {
        stagedRecord: {
          enabled,
        },
      },
    } = getState().v2

    dispatch(updateStagedTextMessageSettings({ enabled: !enabled }))
  }
}

export const toggleOnTextMessageChangeConfirmationModal = () => {
  return (dispatch) => {
    dispatch(toggleTextMessageChangeConfirmationModal(true))
  }
}

export const toggleOffTextMessageChangeConfirmationModal = () => {
  return (dispatch) => {
    dispatch(toggleTextMessageChangeConfirmationModal(false))
  }
}

export const loadingTextMessagesChanges = () => {
  return (dispatch) => {
    dispatch(toggleSubmittingTextMessagesChanges(true))
  }
}

export const finsihedLoandingTextMessagesChanges = () => {
  return (dispatch) => {
    dispatch(toggleSubmittingTextMessagesChanges(false))
  }
}

export const loadingTextMessageChanges = () => {
  return (dispatch) => {
    dispatch(toggleTextMessageChanges(true))
  }
}

export const finishedLoadingTextMessageChanges = () => {
  return (dispatch) => {
    dispatch(toggleTextMessageChanges(false))
  }
}

export const submitTextMessagesSettings = (organizationId) => {
  return async (dispatch, getState) => {
    dispatch(toggleOffTextMessageChangeConfirmationModal())
    dispatch(loadingTextMessageChanges())

    const {
      textMessageSettings: {
        stagedRecord: {
          enabled,
        },
      },
    } = getState().v2

    const textMessageSettings = await create(getState, organizationId, { enabled })
    if (!textMessageSettings) return

    Promise.all([
      dispatch(setCurrentTextMessageSettings(textMessageSettings)),
      dispatch(setStagedTextMessageSettings(textMessageSettings)),
    ]).then(() => {
      dispatch(finishedLoadingTextMessageChanges())
      toastr.success('Successfully submitted your changes')
    })
  }
}

const setStagedTextMessageSettings = createAction(AT.SET_STAGED_TEXT_MESSSAGE_SETTINGS)
const setCurrentTextMessageSettings = createAction(AT.SET_CURRENT_TEXT_MESSSAGE_SETTINGS)
const updateStagedTextMessageSettings = createAction(AT.UPDATE_STAGED_TEXT_MESSSAGE_SETTINGS)
const toggleTextMessageChangeConfirmationModal = createAction(AT.TOGGLE_TEXT_MESSAGE_CHANGE_CONFIRMATION_MODAL)
const toggleSubmittingTextMessagesChanges = createAction(AT.SUBMITTING_TEXT_MESSAGES_CHANGES)
const toggleTextMessageChanges = createAction(AT.TOGGLE_TEXT_MESSAGE_CHANGES)
