import { optimalBlueStates } from '../../actionTypes'

const initialState = []

export default function (state = initialState, action) {
  switch (action.type) {

    case optimalBlueStates.set:
      return action.payload

    default:
      return state
  }
}
