import AT from 'v2/actionTypes/'

const initialState = {
  showEncompassNumberImportModal: false,
  isImportingFile: false,
  encompassResyncModal: { isViewable: false, loanApp: {} },
}

export default function uiFlags(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case AT.TOGGLE_ENCOMPASS_NUMBER_IMPORT_MODAL: {
      return { ...state, showEncompassNumberImportModal: payload }
    }
    case AT.IMPORTING_LOAN_NUMBER_FROM_ENCOMPASS: {
      return { ...state, isImportingFile: payload }
    }
    case AT.TOGGLE_ENCOMPASS_RESYNC_MODAL: {
      return { ...state, encompassResyncModal: payload }
    }
    default: {
      return state
    }
  }
}
