import { find } from 'underscore'
import { createSelector } from 'reselect'

const getDisclosureObject = state => state.v2.disclosuresTasks
const getDisclosureTaskItemObject = state => state.v2.disclosuresTaskItems

const getTaskId = (_state, props) => props.taskId
const getTaskItemId = (_state, props) => props.taskItemId

// getters

export const getDisclosuresTasks = (state) => {
  return getDisclosureObject(state).records
}

export const getDisclosureTask = (state) => {
  return getDisclosureObject(state).stagedRecord
}

export const getDisclosuresTaskItem = (state) => {
  return getDisclosureTaskItemObject(state).stagedRecord
}

export const getDisclosuresUiFlags = (state) => {
  return getDisclosureObject(state).uiFlags
}

// finders

// findDisclosureTask(getState(), { taskId: 1 })
export const findDisclosureTask = createSelector(
  [ getDisclosuresTasks, getTaskId ],
  (records, taskId) => find(records, record => record.id === taskId)
)

// findDisclosuresTaskItem(getState(), { taskId: 1, taskItemId: 2 })
export const findDisclosuresTaskItem = createSelector(
  [findDisclosureTask, getTaskItemId],
  (disclosuresTask, taskItemId) => find(disclosuresTask.taskItems, record => record.id === taskItemId)
)
