import AT from 'v2/actionTypes'

const initialState = {
  flashAlert: {},
  twoFactorCode: '',
  twoFactorCodeRequired: false,
  twoFactorEnabled: null,
  twoFactorPhone: '',
  twoFactorPhoneVerified: null,
  updatePhoneToggle: false,
  updating: false,
  cancelling: false,
}

export default function twoFactorAuth(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case AT.SET_TWO_FACTOR_CODE:
      return { ...state, twoFactorCode: payload }
    case AT.TOGGLE_TWO_FACTOR_CODE_REQUIRED:
      return { ...state, twoFactorCodeRequired: payload }
    case AT.SET_TWO_FACTOR_ENABLED:
      return { ...state, twoFactorEnabled: payload }
    case AT.SET_TWO_FACTOR_PHONE:
      return { ...state, twoFactorPhone: payload }
    case AT.ADD_FLASH_ALERT:
      return { ...state, flashAlert: payload }
    case AT.TOGGLE_MOBILE_PHONE_VERIFIED:
      return { ...state, twoFactorPhoneVerified: payload }
    case AT.TOGGLE_TWO_FACTOR_UPDATING:
      return { ...state, updating: payload }
    case AT.TOGGLE_UPDATE_PHONE:
      return { ...state, updatePhoneToggle: payload }
    case AT.TOGGLE_TWO_FACTOR_CANCELLING:
      return { ...state, cancelling: payload }
    default:
      return state
  }
}
