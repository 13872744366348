import api from 'utils/api'
import Route from 'Services/Route'

import toastr from 'utils/toastr'
import { toastrErrors } from 'utils/error_utils'
import { popToast, errorHandler } from 'v2/actions/system_notifications'

import AT from 'v2/actionTypes/'

import { getLenderSlug, getSelectedTeamId } from 'v2/selectors/page_info'

import { setLoanFile } from '../loan_files'

// Public actions
export function toggleEncompassNumberImportModal(bool = false) {
  return { type: AT.TOGGLE_ENCOMPASS_NUMBER_IMPORT_MODAL, payload: bool }
}

export function importEncompassLoanFile(encompassLoanIdentifier, multiverseLayout = false, teamId = null) {
  return (dispatch, getState) => {
    dispatch(importingLoanNumberFromEncompass(true))
    const url = Route.api.loss.importLoan()
    const req = api(getState).post(url, {
      loanIdentifier: encompassLoanIdentifier,
      teamId: teamId || getSelectedTeamId(getState()),
    })

    return req.then((res) => {
      const loanFile = res?.data?.loanFile
      const successMessage = 'Imported loan file from Encompass!'
      multiverseLayout ? dispatch(popToast({ notice: successMessage })) : toastr.success(successMessage)
      dispatch(setLoanFile(loanFile))
      const slug = getLenderSlug(getState())
      window.location.href = Route.lenders.loanFile.emailEditor({ id: loanFile?.id, slug: slug })
    }).catch((error) => {
      const { errors } = error?.response?.data || {}
      console.info(errors)
      const errorMessage = 'Sorry, We were unable to import your loan file.'
      if (multiverseLayout) {
        if (errors) dispatch(errorHandler({ message: errors }, errorMessage))
      } else toastrErrors(errorMessage)
      dispatch(importingLoanNumberFromEncompass(false))
    })
  }
}

function importingLoanNumberFromEncompass(bool = false) {
  return { type: AT.IMPORTING_LOAN_NUMBER_FROM_ENCOMPASS, payload: bool }
}
