import { createAsyncThunk } from '@reduxjs/toolkit'
import debounce from 'lodash/debounce'
import JSONAPIClient from 'apiV2/apiClient'

export const showLenderSettingsAPI = createAsyncThunk(
  'lenderSettings/show',
  async () => {
    const response = await JSONAPIClient.all('lender_setting').get()

    return response.data
  }
)

export const createOrUpdateLenderSettingsAPI = createAsyncThunk(
  'lenderSettings/create',
  async ({ lenderSettingsId, payload }) => {
    const response = lenderSettingsId
      ? await debouncedLenderSettingsUpdate(lenderSettingsId, payload)
      : await debouncedLenderSettingsCreate(payload)

    return response.data
  }
)

const debouncedLenderSettingsCreate = debounce(
  payload => JSONAPIClient.all('lender_setting').post(payload), 500
)

const debouncedLenderSettingsUpdate = debounce(
  (settingsId, payload) => JSONAPIClient.all('lender_setting').patch({ id: settingsId, ...payload }), 500
)
