import AT from '../../actionTypes/'

const initialState = {}

export default function stagedRecord(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case AT.SET_STAGED_LENDER: {
      return payload
    }
    default: {
      return state
    }
  }
}
