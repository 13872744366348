import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'
import { combineReducers } from 'redux'
import quickPricerSettingSlice from './quickPricer'
import ausSettingSlice from './aus'

import {
  updateSettingsAPI,
  showSettingsAPI,
} from './asyncActions'

const settingSlice = createSlice({
  name: 'setting',
  initialState: baseInitialState,
  reducers: baseReducers,
  extraReducers: {
    [showSettingsAPI.pending](state) {
      state.uiFlags.isFetchingItem = true
    },
    [showSettingsAPI.fulfilled](state, action) {
      state.uiFlags.isFetchingItem = false
      state.stagedRecord = action.payload
    },
    [showSettingsAPI.rejected](state) {
      state.uiFlags = false
    },
    [updateSettingsAPI.pending](state) {
      state.uiFlags.isUpdating = true
    },
    [updateSettingsAPI.fulfilled](state, action) {
      state.uiFlags.isUpdating = false
      state.stagedRecord = { ...state.stagedRecord, ...action.payload }
    },
    [updateSettingsAPI.rejected](state) {
      state.uiFlags.isUpdating = false
    },
  },
})

const settings = combineReducers({
  ausSetting: ausSettingSlice.reducer,
  quickPricerSetting: quickPricerSettingSlice.reducer,
  setting: settingSlice.reducer,
})

export default settings
