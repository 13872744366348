import mirrorCreator from 'mirror-creator'

export default mirrorCreator([
  'SET_STEP',
  'SET_SELECTED_ACCOUNT',
  'REMOVE_SELECTED_ACCOUNT',
  'SET_CONNECTIONS',
  'SET_SELECTED_CONNECTION',
  'SET_DESTINATIONS',
  'ADD_DOCUMENT_GROUP_TEMPLATE',
  'EDIT_DOCUMENT_GROUP_TEMPLATE',
  'REMOVE_DOCUMENT_GROUP_TEMPLATE',
  'SET_DOCUMENT_GROUP_TEMPLATE',
  'MOVE_UP_DOCUMENT_GROUP_TEMPLATE',
  'MOVE_DOWN_DOCUMENT_GROUP_TEMPLATE',
  'REMOVE_ID_FROM_DOCUMENT_GROUP_TEMPLATE_ORDER_LIST',
  'ADD_ID_TO_DOCUMENT_GROUP_TEMPLATE_ORDER_LIST',
  'SET_DOCUMENT_GROUP_TEMPLATE_ERROR',
  'SET_FILE_PREVIEW_ERROR',
  'SET_FILE_PREVIEW_SHOW',
  'SET_FILE_PREVIEW_EMBED_URL',
  'SET_FILE_PREVIEW_CURRENT_DOCUMENT',
  'RESET_FILE_PREVIEW_SHOW',
  'ADD_SELECTED_VAULT_DOCUMENT',
  'REMOVE_SELECTED_VAULT_DOCUMENT',
  'SET_VAULT_DOCUMENTS',
  'SET_DOWNLOADS',
  'ADD_DOWNLOAD',
  'SET_FT_DOCUMENTS',
  'SET_INTERACTIONS',
  'EDIT_LOAN_FILE',
  'TOGGLE_MODAL',
  'ADD_NOTIFICATION',
  'REMOVE_NOTIFICATION',
  'UPDATE_SIGNABLE_DOCUMENT',
  'DESTROY_SIGNABLE_DOCUMENT',
  'SHOW_SIGNABLE_DOCUMENT_EDIT_MODAL',
  'HIDE_SIGNABLE_DOCUMENT_EDIT_MODAL',
  'SHOW_SIGNABLE_DOCUMENT_RESEND_MODAL',
  'HIDE_SIGNABLE_DOCUMENT_RESEND_MODAL',
  'HIDE_SIGNABLE_DOCUMENT_DESTROY_MODAL',
  'SHOW_SIGNABLE_DOCUMENT_DESTROY_MODAL',
  'SET_SOURCES',
  'SET_DISPLAY_SOURCES',
  'FILTER_SOURCES',
  'FILTER_SOURCE_BY_TASK_TITLE',
  'SET_SELECTED_SOURCE',
  'SET_SELECTED_PERIOD',
  'FETCH_EVENT_NOTIFICATIONS',
  'SET_EVENT_NOTIFICATIONS',
  'SET_INTERACTION_FAILURE_STATE',
])
