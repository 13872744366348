import JSONAPIClient from '../../../JSONAPIClient'

JSONAPIClient.define(
  'filter',
  {
    item_id: '',
    title: '',
    data: '',
  },
  { collectionPath: 'bi/reports/filters' },
)
