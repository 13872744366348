import api from 'utils/api'
import Route from 'Services/Route'
import { toastrErrors } from 'utils/error_utils'

export function index(state, teamId) {
  const url = Route.api.teams.loan_files({ teamId })
  const req = api(state).get(url)

  return req.then((res) => {
    const { data: { loanFiles } } = res
    return loanFiles
  }).catch((error) => {
    console.error(error)
    toastrErrors('There was an issue fetching these loan files!')
    if (error.body) toastrErrors(error.body)
    return null
  })
}
