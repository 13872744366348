import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'
import { createQuickPricerApplyAPI } from './asyncActions'

const quickPricerApplySlice = createSlice({
  name: 'quickPricerApply',
  initialState: baseInitialState,
  reducers: baseReducers,
  extraReducers: {
    [createQuickPricerApplyAPI.pending](state) {
      state.uiFlags.isCreating = true
    },
    [createQuickPricerApplyAPI.fulfilled](state) {
      state.uiFlags.isCreating = false
    },
    [createQuickPricerApplyAPI.rejected](state) {
      state.uiFlags.isCreating = false
    },
  },
})

export default quickPricerApplySlice
