import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'
import { showLoanAppAPI, updateLoanAppAPI, destroyLoanAppAPI } from './asyncActions'

const loanAppSlice = createSlice({
  name: 'loanApp',
  initialState: baseInitialState,
  reducers: baseReducers,
  extraReducers: {
    [showLoanAppAPI.pending](state) {
      state.uiFlags.isFetchingItem = true
    },
    [showLoanAppAPI.fulfilled](state, action) {
      state.uiFlags.isFetchingItem = false
      state.stagedRecord = action.payload
    },
    [showLoanAppAPI.rejected](state) {
      state.uiFlags = false
    },
    [updateLoanAppAPI.pending](state) {
      state.uiFlags.isUpdating = true
    },
    [updateLoanAppAPI.fulfilled](state, action) {
      state.uiFlags.isUpdating = false
      state.stagedRecord = action.payload
    },
    [updateLoanAppAPI.rejected](state) {
      state.uiFlags = false
    },
    [destroyLoanAppAPI.pending](state) {
      state.uiFlags.isDeleteting = true
    },
    [destroyLoanAppAPI.fulfilled](state, action) {
      state.uiFlags.isDeleteting = false
      const { id: deletedAppId } = action.payload
      state.stagedRecord = {}
      state.records = state.records.filter(app => +app.id !== +deletedAppId)
    },
    [destroyLoanAppAPI.rejected](state) {
      state.uiFlags.isDeleteting = false
    },
  },
})

export default loanAppSlice
