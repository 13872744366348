import { capitalize } from 'underscore.string'


export const LENDER_BASIC_DOCUMENT_VAULT_METADATA = [
  {
    sortKey: 'fileName',
    sortable: true,
    label: 'File Name',
  },
  {
    sortKey: 'documentGroupTitle',
    sortable: true,
    label: 'Task',
    sortCriteria: ((record, key) => record[key] || 'Other'),
  },
  {
    sortKey: 'status',
    sortable: true,
    label: 'Status',
  },
  {
    sortKey: 'uploadedOn',
    sortable: true,
    label: 'Uploaded',
    sortCriteria: ((obj, key) => new Date(obj[key])) || '000000000',
  },
]

export const LENDER_LQB_DOCUMENT_VAULT_METADATA = [
  {
    sortKey: 'fileName',
    sortable: true,
    label: 'File Name',
  },
  {
    sortKey: 'documentGroupTitle',
    sortable: true,
    label: 'Task',
    sortCriteria: ((obj, key) => capitalize(obj[key]) || 'Other'),
  },
  {
    sortKey: 'externalDocumentStatus',
    sortable: true,
    label: 'Sent to LendingQB',
  },
  {
    sortKey: 'status',
    sortable: true,
    label: 'Status',
  },
  {
    sortKey: 'uploadedOn',
    sortable: true,
    label: 'Uploaded',
    sortCriteria: ((obj, key) => new Date(obj[key])) || '000000000',
  },
]

export const LENDER_ENCOMPASS_DOCUMENT_VAULT_METADATA = [
  {
    sortKey: 'fileName',
    sortable: true,
    label: 'File Name',
  },
  {
    sortKey: 'documentGroupTitle',
    sortable: true,
    label: 'Task',
    sortCriteria: ((obj, key) => capitalize(obj[key]) || 'Other'),
  },
  {
    sortKey: 'externalDocumentStatus',
    sortable: true,
    label: 'Sent to Encompass',
  },
  {
    sortKey: 'status',
    sortable: true,
    label: 'Status',
  },
  {
    sortKey: 'uploadedOn',
    sortable: true,
    label: 'Uploaded',
    sortCriteria: ((obj, key) => new Date(obj[key])) || '000000000',
  },
]

export const LENDER_INTEGRA_DOCUMENT_VAULT_METADATA = [
  {
    sortKey: 'fileName',
    sortable: true,
    label: 'File Name',
  },
  {
    sortKey: 'documentGroupTitle',
    sortable: true,
    label: 'Task',
    sortCriteria: ((obj, key) => capitalize(obj[key]) || 'Other'),
  },
  {
    sortKey: 'externalDocumentStatus',
    sortable: true,
    label: 'Sent to Integra',
  },
  {
    sortKey: 'status',
    sortable: true,
    label: 'Status',
  },
  {
    sortKey: 'uploadedOn',
    sortable: true,
    label: 'Uploaded',
    sortCriteria: ((obj, key) => new Date(obj[key])) || '000000000',
  },
]

export const LENDER_BYTE_DOCUMENT_VAULT_METADATA = [
  {
    sortKey: 'fileName',
    sortable: true,
    label: 'File Name',
  },
  {
    sortKey: 'documentGroupTitle',
    sortable: true,
    label: 'Task',
    sortCriteria: ((obj, key) => capitalize(obj[key]) || 'Other'),
  },
  {
    sortKey: 'externalDocumentStatus',
    sortable: true,
    label: 'Sent to Byte',
  },
  {
    sortKey: 'status',
    sortable: true,
    label: 'Status',
  },
  {
    sortKey: 'uploadedOn',
    sortable: true,
    label: 'Uploaded',
    sortCriteria: ((obj, key) => new Date(obj[key])) || '000000000',
  },
]
