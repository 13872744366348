import { isLocalStorageSupported } from 'Services/LocalStorageHelper'
import AT from '../../actionTypes'

const MESSENGER_KEY = 'maxwell-messenger-viewable'

let messengerViewable = false
if (isLocalStorageSupported()) {
  const messengerViewableValue = localStorage.getItem(MESSENGER_KEY)
  messengerViewable = messengerViewableValue === 'true'
}

const setItemOnLocalStorage = (value) => {
  if (isLocalStorageSupported()) {
    localStorage.setItem(MESSENGER_KEY, value)
  }
}


const initialState = { messengerViewable }

export default function uiFlags(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case AT.SET_MESSENGER_VIEWABLE_STATE:
      setItemOnLocalStorage(payload)
      return { ...state, messengerViewable: payload }
    default:
      return state
  }
}
