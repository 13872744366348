import BaseLoanAppQuestionResponseGetter from './BaseLoanAppQuestionResponseGetter'

export default class LoanAppParentQuestionResponseGetter extends BaseLoanAppQuestionResponseGetter {
  questionName = () => {
    const { name } = this
    if (name === 'approximate_downpayment') {
      return 'homebuying_process_status'
    }
    return null
  }
}
