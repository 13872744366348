import { disclosuresTasks as ActionTypes } from '../../actionTypes'

export default function stagedRecord(state = {}, action) {
  const { type, payload } = action
  switch (type) {
    case ActionTypes.setStagedRecord:
      return payload
    case ActionTypes.updateStagedRecord:
      return { ...state, ...payload }
    default:
      return state
  }
}
