import { isEmpty, map, filter } from 'underscore'

export function isPresent(obj) {
  return !isEmpty(obj)
}

export function caseInsensitiveContains(word, query) {
  if (!word || !query) return false
  return Boolean(~word.toLowerCase().indexOf(query.toLowerCase()))
}
