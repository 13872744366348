import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'

export const indexTaskItemAPI = createAsyncThunk(
  'loan_app_task_item/index',
  async ({ loanAppId, loanFileId, include }) => {
    let response

    if (loanAppId) {
      response = await JSONAPIClient.one('loan_app', loanAppId).all('loan_app_task_items').get({ include: include })
    }

    if (loanFileId) {
      response = await JSONAPIClient.one('loan_file', loanFileId).all('loan_app_task_items').get({ include: include })
    }

    return response.data
  }
)

export const showTaskItemAPI = createAsyncThunk(
  'loan_app_task_item/show',
  async ({ loanAppId, taskItemId, include }) => {
    const response = await JSONAPIClient
      .one('loan_app', loanAppId)
      .one('loan_app_task_items', taskItemId)
      .get({ include: include })
    return response.data
  }
)

export const updateTaskItemAPI = createAsyncThunk(
  'loan_app_task_item/update',
  async ({ taskItemId, payload, include }) => {
    const response = await JSONAPIClient.update(
      'loan_app_task_item', { id: taskItemId, ...payload }, { include: include }, {}
    )
    return response.data
  }
)
