import toastr from 'utils/toastr'

import api from 'utils/api'
import Route from 'Services/Route'
import { toastrErrors } from 'utils/error_utils'

import { getLoanFileId } from 'v2/selectors/loan_files'
import AT from '../../actionTypes'
import { setLoanFile } from '../loan_files'

// Public actions
export function validateLqbNumber(lfId, lendingQbLoanNumber) {
  return (dispatch) => {
    return Promise.all([
      dispatch(valdiatingLoanNumberWithLendingQb(true)),
      dispatch(validateLendingQbLoanNumber({ lendingQbLoanNumber })),
      dispatch(valdiatingLoanNumberWithLendingQb(false)),
    ])
  }
}

export function toggleLendingQbNumberEditModal(bool = false) {
  return { type: AT.TOGGLE_LENDING_QB_NUMBER_EDIT_MODAL, payload: bool }
}

// Private actions
function validateLendingQbLoanNumber({ lendingQbLoanNumber }) {
  return async (dispatch, getState) => {
    const loanFileId = getLoanFileId(getState())
    const url = Route.api.lendingQb.importExternalLoan({ loanFileId })
    const params = { loan_number: lendingQbLoanNumber }

    try {
      const res = await api(getState).post(url, params)
      const { loanFile } = res.data
      dispatch(toggleLendingQbNumberEditModal(false))
      toastr.success('Validated loan number with LendingQB!')
      dispatch(setLoanFile(loanFile))
    } catch (err) {
      const { errors } = err.response.data
      if (errors) toastrErrors(errors)
    }
  }
}

function valdiatingLoanNumberWithLendingQb(bool = false) {
  return { type: AT.VALIDATING_LOAN_NUMBER_WITH_LENDING_QB, payload: bool }
}
