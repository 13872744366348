const keyPrefix = 'maxwell'

export const isLocalStorageSupported = () => {
  try {
    const key = `${keyPrefix}-test-local-storage`
    localStorage.setItem(key, key)
    localStorage.removeItem(key)
    return true
  } catch (e) {
    return false
  }
}
