import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'
import snakeCase from 'lodash/snakeCase'

const PAGE_SIZE = 20

export const indexLoanFileDashboardStatAPI = createAsyncThunk(
  'loanFileDashboardStat/index',
  async ({ page: { number = 1, size = PAGE_SIZE }, sortKey = 'borrower_name', sortDirection = 'asc', filter }) => {
    const sortOperator = sortDirection === 'asc' ? '' : '-'
    const response = await JSONAPIClient.findAll('loan_file_dashboard_stat', {
      page: {
        number,
        size,
      },
      sort: `${sortOperator}${snakeCase(sortKey)}`,
      filter,
    })
    const { meta, data, links } = response

    return { meta, data, links }
  }
)

export const showLoanFileDashboardStatApi = createAsyncThunk(
  'loanFileDashboardStat/show',
  async ({ id }) => {
    const response = await JSONAPIClient.one('loan_file_dashboard_stat', id).get()
    return response.data
  }
)

export const updateLoanFileDashboardStat = createAsyncThunk(
  'loanFileDashboardStat/update',
  async payload => payload
)
