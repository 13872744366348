import { toastrErrors } from 'utils/error_utils'
import api from 'utils/api'
import toastr from 'utils/toastr'
import AT from '../actionTypes'
import Route from '../../Services/Route'
import { isInvalidTimePeriod } from '../../utils/task_utils'

export function startDocumentGroupTemplateCreate() {
  return (dispatch) => {
    dispatch(setStagedTaskTemplate({ type: 'DocumentGroupTemplate' }))
    dispatch(toggleTaskTemplateFormModal(true))
  }
}

export function startInsureDocsTemplateCreate() {
  return (dispatch) => {
    const insureTask = {
      type: 'DocumentGroupTemplate',
      note: `Don’t have homeowners insurance yet?
        Get a quote in less than 15 minutes and we’ll automatically sync it to your account.`,
      period: '',
      title: 'Homeowners Insurance',
    }
    Promise.all([
      dispatch(setStagedTaskTemplate(insureTask)),
      dispatch(toggleTaskTemplateFormModal(true)),
    ])
  }
}

export function startLoanApplicationTemplateCreate({ enhanced = false }) {
  return (dispatch) => {
    dispatch(
      setStagedTaskTemplate({ type: enhanced ? 'LoanAppV2Template' : 'LoanAppTemplate', templateId: 0, enhanced })
    )
    dispatch(toggleTaskTemplateFormModal(true))
  }
}

export function startTaskTemplateTemplateEdit(template) {
  return (dispatch) => {
    dispatch(setStagedTaskTemplate(template))
    dispatch(toggleTaskTemplateFormModal(true))
  }
}

export function startTaskTemplateDestroy(template) {
  return (dispatch) => {
    dispatch(setStagedTaskTemplate(template))
    dispatch(toggleTaskTemplateDestroyModal(true))
  }
}

export function updateStagedTaskTemplate(updates) {
  return { type: AT.UPDATE_STAGED_DOCUMENT_GROUP_TEMPLATE, payload: updates }
}

export function toggleTaskTemplateFormModal(payload = false) {
  return { type: AT.SHOW_TASK_TEMPLATE_FORM_MODAL, payload: payload }
}
export function toggleTaskTemplateDestroyModal(payload = false) {
  return { type: AT.SHOW_TASK_TEMPLATE_DESTROY_MODAL, payload: payload }
}

export function toggleCloneTaskTemplateModal(payload = false) {
  return { type: AT.SHOW_TASK_TEMPLATE_CLONE_MODAL, payload: payload }
}

export function cloneTaskTemplate(loanFileTemplateId, master = false) {
  return (dispatch, getState) => {
    // const { stagedRecord } = getState().v2.taskTemplates

    let url

    if (master) {
      url = Route.api.masterLoanFileTemplates.taskTemplates.clone(
        { loanFileTemplateId: loanFileTemplateId },
      )
    } else {
      // TODO : Implement cloning for loan_file_templates
    }

    const req = api(getState).post(url)

    req.then((res) => {
      const { taskTemplate } = res.data
      toastr.success(`Successfully cloned ${taskTemplate.displayTitle}`)
      dispatch(addTaskTemplate(taskTemplate))
      dispatch(toggleCloneTaskTemplateModal(false))
      dispatch(setStagedTaskTemplate({}))
    }).catch((error) => {
      const { errors } = error?.response?.data || {}
      if (errors) toastrErrors(errors)
    })
  }
}

export function createTaskTemplate(loanFileTemplateId, master = false) {
  return (dispatch, getState) => {
    const { stagedRecord } = getState().v2.taskTemplates
    if (isInvalidTimePeriod(stagedRecord.timePeriod)) {
      toastrErrors('Please enter a valid number of months')
      return
    }

    const context = master ? 'masterLoanFileTemplates' : 'loanFileTemplates'

    const url = Route.api[context].taskTemplates.create(
      { loanFileTemplateId: loanFileTemplateId },
    )

    const req = api(getState).post(url, taskTemplateData(stagedRecord))

    req.then((res) => {
      const { taskTemplate } = res.data
      toastr.success(`Successfully added ${taskTemplate.displayTitle}`)
      dispatch(addTaskTemplate(taskTemplate))
      dispatch(toggleTaskTemplateFormModal(false))
      dispatch(setStagedTaskTemplate({}))
    }).catch((error) => {
      const { errors } = error?.response?.data || {}
      if (errors) toastrErrors(errors)
    })
  }
}

export function updateTaskTemplate(master = false) {
  return (dispatch, getState) => {
    const { stagedRecord } = getState().v2.taskTemplates
    if (isInvalidTimePeriod(stagedRecord.timePeriod)) {
      toastrErrors('Please enter a valid number of months')
      return
    }

    let url

    if (master) {
      url = Route.api.masterLoanFileTemplates.taskTemplates.update(stagedRecord)
    } else {
      url = Route.api.loanFileTemplates.taskTemplates.update(stagedRecord)
    }

    const req = api(getState).patch(url, taskTemplateData(stagedRecord))

    req.then((res) => {
      const { taskTemplate } = res.data
      toastr.success(`Successfully updated ${taskTemplate.displayTitle}`)
      dispatch(editTaskTemplate(taskTemplate))
      dispatch(toggleTaskTemplateFormModal(false))
      dispatch(setStagedTaskTemplate({}))
    }).catch((error) => {
      const { errors } = error?.response?.data || {}

      console.info(errors)
      if (errors) toastrErrors(errors)
    })
  }
}

export function destroyTaskTemplate(master = false) {
  return (dispatch, getState) => {
    const { stagedRecord } = getState().v2.taskTemplates

    let url

    if (master) {
      url = Route.api.masterLoanFileTemplates.taskTemplates.destroy(stagedRecord)
    } else {
      url = Route.api.loanFileTemplates.taskTemplates.destroy(stagedRecord)
    }

    const req = api(getState).delete(url)

    req.then((res) => {
      const { taskTemplate } = res.data
      toastr.success(`Successfully deleted ${taskTemplate.displayTitle}`)
      dispatch(removeTaskTemplate(taskTemplate))
      dispatch(endTaskTemplateDestroy())
    }).catch((error) => {
      const { errors } = error?.response?.data || {}

      console.info(errors)
      if (errors) toastrErrors(errors)
    })
  }
}

export function saveDocumentGroupTemplateDisplayOrder(loanFileTemplateId, master = false) {
  return (dispatch, getState) => {
    const { documentGroupTemplateDisplayOrder } = getState().v2

    let url

    if (master) {
      url = Route.api.masterLoanFileTemplates.update({ id: loanFileTemplateId })
    } else {
      url = Route.api.loanFileTemplates.update({ id: loanFileTemplateId })
    }

    const params = { data: { document_group_template_display_order: documentGroupTemplateDisplayOrder } }

    const req = api(getState).patch(url, params)

    req.then(() => {
      toastr.success('Ordering was saved successfully.')
    }).catch(() => {
      toastr.error('Failed to save the ordering. Please try again in some time.')
    })
  }
}

function endTaskTemplateDestroy() {
  return (dispatch) => {
    dispatch(setStagedTaskTemplate({}))
    dispatch(toggleTaskTemplateDestroyModal(false))
  }
}

function addTaskTemplate(taskTemplate) {
  return { type: AT.ADD_TASK_TEMPLATE, payload: taskTemplate }
}

export function removeTaskTemplate(taskTemplate) {
  return { type: AT.REMOVE_TASK_TEMPLATE, payload: taskTemplate }
}

function editTaskTemplate(taskTemplate) {
  return { type: AT.EDIT_TASK_TEMPLATE, payload: taskTemplate }
}

export function moveUpTaskTemplate(taskTemplate) {
  return { type: AT.MOVE_UP_DOCUMENT_GROUP_TEMPLATE, payload: taskTemplate }
}

export function moveDownTaskTemplate(taskTemplate) {
  return { type: AT.MOVE_DOWN_DOCUMENT_GROUP_TEMPLATE, payload: taskTemplate }
}

export function setStagedTaskTemplate(taskTemplate) {
  return { type: AT.SET_STAGED_DOCUMENT_GROUP_TEMPLATE, payload: taskTemplate }
}

function taskTemplateData(taskTemplate) {
  return ({
    task_template: {
      category: taskTemplate.category,
      title: taskTemplate.title,
      due_in_days: taskTemplate.dueInDays,
      note: taskTemplate.note,
      subtitle: taskTemplate.subtitle,
      period: taskTemplate.period,
      timePeriod: taskTemplate.timePeriod,
      type: taskTemplate.type,
      template_id: taskTemplate.templateId,
      enhanced: taskTemplate.enhanced,
      requiredFor: taskTemplate.requiredFor,
    },
  })
}
