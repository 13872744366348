import { createAsyncThunk } from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash'
import { errorHandler, alertHandler } from 'v2/actions'
import JSONAPIClient from 'apiV2/apiClient'
import snakecaseKeys from 'snakecase-keys'

export const createUserAccountAPI = createAsyncThunk(
  'user/accountCreate',
  async (payload, thunkAPI) => {
    try {
      payload = snakecaseKeys(payload || {}, { deep: true })
      const response = await JSONAPIClient.axios.post('/users.json', payload)
      return response.data
    } catch (e) {
      if (e.response?.status === 302 && e.response?.data?.redirect_url) {
        window.location.href = e.response?.data?.redirect_url
        return {}
      }

      let message

      if (e.response?.status === 409) {
        message = `Something went wrong while creating your account.
        A link to activate your account has been emailed to the address provided.`
      } else {
        message = 'Something went wrong. Please try again, or contact your loan officer if you think this is a mistake.'
      }
      thunkAPI.dispatch(alertHandler(message))
      return {}
    }
  }
)

export const sendOtpAPI = createAsyncThunk(
  'user/sendOtp',
  async ({ userId, payload }) => {
    try {
      return await JSONAPIClient.request(`/api/v2/users/${userId}/send_otp`, 'POST', {}, payload)
    } catch (e) {
      return e
    }
  }
)

export const confirmOtpAPI = createAsyncThunk(
  'user/confirmOtp',
  async ({ userId, payload }) => {
    try {
      return await JSONAPIClient.request(`/api/v2/users/${userId}/confirm_otp`, 'POST', {}, payload)
    } catch (e) {
      return e
    }
  }
)

export const updateUserAPI = createAsyncThunk(
  'user/update',
  async ({ userId, payload }) => {
    try {
      return await JSONAPIClient.request(`/api/v2/users/${userId}`, 'PATCH', {}, payload)
    } catch (e) {
      return e
    }
  }
)

export const showUserAPI = createAsyncThunk(
  'user/show',
  async (userId) => {
    try {
      return await JSONAPIClient.request(`/api/v2/users/${userId}`, 'GET')
    } catch (e) {
      return e
    }
  }
)

export const confirmUserAccountAPI = createAsyncThunk(
  'user/accountConfirm',
  async (payloadParam, thunkAPI) => {
    try {
      const payload = cloneDeep(payloadParam)
      const { user: { name: { first, middle, last, suffix } } } = payload
      payload.user.first_name = first
      payload.user.middle_name = middle
      payload.user.last_name = last
      payload.user.suffix = suffix
      const response = await JSONAPIClient.axios.patch('/user/confirmation/new.json', payload)
      return response.data
    } catch (error) {
      const { response: { data = {} } } = error

      let errorMsg = 'Something went wrong. Please try again, or reset your password if you think this is a mistake.'

      if (Object.keys(data).includes('username')) {
        errorMsg = 'Username must be unique to create an account'
      } else if (Object.keys(data).includes('name')) {
        errorMsg = `Name ${data.name}`
      }

      thunkAPI.dispatch(errorHandler({}, errorMsg))

      return thunkAPI.rejectWithValue({ payload: {} })
    }
  }
)

