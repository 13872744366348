import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'
import { popToast } from 'v2/actions/system_notifications'

export const indexLegalAgreements = createAsyncThunk(
  'legalAgreements/index',
  async (params = {}, thunkAPI) => {
    try {
      const { data } = await JSONAPIClient.all('legal_agreement').get(params)
      return data
    } catch (e) {
      thunkAPI.dispatch(popToast({ error: 'There was an error fetching legal agreements' }))
      return e.response.error
    }
  }
)
