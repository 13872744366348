import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'

import { indexSignatures, createSignature } from './asyncActions'

const signaturesSlice = createSlice({
  name: 'legalAgreementsSignature',
  initialState: baseInitialState,
  reducers: baseReducers,
  extraReducers: {
    [indexSignatures.fulfilled](state, action) {
      state.records = action.payload
    },
    [createSignature.fulfilled](state, action) {
      state.records = [action.payload]
    },
  },
})

export default signaturesSlice
