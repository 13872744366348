import api from 'utils/api'
import { toastrErrors } from 'utils/error_utils'
import Route from 'Services/Route'
import { getLoanFileId } from 'v2/selectors/loan_files'
import AT from '../actionTypes'
import { setLoanFile } from './loan_files'
import { setIncompleteTasks } from './tasks'
import { setSignableDocuments } from './signable_documents'
import { setLoanApps } from './loan_app/loan_app'


export function setSelectedTaskTemplates(templateIds) {
  return { type: AT.SET_SELECTED_TASK_TEMPLATES, payload: templateIds }
}

// Set the task template on the loan_file
// and Update the loan_file
export function updateLoanFileTaskTemplate(taskTemplateIds) {
  return async (dispatch, getState) => {
    const loanFileId = getLoanFileId(getState())

    const url = Route.api.loanFile.defaultTemplates({ loanFileId })
    const params = { template_ids: taskTemplateIds }

    try {
      const res = await api(getState).post(url, params)
      const {
        tasks,
        signableDocuments,
        loanApps,
        loanFile: updatedLoanFile,
        v2LoanAppTasks,
      } = res.data

      dispatch(setLoanFile(updatedLoanFile))
      dispatch(setIncompleteTasks(tasks))
      dispatch(setSignableDocuments(signableDocuments))
      dispatch(setLoanApps(v2LoanAppTasks.concat(loanApps)))
    } catch (error) {
      const { errors } = error?.response?.data || {}
      if (errors) toastrErrors(errors)
      throw error
    }
  }
}
