import AT from 'v2/actionTypes'

const initialState = {
  toasts: [],
  alerts: [],
}

export default function systemNotifications(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case AT.POP_TOAST:
      return { ...state, toasts: [...state.toasts, payload] }
    case AT.RESET_TOASTS:
      return { ...state, toasts: payload }
    case AT.POP_ALERT:
      return { ...state, alerts: [...state.alerts, payload] }
    case AT.RESET_ALERTS:
      return { ...state, alerts: payload }
    default:
      return state
  }
}
