import api from 'utils/api'
import Route from 'Services/Route'

export const logInfo = payload => log({
  level: 'info',
  ...payload,
})

const log = payload => async (_, getState) => {
  const url = Route.api.log()
  try {
    await api(getState).post(url, payload)
  } catch (error) {
    // Do something with error
  }
}
