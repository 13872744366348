import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'
import {
  createScenario,
  destroyScenario,
  indexScenarios,
  showScenario,
} from './asyncActions'

export const ausScenariosSlice = createSlice({
  name: 'ausScenarios',
  initialState: baseInitialState,
  reducers: baseReducers,
  extraReducers: {
    [createScenario.pending](state) {
      state.uiFlags.isCreating = true
    },
    [createScenario.fulfilled](state, action) {
      state.uiFlags.isCreating = false
      state.records = [...state.records, action.payload]
    },
    [createScenario.rejected](state) {
      state.uiFlags.isCreating = false
    },
    [indexScenarios.pending](state) {
      state.uiFlags.isFetching = true
    },
    [indexScenarios.fulfilled](state, action) {
      state.uiFlags.isFetching = false
      state.records = action.payload
    },
    [indexScenarios.rejected](state) {
      state.uiFlags.isFetching = false
    },
    [showScenario.pending](state) {
      state.uiFlags.isFetchingItem = true
    },
    [showScenario.fulfilled](state, action) {
      state.uiFlags.isFetchingItem = false
      state.stagedRecord = action.payload
    },
    [showScenario.rejected](state) {
      state.uiFlags.isFetchingItem = false
    },
    [destroyScenario.pending](state) {
      state.uiFlags.isDeleting = true
    },
    [destroyScenario.fulfilled](state, action) {
      state.uiFlags.isDeleting = false
      const { id: scenarioId } = action.payload
      state.records = state.records.filter(s => +s.id !== +scenarioId)
    },
    [destroyScenario.rejected](state) {
      state.uiFlags.isDeleting = false
    },
  },
})

export default ausScenariosSlice.reducer
