import { apiWithoutInflections as api } from 'utils/api'
import Route from 'Services/Route'
import { toastrErrors } from 'utils/error_utils'

import AT from '../actionTypes'

export function setCommunications(communications) {
  return {
    type: AT.SET_COMMUNICATIONS,
    payload: communications,
  }
}

export function addCommunication(communications) {
  return {
    type: AT.ADD_COMMUNICATION,
    payload: communications,
  }
}

export function toggleAttachmentModal(isViewable = false) {
  return {
    type: AT.TOGGLE_ATTACHMENT_MODAL,
    payload: isViewable,
  }
}

export function removeCommunication(communication) {
  return {
    type: AT.REMOVE_COMMUNICATION,
    payload: communication,
  }
}

function setMockCommunication(communication) {
  return {
    type: AT.SET_MOCK_COMMUNICATION,
    payload: communication,
  }
}

function removeMockCommunication(mockCommunication) {
  return {
    type: AT.REMOVE_MOCK_COMMUNICATION,
    payload: mockCommunication,
  }
}

function toggleNlpLoading(bool = false) {
  return { type: AT.TOGGLE_NLP_LOADING, payload: bool }
}

export function onYesClick() {
  return (dispatch) => {
    dispatch(updateNlpHelpful('yes'))
  }
}

export function onNoClick() {
  return (dispatch) => {
    dispatch(updateNlpHelpful('no'))
  }
}

function updateNlpHelpful(helpful = 'yes') {
  return (dispatch, getState) => {
    const id = getState().v2LoanFiles.loanFile.id
    const comms = getState().v2.communications.records
    const mockCommunication = getState().v2.communications.mockCommunication

    const unacknowledgedComms = comms.filter(comm => !comm.acknowledged)
    // wit.ai communication
    const lastComm = unacknowledgedComms.slice(-1)[0]

    const url = Route.api.messenger.acknowledged({
      id: id,
      communicationId: lastComm.id,
    })

    const req = api(getState).post(url, { helpful: helpful })

    // after request has been submitted and acknowledged
    req.then(() => {
      dispatch(removeMockCommunication(mockCommunication))

      scrollToBottomOfMessenger()
    }).catch((error) => {
      const { errors } = error?.response?.data || {}

      console.info(errors)
      if (errors) toastrErrors(errors)
    })
  }
}

export function scrollToBottomOfMessenger() {
  const messenger = document.getElementById('chat-container')
  if (messenger) messenger.scrollTop = messenger.scrollHeight
}

export function fetchCommunications(loanFileId, poolType = 'borrower') {
  return (dispatch, getState) => {
    dispatch(setCommunicationsLoading(true))
    const id = loanFileId || getState().v2LoanFiles.loanFile.id
    const url = Route.api.messenger.communications({ id })

    const req = api(getState).get(url, { params: { pool_type: poolType } })

    req.then((res) => {
      const { communications } = res.data

      dispatch(setCommunications(communications))
      dispatch(setCommunicationsLoading(false))
      scrollToBottomOfMessenger()
    }).catch((error) => {
      const { errors } = error?.response?.data || {}

      console.info(errors)
      if (errors) toastrErrors(errors)
    })
  }
}

export function fetchCommunication(loanFileId) {
  return (dispatch, getState) => {
    const id = loanFileId || getState().v2LoanFiles.loanFile.id
    const url = Route.api.messenger.communication({ id })

    const req = api(getState).get(url)

    req.then((res) => {
      const { communication } = res.data

      dispatch(addCommunication(communication))
      scrollToBottomOfMessenger()
    })
  }
}

export function createCommunication(loanFileId, message, poolType = 'borrower') {
  return (dispatch, getState) => {
    const data = {
      message: message,
      pool_type: poolType,
    }

    const id = loanFileId || getState().v2LoanFiles.loanFile.id
    const url = Route.api.messenger.communications({ id })

    const req = api(getState).post(url, data)

    req.then((res) => {
      const {
        communication,
        loadingCommunication,
      } = res.data

      dispatch(addCommunication(communication))

      if (communication.triggered === true) {
        dispatch(toggleNlpLoading(true))
        dispatch(setMockCommunication(loadingCommunication))

        setTimeout(() => {
          dispatch(fetchCommunication(id))
          dispatch(toggleNlpLoading(false))
        }, 10000)
      }

      scrollToBottomOfMessenger()
    })
  }
}

// private functions

function setCommunicationsLoading(bool = false) {
  return { type: AT.SET_COMMUNICATIONS_LOADING, payload: bool }
}
