import JSONAPIClient from '../../JSONAPIClient'

JSONAPIClient.define(
  'dus',
  {
    id: '',
    owner_id: '',
    owner_type: '',
    username: '',
    password: '',
    config_is_active: '',
    sandbox: '',
  },
  { type: 'du', collectionPath: 'external_configs/du' }
)
