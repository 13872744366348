import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'

import { findQuestionFeedback, createQuestionFeedback, updateQuestionFeedback } from './asyncActions'

const biQuestionFeedbackSlice = createSlice({
  name: 'bi_question_feedback',
  initialState: baseInitialState,
  reducers: baseReducers,
  extraReducers: {
    [findQuestionFeedback.fulfilled](state, action) {
      state.stagedRecord = action.payload || {}
    },
    [createQuestionFeedback.fulfilled](state, action) {
      state.stagedRecord = action.payload
    },
    [updateQuestionFeedback.fulfilled](state, action) {
      state.stagedRecord = action.payload
    },
  },
})

export default biQuestionFeedbackSlice
