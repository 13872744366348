import { combineReducers } from 'redux'
import { reducer as reduxFormReducer } from 'redux-form'

// V2 REDUCERS
//
// TODO : Remove v2 from reducer filenames and add to v2 namespace
import ausScenarios from 'apiV2/store/externalConfigs/aus/scenarios'
import ausUnderwritingDecisions from 'apiV2/store/externalConfigs/aus/underwritingDecisions'
import ausScenarioAnswers from 'apiV2/store/externalConfigs/aus/scenarioAnswers'
import ausSettings from 'apiV2/store/settings/aus'
import creditVendors from 'apiV2/store/credit'
import externalCreditConfigs from 'apiV2/store/externalConfigs/credit'
import lpa from 'apiV2/store/externalConfigs/LPA'
import legalAgreements from 'apiV2/store/legalAgreements'
import lendersV2 from 'apiV2/store/lenders'
import loanNotesSlice from 'apiV2/store/loanNotes'
import getstream from 'apiV2/store/getstream'
import loanApplicationsV2 from 'apiV2/store/loanApplications'
import miReports from 'apiV2/store/bi/reports'
import biQuestionsSlice from 'apiV2/store/bi/questions'
import biPresetQuestionsSlice from 'apiV2/store/bi/presetQuestions'
import biQuestionFeedbackSlice from 'apiV2/store/bi/questionFeedbacks'
import biFavoriteQuestionSlice from 'apiV2/store/bi/favoriteQuestions'
import { loanFileReducer } from 'apiV2/store/loanFiles'
import { consentReducer } from 'apiV2/store/consent'
import quickApply from 'apiV2/store/quickApply'
import quickPricer from 'apiV2/store/quickPricer'
import settings from 'apiV2/store/settings'
import statementImportSlice from 'apiV2/store/statementImport'
import loanOfficerTeam from 'apiV2/store/loanOfficerTeam'
import teams from 'apiV2/store/teams'
import taskListTemplates from 'apiV2/store/taskListTemplates'

// Old "V2", not JSONAPI implementation
import v2 from 'v2/reducers'
import v2Documents from 'v2/reducers/documents'
import v2Downloads from 'v2/reducers/v2_downloads'
import v2FileSelector from 'v2/reducers/file_selector'
import v2Lenders from 'v2/reducers/lenders'
import v2LoanFiles from 'v2/reducers/loan_files'
import v2LoanFileTemplates from 'v2/reducers/loan_file_templates'
import v2Tasks from 'v2/reducers/tasks'

import documentGroupTemplates from './document_group_templates'
import documentGroupTemplateDisplayOrder from './document_group_template_display_order'
import documentGroupTemplateForm from './document_group_template_form'
import documentPreview from './document_preview'
import downloads from './downloads'
import interactions from './interactions'
import modal from './modal'
import notifications from './notifications'
import selectedVaultDocuments from './selected_vault_documents'
import signableDocuments from './signable_documents'
import spinner from './spinner'
import pageInfo from './pageInfo'

// FILETHIS COMPONENTS
import accountConnections from './account_connections'
import connections from './connections'
import sources from './sources'
import periods from './periods'
import eventNotifications from './event_notifications'

const reducers = {
  accountConnections,
  ausScenarios,
  ausUnderwritingDecisions,
  ausScenarioAnswers,
  ausSettings,
  connections,
  consent: consentReducer,
  creditVendors,
  externalCreditConfigs,
  documentGroupTemplateDisplayOrder,
  documentGroupTemplateForm,
  documentGroupTemplates,
  documentPreview,
  downloads,
  eventNotifications,
  form: reduxFormReducer,
  getstreamReducer: getstream.reducer,
  interactions,
  lpa,
  legalAgreements,
  lendersV2,
  loanNotesV2: loanNotesSlice.reducer,
  loanApplicationsV2,
  miReports,
  biQuestions: biQuestionsSlice.reducer,
  biPresetQuestions: biPresetQuestionsSlice.reducer,
  biQuestionFeedbacks: biQuestionFeedbackSlice.reducer,
  biFavoriteQuestions: biFavoriteQuestionSlice.reducer,
  loanFiles: loanFileReducer,
  modal,
  notifications,
  pageInfo,
  periods,
  quickApply,
  quickPricer,
  selectedVaultDocuments,
  settings,
  signableDocuments,
  sources,
  spinner,
  statementImport: statementImportSlice.reducer,
  loanOfficerTeam,
  teams,
  taskListTemplates,
  v2,
  v2Documents,
  v2Downloads,
  v2FileSelector,
  v2Lenders,
  v2LoanFiles,
  v2LoanFileTemplates,
  v2Tasks,
}

const rootReducer = combineReducers(reducers)
export default rootReducer
