import { combineReducers } from 'redux'

import records from './records'
import stagedRecord from './stagedRecord'
import uiFlags from './uiFlags'

export default combineReducers({
  uiFlags,
  stagedRecord,
  records,
})
