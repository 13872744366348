import { disclosuresTasks, disclosureSelfService } from '../../actionTypes'

const initialState = {
  showEmbeddedDocutechModal: false,
  loading: false,
  docutechDocLinkURL: null,
  docutechSsoToken: null,
  isFetchingSelfService: false,
  successfulResponse: false,
}

export default function uiFlags(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case disclosuresTasks.toggleEmbeddedDocutechModal:
      return {
        ...state,
        ...payload,
      }
    case disclosuresTasks.setSsoToken:
      return {
        ...state,
        docutechSsoToken: payload,
      }
    case disclosureSelfService.loading:
      return {
        ...state,
        isFetchingSelfService: action.payload,
      }
    case disclosureSelfService.success:
      return {
        ...state,
        successfulResponse: true,
      }
    default:
      return state
  }
}
