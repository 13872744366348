import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'
import { popToast } from 'v2/actions/system_notifications'

export const indexSignatures = createAsyncThunk(
  'legalAgreements/signatures/index',
  async (params = {}, thunkAPI) => {
    try {
      const { data } = await JSONAPIClient.all('signature').get(params)
      return data
    } catch (e) {
      thunkAPI.dispatch(popToast({ error: 'There was an error fetching legal agreement signatures' }))
      return e.response.error
    }
  }
)

export const createSignature = createAsyncThunk(
  'legalAgreements/signatures/create',
  async (payload, thunkAPI) => {
    try {
      const { data } = await JSONAPIClient.create('signature', payload)
      return data
    } catch (e) {
      thunkAPI.dispatch(popToast({ error: 'There was an error creating a signature' }))
      return e.response.error
    }
  }
)
