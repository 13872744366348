import { preApprovalLetterTemplates as ActionTypes } from '../../actionTypes/'

const initialState = { content: { } }

export default function stagedRecord(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case ActionTypes.setStagedRecord:
      return payload
    case ActionTypes.updateStagedRecord:
      return { ...state, ...payload }
    default:
      return state
  }
}
