import { TOGGLE_SPINNER } from 'actions'

const initialState = { isLoading: false }

export default function toggleSpinner(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_SPINNER: {
      return { ...state, isLoading: action.payload.isLoading }
    }
    default: {
      return state
    }
  }
}
