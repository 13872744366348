import { getLoanFile } from './loan_files'

const getEmploymentIncomeVerificationTaskObject = state => state.v2.employmentIncomeVerificationTasks

// getters - TODO: BRET: ADD SPECS TO THIS AS A FOLLOWUP CHORE

export const getEmploymentIncomeVerificationTask = (state) => {
  return getEmploymentIncomeVerificationTaskObject(state).stagedRecord
}

export const getEmploymentIncomeVerificationTasks = (state) => {
  return getEmploymentIncomeVerificationTaskObject(state).records
}

export const getEmploymentIncomeVerificationTaskUiFlags = (state) => {
  return getEmploymentIncomeVerificationTaskObject(state).uiFlags
}

export const getDefaultEmploymentIncomeVerificationTaskAttributes = (state) => {
  return getLoanFile(state).defaultEmploymentIncomeTaskVerificationParams
}

export const getResearchedPosition = (state) => {
  return getEmploymentIncomeVerificationTaskObject(state).position
}

export const getMostRecentEmploymentIncomeVerificationRequest = (state) => {
  const stagedRecord = getEmploymentIncomeVerificationTask(state)
  const { requests = [] } = stagedRecord
  return requests[requests.length - 1]
}
