import AT from '../../actionTypes'

const initialState = { loading: false }

export default function uiFlags(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case AT.SET_COMMUNICATIONS_LOADING: {
      return { ...state, loading: payload }
    }
    default: {
      return state
    }
  }
}
