import toastr from 'utils/toastr'

import { createAction } from 'utils/reducer_utils'
import AT from '../actionTypes'
import { deployEmailSignatureTemplate } from './email_signature_templates'
import { deployEmailTemplates } from './email_templates'
import { setOrganization } from './organizations'

export const toggleOnEmailChangesConfirmationModal = () => {
  return (dispatch) => {
    dispatch(toggleEmailTemplatesChangeConfirmationModal(true))
  }
}

export const toggleOffEmailChangesConfirmationModal = () => {
  return (dispatch) => {
    dispatch(toggleEmailTemplatesChangeConfirmationModal(false))
  }
}

const startAllEmailTemplatesDeploy = () => {
  return (dispatch, getState) => {
    const organization = getState().v2.organizations.stagedRecord

    dispatch(toggleSubmittingEmailChanges(true))
    dispatch(setOrganization({ ...organization, deployingEmailTemplates: true }))
  }
}

export const submitEmailChanges = (organizationId) => {
  return async (dispatch) => {
    dispatch(toggleOffEmailChangesConfirmationModal())
    dispatch(startAllEmailTemplatesDeploy())

    dispatch(deployEmailSignatureTemplate(organizationId))
    dispatch(deployEmailTemplates(organizationId))

    dispatch(toggleSubmittingEmailChanges(false))
    toastr.success('Your changes are being processed in the background.')
  }
}

const toggleEmailTemplatesChangeConfirmationModal = createAction(AT.TOGGLE_EMAIL_TEMPLATES_CHANGE_CONFIRMATION_MODAL)
const toggleSubmittingEmailChanges = createAction(AT.SUBMITTING_EMAIL_CHANGES)
