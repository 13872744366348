// Source Maxwell sheet: https://docs.google.com/spreadsheets/d/1QPY7nXlJ1RuMXqug87Jwv7pkT7-IspoyXyATkkx_hN0

export const MORTGAGE_STATEMENT_INSTITUTIONS = [
  '360 Mortgage',
  'AimLoan Mortgage',
  'Amegy Bank Mortgage',
  'Amerihome Mortgage',
  'Amerisave Mortgage',
  'Arvest Mortgage',
  'Astoria Federal Savings Mortgage',
  'Bank of Albuquerque Mortgage',
  'Bank of Arizona Mortgage',
  'Bank of Hawaii Mortgage',
  'Bank of Oklahoma Mortgage',
  'Bank of Texas Mortgage',
  'Cal HFA Mortgage',
  'CapitalOne Mortgage',
  'Cardinal Financial Mortgage',
  'CCO Mortgage',
  'CENLAR Mortgage',
  'Central Mortgage',
  'Centris FCU Mortgage',
  'CIT Bank Mortgage',
  'CitiMortgage',
  'Citizens Mortgage',
  'Comerica Bank Mortgage',
  'Commerce Mortgage',
  'Cornerstone Lending',
  'Cross Country Mortgage',
  'Ditech Mortgage',
  'Dovenmuehle Mortgage',
  'EastWest Bank Mortgage',
  'Evolve Mortgage',
  'FedLoan Servicing',
  'First Bank Mortgage',
  'First United Bank Mortgage',
  'Firstland Mortgage Servicing',
  'Firstrust Mortgage',
  'Flagstar My Loans',
  'Franklin American Mortgage',
  'Freedom Mortgage',
  'GMFS Mortgage',
  'Guaranteed Rate Mortgage',
  'Guidance Residential Mortgage',
  'HomeStreet Bank Mortgage',
  'Huntington Bank Mortgage',
  'IndyMacMortgage',
  'M&T Bank Mortgage',
  'Michigan Mutual Mortgage',
  'Mobank Mortgage',
  'Mosaic',
  'Mr. Cooper Mortgage',
  'New American Funding',
  'Oceanside Mortgage',
  'Ocwen Mortgage',
  'PennyMac Mortgage',
  'PHH Mortgage',
  'PNC Mortgage',
  'Provident Funding',
  'Quicken Loans',
  'QuickenLoans SchwabBank',
  'RBC Bank Mortgage',
  'Regions Mortgage',
  'Rhode Island Housing Mortgage',
  'Round Point Mortgage Servicing',
  'Ruoff Mortgage',
  'Seneca Mortgage',
  'ServiSolutions',
  'South Shore Bank Mortgage',
  'Suntrust Mortgage',
  'SWBC Mortgage',
  'UBT Mortgage',
  'Union Home Mortgage',
  'United Wholesale Mortgage',
  'US Bank Home Mortgage',
  'Veterans United Home Loan',
  'Wells Fargo Mortgage',
  'Wells Fargo YourMortgage',
]

export const BANK_AND_INVESTMENT_INSTITUTIONS = [
  "Boeing Employee's Credit Union",
  "Georgia's Own CU",
  "NNY's 529 Direct",
  "People's United Bank",
  '1st Source Bank',
  'Acorns',
  'ADP TotalSource Retirement',
  'All South FCU',
  'AllianceBernstein Investments',
  'Alliant Credit Union',
  'Ally Bank',
  'Amalgamated Bank',
  'Amarillo National Bank',
  'American Bank Waco',
  'American Express Personal Savings',
  'American Funds Retirement',
  'American Funds',
  'Ameriprise Financial',
  'Arizona Central CU',
  'Arizona Federal CU',
  'Aspire FCU',
  'Associated Credit Union',
  'Atlantic Capital Bank',
  'Atlantic Stewardship Bank',
  'Bangor Savings',
  'Bank Leumi USA',
  'Bank of Albuquerque',
  'Bank of America',
  'Bank of Arizona',
  'Bank of Arkansas Mortgage',
  'Bank of Arkansas',
  'Bank of Colorado Business',
  'Bank of Hawaii',
  'Bank of Kansas City',
  'Bank of Oklahoma',
  'Bank of Stockton',
  'Bank of Texas',
  'Bank of the West',
  'Barclays Online Banking',
  'BB&T',
  'BBVA Compass',
  'Beneficial Bank',
  'Betterment',
  'BMO Harris Bank',
  'BMO',
  'Boston Firefighters CU',
  'Boston Private',
  'Bryn Mawr Trust',
  'Cambridge Investment',
  'Cap Com FCU',
  'CapitalOne 360',
  'CapitalOne Bank',
  'CapitalOne Credit Cards',
  'CapitalOne Sharebuilder',
  'Centier Bank',
  'Charles Schwab',
  'Chase',
  'Chelsea State Bank',
  'Chemical Bank',
  'CIBC',
  'CIT Bank',
  'Citi Wealth Management',
  'Citibank',
  'Citizens Bank',
  'Citizens Investment',
  'City National Bank',
  'City of Los Angeles Retirement',
  'City of Mesa Retirement',
  'Coastal Community Teachers CU',
  'Coastal Federal Credit Union',
  'College Choice Advisors 529',
  'College Choice Direct',
  'College Invest Direct',
  'College Save 529',
  'College Saving Iowa',
  'Colorado State Bank and Trust',
  'Comerica Bank',
  'Commerce Bank',
  'Commerce Brokerage',
  'Community Resource CU',
  'Connex CU',
  'County of Los Angeles Retirement',
  'County of Milwaukee Retirement',
  'Delta Community CU',
  'Digital Federal Credit Union',
  'Discover Bank',
  'Discover',
  'Dort FCU',
  'e*Trade',
  'East West Bank',
  'Eastman CU',
  'Edward Jones',
  'Elements Financial',
  'Elevations Credit Union',
  'Empower Retirement',
  'Endeavor Bank',
  'ESL FCU',
  'Everbank',
  'Exchange Bank',
  'Fidelity',
  'Fifth Third Bank Retirement',
  'Fifth Third Bank',
  'Fifth Third Securities',
  'First Atlantic FCU',
  'First Citizens National Bank',
  'First Community CU',
  'First County Bank Business',
  'First County Bank',
  'First Hawaiian Bank',
  'First Light FCU',
  'First National',
  'First Republic Bank',
  'First Tech Federal Credit Union',
  'FirstBank',
  'Flagstar Bank',
  'Flagstar Business',
  'FNB Bellville',
  'Franklin Templeton Investments',
  'Front Range Bank',
  'Frost Brokerage',
  'Fulton Bank of NJ',
  'Fulton Bank',
  'GECU',
  'German American Bank',
  'Global CU',
  'Goldman Sachs Wealth Management',
  'Grandview State Bank',
  'Great Southern Bank MS',
  'Great West Retirement Services',
  'Guaranty Bank and Trust',
  'Hancock Investments',
  'Happy State Bank',
  'Hawaii State FCU',
  'Heritage Bank NW',
  'HI 529',
  'Hiway Federal CU',
  'Houston Federal CU',
  'HSA Bank',
  'HSBC Bank',
  'Huntington Bank',
  'Huntington Retirement',
  'Ideal 529',
  'IHMVCU',
  'Indiana Members CU',
  'Indiana University CU',
  'John Hancock NY Pensions',
  'John Hancock Pensions',
  'JP Morgan Asset Management',
  'JP Morgan',
  'KeyBank',
  'Kitsap CU',
  'Klein Bank',
  'Lake Trust CU',
  'LaSalle St Securities',
  'Learning Quest 529',
  'LendingClub',
  'Liberty Bank',
  'Library of Congress FCU',
  'Lincoln Financial Advisors',
  'Lincoln Financial Securities',
  'LPL Financial',
  'M&T Bank',
  'Mainsource Bank',
  'Mass SMART Retirement',
  'MassMutual Retirement',
  'Matadors Community CU',
  'Mechanics Bank',
  'Merrick Bank',
  'Merrill Lynch Wealth Management',
  'Merrimack County Savings',
  'Mesirow Financial',
  'MESP',
  'MetLife Retirement',
  'Metlife Securities',
  'Middlesex Savings Bank',
  'Missouri Most 529',
  'MML Investors',
  'Montana Saves 529',
  'Montgomery County Employees FCU',
  'Morgan Stanley Wealth Management',
  'MVB Bank',
  'My First Farmers',
  'myStreetScape',
  'National Bank of Canada',
  'National Securities',
  'Navy Federal Credit Union',
  'NCSECU',
  'NEFCU',
  'Neighborhood Credit Union',
  'Nicolet Bank',
  'NIHFCU',
  'Northern Trust',
  'Northwestern Mutual Investments',
  'Northwestern Mutual',
  'NYC DCP Retirement',
  'NYLife Securities',
  'Ocean First Bank',
  'Ohio College Advantage 529',
  'Oppenheimer Wealth Management',
  'OptionsXpress',
  'Optum Bank',
  'Opus Bank Business',
  'PA 529 College Savings',
  'Park Avenue Securities',
  'Pen Air FCU',
  'Penn State Hershey Retirement',
  'Pentagon Federal Credit Union',
  'People First FCU',
  'Pinnacle Bank Business',
  'Pinnacle Bank SC',
  'PNC',
  'Power Financial CU',
  'Premier America CU',
  'Primeway FCU',
  'Principal Financial Personal',
  'Prosperity Bank TX',
  'Prudential Brokerage',
  'Prudential Retirement',
  'Raymond James',
  'RBC',
  'Redstone Federal CU',
  'Regions Bank',
  'Santander Bank',
  'ScholarShare',
  'Schwab Retirement',
  'Scotiabank',
  'Scottrade',
  'SECUMD Business',
  'SECUMD',
  'Service Credit Union',
  'Siebert',
  'Signature Bank NY',
  'Silicon Valley Bank Private Banking',
  'Silicon Valley Bank',
  'Simple',
  'Smart 529',
  'SoFi Mohela',
  'SOFI',
  'SSgA Upromise 529',
  'State Department FCU',
  'State Farm Bank',
  'State of Florida Retirement',
  'State of Mississippi Retirement',
  'State of South Carolina Retirement',
  'Summit FCU',
  'Sunmark FCU',
  'Suntrust Bank',
  'Suntrust Investment',
  'Suntrust Online Cash Manager',
  'Suntrust Retirement',
  'T. Rowe Price Investment',
  'T. Rowe Price Retirement',
  'TD Bank',
  'TD Canada',
  'TDAmeritrade Institutional',
  'TDAmeritrade',
  'TDWealthManagement',
  'Third Federal',
  'TIAA Bank',
  'TIAA-CREF',
  'Total Bank',
  'Tower Federal Credit Union',
  'Travelers',
  'Truwest Credit Union',
  'Tyndall FCU',
  'UBS Wealth Management',
  'UMB Bank HSA',
  'Union Banc Investment',
  'Union Bank & Trust',
  'Union Bank',
  'Unitus CCU',
  'USAA',
  'USBank Access Online',
  'USBank',
  'Uwharrie Bank',
  'Valic Financial',
  'Valley National Bank',
  'Vanguard Institutional',
  'Vanguard',
  'Vantage West CU',
  'Voya Financial',
  'Voya Retirement',
  'Washington State Employees Credit Union',
  'Washington Trust Company',
  'Wealthfront',
  'Wells Fargo Advisors',
  'Wells Fargo',
  'Wright-Patt CU',
  'Xceed Financial',
  'Zions Bank Business',
  'Zions Bank',
]

export const TAX_INSTITUTIONS = [
  '1040.com',
  'H&R Block',
  'TaxAct',
  'TaxSlayer',
  'TurboTax',
]

export const TEST_INSTITUTIONS = ['Test Site']

export const HIDDEN_INSTITUTIONS = [
  "Bergner's Credit Card",
  "BJ's Perks MasterCard",
  "Carson's Credit Card",
  "David's Bridal Credit Card",
  "Herberger's Credit Card",
  "Kohl's Store Card",
  "Lowe's Store Card",
  "Macy's",
  "Men's Wearhouse Store Card",
  "Nordstrom's Store Card",
  "People's Energy",
  "People's Gas",
  "Sam's Club Business Card",
  "Sam's Club Store Card",
  "Toy's R Us Store Card",
  "Victoria's Secret Store Card",
  '76 Conoco Phillips Gas Card',
  'A&N Electric',
  'ABCWUA',
  'Abercrombie & Fitch Store Card',
  'Acura Financial Services',
  'ADT Security',
  'AEP Appalachian',
  'AEP IndianaMichigan',
  'AEP Kentucky',
  'AEP Ohio',
  'AEP Oklahoma',
  'AEP Southwestern',
  'AEP Texas',
  'AIG Loanadministration',
  'Alabama Power',
  'Allstate Financial',
  'Allstate',
  'Ally Auto',
  'Alon Credit Card',
  'Amazon Corporate Credit',
  'Amazon Store Card',
  'Ameren',
  'American Eagle Store Card',
  'American Education Services (AES)',
  'American Water',
  'Amica',
  'AnnTaylor',
  'AQUA',
  'Arizona Public Service',
  'AT&T Business Direct Premier',
  'AT&T Phone and Internet',
  'AT&T U-verse',
  'AT&T Universal Card',
  'AT&T Wireless',
  'Atlanta Water',
  'Atlantic City Electric',
  'Atmos Energy',
  'Audi Financial Services',
  'Avenue.com Credit Card',
  'Baltimore Gas and Electric',
  'Banana Republic Store Card',
  'Bealls Credit Card',
  'Bealls Outlet Credit Card',
  'Belk Store Card',
  'Benton Rural Electric',
  'Best Buy Credit Card',
  'Black Hills Energy',
  'Bloomingdales',
  'BMW Financial Services',
  'Boone Electric',
  'Boone REMC',
  'Boston Store Credit Card',
  'Bowflex Credit Card',
  'BP Credit Card',
  'Brand Source Card',
  'Bright House Networks',
  'Brooks Brothers Store Card',
  'Capital Electric',
  'CapitalOne GM Card',
  'CarMax',
  'Catherines Credit Card',
  'Centerpoint Energy',
  'Central Electric COOP',
  'Central Hudson',
  'Central Maine Power',
  'CenturyLink Business',
  'CenturyLink',
  'Ceridian Self Service',
  'Champion Energy',
  'Charter Communications',
  'Chattanooga Gas',
  'ChevronTexaco Credit Card',
  'Chino Commercial Cash Management',
  'Chubb Insurance',
  'Cincinnati Bell',
  'Citgo Gas Card',
  'CitiBusiness Payroll',
  'Citicards',
  'Citizens Energy',
  'City of Austin Utilities',
  'City of Bellevue Utilities',
  'City of Fort Collins',
  'City of Milpitas Water',
  'Cloverland Electric',
  'Co-Mo Electric',
  'Coldwater Creek Store Card',
  'Columbia Gas of Kentucky',
  'Columbia Gas of Maryland',
  'Columbia Gas of Massachusetts',
  'Columbia Gas of Ohio',
  'Columbia Gas of Pennsylvania',
  'Columbia Gas of Viriginia',
  'Comcast Business',
  'Comcast',
  'ComEd',
  'Comerica Bank Credit Cards',
  'Commerce Bank Commercial Cards',
  'ConEdison',
  'Connecticut Light & Power',
  'Conservice',
  'Constellation Energy',
  'Consumer Cellular',
  'Consumers Energy',
  'Costco Credit Card',
  'Cox Business',
  'Cox',
  'CPS Energy',
  'Crate & Barrel Store Card',
  'Dayton Power & Light',
  'Delmarva Power',
  'DirecTV',
  'Discount Power TX',
  'Dish',
  'Dominion Electric',
  'Dress Barn Store Card',
  'DTE Energy',
  'Duke Energy - Progress',
  'Duke Energy',
  'Durham Utilities',
  'EBMUD',
  'Eddie Bauer Store Card',
  'Edward Jones Credit Cards',
  'Elan Financial Credit Cards',
  'Elder-Beerman Credit Card',
  'Elizabethtown Gas',
  'Elkton Gas',
  'Entergy',
  'Eversource',
  'ExpressNext Credit Card',
  'ExxonMobil Gas Card',
  'EZ Pass MA',
  'EZ Pass MD',
  'EZ Pass NJ',
  'EZ Pass NY',
  'EZPass PA',
  'Farmers Insurance',
  'FasTrak',
  'FedEx',
  'FIA Card Services',
  'FirstEnergy',
  'Florida City Gas',
  'Florida Power & Light',
  'Ford Credit Card',
  'Ford Credit',
  'Frontier Communications',
  'Furniture Row Store Card',
  'GameStop Credit Card',
  'Gap Credit Card',
  'Geico Insurance',
  'Georgia Natural Gas',
  'Georgia Power',
  'Gexa Energy',
  'Glendale Water and Power',
  'GM Financial',
  'Goodyear Tire Credit Card',
  'Google Store Credit Card',
  'Great Lakes Student Loans',
  'Green Mountain Energy',
  'Guaranty Loans',
  'Guitar Center Store Card',
  'Gulf Power',
  'Harris County Toll Road',
  'Hilton Honors Credit Card',
  'Home Depot Commercial Card',
  'Home Depot Commercial Revolving Charge',
  'Home Depot Store Card',
  'Honda Financial',
  'Houston Water',
  'HSBC AccountCentral Credit Card',
  'HSN MasterCard',
  'HSN Store Card',
  'Hyundai Motor Finance',
  'iFleet',
  'Indianapolis Power & Light',
  'Infiniti Financial Services',
  'J. Crew Store Card',
  'JC Penney Store Card',
  'JJill Credit Card',
  'Johnson County REMC',
  'Kansas Gas Service',
  'KCP&L',
  'Kia Motors Finance',
  'KingSize Credit Card',
  'Kootenai Electric',
  'L.L. Bean Store Card',
  'Laclede Electric',
  'LADWP',
  'Lane Bryant Store Credit',
  'Lane Electric',
  'Las Virgenes Water District',
  'Lexus Financial Services',
  'LG&E',
  'Liberty Mutual',
  'LoveLoft MasterCard',
  'LoveLoft Store Card',
  'Marathon Credit Card',
  'Marathon Visa Credit Card',
  'Marin Municipal Water District',
  'Maurices Credit Card',
  'Meijer Blue Card',
  'Meijer Store Card',
  'Menards Big Card',
  'Mercedes-Benz Financial Services',
  'Mercury Insurance',
  'MetLife Insurance',
  'Metro ExpressLanes',
  'Michigan Gas Utilities',
  'Midstate Electric',
  'Midwest Energy Coop',
  'MiEnergy Coop',
  'Mini Financial',
  'Minnesota Valley Electric',
  'Mississippi Power',
  'MLGW',
  'Mohave Electric',
  'Mohela',
  'Montrail-Williams Electric',
  'Mor-Gran-Sou Energy',
  'National General Insurance',
  'National Grid',
  'Nationwide Insurance',
  'Navient',
  'Neiman Marcus Store Card',
  'Nelnet',
  'Netflix',
  'New Hampshire Electric',
  'New Jersey Natural Gas',
  'New Mexico Gas',
  'New York & Company Store Card',
  'NFL Credit Card',
  'Nicor',
  'NineStar Connect',
  'NIPSCO',
  'Nissan Motor Finance',
  'North West REC',
  'Northern Trust Credit Cards',
  'Novec',
  'NV Energy',
  'NW Natural Gas',
  'NYCM Insurance',
  'NYSEG',
  'Office Depot Store Card',
  'Oklahoma Natural Gas',
  'Old Navy Store Card',
  'OPPD',
  'Optimum',
  'Orchard Bank Credit Card',
  'Pacific Life Insurance',
  'Pacific Power',
  'Pacific Sunwear Store Card',
  'PayPal Credit',
  'PECO Energy',
  'Pedernales Electric',
  'Pee Dee Electric',
  'Pepco',
  'PG&E',
  'Philadelphia Gas Works',
  'Piedmont Electric',
  'Piedmont Natural Gas',
  'Pier One Imports Store Card',
  'PNM',
  'Portland General Electric',
  'Pottery Barn Store Card',
  'PPL Electric',
  'Priceline Credit Card',
  'Progressive Insurance',
  'PSEG Long Island',
  'PSEG',
  'PSNC Energy',
  'Puget Sound Energy',
  'Questar Gas',
  'QVC Credit Card',
  'RCN',
  'Recology American Canyon',
  'Recology Ashland',
  'Recology Auburn Placer',
  'Recology Butte Colusa Counties',
  'Recology Del Norte',
  'Recology Dixon',
  'Recology Golden Gate',
  'Recology Humboldt County',
  'Recology Los Angeles',
  'Recology of the Coast',
  'Recology Portland',
  'Recology San Benito County',
  'Recology San Mateo County',
  'Recology Silicon Valley',
  'Recology South Bay',
  'Recology South Valley',
  'Recology Sunset Scavenger',
  'Recology Vacaville Solano',
  'Recology Vallejo',
  'Recology Western Oregon',
  'Recology Yuba-Sutter',
  'Reliant Energy',
  'Republic Services My Resource',
  'Republic Services',
  'Rochester Gas & Electric',
  'Rock Energy',
  'Rocky Mountain Power',
  'Safeco',
  'Saks Fifth Avenue Store Card',
  'Salem Electric',
  'Sallie Mae Student Loans',
  'Salt River Project',
  'Samuels Jewelers Credit Card',
  'San Diego Water',
  'San Jose Water',
  'Sawnee EMC',
  'SAWS',
  'Scana Energy',
  'SCE&G',
  'SDG&E',
  'Sears Store Card',
  'Seattle Public Utilities',
  'SF Water',
  'Shell Credit Card',
  'ShopNBC Credit Card',
  'Singing River Electric',
  'SMUD',
  'Snohomish County PUD',
  'SoCal Edison',
  'SoCal Gas',
  'Southwest Gas',
  'Southwest Mississippi Electric',
  'Spartanburg Water',
  'Spectrum Business',
  'Spectrum',
  'Spire Energy',
  'Sports Authority Store Card',
  'Sprint',
  'Staples Store Card',
  'State Farm Insurance',
  'Stein Mart Store Card',
  'Stillwater Insurance',
  'SuddenLink',
  'Suffolk County Water',
  'Sunoco Corporate Gas Card',
  'Sunoco Gas Card',
  'Suwannee Valley Electric',
  'Synchrony Bank',
  'T-Mobile',
  'Talbots Store Card',
  'Target REDcard',
  'TD Bank Credit Card',
  'TDPUD',
  'TECO',
  'Texas Gas Service',
  'The Energy COOP',
  'The Gift Plan',
  'The New York Times',
  'Thumb Electric Coop',
  'Time Warner Cable Business',
  'Tipmont REMC',
  'TJMaxx Credit Card',
  'Total Rewards Visa',
  'Town of Gilbert Water',
  'Toyota Financial Services',
  'Travelocity Credit Card',
  'Tricounty Electric',
  'TXU Energy',
  'UGI Utilities',
  'UPS Billing',
  'US Airways Credit Card',
  'Vectren',
  'Verdigris Valley Electric',
  'Verizon Residential',
  'Verizon Wireless Business',
  'Verizon Wireless',
  'Virgin America Credit Card',
  'Virgin America Visa',
  'Virginia Natural Gas',
  'Volvo Financial',
  'Vonage',
  'VW Credit',
  'Wake Electric',
  'Walmart Store Card',
  'Washington Gas',
  'Waste Management',
  'Wave Broadband',
  'We Energies',
  'Wells Fargo Dealer Services',
  'West Central Telephone',
  'West Elm Store Card',
  'Westar Energy',
  'White River Valley Electric',
  'Windstream Communications',
  'WOW!',
  'Xcel Energy',
  'XPressBillPay',
  'Younkers Credit Card',
  'Zales Credit Card',
]

export const PAYROLL_INSTITUTIONS = [
  'ADP iPayStatements',
  'ADP Portal',
  'ADP Workforce Now',
  'ADP Run Payroll',
  'Gusto',
  'ADP Payroll Workcenter',
  'Paychex',
  'Ultipro',
  'Paychex Flex',
  'Intuit Paycheck Records',
  'IntuitViewMyPaycheck',
  'Trinet',
  'DFAS MyPay',
  'ADP TotalSource',
  'Paycom',
  'Paycor',
  'JustWorks',
  'KaiserPay Online',
  'Paychex eServices',
  'Sure Payroll',
  'Ceridian SeeMyW2',
  'Ascentis',
  'Inova Payroll',
  'USD Payroll',
  'Agile Payroll',
]
