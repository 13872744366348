import api from 'utils/api'
import Route from 'Services/Route'
import toastr from 'utils/toastr'
import { toastrErrors } from 'utils/error_utils'

export function create(state, loanFileId, id, realtorId) {
  const url = Route.api.loanFile.preApprovalLetters.tasks.create({ loanFileId })
  const req = api(state).post(url, {
    team_pre_approval_template_id: id,
    realtor_id: realtorId,
  })

  return req.then(parseResponse).catch((error) => {
    console.error(error)
    const errors = error?.response?.data || null
    if (errors) toastrErrors(errors)
  })
}

export function show(state, loanFileId, id) {
  const url = Route.api.loanFile.preApprovalLetters.tasks.show({ loanFileId, id })
  const req = api(state).get(url)

  return req.then(parseResponse).catch(error => {
    console.error(error)
    const errors = error?.response?.data || null
    if (errors) toastrErrors(errors)
  })
}

export function update(state, loanFileId, id, payload) {
  const url = Route.api.loanFile.preApprovalLetters.tasks.update({ loanFileId, id })
  const req = api(state).put(url, payload)

  return req.then(parseResponse).catch((error) => {
    console.error(error)
    const errors = error?.response?.data || null
    if (errors) toastrErrors(errors)
  })
}

export function generateNewLetter(state, loanFileId, id, payload) {
  const url = Route.api.loanFile.preApprovalLetters.tasks.generateNewLetter({ loanFileId, id })
  const req = api(state).post(url, payload)

  return req.then((res) => {
    toastr.success('Your letter has been created! Please check your email where you’ll find the letter attached as a PDF.', '', { timeOut: 4000 })
    return res.data
  }).catch(error => {
    console.error(error)
    const errors = error?.response?.data || null
    if (errors) toastrErrors(errors)
  })
}

export function fetchLetters(state, loanFileId, id) {
  const url = Route.api.loanFile.preApprovalLetters.tasks.fetchLetters({ loanFileId, id })
  const req = api(state).get(url)

  return req.then((res) => {
    return res.data.letters
  }).catch(error => {
    console.error(error)
    const errors = error?.response?.data || null
    if (errors) toastrErrors(errors)
  })
}

export function saveAndPreview(state, loanFileId, id, payload) {
  const url = Route.api.loanFile.preApprovalLetters.tasks.saveAndPreview({ loanFileId, id })
  const req = api(state).post(url, payload)

  return req.then(res => res.data.path).catch((error) => {
    console.error(error)
    const errors = error?.response?.data || null
    if (errors) toastrErrors(errors)
  })
}

const parseResponse = (res) => {
  const {
    preApprovalLetterTask,
  } = res.data

  return preApprovalLetterTask
}

