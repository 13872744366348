import { sortList } from 'Services/SortingHelpers'
import _ from 'underscore'
import AT from '../actionTypes'
import { fetchDashboardMetadataForRole } from '../../utils/reducer_utils'

const initialState = {
  loanFile: {},
  stagedRecord: {},
  activeLoanFiles: [],
  closedLoanFiles: [],
  archivedLoanFiles: [],
  activeLoanFileTab: 'active',
  newClientModal: false,
  loadingActiveLoanFiles: true,
  loadingClosedLoanFiles: true,
  loadingArchivedLoanFiles: true,
  creatingNewLoanFile: false,
  submittingNewClient: false,
  step: 'clientInfo',
  currentSortData: {},
  sortedActiveLoanFilePositions: {},
  sortedClosedLoanFilePositions: {},
  sortedArchivedLoanFilePositions: {},
}

const getSortByPipelineSort = ({ pipelineSort, dashboardMetadata }) => {

  let sortObject
  if (pipelineSort) {
    sortObject = _.find(dashboardMetadata, data => data.sortKey === pipelineSort.sortKey)
  }
  if (!sortObject) {
    sortObject = _.find(dashboardMetadata, data => _.has(data, 'default'))
  }

  return {
    sortKey: sortObject.sortKey,
    reverse: pipelineSort ? pipelineSort.reverse : sortObject.default === 'desc',
  }
}

function setDefaultSortByUserRole({ userInfo, pipelineSort } = {}) {
  const dashboardMetadata = fetchDashboardMetadataForRole(userInfo)
  return getSortByPipelineSort({ pipelineSort, dashboardMetadata })
}

function computePositions({ loanFiles = [], userInfo, preSorted }) {
  const sortedLoanFilePositions = {}
  const dashboardMetadata = fetchDashboardMetadataForRole(userInfo)
  dashboardMetadata.forEach((metadata) => {
    let sortedPositionsAscending
    if (preSorted) {
      sortedPositionsAscending = loanFiles.map((lf, i) => i).reverse()
    } else {
      sortedPositionsAscending = sortList(loanFiles, metadata.sortKey, metadata.sortCriteria)
    }

    sortedLoanFilePositions[metadata.sortKey] = {
      asc: sortedPositionsAscending,
      desc: [...sortedPositionsAscending].reverse(),
    }
  })
  return sortedLoanFilePositions
}

function setLoanFileStagedRecordAttributes(previousState, payload) {
  return {
    ...previousState,
    ...Object.entries(payload).forEach(([key, value]) => {
      previousState.stagedRecord[key] = value
    }),
  }
}

export default function v2LoanFiles(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case AT.SET_FILTERED_LOAN_FILES: {
      return {
        ...state,
        filteredLoanFiles: payload.loanFiles,
        sortedFilteredLoanFilePositions: computePositions(payload),
      }
    }
    case AT.SET_ACTIVE_LOAN_FILES: {
      return {
        ...state,
        activeLoanFiles: payload.loanFiles,
        sortedActiveLoanFilePositions: computePositions(payload),
      }
    }
    case AT.SET_CLOSED_LOAN_FILES: {
      return {
        ...state,
        closedLoanFiles: payload.loanFiles,
        sortedClosedLoanFilePositions: computePositions(payload),
      }
    }
    case AT.SET_ARCHIVED_LOAN_FILES: {
      return {
        ...state,
        archivedLoanFiles: payload.loanFiles,
        sortedArchivedLoanFilePositions: computePositions(payload),
      }
    }
    case AT.SET_DEFAULT_SORT_BY: {
      return {
        ...state,
        currentSortData: setDefaultSortByUserRole(payload),
      }
    }
    case AT.SET_ACTIVE_LOAN_FILE_TAB:
      return { ...state, activeLoanFileTab: payload }
    case AT.SET_LOAN_FILE:
      return { ...state, loanFile: payload }
    case AT.UPDATE_LOAN_FILE:
      return { ...state, loanFile: { ...state.loanFile, ...payload } }
    case AT.SET_LOAN_FILE_STAGED_RECORD:
      return { ...state, stagedRecord: payload }
    case AT.SET_LOAN_FILE_STAGED_RECORD_ATTRIBUTES:
      return setLoanFileStagedRecordAttributes(state, payload)
    case AT.TOGGLE_NEW_CLIENT_MODAL:
      return { ...state, newClientModal: payload }
    case AT.TOGGLE_EDIT_LOAN_FILE_MODAL:
      return { ...state, editLoanFileModal: payload }
    case AT.LOADING_ACTIVE_LOAN_FILES:
      return { ...state, loadingActiveLoanFiles: payload }
    case AT.LOADING_LOAN_FILE:
      return { ...state, loadingLoanFile: payload }
    case AT.LOADING_CLOSED_LOAN_FILES:
      return { ...state, loadingClosedLoanFiles: payload }
    case AT.LOADING_ARCHIVED_LOAN_FILES:
      return { ...state, loadingArchivedLoanFiles: payload }
    case AT.CREATING_NEW_LOAN_FILE:
      return { ...state, creatingNewLoanFile: payload }
    case AT.SET_NEW_CLIENT_STEP:
      return { ...state, step: payload }
    case AT.SET_CURRENT_SORT_BY:
      return { ...state, currentSortData: payload }
    case AT.NEW_CLIENT_SUBMITTING:
      return { ...state, submittingNewClient: payload }
    case AT.SEND_LOAN_FILE_STATUS_EMAIL_SUCCESS:
      return { ...state, redirectUrl: payload }
    default:
      return state
  }
}
