import { findIndex } from 'underscore'
import { duDecisionTemplates } from '../../actionTypes'

const initialState = { template: {}, responses: [], controlInputs: [], controlInputTemplate: {} }

export default function (state = initialState, action) {
  switch (action.type) {
    case duDecisionTemplates.set:
      return { ...action.payload }


    case duDecisionTemplates.setResponse: {
      const { value, templateQuestionId } = action.payload
      const responseIndex = findIndex(
        state.responses,
        (response) => response.templateQuestionId === templateQuestionId,
      )
      if (responseIndex > -1) {
        const responses = [...state.responses]
        responses[responseIndex] = { ...responses[responseIndex], value }
        return {
          ...state,
          responses,
        }
      }
      return state
    }

    case duDecisionTemplates.setErrors: {
      const { errors, templateQuestionId } = action.payload
      const responseIndex = findIndex(
        state.responses,
        (response) => response.templateQuestionId === templateQuestionId,
      )
      if (responseIndex > -1) {
        const responses = [...state.responses]
        responses[responseIndex] = { ...responses[responseIndex], errors }
        return {
          ...state,
          responses,
        }
      }
      return state
    }

    case duDecisionTemplates.setControlInput: {
      const { value, templateQuestionId } = action.payload
      const responseIndex = findIndex(
        state.controlInputs,
        (response) => response.templateQuestionId === templateQuestionId,
      )
      if (responseIndex > -1) {
        const controlInputs = [...state.controlInputs]
        controlInputs[responseIndex] = { ...controlInputs[responseIndex], value }
        return {
          ...state,
          controlInputs,
        }
      }
      return state
    }

    default:
      return state
  }
}
