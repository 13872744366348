import AT from '../../actionTypes/'

const initialState = {
  showEmailTemplatesChangeConfirmationModal: false,
}

export default function uiFlags(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case AT.TOGGLE_EMAIL_TEMPLATES_CHANGE_CONFIRMATION_MODAL:
      return { ...state, showEmailTemplatesChangeConfirmationModal: payload }
    case AT.SUBMITTING_EMAIL_CHANGES:
      return { ...state, submittingEmailChanges: payload }
    default:
      return state
  }
}
