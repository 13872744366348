import { createAsyncThunk } from '@reduxjs/toolkit'
import { getPageInfo } from 'v2/selectors/page_info'
import JSONAPIClient from 'apiV2/apiClient'

const filterAusSections = (sections) => {
  const lenderLoanInfoParents = {}

  for (let i = 0; i < sections.length; i++) {
    const section = sections[i]
    if (section.name === 'lender_loan_info') {
      lenderLoanInfoParents[section.id] = true
      section.questionIds.forEach(id => {
        lenderLoanInfoParents[id] = true
      })
    }
    if (lenderLoanInfoParents[section.parentId]) {
      lenderLoanInfoParents[section.id] = true
      section.questionIds.forEach(id => {
        lenderLoanInfoParents[id] = true
      })
    }
  }
  return sections.filter(section => !lenderLoanInfoParents[section.id] && !lenderLoanInfoParents[section.parentId])
}

export const indexSectionAPI = createAsyncThunk(
  'section/index',
  async ({ templateId, shouldFilterAusSections }, { getState }) => {
    const { ausInfo: isInAusScenario } = getPageInfo(getState())

    const response = await JSONAPIClient.one('template', templateId).all('section').get()
    const sections = response.data

    if (isInAusScenario && !shouldFilterAusSections) {
      return sections
    } else {
      return filterAusSections(sections)
    }

  }
)

export const updateSectionAPI = createAsyncThunk(
  'section/update',
  async ({ sectionId, templateId, payload }, thunkAPI) => {
    await JSONAPIClient
      .one('template', templateId)
      .one('section', sectionId)
      .patch({ id: sectionId, ...payload })

    // refresh templateSections to account for possible section re-ordering
    await thunkAPI.dispatch(indexSectionAPI({ templateId, background: true }))
  }
)
