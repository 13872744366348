import AT from '../actionTypes/'

const initialState = {
  downloads: [],
  downloadMetadata: {
    totalDownloadCount: 0,
    completedDownloadCount: 0,
  },
  loading: false,
}

export default function v2Downloads(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case AT.SET_V2_DOWNLOADS: {
      return { ...state, downloads: payload }
    }

    case AT.SET_V2_DOWNLOAD_METADATA: {
      return { ...state, downloadMetadata: payload }
    }

    case AT.ADD_V2_DOWNLOAD: {
      return { ...state, downloads: [...state.downloads, payload] }
    }

    case AT.SET_V2_DOWNLOAD_LOADING: {
      return { ...state, loading: payload }
    }

    default: {
      return state
    }
  }
}
