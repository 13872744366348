import AT from 'actionTypes/'
import _ from 'underscore'

const FAKE_SOURCE_ID = '100100'

const initialState = {
  sources: [],
  displaySources: [],
  filteredSources: [],
  selectedSource: {},
  taskSources: [],
}

export default function sources(state = initialState, action) {
  switch (action.type) {
    case AT.SET_SOURCES: {
      const { sources: sourcesArr, fakeSourceEnabled } = action.payload
      let fakeSource
      if (fakeSourceEnabled) {
        fakeSource = _.findWhere(sourcesArr, { id: FAKE_SOURCE_ID })
      }
      return { ...state, sources: sourcesArr, fakeSourceEnabled, fakeSource }
    }
    case AT.SET_DISPLAY_SOURCES: {
      return { ...state, displaySources: action.payload }
    }
    case AT.SET_SELECTED_SOURCE: {
      const selectedSource = _.findWhere(state.sources, { id: action.payload })
      return { ...state, selectedSource: selectedSource }
    }
    case AT.FILTER_SOURCES: {
      return { ...state, filteredSources: action.payload }
    }
    case AT.FILTER_SOURCE_BY_TASK_TITLE: {
      return { ...state, filteredSources: action.payload, taskSources: action.payload }
    }
    default: {
      return state
    }
  }
}
