import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'
import { showLenderAPI } from './asyncActions'

const lenderSlice = createSlice({
  name: 'lender',
  initialState: baseInitialState,
  reducers: baseReducers,
  extraReducers: {
    [showLenderAPI.pending](state) {
      state.uiFlags.isFetchingItem = true
    },
    [showLenderAPI.fulfilled](state, action) {
      state.uiFlags.isFetchingItem = false
      state.stagedRecord = action.payload
    },
    [showLenderAPI.rejected](state) {
      state.uiFlags.isFetchingItem = false
    },
  },
})

export default lenderSlice
