import { any, compact, every } from 'underscore'

import LoanAppDependentQuestionResponseGetter from 'Services/LoanAppDependentQuestionResponseGetter'
import {
  isAValidAmortizationTerm,
  isBlankValue,
  isEmail,
  isNotFutureDate,
  isNotFutureYear,
  isPostalCode,
  isPriceUnderEncompassLimit,
  isValidApproximatePrice,
  isValidBirthdateCentury,
  isValidBirthdayDateRange,
  isValidDate,
  isValidDateWithinCentury,
  isValidDownpaymentPrice,
  isValidEmploymentDates,
  isValidFirstLastName,
  isValidYear,
  isWithinCentury,
  isWithinCenturyEmploymentDates,
  ssnNotEqual,
} from 'utils/validations'
import { MAX_32_BIT_INTEGER } from '../constants'

const DEPENDENT_AGES_REGEXP = /^(\d+, ?)*\d+$/

export default class LoanAppValidationService {
  constructor(question, value, questionResponseObj, options) {
    this.question = question
    this.value = value

    const {
      loanFile,
      loanFileRecord,
      emailSharingEnabled,
    } = options
    this.loanFile = loanFile
    this.loanFileRecord = loanFileRecord
    this.emailSharingEnabled = emailSharingEnabled

    const {
      loanAppResponses,
      loanAppTemplateQuestions,
    } = questionResponseObj

    this.loanAppResponses = loanAppResponses
    this.loanAppTemplateQuestions = loanAppTemplateQuestions
  }

  perform = () => {
    return compact(
      this.applyValidations(),
    )
  }

  isValidSsn = (value) => {
    if (value.toString().replace(/[() _-]/g, '').length !== 9) {
      return 'Invalid SSN'
    }
    return null
  }

  emailNotEqual = (value) => {
    const { user: { email: loanFileEmail = '' } = {} } = this.loanFile
    const { borrowerEmail = '' } = this.loanFileRecord
    const lowerValue = value.toLocaleLowerCase()
    return lowerValue === loanFileEmail.toLocaleLowerCase() || lowerValue === borrowerEmail.toLocaleLowerCase()
      ? 'Co-borrower email must be different from borrower'
      : undefined
  }

  isMultiCurrencyInputPresent = (values) => {
    if (values) {
      const res = values.map(({ amount, type }) => {
        if (!amount || amount === '0' || !type) return false
        return true
      })
      return every(res, val => val)
    }
    return true
  }

  isIncomePresent = (value) => {
    if (!this.isMultiCurrencyInputPresent(value)) return 'Both Income type and amount need to be present.'
    return null
  }

  isAssetPresent = (value) => {
    if (!this.isMultiCurrencyInputPresent(value)) return 'Both Asset type and amount need to be present.'
    return null
  }

  isUladOtherCreditsPresent = (value) => {
    if (!this.isMultiCurrencyInputPresent(value)) return 'Both Credit type and amount need to be present.'
    return null
  }

  isUladAssetFieldsPresent = (values) => {
    if (values) {
      const res = values.map(({
        amount,
        type,
      }) => {
        if (!amount || amount === '0' || !type) return false
        return true
      })
      return every(res, val => val)
    }
    return true
  }

  isUladAssetPresent = (values) => {
    if (!this.isUladAssetFieldsPresent(values)) return 'Current balance and type need to be present.'
    return null
  }

  isExpensePresent = (value) => {
    if (!this.isMultiCurrencyInputPresent(value)) return 'Both Expense type and amount need to be present.'
    return null
  }

  isUladLiabilitiesFieldsPresent = (values) => {
    if (values) {
      const res = values.map(({
        type,
        amount,
        unpaid_balance: unpaidBalance,
      }) => {
        if (!type || !amount || amount === '0' || !unpaidBalance || unpaidBalance === '0') return false
        return true
      })
      return every(res, val => val)
    }
    return true
  }

  isUladLiabilitiesPresent = (values) => {
    if (!this.isUladLiabilitiesFieldsPresent(values)) return 'Monthly payment, unpaid balance and '
      + 'type need to be present.'
    return null
  }

  isUladLiabilitiesOtherPresent = (values) => {
    if (!this.isMultiCurrencyInputPresent(values)) return 'Both Debt type and amount need to be present.'
    return null
  }

  hasAValidCreditorName = (values) => {
    if (values) {
      const res = values.map(({ creditor_name: creditorName }) => {
        if (creditorName && creditorName.length > 35) {
          return false
        }
        return true
      })
      return every(res, val => val)
    }
    return true
  }

  isAValidExpenseWithCreditorName = (values) => {
    if (!this.hasAValidCreditorName(values)) {
      return 'Creditor name must be 35 characters or less'
    }
    return null
  }

  isMultiBelowMaxInt = (values) => {
    const aboveMaxInt = any(values, ({ amount }) => {
      const intAmount = parseInt(amount?.toString().replace(/\D/g, ''), 10)
      return intAmount > MAX_32_BIT_INTEGER
    })

    if (aboveMaxInt) return 'Amount must be less than 2,147,483,648'
    return null
  }

  isValidDependentAges = (value) => {
    if (!DEPENDENT_AGES_REGEXP.test(value)) return 'Full years only, separating dependents by a comma '
      + 'if necessary (e.g., 7, 10)'
    return null
  }

  isAValidReferralSource = (value) => {
    if (value?.length > 250) return 'The referral name must be less than 250 characters'
    return null
  }

  applyValidations = () => {
    const errorArray = []
    const { question, value, loanAppResponses, loanAppTemplateQuestions } = this
    const { name } = question
    let startDate
    let endDate

    if (isBlankValue(question, value)) {
      if (question.required) return ['Answer Required']
      else return []
    }

    switch (name.replace(/^ulad_/, '')) {
      case 'borrower_full_name':
      case 'coborrower_full_name':
        return [
          ...errorArray,
          isValidFirstLastName(value),
        ]
      case 'borrower_current_address':
      case 'borrower_previous_address_1':
      case 'borrower_previous_address_2':
      case 'coborrower_current_address':
      case 'coborrower_previous_address_1':
      case 'coborrower_previous_address_2':
      case 'address_of_house_under_contract':
      case 'address_of_house_being_refinanced':
      case 'current_employer_address':
      case 'secondary_employer_address':
      case 'previous_employer_address':
      case 'borrower_current_employer_address':
      case 'borrower_secondary_employer_address':
      case 'borrower_previous_employer_address':
      case 'coborrower_current_employer_address':
      case 'coborrower_secondary_employer_address':
      case 'coborrower_previous_employer_address':
        if (isBlankValue(question, value)) {
          return []
        }
        return [
          ...errorArray,
          isPostalCode(value.zipcode),
        ]
      case 'borrower_email':
      case 'coborrower_email': {
        return [
          ...errorArray,
          isEmail(value),
          !this.emailSharingEnabled ? this.emailNotEqual(value) : undefined,
        ]
      }
      case 'borrower_ssn': {
        const loanAppResponseGetter = new LoanAppDependentQuestionResponseGetter(
          loanAppResponses,
          loanAppTemplateQuestions,
          name,
        )
        const coborrowerSsn = loanAppResponseGetter.perform()

        return [
          ...errorArray,
          this.isValidSsn(value),
          ssnNotEqual(coborrowerSsn)(value),
        ]
      }
      case 'coborrower_ssn': {
        const loanAppResponseGetter = new LoanAppDependentQuestionResponseGetter(
          loanAppResponses,
          loanAppTemplateQuestions,
          name,
        )
        const borrowerSsn = loanAppResponseGetter.perform()

        return [
          ...errorArray,
          this.isValidSsn(value),
          ssnNotEqual(borrowerSsn)(value),
        ]
      }
      case 'approximate_downpayment': {
        const approximateDownpayment = value
        const loanAppResponseGetter = new LoanAppDependentQuestionResponseGetter(
          loanAppResponses,
          loanAppTemplateQuestions,
          name,
        )
        const approximatePrice = loanAppResponseGetter.perform()
        const approximatePriceQuestion = loanAppResponseGetter.dependentQuestion()
        return [
          ...errorArray,
          isValidDownpaymentPrice(approximatePriceQuestion, approximatePrice, approximateDownpayment),
        ]
      }
      case 'approximate_price':
      case 'price_of_house_under_contract': {
        const approximatePrice = value
        const loanAppResponseGetter = new LoanAppDependentQuestionResponseGetter(
          loanAppResponses,
          loanAppTemplateQuestions,
          name,
        )
        const approximateDownpayment = loanAppResponseGetter.perform()
        return [
          ...errorArray,
          isValidApproximatePrice(approximatePrice, approximateDownpayment),
          isPriceUnderEncompassLimit(approximatePrice),
        ]
      }
      case 'previous_employer_start_date':
      case 'secondary_employer_start_date':
      case 'borrower_previous_employer_start_date':
      case 'borrower_secondary_employer_start_date':
      case 'coborrower_previous_employer_start_date':
      case 'coborrower_secondary_employer_start_date': {
        startDate = value

        const loanAppResponseGetter = new LoanAppDependentQuestionResponseGetter(
          loanAppResponses,
          loanAppTemplateQuestions,
          name,
        )

        endDate = loanAppResponseGetter.perform()
        return [
          ...errorArray,
          isValidDate(value),
          isValidDateWithinCentury(value),
          isValidEmploymentDates(startDate, endDate),
          isNotFutureDate(startDate),
          isWithinCenturyEmploymentDates(startDate, endDate),
        ]
      }
      case 'previous_employer_end_date':
      case 'borrower_previous_employer_end_date':
      case 'coborrower_previous_employer_end_date':
      case 'coborrower_secondary_employer_end_date': {
        endDate = value

        const loanAppResponseGetter = new LoanAppDependentQuestionResponseGetter(
          loanAppResponses,
          loanAppTemplateQuestions,
          name,
        )

        startDate = loanAppResponseGetter.perform()

        return [
          ...errorArray,
          isValidDate(value),
          isValidDateWithinCentury(value),
          isValidEmploymentDates(startDate, endDate),
          isNotFutureDate(endDate),
          isWithinCenturyEmploymentDates(startDate, endDate),
        ]
      }
      case 'coborrower_current_employer_start_date':
      case 'current_employer_start_date':
      case 'borrower_current_employer_start_date':
        return [
          ...errorArray,
          isValidDate(value),
          isValidDateWithinCentury(value),
          isNotFutureDate(value),
        ]
      case 'year_of_purchase':
        return [
          ...errorArray,
          isValidYear(value),
          isWithinCentury(value),
          isNotFutureYear(value),
        ]
      case 'coborrower_dob':
      case 'borrower_dob':
        return [
          ...errorArray,
          isValidDate(value),
          isNotFutureDate(value),
          isValidBirthdayDateRange(value),
          isValidBirthdateCentury(value),
        ]
      case 'borrower_dependent_ages':
      case 'coborrower_dependent_ages':
        return [
          ...errorArray,
          this.isValidDependentAges(value),
        ]
      case 'borrower_income_sources':
      case 'coborrower_income_sources':
      case 'borrower_current_employer_income':
      case 'coborrower_current_employer_income':
      case 'borrower_secondary_employer_income':
      case 'coborrower_secondary_employer_income':
      case 'borrower_income_other':
      case 'coborrower_income_other':
        return [
          this.isIncomePresent(value),
          this.isMultiBelowMaxInt(value),
        ]
      case 'borrower_asset_sources':
      case 'coborrower_asset_sources':
      case 'borrower_assets_other':
      case 'coborrower_assets_other':
        return [
          this.isAssetPresent(value),
        ]
      case 'borrower_credits_other':
      case 'coborrower_credits_other':
        return [
          this.isUladOtherCreditsPresent(value),
        ]
      case 'borrower_assets_accounts':
      case 'coborrower_assets_accounts':
        return [
          this.isUladAssetPresent(value),
        ]
      case 'borrower_montly_expenses':
      case 'coborrower_montly_expenses':
        return [
          this.isExpensePresent(value),
          this.isAValidExpenseWithCreditorName(value),
        ]
      case 'borrower_liabilities_accounts':
      case 'coborrower_liabilities_accounts':
        return [
          this.isUladLiabilitiesPresent(value),
        ]
      case 'borrower_liabilities_other':
      case 'coborrower_liabilities_other':
        return [
          this.isUladLiabilitiesOtherPresent(value),
        ]
      case 'property_number_of_units':
      case 'property_no_units': {
        const numericValue = value ? Number(value) : 0
        return numericValue >= 1 && numericValue <= 4 ? [...errorArray] : [
          ...errorArray,
          'Number of units should be a value in between 1 and 4',
        ]
      }
      case 'mortgage_amortization_type':
        return [
          isAValidAmortizationTerm(value),
        ]
      case 'referral_source':
        return [
          this.isAValidReferralSource(value),
        ]
      default:
        return []
    }
  }
}
