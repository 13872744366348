import { quickPricerScenarios } from 'v2/actionTypes'

const initialState = []

export default function (state = initialState, action) {
  switch (action.type) {
    case quickPricerScenarios.set:
      return action.payload.scenarios
    case quickPricerScenarios.create:
      return [...state, action.payload]

    case quickPricerScenarios.update: {
      const updatedState = [...state]
      const selectedScenarioIndex = updatedState.findIndex(
        scenario => scenario.id === action.payload.id
      )
      if (selectedScenarioIndex > -1) {
        updatedState[selectedScenarioIndex] = {
          ...updatedState[selectedScenarioIndex],
          ...action.payload.scenario,
        }
      }
      return updatedState
    }
    case quickPricerScenarios.updateFirstProduct: {
      const updatedState = [...state]
      const selectedScenarioIndex = updatedState.findIndex(
        scenario => scenario.id === action.payload.id
      )
      if (selectedScenarioIndex > -1) {
        updatedState[selectedScenarioIndex] = {
          ...updatedState[selectedScenarioIndex],
          isProductListVisible: false,
          firstProduct: action.payload.firstProduct,
          searchId: action.payload.searchId,
        }
      }
      return updatedState
    }
    case quickPricerScenarios.destroy:
      return state.filter(scenario => scenario.id !== action.payload)
    default:
      return state
  }
}
