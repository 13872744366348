import { duCaseFiles } from '../../actionTypes'

const initialState = { }

export default function (state = initialState, action) {
  switch (action.type) {

    case duCaseFiles.setStagedRecord:
      return { ...action.payload }

    default:
      return state
  }
}
