import api from 'utils/api'
import Route from 'Services/Route'

import { createAction } from 'utils/reducer_utils'

import AT from 'v2/actionTypes'

import { popToast } from './system_notifications'

export const setOnboardingStep = createAction(AT.SET_ONBOARDING_STEP)
export const setOnboardingUpdating = createAction(AT.SET_ONBOARDING_UPDATING)
export const setOnboardingCancelling = createAction(AT.SET_ONBOARDING_CANCELLING)
export const setProfileValues = createAction(AT.SET_PROFILE_VALUES)

export const updateLenderProfile = (values) => async (dispatch, getState) => {
  try {
    dispatch(setOnboardingUpdating(true))
    const { lender } = values
    const url = Route.api.lenders.profile.update()
    const formData = new FormData()
    Object.keys(lender).forEach(key => {
      formData.append(`lender[${key}]`, lender[key])
    })

    const res = await api(getState).put(url, formData)
    const { notice } = res.data
    const flashAlert = { notice }
    dispatch(popToast(flashAlert))
    redirectUrl()
  } catch (e) {
    const { errors } = e.response.data
    if (errors) {
      const flashAlert = { error: errors }
      dispatch(popToast(flashAlert))
    }
    dispatch(setOnboardingUpdating(false))
  }
}

export const cancelUpdateProfile = () => (dispatch) => {
  dispatch(setOnboardingCancelling(true))
  redirectUrl()
}

const redirectUrl = () => {
  window.location.href = Route.lenders.root()
}
