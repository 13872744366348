import JSONAPIClient from '../JSONAPIClient'

JSONAPIClient.define('document_group', {
  argyle_accounts: '',
  argyle_task: '',
  category: '',
  display_title: '',
  documents: {
    jsonApi: 'hasMany',
    type: 'documents',
  },
  due_date: '',
  finicity_task: '',
  finicity_customer_institution_logins: '',
  rejected: '',
  required_for: '',
  note: '',
  overdue: '',
  quote_status: '',
  status: '',
  title: '',
  display_time_period: '',
  time_period: '',
  updated_at: '',
})
