import toastr from 'utils/toastr'
import { popToast } from 'v2/actions/system_notifications'
import { getUserInfo } from 'v2/selectors/page_info'
import api from 'utils/api'
import Route from 'Services/Route'
import { hideVerificationTaskPicker } from 'v2/actions'
import { createActionsFromActionTypes } from 'utils/reducer_utils'
import { getLoanFileId, getITPAcceptedAt } from 'v2/selectors/loan_files'
import { fetchCommunications } from './communications'
import { paymentTasks as paymentTaskActionTypes } from '../actionTypes'
import { toggleTaskTypePicker } from './taskTypePicker'
import { toastrErrors } from '../../utils/error_utils'
import { updateLoanFileLocally } from './loan_files'

const reducerActions = createActionsFromActionTypes(paymentTaskActionTypes)
const { update, paymentTasks } = Route.api.loanFiles

// Payment Create Modal
export const showCreateModal = () => dispatch => dispatch(reducerActions.showCreateModal(true))
export const hideCreateModal = () => dispatch => dispatch(reducerActions.showCreateModal(false))

export const showPaymentUpdateModal = (task) => dispatch => {
  dispatch(reducerActions.setStagedRecord(task))
  dispatch(reducerActions.showUpdateModal(true))
}
export const hidePaymentUpdateModal = () => dispatch => dispatch(reducerActions.showUpdateModal(false))

// ITP Actions
export const showITPModal = () => dispatch => dispatch(reducerActions.toggleITPModal(true))
export const hideITPModal = () => dispatch => dispatch(reducerActions.toggleITPModal(false))
export const setITPSubmitting = (payload) => dispatch => dispatch(reducerActions.setITPSubmitting(payload))
export const setITPFetching = (payload) => dispatch => dispatch(reducerActions.setITPFetching(payload))

export const startPaymentTaskCreate = () => (dispatch, getState) => {
  dispatch(toggleTaskTypePicker(false))
  const itpAcceptedAt = getITPAcceptedAt(getState())
  const { encompassConnected, lendingqbConnected } = getUserInfo(getState())
  if (lendingqbConnected || encompassConnected || itpAcceptedAt) {
    dispatch(showCreateModal())
  } else {
    dispatch(showITPModal())
  }
}

export const updateITPAcceptedAt = ({ itpAcceptedAt }) => async (dispatch, getState) => {
  const { v2LoanFiles: { loanFile: { id: loanFileId } } } = getState()
  const url = update({ loanFileId })
  try {
    dispatch(setITPSubmitting(true))
    await api(getState).patch(url, { loan_file: { itpAcceptedAt } })
    dispatch(setITPSubmitting(false))
    dispatch(hideITPModal())
    dispatch(updateLoanFileLocally({ itpAcceptedAt }))
    dispatch(showCreateModal())
  } catch (error) {
    alertError(error)
    dispatch(popToast({ error }))
    dispatch(setITPSubmitting(false))
  }
}

export const fetchITPAcceptedAt = () => async (dispatch, getState) => {
  try {
    const loanFileId = getLoanFileId(getState())
    const url = Route.api.loanFiles.fetchItpAcceptedAt({ loanFileId: loanFileId })
    dispatch(setITPFetching(true))
    const response = await api(getState).get(url)
    const { itpAcceptedAt } = response.data
    dispatch(updateLoanFileLocally({ itpAcceptedAt }))
    dispatch(setITPFetching(false))
  } catch (error) {
    alertError(error)
    dispatch(popToast({ error }))
    dispatch(setITPFetching(false))
  }
}

export const updatePaymentTask = ({ taskId, values }) => async (dispatch, getState) => {
  try {
    const loanFileId = getLoanFileId(getState())
    dispatch(reducerActions.loading(true))
    const url = paymentTasks.update({ loanFileId, taskId })
    const response = await api(getState).put(url, { payment_task: values })
    dispatch(reducerActions.update(response.data))
    toastr.success('Payment task updated successfully')
  } catch (error) {
    const { response: { data: { error: errorMessage } = {} } = {} } = error
    toastr.error(
      errorMessage || 'There was an error updating the payment, please try again'
    )
    dispatch(popToast({ error: errorMessage || 'There was an error updating the payment, please try again' }))
  } finally {
    dispatch(hidePaymentUpdateModal())
    dispatch(reducerActions.loading(false))
  }
}

export const destroyPaymentTask = ({ loanFileId, taskId }) => async (dispatch, getState) => {
  try {
    const url = paymentTasks.destroy({ loanFileId, taskId })
    await api(getState).delete(url)
    dispatch(reducerActions.destroy({ taskId }))
    toastr.success('Payment task has been deleted successfully')
  } catch (error) {
    dispatch(popToast({ error: 'There was an error deleting the payment, please try again' }))
    toastr.error('There was an error deleting the payment, please try again')
  }
}

export function submitNewTask(values) {
  return (dispatch) => {
    try {
      dispatch(create(values))
      toastr.success('Successfully created payment task')
      dispatch(hideCreateModal())
      dispatch(hideVerificationTaskPicker())
    } catch (error) {
      alertError(error)
      dispatch(popToast({ error }))
    }
  }
}

export function setPaymentTasks(tasks) {
  return (dispatch) => {
    dispatch(reducerActions.set(tasks))
  }
}

export const markPaymentTaskAsComplete = ({ taskId }) => dispatch => dispatch(reducerActions.markAsComplete({ taskId }))

// Private

function create(values) {
  return async (dispatch, getState) => {
    const { v2LoanFiles: { loanFile: { id: loanFileId } } } = getState()

    const url = paymentTasks.create({ loanFileId })
    try {
      const res = await api(getState).post(url, { payment_task: values })
      const { data: paymentTask } = res
      dispatch(reducerActions.create(paymentTask))
      dispatch(fetchCommunications(loanFileId))
    } catch (error) {
      alertError(error)
      dispatch(popToast({ error }))
    }
  }
}

function alertError(error) {
  if (error.response && error.response.data.errors) {
    toastrErrors(error.response.data.errors)
  } else {
    toastrErrors('Something went wrong! We are looking into it!')
  }
}
