import { push, remove } from 'Services/ImmutatingHelpers'
import AT from '../../actionTypes/'

const initialState = [
  { communications: { communications: { sender: {}, confidence: 0.0 } } },
]

export default function records(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case AT.SET_COMMUNICATIONS: {
      return payload
    }
    case AT.ADD_COMMUNICATION: {
      return push(state, payload)
    }
    case AT.REMOVE_COMMUNICATION: {
      return remove(state, -1)
    }
    default:
      return state
  }
}
