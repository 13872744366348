import { findIndex } from 'underscore'

const { isArray } = Array

export function replace(object, index, newElement) {
  if (isArray(object)) {
    return [...object.slice(0, index), newElement, ...object.slice(index + 1)]
  } else if (isObject(object)) {
    return {
      ...object,
      [index]: newElement,
    }
  }
  throw new Error('Supported types : Array, Object')
}

export function replaceByKey(object, key, newElement) {
  if (isArray(object)) {
    const index = findIndex(object, (el) => el[key] === newElement[key])

    return replace(object, index, newElement)
  }
  throw new Error('Supported types : Array')
}

export function remove(object, index) {
  if (isArray(object)) {
    return [...object.slice(0, index), ...object.slice(index + 1)]
  } else if (isObject(object)) {
    const {
      [index.toString()]: removedItem, // eslint-disable-line no-unused-vars
      ...newObject
    } = object
    return newObject
  }
  throw new Error('Supported types : Array, Object')
}

export function push(object, item) {
  if (isArray(object)) {
    return [...object, item]
  }
  throw new Error('Supported types : Array')
}

/**
* Add or remove array element based on existence within array
* @param {array} object
* @param {integer} item
*/
export function toggle(object, item) {
  if (!(object.includes(item) && object.length)) return push(object, item)

  const index = _.indexOf(object, item)
  return remove(object, index)
}

function indicesOutOfBounds(object, index1, index2) {
  return ((object[index1] === undefined) || (object[index2] === undefined))
}

function exchange(object, index1, index2) {
  const temp = object[index1]
  object[index1] = object[index2]
  object[index2] = temp
  return object
}

function isObject(item) {
  return (item !== null && typeof item === 'object')
}

// export these acessory functions for testing
export const acessoryFunctions = { indicesOutOfBounds, exchange }

export function swap(object, index1, index2) {
  if (indicesOutOfBounds(object, index1, index2)) {
    return object
  } else return exchange(object, index1, index2)
}
