import api from 'utils/api'
import Route from 'Services/Route'
import { toastrErrors } from 'utils/error_utils'

import AT from '../../actionTypes'
import { fetchAttachments } from '../s3attachments'

export function syncingWithLOSS(bool) {
  return { type: AT.SYNCING_WITH_LOSS, payload: bool }
}

export const sendDocumentsToLOSS = (loanFileId, documentIds) => {
  return (dispatch, getState) => {
    dispatch(syncingWithLOSS(true))

    const url = Route.api.loss.sendDocuments()
    const data = { attachmentIds: documentIds }
    data.loanFileId = loanFileId

    const req = api(getState).post(url, data)

    req.then(() => {
      dispatch(fetchAttachments({ loanFileId }))
    }).catch((error) => {
      const { errors } = error?.response?.data || {}
      console.info(errors)

      if (errors) toastrErrors(errors)
    }).then(() => {
      dispatch(syncingWithLOSS(false))
    })
  }
}
