import AT from 'v2/actionTypes'

const initialState = {
  step: 'profile',
  updating: false,
  cancelling: false,
  values: {},
}

export default function lenderOnboarding(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case AT.SET_ONBOARDING_STEP:
      return { ...state, step: payload }
    case AT.SET_ONBOARDING_UPDATING:
      return { ...state, updating: payload }
    case AT.SET_ONBOARDING_CANCELLING:
      return { ...state, cancelling: payload }
    case AT.SET_PROFILE_VALUES:
      return { ...state, values: payload }
    default:
      return state
  }
}
