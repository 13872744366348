import _ from 'underscore';
import { remove, push, swap } from 'Services/ImmutatingHelpers'
import AT from '../actionTypes/'

const { isArray } = Array


export default function documentGroupTemplateDisplayOrder(state = [], action) {
  const { payload, type } = action
  switch (type) {
    case AT.MOVE_DOWN_DOCUMENT_GROUP_TEMPLATE: {
      if (isArray(state)) {
        const newState = _.clone(state)
        const index = newState.indexOf(payload.id)
        return swap(newState, index, index + 1)
      }
      return state;
    }
    case AT.MOVE_UP_DOCUMENT_GROUP_TEMPLATE: {
      if (isArray(state)) {
        const newState = _.clone(state)
        const index = newState.indexOf(payload.id)
        return swap(newState, index - 1, index)
      }
      return state
    }

    case AT.ADD_TASK_TEMPLATE:
      return push(state, payload.id)

    case AT.REMOVE_TASK_TEMPLATE: {
      return remove(state, state.indexOf(payload.id))
    }

    default:
      return state
  }
}
