import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'

import {
  createQuickPricerSettingsAPI,
  updateQuickPricerSettingsAPI,
  showQuickPricerSettingsAPI,
} from './asyncActions'

const quickPricerSettingSlice = createSlice({
  name: 'quickPricerSetting',
  initialState: baseInitialState,
  reducers: baseReducers,
  extraReducers: {
    [showQuickPricerSettingsAPI.pending](state) {
      state.uiFlags.isFetchingItem = true
    },
    [showQuickPricerSettingsAPI.fulfilled](state, action) {
      state.uiFlags.isFetchingItem = false
      state.stagedRecord = action.payload
    },
    [showQuickPricerSettingsAPI.rejected](state) {
      state.uiFlags = false
    },
    [createQuickPricerSettingsAPI.pending](state) {
      state.uiFlags.isCreating = true
    },
    [createQuickPricerSettingsAPI.fulfilled](state, action) {
      state.uiFlags.isCreating = false
      state.stagedRecord = { ...state.stagedRecord, ...action.payload }
    },
    [createQuickPricerSettingsAPI.rejected](state) {
      state.uiFlags = false
    },
    [updateQuickPricerSettingsAPI.pending](state) {
      state.uiFlags.isUpdating = true
    },
    [updateQuickPricerSettingsAPI.fulfilled](state, action) {
      state.uiFlags.isUpdating = false
      state.stagedRecord = { ...state.stagedRecord, ...action.payload }
    },
    [updateQuickPricerSettingsAPI.rejected](state) {
      state.uiFlags = false
    },
  },
})

export default quickPricerSettingSlice
