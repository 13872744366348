import AT from '../actionTypes/'

const initialState = {
  roles: [],
}

export default function teamRoles(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case AT.SET_TEAM_ROLES: {
      return { ...state, roles: payload }
    }
    default: {
      return state
    }
  }
}
