import AT from 'v2/actionTypes/'

const propertyAddress = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case AT.SET_PROPERTY_ADDRESS: {
      return payload
    }
    default: {
      return state
    }
  }
}

export default propertyAddress
