import AT from '../actionTypes/'

const initialState = {
  optionsForTeamSelect: [[], []],
  selectedTeamId: null,
  updatingSelectedTeam: false,
}

export default function v2Lenders(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case AT.SET_OPTIONS_FOR_TEAM_SELECT: {
      return { ...state, optionsForTeamSelect: payload }
    }

    case AT.UPDATING_SELECTED_TEAM: {
      return { ...state, updatingSelectedTeam: payload }
    }

    case AT.TEAM_SELECTION_COMPLETE: {
      return { ...state, selectedTeamId: payload }
    }

    case AT.SET_SELECTED_TEAM: {
      return { ...state, selectedTeamId: payload }
    }

    default: {
      return state
    }
  }
}
