import { indexOf } from 'underscore'
import AT from '../actionTypes'

const initialState = {
  participants: [],
  participantsToAdd: [],
  displayTeamPickerModal: false,
}

export default function loanFileParticipants(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case AT.SET_LOAN_FILE_PARTICIPANTS: {
      return { ...state, participants: payload }
    }
    case AT.TOGGLE_TEAM_PICKER_MODAL: {
      return { ...state, displayTeamPickerModal: payload }
    }
    case AT.ADD_LOAN_FILE_PARTICIPANT: {
      return {
        ...state,
        participantsToAdd: [
          ...state.participantsToAdd,
          payload,
        ],
      }
    }
    case AT.SET_PARTICIPANTS_TO_ADD: {
      return {
        ...state,
        participantsToAdd: payload,
      }
    }
    case AT.REMOVE_LOAN_FILE_PARTICIPANT: {
      const index = indexOf(state.participantsToAdd, payload)

      return {
        ...state,
        participantsToAdd: [
          ...state.participantsToAdd.slice(0, index),
          ...state.participantsToAdd.slice(index + 1),
        ],
      }
    }
    case AT.CLEAR_LOAN_FILE_PARTICIPANTS: {
      return {
        ...state,
        participantsToAdd: [],
      }
    }
    default: {
      return state
    }
  }
}
