import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'
import { popToast } from 'v2/actions/system_notifications'

export const indexCategories = createAsyncThunk(
  'bi/reports/categories/index',
  async (params = {}, thunkAPI) => {
    try {
      const response = await JSONAPIClient.all('category').get(params)
      return response.data
    } catch (e) {
      thunkAPI.dispatch(popToast({ notice: 'there was an error fetching categories' }))
      return e.response.error
    }
  }
)
