import { without } from 'underscore'

// values = { someKey-uniqueId: true, someKey-uniqueId: false, sosomeKey-uniqueIdmeKey: true }
// values = { document-12: true, document-13: false, document-14: true }
// returns [12,14]

export const idsOfItemsChecked = (allValues) => {
  const checkedIds = Object.entries(allValues).map(value => {
    // eslint-disable-next-line maxwell/no-explicit-boolean-check
    if (value[1] === true) {
      return parseInt(value[0].split('-')[1], 10)
    }
    return undefined
  })

  return without(checkedIds, undefined)
}
