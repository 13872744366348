import { indexBy, isString, isNull, isObject, all } from 'underscore'
import { strip, replaceAll } from 'underscore.string'
import { isDateBeforeMonths } from 'utils/loan_app_display_logic_utils'

const perform = (props) => {
  // TODO:LOANAPP: Improve and optimize the logic - on its own it is fast (roughly 3ms on localhost)
  // But it runs for every section...

  const { element, loanAppTemplateQuestions, loanAppResponses, loanAppTemplateSections } = props

  // If the user switches from one template to another, there may be an interim state where all of the necessary records
  // are not yet present. During this state, we short-circuit out without checking any kind of display logic.
  if (loanAppTemplateQuestions.records.length === 0
      || loanAppTemplateSections.records.length === 0
      || loanAppResponses.records.length === 0) {
    return false
  }

  const questions = loanAppTemplateQuestions.records
  const responses = loanAppResponses.records
  let { displayLogic } = element
  const indexedResponses = indexBy(responses, 'templateQuestionId')

  // If the section is hidden then hide the question
  if (element.templateSectionId) {
    const visibleSections = loanAppTemplateSections.records.filter((e) => {
      return perform({ ...props, element: e })
    })
    const visibleSectionIds = visibleSections.map(sec => sec.id)
    if (visibleSectionIds.indexOf(element.templateSectionId) < 0)
      return false
  }

  if (element.groupName) {
    const questionsInTheGroup = questions.filter(q => q.groupName === element.groupName)
    if (all(questionsInTheGroup, q => q.hidden)) return false
  } else if (element.hidden)
    return false
  if (!displayLogic) return true

  displayLogic = strip(displayLogic)
  const tempQuestionCache = {}
  questions.forEach((q) => {
    const value = (indexedResponses[q.id] || {}).value
    if (isString(value))
      displayLogic = replaceAll(displayLogic, `{${q.name}}`, `'${value.toString()}'`)
    else if (isObject(value)) {
      tempQuestionCache[q.name] = value
      displayLogic = replaceAll(displayLogic, `{${q.name}}`, `tempQuestionCache.${q.name}`)
    } else if (isNull(value))
      displayLogic = replaceAll(displayLogic, `{${q.name}}`, '""')
  })
  // since eval may see only closest vars we need this
  // DON'T EVER DELETE/RENAME THIS OBJ AND ITS PROPERTIES
  // eslint-disable-next-line no-unused-vars
  const context = { isDateBeforeMonths }
  return eval(displayLogic) // eslint-disable-line no-eval
}

export default { perform }
