import { assetVerificationTasks } from '../../actionTypes'

const initialState = {
  showCreateModal: false,
  showGenerateAssetReportModal: false,
  showUpdateModal: false,
}

export default function uiFlags(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case assetVerificationTasks.showCreateModal:
      return { ...state, showCreateModal: payload }
    case assetVerificationTasks.showGenerateAssetReportModal:
      return { ...state, showGenerateAssetReportModal: payload }
    case assetVerificationTasks.showUpdateModal:
      return { ...state, showUpdateModal: payload }
    default:
      return state
  }
}
