import JSONAPIClient from '../JSONAPIClient'

JSONAPIClient.define('loan_app', {
  created_at: '',
  current_participant_id: '',
  current_participant_task_item: '',
  loan_file_id: '',
  primary_participant_id: '',
  required_questions_map: '',
  status: '',
  created_by: '',
  task_id: '',
  task_items: '',
  task_status: '',
  template_id: '',
  template_hidden_section_ids: '',
  template_optional_section_ids: '',
  template_name: '',
})
