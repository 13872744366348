import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'

import {
  indexLoanFileDashboardStatAPI,
  showLoanFileDashboardStatApi,
  updateLoanFileDashboardStat,
} from './asyncActions'

const loanFileDashboardStatSlice = createSlice({
  name: 'loanFileDashboardStat',
  initialState: {
    ...baseInitialState,
    recordsCount: 0,
  },
  reducers: baseReducers,
  extraReducers: {
    [indexLoanFileDashboardStatAPI.pending](state) {
      state.uiFlags.isFetching = true
    },
    [indexLoanFileDashboardStatAPI.fulfilled](state, action) {
      const { payload: { data, recordCount } } = action
      state.records = data
      state.recordsCount = recordCount
      state.uiFlags.isFetching = false
    },
    [indexLoanFileDashboardStatAPI.rejected](state) {
      state.uiFlags.isFetching = false
    },
    [showLoanFileDashboardStatApi.pending](state) {
      state.uiFlags.isFetching = true
    },
    [showLoanFileDashboardStatApi.fulfilled](state, action) {
      const { id } = action.payload
      if (state.records.find(rec => rec.id === id)) {
        state.records = state.records.map(rec => (rec.id === id ? action.payload : rec))
      } else {
        state.records.push(action.payload)
      }
      state.stagedRecord = action.payload
      state.uiFlags.isFetching = false
    },
    [showLoanFileDashboardStatApi.rejected](state) {
      state.uiFlags.isFetching = false
    },
    [updateLoanFileDashboardStat.pending](state) {
      state.uiFlags.isFetching = true
    },
    [updateLoanFileDashboardStat.fulfilled](state, action) {
      const { loanFileIds, loanFileStatus } = action.payload
      state.records = state.records.map(record => {
        const { loanFileId } = record
        return loanFileIds.includes(loanFileId) ? { ...record, loanFileStatus } : record
      })
      state.stagedRecord = action.payload
      state.uiFlags.isFetching = false
    },
    [updateLoanFileDashboardStat.rejected](state) {
      state.uiFlags.isFetching = false
    },
  },
})

export default loanFileDashboardStatSlice
