import AT from 'actionTypes/'
import _ from 'underscore';

const initialState = [];

export default function documentGroupTemplates(state = initialState, action) {
  switch (action.type) {
    case AT.ADD_DOCUMENT_GROUP_TEMPLATE:
      return [...state, action.documentGroupTemplate];
    case AT.EDIT_DOCUMENT_GROUP_TEMPLATE:
      return state.map((template) => {
        if (template.id === action.documentGroupTemplate.id)
          return action.documentGroupTemplate;
        else
          return template;
      });
    case AT.REMOVE_DOCUMENT_GROUP_TEMPLATE: {
      const index = _.indexOf(state, action.documentGroupTemplate)
      return [ ...state.slice(0, index), ...state.slice(index + 1)];
    }
    default:
      return state
  }
}
