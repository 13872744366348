import { createAction } from 'utils/reducer_utils'
import { toastrErrors } from 'utils/error_utils'
import api from 'utils/api'
import toastr from 'utils/toastr'
import Route from 'Services/Route'
import AT from 'v2/actionTypes'
import { setLoanFile } from '../loan_files'

export const setLoanFileTaskReplyTo = createAction(AT.SET_LOAN_FILE_TASK_REPLY_TO)
export const setLoanFileTaskReplyToCc = createAction(AT.SET_LOAN_FILE_TASK_REPLY_TO_CC)
export const setTaskNotificationsChecked = createAction(AT.SET_TASK_NOTIFICATIONS_CHECKED)
export const setTaskNotificationsFrequency = createAction(AT.SET_TASK_NOTIFICATIONS_FREQUENCY)
export const toggleTaskNotificationsModal = createAction(AT.TOGGLE_TASK_NOTIFICATION_EMAIL_MODAL)

export function saveTaskNotificationSettings(loanFileId, values) {
  return (dispatch, getState) => {
    dispatch(setTaskNotificationsLoading(true))
    const url = Route.api.loanFile.loanProfile.update({ loanFileId })
    const req = api(getState).put(url, values)

    return req.then((res) => {
      const { data } = res
      const { loanFile } = data

      Promise.all([
        dispatch(setLoanFile(loanFile)),
      ])

      const message = 'Successfully updated task reminder settings'
      toastr.success(message)
    }).then(() => {
      setTimeout(() => {
        dispatch(setTaskNotificationsLoading(false))
      }, 200)
    }).catch((error) => {
      const { errors } = error?.response?.data || {}

      console.info(errors)
      if (errors) toastrErrors(errors)
    })
  }
}

export function sendTaskNotifications(loanFileId) {
  return (dispatch, getState) => {
    dispatch(setTaskNotificationsSending(true))
    const url = Route.api.notifications.base.create({ loanFileId })
    const req = api(getState).post(url)

    return req.then((res) => {
      const { data } = res
      const {
        loanFile,
        loanFile: { borrower },
      } = data

      Promise.all([
        dispatch(setLoanFile(loanFile)),
      ])

      const message = `Successfully sent notifications to ${borrower}`
      toastr.success(message)
    }).then(() => {
      setTimeout(() => {
        dispatch(setTaskNotificationsSending(false))
      }, 200)
    }).catch((error) => {
      const { errors } = error?.response?.data || {}

      console.info(errors)
      if (errors) toastrErrors(errors)
    })
  }
}

// private actions
const setTaskNotificationsLoading = createAction(AT.SET_TASK_NOTIFICATIONS_LOADING)
const setTaskNotificationsSending = createAction(AT.SET_TASK_NOTIFICATIONS_SENDING)
