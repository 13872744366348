import { createSlice } from '@reduxjs/toolkit'
import { uniq } from 'lodash'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'
import {
  showScenarioAnswerAPI,
  createScenarioAnswerAPI,
  indexScenarioAnswersAPI,
  updateScenarioAnswerAPI,
  destroyScenarioAnswerAPI,
  ausSectionDestroyAPI,
} from './asyncActions'

export const ausScenarioAnswersSlice = createSlice({
  name: 'ausScenarioAnswers',
  initialState: baseInitialState,
  reducers: baseReducers,
  extraReducers: {
    [createScenarioAnswerAPI.pending](state) {
      state.uiFlags.isCreating = true
    },
    [createScenarioAnswerAPI.fulfilled](state, action) {
      state.records = [...state.records, action.payload]
      state.uiFlags.isCreating = false
    },
    [createScenarioAnswerAPI.rejected](state) {
      state.uiFlags.isCreating = false
    },
    [indexScenarioAnswersAPI.pending](state) {
      state.uiFlags.isFetching = true
    },
    [indexScenarioAnswersAPI.fulfilled](state, action) {
      state.records = [ ...action.payload]
      state.uiFlags.isFetching = false
    },
    [indexScenarioAnswersAPI.rejected](state) {
      state.uiFlags.isFetching = false
    },
    [showScenarioAnswerAPI.pending](state) {
      state.uiFlags.isFetchingItem = true
    },
    [showScenarioAnswerAPI.fulfilled](state, action) {
      state.stagedRecord = action.payload
      state.uiFlags.isFetchingItem = false
    },
    [showScenarioAnswerAPI.rejected](state) {
      state.uiFlags.isFetchingItem = false
    },
    [updateScenarioAnswerAPI.pending](state) {
      state.uiFlags.isUpdating = true
    },
    [updateScenarioAnswerAPI.fulfilled](state, action) {
      const { id } = action.payload
      const recordIndex = state.records.findIndex(r => r.id === id)
      if (recordIndex !== -1) {
        state.records[recordIndex] = action.payload
      }
      state.uiFlags.isUpdating = false
    },
    [updateScenarioAnswerAPI.rejected](state) {
      state.uiFlags = false
    },
    [destroyScenarioAnswerAPI.pending](state) {
      state.uiFlags.isDeleting = true
    },
    [destroyScenarioAnswerAPI.fulfilled](state, action) {
      const { id, reindexedAnswers } = action.payload
      if (reindexedAnswers) {
        state.records = reindexedAnswers
      } else {
        state.records = state.records.filter(r => r.id !== id)
      }
      state.uiFlags.isDeleting = false
    },
    [destroyScenarioAnswerAPI.rejected](state) {
      state.uiFlags.isDeleting = false
    },
    [ausSectionDestroyAPI.pending](state) {
      state.uiFlags.isDeleting = true
    },
    [ausSectionDestroyAPI.fulfilled](state, action) {
      const { sectionId, answers = [] } = action.payload
      const sectionIds = answers.length ? uniq(answers.map(a => +a.sectionId)) : [+sectionId]
      state.records = [...state.records.filter(r => !sectionIds.includes(+r.sectionId)), ...answers]
      state.uiFlags.isDeleting = false
    },
    [ausSectionDestroyAPI.rejected](state) {
      state.uiFlags.isDeleting = false
    },
  },
})

export default ausScenarioAnswersSlice.reducer
