import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'

export const findQuestionFeedback = createAsyncThunk(
  'bi/questionFeedback/find',
  async (questionId) => {
    const { data } = await JSONAPIClient.all('bi_question_feedback').get({ filter: { questionId } })
    return data[0]
  },
)

export const createQuestionFeedback = createAsyncThunk(
  'bi/questionFeedback/create',
  async (payload) => {
    const { data } = await JSONAPIClient.create('bi_question_feedback', payload)
    return data
  },
)

export const updateQuestionFeedback = createAsyncThunk(
  'bi/questionFeedback/update',
  async (payload) => {
    const { data } = await JSONAPIClient.update('bi_question_feedback', payload)
    return data
  },
)
