import AT from '../../actionTypes/'

const initialState = {
  loadingText: 'Loading...',
}

export default function uiFlags(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case AT.TOGGLE_EMAIL_TEMPLATE_MODAL:
      return { ...state, showEmailTemplateModal: payload }
    default:
      return state
  }
}
