import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

const options = {
  order: ['localStorage', 'navigator'],
  lookupLocalStorage: 'i18nextLng',
}

export const SUPPORTED_LANGUAGES = ['es', 'en']

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: options,
    resources: {},
    fallbackLng: 'en',
    supportedLngs: SUPPORTED_LANGUAGES,
    nonExplicitSupportedLngs: true,
  })

export default i18next
