import { eConsentSetting as actionTypes } from 'v2/actionTypes'

const initialState = { isUpdating: true }

export default function uiFlags(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case actionTypes.loading:
      return { ...state, isUpdating: payload }
    default:
      return state
  }
}
