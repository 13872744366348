import LoanAppAfterResponseUpdateService from 'Services/LoanAppAfterResponseUpdateService'
import LoanAppDependentQuestionResponseGetter from 'Services/LoanAppDependentQuestionResponseGetter'
import LoanAppValidationService from 'Services/LoanAppValidationService'
import { indexBy, isEmpty } from 'underscore'
import AT from '../../actionTypes'
import { syncResponsesToBackend } from './loan_app'
import { perform } from '../../../Services/ApplyIDResponseBuilder'

export function setLoanAppResponses(loanAppResponses) {
  return { type: AT.SET_LOAN_APP_RESPONSES, payload: loanAppResponses }
}

export const updatePrefillResponses = (responses = {}) => (dispatch, getState) => {
  const { loanAppTemplateQuestions, loanAppResponses, loanApps } = getState().v2
  const { stagedRecord } = loanApps
  const { isULAD } = stagedRecord
  const formattedResponse = perform({
    loanAppTemplateQuestions,
    loanAppResponses,
    isULAD,
    responseValues: responses,
  })
  if (isEmpty(formattedResponse)) {
    return
  }

  dispatch({
    type: AT.UPDATE_PREFILL_RESPONSES,
    payload: formattedResponse,
  })
  dispatch(syncResponsesToBackend())
  dispatch(validateAllResponses())
}

const updateAndValidateResponse = (question, value) => dispatch => {
  dispatch(updateLoanResponseValue(question.id, value))
  dispatch(validateAffectedQuestions(question, value))
}

export const updateResponse = (question, event) => (dispatch, getState) => {
  if (event && event.target) {
    const { value } = event.target
    dispatch(updateAndValidateResponse(question, value))
    const { loanAppTemplateQuestions } = getState().v2
    LoanAppAfterResponseUpdateService.perform(
      loanAppTemplateQuestions,
      question,
      event.target.value,
      (templateQuestion, responseValue) => dispatch(
        updateAndValidateResponse(templateQuestion, responseValue)
      ),
    )
  }
}


export function validateAllResponses() {
  return (dispatch, getState) => {
    const { loanAppTemplateQuestions, loanAppResponses } = getState().v2
    const { v2LoanFiles: { loanFile, stagedRecord: loanFileRecord } } = getState()
    const { pageInfo: { userInfo: { coborrowerSameEmailSupportEnabled: emailSharingEnabled } } } = getState()
    const indexLoanAppResponses = indexBy(loanAppResponses.records, 'templateQuestionId')
    const payload = loanAppTemplateQuestions.records.map((question) => {
      const { id } = question
      const { value } = indexLoanAppResponses[id]
      const filterObj = {
        loanAppResponses: loanAppResponses.records,
        loanAppTemplateQuestions: loanAppTemplateQuestions.records,
      }

      const service = new LoanAppValidationService(
        question,
        value,
        filterObj,
        {
          loanFile,
          loanFileRecord,
          emailSharingEnabled,
        }
      )

      const errors = service.perform()
      return {
        templateQuestionId: question.id,
        templateSectionId: question.templateSectionId,
        errors,
      }
    })
    dispatch(setLoanAppResponseErrors(payload))
  }
}

function updateLoanResponseValue(templateQuestionId, value) {
  return {
    type: AT.UPDATE_LOAN_APP_RESPONSE_VALUE,
    payload: { templateQuestionId, value },
  }
}

function validateAffectedQuestions(question, value) {
  return (dispatch) => {
    // Why use promise here? We have to wait until ex.
    // end date is updated in redux store before we can process start date
    // else we juse use old data.
    Promise.all([
      dispatch(validateResponse(question, value)),
      dispatch(validateDependentQuestionResponse(question)),
    ])
  }
}

function validateDependentQuestionResponse(question) {
  return (dispatch, getState) => {
    const {
      loanAppResponses,
      loanAppTemplateQuestions,
    } = getState().v2

    const loanAppDependentQuestionResponseGetter = new LoanAppDependentQuestionResponseGetter(
      loanAppResponses.records,
      loanAppTemplateQuestions.records,
      question.name,
    )

    const dependentQuestion = loanAppDependentQuestionResponseGetter.dependentQuestion()
    if (dependentQuestion) {
      const dependentResponseValue = loanAppDependentQuestionResponseGetter.perform()
      dispatch(validateResponse(dependentQuestion, dependentResponseValue))
    }
  }
}

function validateResponse(templateQuestion, value) {
  return (dispatch, getState) => {
    const {
      loanAppResponses,
      loanAppTemplateQuestions,
    } = getState().v2
    const { v2LoanFiles: { loanFile, stagedRecord: loanFileRecord } } = getState()
    const { pageInfo: { userInfo: { coborrowerSameEmailSupportEnabled: emailSharingEnabled } } } = getState()
    const filterObj = {
      loanAppResponses: loanAppResponses.records,
      loanAppTemplateQuestions: loanAppTemplateQuestions.records,
    }

    const service = new LoanAppValidationService(
      templateQuestion,
      value,
      filterObj,
      {
        loanFile,
        loanFileRecord,
        emailSharingEnabled,
      }
    )

    const errors = service.perform()
    dispatch(updateLoanAppResponseErrors(templateQuestion, errors))
  }
}
function updateLoanAppResponseErrors(templateQuestion, errors) {
  return {
    type: AT.UPDATE_LOAN_APP_RESPONSE_ERRORS,
    payload: {
      templateQuestionId: templateQuestion.id,
      templateSectionId: templateQuestion.templateSectionId,
      errors,
    },
  }
}


function setLoanAppResponseErrors(payload) {
  return {
    type: AT.SET_LOAN_APP_RESPONSE_ERRORS,
    payload,
  }
}
