import JSONAPIClient from '../../JSONAPIClient'

JSONAPIClient.define('lpa', {
  id: '',
  owner_id: '',
  owner_type: '',
  username: '',
  password: '',
  party_type: '',
  party_identifier: '',
  config_is_active: '',
  sandbox: '',
})
