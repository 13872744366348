import { teams as actionTypes } from '../../actionTypes'

const initialState = { loading: false }

export default function uiFlags(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case actionTypes.loading: {
      return { ...state, loading: payload }
    }
    default:
      return state
  }
}
