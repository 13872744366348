import _ from 'underscore'
import Fuse from 'fuse.js'
import { humanize, titleize } from 'underscore.string'

import { LENDER_PIPELINE_FUZZY_SEARCH_OPTIONS } from '../constants'

// TODO : We need to add descriptor text for each status!
//  It's probably not the best idea to manage it here,
//  we can manage via yaml and pass in presenter
export function statusDescriptor(status) {
  switch (status) {
    case 'start':
      return `
        We will take good care of you as you work on your mortgage. Next up is
        completing your mortgage application. This includes answering some questions
        about your financial situation.
      `
    case 'getting_started':
      return `
        Next up is for you to provide documents that your loan officer will request.
        They will need things like W2's and Bank Statements to keep things moving.
      `
    case 'application_submitted':
      return `
        Your loan application has been submitted. Your loan officer will be reviewing
        it and might request some additional documents.
      `
    case 'initial_documents_provided':
      return `
        Now that you've gotten the upfront documents done, your loan officer
        will analyze them and likely have some follow up questions and need some
        more information. This is all a normal part of the process.
      `
    case 'appraisal_ordered':
      return `
        Your appraisal has been ordered. This is where an appraiser comes to give
        a professional opinion on the value of the home.
      `
    case 'appraisal_received':
      return `
        The appraiser has finished their work and we have the information back.
        Next up we'll make sure we have all the information needed and give
        everything to an underwriter. This is someone that will review your info
        to make sure you qualify. This is a normal part of the process.
      `
    case 'submitted_to_underwriting':
      return `
        We've submitted your information to underwriting. 99% of the time,
        underwriters want additional info, so be on the lookout for requests from
        our team.
      `
    case 'conditionally_approved':
      return `
        You've been conditionally approved. The “conditions” are items that you
        must provide to be fully approved for the loan, so it's really important
        we get any requested information back quickly.
      `
    case 'clear_to_close':
      return `
        You're almost done! The underwriter has what they need and you've been
        approved! Next up, we'll send out a document that has information about
        your monthly payment and closing costs. It's required by law and is
        called the Closing Disclosure (CD).
      `
    case 'closing_disclosure_sent':
      return `
        Next up, we'll send all the documents you need to sign to the title
        company. This is the last step before closing the loan.
      `
    case 'documents_sent_to_title':
      return `
        All that's left now is to sign your closing documents. Almost there!
      `
    case 'loan_closed':
      return `
        Congratulations! Your loan has closed.
      `
    case 'archived':
      return `
        Your loan has been archived. Please contact your lender for more information.
      `
    default:
      return `
        We will take good care of you as you work on your mortgage.
      `
  }
}

export function displayStatus(status) {
  return titleize(humanize(status))
}

export function filterByStatus(loanFiles, filter = 'active') {
  switch (filter) {
    case 'active': {
      return _activeLoanFiles(loanFiles)
    }
    case 'closed': {
      return _closedLoanFiles(loanFiles)
    }
    case 'archived': {
      return _archivedLoanFiles(loanFiles)
    }
    default: {
      return loanFiles
    }
  }
}

function _activeLoanFiles(loanFiles) {
  if (typeof loanFiles === 'undefined') return []

  return loanFiles.filter((loanFile) => {
    return (!_.contains(['loan_closed', 'archived'], loanFile.status))
  })
}

function _closedLoanFiles(loanFiles) {
  return _loanFilesWithStatus(loanFiles, 'loan_closed')
}

function _archivedLoanFiles(loanFiles) {
  return _loanFilesWithStatus(loanFiles, 'archived')
}

function _loanFilesWithStatus(loanFiles, status = 'loan_closed') {
  if (typeof loanFiles === 'undefined') return []
  return loanFiles.filter((loanFile) => {
    return (loanFile.status === status)
  })
}

export function fuzzySearchLoanFiles(loanFiles, searchTerm, { fuzzySearchOptions } = { }) {
  let fuse = {}
  if (typeof (fuzzySearchOptions) !== 'undefined') {
    fuse = new Fuse(loanFiles, fuzzySearchOptions)
  } else {
    fuse = new Fuse(loanFiles, LENDER_PIPELINE_FUZZY_SEARCH_OPTIONS)
  }

  return fuse.search(searchTerm)

}

export function isRealtor(realtor) {
  return realtor !== 'None assigned'
}

export function isClosed(status) {
  return status === 'loan_closed'
}

export function isArchived(status) {
  return status === 'archived'
}

export function isGettingStarted(status) {
  return status === 'getting_started'
}

export function isStart(status) {
  return status === 'start'
}

export function isNotificationDisabledStatus(status) {
  return (isGettingStarted(status) || isArchived(status) || isStart(status))
}

export function shouldStatusChangeNotify(status, borrowerBlocklist, realtorBlocklist) {
  return !(statusInBlocklist(status, borrowerBlocklist) && statusInBlocklist(status, realtorBlocklist))
}

export function statusInBlocklist(status, blocklist) {
  return blocklist.includes(status)
}

export function noRealtor(realtor) {
  return realtor === 'None assigned'
}

export function formatRole(teamRole) {
  return titleize(humanize(teamRole))
}

export function isParticipantRemoveable(currentUserId, loParticipantableId, teamMember) {
  return (
    currentUserId !== teamMember.participantableId
    && loParticipantableId !== teamMember.participantableId
    && !roleIsManager(teamMember.role)
    && !teamMember.externalLenderParticipant
  )
}

export function roleIsManager(role) {
  return role === 'manager'
}

export const formatPropertyAddress = (objectAddress) => {
  const {
    address = '',
    addressCont = '',
    city = '',
    state = '',
    zip = '',
  } = objectAddress

  if ((address === null || addressCont === null || city === null || state === null || zip === null)) {
    return 'TBD'
  } else {
    return [(`${address} ${addressCont}`.trim()), city, (`${state} ${zip}`.trim())].filter(e => e).join(', ')
  }
}

export const hasTasksParams = () => window.location.search.includes('tasks')
export const hasDesktopUnderwriterParams = () => window.location.search.includes('desktop_underwriter')
export const hasInfoParams = () => window.location.search.includes('info')
export const hasTeamParams = () => window.location.search.includes('team')
