import api from 'utils/api'
import toastr from 'utils/toastr'
import URLSearchParams from '@ungap/url-search-params'

import {
  findWhere,
  findIndex,
  contains,
} from 'underscore'

import { replace, toggle } from 'Services/ImmutatingHelpers'

import Route from 'Services/Route'

import { toastrErrors } from 'utils/error_utils'
import { createAction } from 'utils/reducer_utils'
import { hasVaultInSearchParams } from 'utils/document_utils'
import { idsOfItemsChecked } from 'components/utils/ReduxForm/Services/utils'

import {
  metadataFromLendingQBDocuments,
  lendingQbPollableDocuments,
} from 'utils/lending_qb_document_utils'

import {
  displayFileNameExtenion,
  base64ArrayBuffer,
  fileExtensionFromDocumentKey,
} from 'utils/file_utils'

import AT from '../actionTypes'

import {
  fetchLendingQbDocuments,
  setLendingQbDocuments,
  setLendingQbDocumentsMetadata,
  syncingWithLendingQB,
} from './lending_qb/documents'

import {
  fetchDownloads,
  setDownloadLoading,
} from './downloads'
import {
  destroyAttachments,
  updateAttachmentName,
  updateAttachmentStatus,
  fetchAttachments,
} from './s3attachments'

export function setLoadingDocument(bool) {
  return { type: AT.SET_LOADING_DOCUMENTS, payload: bool }
}

export function setDocuments(documents) {
  return { type: AT.SET_V2_DOCUMENTS, payload: documents }
}

export function setDocument(document) {
  return { type: AT.SET_V2_DOCUMENT, payload: document }
}

export function toggleAllDocuments(ids) {
  return { type: AT.TOGGLE_ALL_DOCUMENTS, payload: ids }
}

export function toggleDocument(ids) {
  return { type: AT.TOGGLE_DOCUMENT, payload: ids }
}

export function setSelectedDocumentId(id) {
  return { type: AT.SET_SELECTED_DOCUMENT_ID, payload: id }
}

export const deleteDocsMultiple = (ids) => (dispatch, getState) => {
  const { id: loanFileId } = getState().v2LoanFiles.loanFile
  dispatch(destroyAttachments({ loanFileId, attachmentIds: ids }))
  dispatch(toggleDocumentIds(null))
}

export const deleteDocsAndCloseModal = (ids) => (dispatch) => {
  dispatch(deleteDocsMultiple(ids))
  dispatch(toggleDeleteBulkFileModal(false))
}

export const deleteSingleDoc = (id) => (dispatch, getState) => {
  const selectedDocsIds = getState().v2Documents.selectedDocumentIds
  const selectedDocId = getState().v2Documents.selectedDocumentId

  if (contains(selectedDocsIds, selectedDocId[0])) {
    dispatch(toggleDocumentId(selectedDocId[0]))
  }

  dispatch(setSelectedDocumentId([]))

  const { id: loanFileId } = getState().v2LoanFiles.loanFile
  dispatch(destroyAttachments({ loanFileId, attachmentIds: id }))
}

export const deleteSingleDocAndCloseModal = (id) => (dispatch) => {
  dispatch(deleteSingleDoc(id))
  dispatch(toggleDeleteSingleFileModal(false))
}

export function toggleDeleteBulkFileModal(isViewable = false) {
  return { type: AT.TOGGLE_DELETE_BULK_FILE_MODAL, payload: isViewable }
}

export function toggleDeleteSingleFileModal(singleIsViewable = false) {
  return { type: AT.TOGGLE_DELETE_SINGLE_FILE_MODAL, payload: singleIsViewable }
}

export function downloadSelectedDocuments(ids, loanFileId = null) {
  return (dispatch, getState) => {
    const message = `
      We've received your download request.
      Your documents are being processed, packaged up and will be ready in the downloads bar momentarily.
    `
    // Display toastr and add spinner
    toastr.success(message)
    dispatch(setDownloadLoading(true))

    const id = loanFileId || getState().v2LoanFiles.loanFile.id
    const documentIds = ids || getState().v2Documents.selectedDocumentIds

    const data = {}
    data.attachmentIds = documentIds
    const url = Route.api.downloads.index({ id })

    const req = api(getState).post(url, data)

    req.then(() => {
      // Add download by fetching all downloads.
      dispatch(fetchDownloads(id))
    }).catch((error) => {
      const { errors } = error?.response?.data || {}

      console.info(errors)
      if (errors) toastrErrors(errors)
    })
  }
}

export function toggleDocumentIds() {
  return (dispatch, getState) => {
    const selectedIds = getState().v2Documents.selectedDocumentIds
    const { documents } = getState().v2Documents

    // if there are selected ids, deselect
    const ids = selectedIds.length ? [] : documents.map(document => document.id)

    dispatch(toggleAllDocuments(ids))
  }
}

export function toggleDocumentId(id) {
  return (dispatch, getState) => {
    const selectedIds = getState().v2Documents.selectedDocumentIds
    const ids = toggle(selectedIds, id)
    dispatch(toggleDocument(ids))
  }
}

export const pollLendingQBStatus = ({ lendingQBDocuments, isOnVaultTab, loanFileId }) => dispatch => {
  // Poll if there are LendingQB documents still sending
  const lendingQBDocumentsSending = lendingQbPollableDocuments(lendingQBDocuments)
  // Stop spinner if no documents sending to LendingQB
  if (lendingQBDocumentsSending.length === 0) {
    dispatch(syncingWithLendingQB(false))
  }
  if (lendingQBDocumentsSending.length > 0 && isOnVaultTab) {
    // Start polling for download changes
    setTimeout(() => {
      dispatch(fetchLendingQbDocuments(loanFileId))
    }, 5000)
  }
  const metadata = metadataFromLendingQBDocuments(lendingQBDocuments)
  dispatch(setLendingQbDocumentsMetadata(metadata))
  dispatch(setLendingQbDocuments(lendingQBDocuments))
}

export const updateFileName = (loanFileId, fileName) => {
  return async (dispatch, getState) => {
    const {
      document: {
        id,
        fileName: originalFileName,
      },
    } = getState().v2Documents

    const originalFileExtension = displayFileNameExtenion(originalFileName)
    const documentKey = `${fileName}.${originalFileExtension}`

    dispatch(updatingDocument(true))
    await dispatch(updateAttachmentName({
      loanFileId,
      attachmentId: id,
      data: { filename: documentKey },
    }))

    dispatch(updateDocument(id, documentKey))
  }
}

export const updateDocument = (id, fileName) => {
  return async (dispatch, getState) => {
    const { documents } = getState().v2Documents
    const attachment = findWhere(documents, { id })

    const attachmentIndex = findIndex(documents, (doc) => doc.id === id)
    const newAttachment = { ...attachment, fileName }
    const newDocs = replace(documents, attachmentIndex, newAttachment)
    Promise.all([
      dispatch(setAllDocuments(newDocs, newAttachment)),
      dispatch(updatingDocument(false)),
    ]).then(() => {
      toastr.success('Successfully updated the file name')
    })
  }
}

const setAllDocuments = (documents, attachment) => {
  return (dispatch) => {
    Promise.all([
      dispatch(setDocuments(documents)),
      dispatch(setDocument(attachment)),
    ])
  }
}

const updatingDocument = createAction(AT.UPDATING_DOCUMENT)

export function receivedDocument(doc) {
  return (dispatch) => {
    return dispatch(updateAttachmentStatus(doc, 'received'))
  }
}

export function viewedDocument(doc) {
  return (dispatch) => {
    return dispatch(updateAttachmentStatus(doc, 'viewed'))
  }
}

export function acceptDocument(doc, losType = null) {
  return (dispatch) => {
    return dispatch(updateAttachmentStatus(doc, 'accepted', { losType }))
  }
}

export function rejectDocument(doc, reason) {
  return (dispatch) => {
    return dispatch(updateAttachmentStatus(doc, 'rejected', { reason }))
  }
}

// Processor Toolkit

export const toggleOnDocumentChanged = () => {
  return (dispatch) => {
    dispatch(toggleDocumentChanged(true))
  }
}

export const toggleOnPdfTronLoaded = () => {
  return (dispatch) => {
    dispatch(togglePdfTronLoaded(true))
  }
}
export const saveDocument = (data) => {
  return (dispatch) => {
    dispatch(toggleDocumentSaving(true))
    dispatch(createDocumentVersion(data)).then(() => {
      dispatch(toggleDocumentSaving(false))
      dispatch(toggleDocumentChanged(false))
    })
  }
}

export const cancelDocumentChanges = (ViewerInstance) => {
  return (dispatch, getState) => {
    const { blobUrl } = getState().v2Documents.document

    Promise.all([
      dispatch(toggleDocumentSaving(true)),
      dispatch(disableCrop()),
      ViewerInstance.loadDocument(blobUrl),
      dispatch(toggleDocumentSaving(false)),
      dispatch(toggleDocumentChanged(false)),
    ])
  }
}

export const mergeDocuments = (values) => {
  return (dispatch) => {
    dispatch(togglePdfTronMerging(true))
    dispatch(pdfTronMergeDocuments(values))
  }
}

const pdfTronMergeDocuments = (values) => {
  return (dispatch, getState) => {
    const idsOfMaxwellDocsToBeMerged = idsOfItemsChecked(values)
    const { v2Documents, pageInfo: { pdftronInfo: { key } } } = getState()
    const { documents, CoreControls, currentPdfTronDocument } = v2Documents

    idsOfMaxwellDocsToBeMerged.forEach((documentId, index) => {
      const maxwellDocToBeMerged = documents.find(document => document.id === documentId)
      const { documentKey, fileName } = maxwellDocToBeMerged

      // Constructs a new empty Document, representing a document with individual pages (canvases)
      // that can be displayed on screen and printed.
      // https://www.pdftron.com/api/web/CoreControls.Document.html
      const newPdfTronDocument = new CoreControls.Document(documentKey, 'pdf')

      const maxwellDocToBeMergedExtension = fileExtensionFromDocumentKey(documentKey || fileName)

      // Gets the default pdf backend type that should be used for this browser.
      CoreControls.getDefaultBackendType().then((backendType) => {
        const options = {
          workerTransportPromise: CoreControls.initPDFWorkerTransports(
            backendType,
            {},
            key,
          ), // Begins setup of PDF Worker Object.
          extension: maxwellDocToBeMergedExtension,
        }

        // ExternalPdfPartRetriever retrieves Document data from a url.
        const partRetriever = new CoreControls.PartRetrievers.ExternalPdfPartRetriever(
          maxwellDocToBeMerged.blobUrl
        )

        // Initialize a Document so that it can be used to load page canvases.
        newPdfTronDocument.loadAsync(partRetriever, (err) => {
          if (err) {
            console.error('Could not open file. Please try a different format')
            return
          }

          const pages = []

          for (let i = 0; i < newPdfTronDocument.getPageCount(); i++) {
            pages.push(i + 1)
          }

          // [PDF Document only] Inserts a set of pages from the provided Document before a given page number.
          // Note that this method will need to wait for the entire file to be downloaded before the change is applied.
          currentPdfTronDocument.insertPages(newPdfTronDocument, pages, currentPdfTronDocument.getPageCount() + 1)

          // Only dispatch false when ALL the documents are done merging
          if (idsOfMaxwellDocsToBeMerged.length === (index + 1)) {
            dispatch(togglePdfTronMerging(false))
          }
        }, options)
      })
    })
  }
}

const createDocumentVersion = (arrayBuffer) => {
  return async (dispatch, getState) => {
    const { v2Documents } = getState()
    const { documents, document: currentDocument } = v2Documents
    const { loanFileId, id } = currentDocument
    const fileType = 'attachments'
    const urlParams = { loanFileId, attachmentId: id }
    const url = Route.api.loanFiles[fileType].newVersion.create(urlParams)
    const base64Data = base64ArrayBuffer(arrayBuffer)
    const form = new FormData()
    const extraHeaders = { 'content-type': `multipart/form-data; boundary=${form._boundary}` }

    form.append('content', base64Data)

    const res = await api(getState, extraHeaders).post(url, form)
    const { attachment } = res.data
    const newFile = attachment
    const idToBeReplaced = id

    const existingDocumentPosition = findIndex(documents, (doc) => doc.id === idToBeReplaced)
    const updatedDocumentList = replace(documents, existingDocumentPosition, newFile)

    const { slug } = getState().v2LoanFiles.loanFile
    const newAttachmentUrl = Route.lenders.loanFile.attachments.show({ loanFileId, slug, attachmentId: newFile.id })
    const newAttachmentUrlWithOptions = `${newAttachmentUrl}${window.location.search}`
    window.history.replaceState(null, null, newAttachmentUrlWithOptions)

    toastr.success('Document saved!')
    Promise.all([
      dispatch(setDocument(newFile)),
      dispatch(setDocuments(updatedDocumentList)),
    ])
  }
}

export const toggleOpenedDocumentGroup = (documentGroup) => {
  return async (dispatch, getState) => {
    const { v2Documents: { uiFlags: { openedDocumentGroups } } } = getState()
    const newOpenedDocumentGroups = toggle(openedDocumentGroups, documentGroup)
    dispatch(setOpenedDocumentGroups(newOpenedDocumentGroups))
  }
}

export const setPdfTronInstance = ({ currentPdfTronDocument = '', CoreControls = {}, ViewerInstance = {} }) => {
  return (dispatch) => {
    return Promise.all([
      dispatch(setCurrentPdfTronDocument(currentPdfTronDocument)),
      dispatch(setPdfTronCoreControls(CoreControls)),
      dispatch(setPdfTronViewerInstance(ViewerInstance)),
    ])
  }
}

export const activateCrop = (currentPageNumber, zoom, bounds) => {
  return async (dispatch) => {
    Promise.all([
      dispatch(setDocumentCurrentPageNumber(currentPageNumber)),
      dispatch(setDocumentCurrentPageZoom(zoom)),
      dispatch(setDocumentCroppingBounds(bounds)),
      dispatch(setDocumentCropSelection(bounds)),
      dispatch(toggleShowCrop(true)),
    ])
  }
}

export const disableCrop = () => {
  return async (dispatch) => {
    dispatch(setDocumentCroppingBounds({}))
    dispatch(toggleShowCrop(false))
  }
}

export const applyCrop = (currentDocument) => {
  return async (dispatch, getState) => {
    const {
      croppingSelection,
      croppingBounds,
      currentPageNumber,
      currentPageZoom,
    } = getState().v2Documents.uiFlags
    const topMargin = (croppingSelection.top - croppingBounds.top) / currentPageZoom
    const bottomMargin = (croppingSelection.bottom - croppingBounds.bottom) / currentPageZoom
    const leftMargin = (croppingSelection.left - croppingBounds.left) / currentPageZoom
    const rightMargin = (croppingSelection.right - croppingBounds.right) / currentPageZoom

    await currentDocument.cropPages([currentPageNumber], topMargin, bottomMargin, leftMargin, rightMargin)
    dispatch(toggleShowCrop(false))
  }
}

export const resetPdfTronTools = () => {
  return (dispatch, getState) => {
    const { uiFlags: { showCrop, changed } } = getState().v2Documents

    if (showCrop) {
      dispatch(disableCrop())
    }

    if (changed) {
      dispatch(toggleDocumentChanged(false))
    }

    dispatch(resetPdfRightSideBar())
  }
}

export const resetPdfRightSideBar = () => {
  return (dispatch) => {
    Promise.all([
      dispatch(togglePdfTronReorderForm(false)),
      dispatch(togglePdfTronDeleteForm(false)),
      dispatch(togglePdfTronMergeModal(false)),
    ])
  }
}

export const setCurrentPdfTronDocument = createAction(AT.SET_PDF_TRON_CURRENT_DOCUMENT)
export const setDocumentCropSelection = createAction(AT.SET_DOCUMENT_CROPPING_SELECTION)
export const togglePdfTronReorderForm = createAction(AT.TOGGLE_PDF_TRON_REORDER_FORM)
export const togglePdfTronDeleteForm = createAction(AT.TOGGLE_PDF_TRON_DELETE_FORM)
export const togglePdfTronMergeModal = createAction(AT.TOGGLE_PDF_TRON_MERGE_MODAL)
export const setDocumentSortData = createAction(AT.SET_DOCUMENT_SORT_DATA)

const setPdfTronCoreControls = createAction(AT.SET_PDF_TRON_CORE_CONTROLS)
const setPdfTronViewerInstance = createAction(AT.SET_PDF_TRON_VIEWER_INSTANCE)
const setOpenedDocumentGroups = createAction(AT.SET_OPENED_DOCUMENT_GROUPS)
const toggleDocumentChanged = createAction(AT.TOGGLE_DOCUMENT_CHANGED)
const toggleDocumentSaving = createAction(AT.TOGGLE_DOCUMENT_SAVING)
const togglePdfTronLoaded = createAction(AT.TOGGLE_PDF_TRON_LOADED)
const togglePdfTronMerging = createAction(AT.TOGGLE_PDF_TRON_MERGING)
const toggleShowCrop = createAction(AT.TOGGLE_SHOW_CROP)
const setDocumentCroppingBounds = createAction(AT.SET_DOCUMENT_CROPPING_BOUNDS)
const setDocumentCurrentPageNumber = createAction(AT.SET_DOCUMENT_CURRENT_PAGE_NUMBER)
const setDocumentCurrentPageZoom = createAction(AT.SET_DOCUMENT_CURRENT_PAGE_ZOOM)
