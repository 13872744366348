import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'

export const createUnderwritingDecisionAPI = createAsyncThunk(
  'externalConfigs/underwritingDecisions/create',
  async ({ scenarioId, runType }) => {
    const response = await JSONAPIClient
      .one('scenario', scenarioId)
      .all('underwriting_decision').post({ run_type: runType })
    return response.data
  }
)

export const indexUnderwritingDecisionsAPI = createAsyncThunk(
  'externalConfigs/underwritingDecisions/index',
  async (scenarioId) => {
    const response = await JSONAPIClient.one('scenario', scenarioId).all('underwriting_decisions').get()
    return response.data
  }
)

export const showUnderwritingDecisionAPI = createAsyncThunk(
  'externalConfigs/underwritingDecisions/show',
  async ({ scenarioId, decisionId }) => {
    const response = await JSONAPIClient.one('scenario', scenarioId).one('underwriting_decision', decisionId).get()
    return response.data
  }
)

export const updateUnderwritingDecisionAPI = createAsyncThunk(
  'externalConfigs/underwritingDecisions/show',
  async ({ scenarioId, decisionId, status }) => {
    const response = await JSONAPIClient.one('scenario', scenarioId)
      .one('underwriting_decision', decisionId)
      .patch({ id: decisionId, status })
    return response.data
  }
)
