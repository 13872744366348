import JSONAPIClient from '../JSONAPIClient'

JSONAPIClient.define('disclosures_task_item', {
  user_id: '',
  user_name: '',
  due_date: '',
  identifier: '',
  status: '',
  user_identifier: '',
  title: '',
  vendor: '',
  sign_url: '',
})
