import validator from 'validator'
import moment from 'moment'
import { compact, isEmpty, isEqual, sortBy, values, without } from 'underscore'
import { displayFileNameExtenion } from 'utils/file_utils'

// return value of true or undefined is error
// return value of false is not error
export const presenceValidation = (q, value) => {
  // If question is not required, no error
  if (q && !q.required) return false
  // if no question or value, return error
  return isBlankValue(q, value)
}

export const ssnNotEqual = (firstSSN) => secondSSN => {
  return firstSSN === secondSSN ? 'Duplicate SSNs for borrower and coborrower' : undefined
}

export const validatePositiveInteger = ({ value, fieldName }) => {
  return Number.isNaN(Number(value)) || Number(value) < 0 ? { [fieldName]: 'Field must be a positive integer' } : {}
}

export function isBlankValue(q, value) {
  if (!q || !value) return true

  if (isAddressQuestion(q, value)) {
    return isInvalidLoanAppAddressField(q, value)
  } else if (isPhoneGroupQuestion(q)) {
    return isInvalidLoanAppPhoneGroupField(q, value)
  } else if (isPhoneQuestion(q)) {
    return isInvalidLoanAppPhoneField(q, value)
  } else if (isAssetQuestion(q)) {
    return isInvalidLoanAppAssetField(q, value)
  } else if (isCheckboxQuestion(q, value)) {
    return isInvalidCheckboxField(q, value)
  } else if (isNumberQuestion(q)) {
    return isInvalidNumberField(q, value)
  } else if (isMonthYearQuestion(q)) {
    return isInvalidNumberField(q, value)
  } else if (isRadioQuestion(q)) {
    return isInvalidLoanAppField(q, value)
  } else if (isMultiSelectQuestion(q)) {
    return isInvalidMultiSelectField(q, value)
  } else {
    return isInvalidLoanAppField(q, value)
  }
}

export function isRadioQuestion(q) {
  return q.questionType === 'radio'
}

export function isNumberQuestion(q) {
  return q.questionType === 'month_year'
}

export function isCheckboxQuestion(q) {
  return q.questionType === 'checkbox_group'
}

export function isAssetQuestion(q) {
  return q.questionType === 'income' || q.questionType === 'asset' || q.questionType === 'expense'
}

export function isAddressQuestion(q, value) {
  return q.questionType === 'address' && value && typeof value === 'object'
}

export function isPhoneQuestion(q) {
  return q.questionType === 'phone'
}

export function isPhoneGroupQuestion(q) {
  return q.questionType === 'phone_group'
}

export function isMonthYearQuestion(q) {
  return q.questionType === 'month_year'
}

export function isMultiSelectQuestion(q) {
  return q.questionType === 'multi_select'
}

export function isInvalidMultiSelectField(q, value) {
  return q.required && value.length <= 0
}

export function isInvalidCheckboxField(q, value) {
  return q.required && value.length <= 0
}

export function isInvalidNumberField(q, value) {
  const valArr = compact(Object.values(value))
  const valArrLen = valArr.length
  const validLength = valArrLen > 0

  return q.required && value && !validLength
}

// Required with no value
export function isInvalidLoanAppField(q, value) {
  return q.required && !value
}

// Check validity of Address fields, test that strings are present
export function isInvalidLoanAppAddressField(q, value) {
  return q.required && (
    isEmpty(value)
    || (!isAddressFieldWithoutStreet(q) && !/\S/.test(value.street || ''))
    || !/\S/.test(value.city || '')
    || !/\S/.test(value.state || '')
    || !/\S/.test(value.zipcode || '')
  )
}

function isAddressFieldWithoutStreet(q) {
  return q.options.find(({ key, value }) => key === 'hideStreet' && value)
}


export function isInvalidLoanAppPhoneField(q, value) {
  const error = isMobilePhone(value)

  return value && q.required && error === 'Invalid phone number'
}

// handle phone_groups
export function isInvalidLoanAppPhoneGroupField(q, value) {
  const strippedPhones = compact(Object.values(value))
  const valid = strippedPhones.length >= 1

  return value && q.required && !valid
}

// handle multiple income groups
export function isInvalidLoanAppAssetField(q, value) {
  const validGroups = value.map(el => {
    // check for amount presence and type presence
    if (el.amount && el.type && el.type !== null) {
      return true
    } else {
      return false
    }
  })

  const hasGroupedError = !validGroups.length || validGroups.includes(false)

  return q.required && hasGroupedError
}

export function validEmail(email) {
  return validator.isEmail(email)
}

export const isValidFolder = value => {
  return value ? undefined : 'Default folder does not exist'
}

export const isRequired = value => {
  return value ? undefined : 'Required'
}

export const isValidFileName = value => {
  const filter = /^[a-zA-Z0-9 ._-]+$/
  return value.match(filter) ? undefined : 'Please input alphanumeric characters only'
}

export const duplicateFileName = (docs, currentDoc) => value => {
  const fileExtension = displayFileNameExtenion(currentDoc.fileName)
  const duplicateName = docs.find(doc => doc.fileName === `${value}.${fileExtension}`)
  return duplicateName && !isEmpty(duplicateName) ? '*File Name Already Taken' : undefined
}

export const isStrongPasswordRequired = value => {
  const { password } = value
  return password ? undefined : 'Required'
}

export const isStrongPassword = value => {
  const { isValid } = value
  return isValid ? undefined : 'Please choose a good password'
}
export const isSSN = value => {
  const pattern = /^\d{3}-?\d{2}-?\d{4}$/

  return value && !validator.matches(value, pattern) ? 'Not a valid SSN' : undefined
}

export const isLoanNumber = value => {
  return value && !validator.isAlphanumeric(value, 'en-US')
    ? 'Invalid loan number. Please use alphanumeric characters only.'
    : undefined
}

const isGreaterThanYear = (momentDate, year = 100) => {
  if (!momentDate.isValid()) return // this validation should be performed seperatly
  const yearsAgo = moment().subtract(year, 'y')

  const validation = yearsAgo && momentDate.isAfter(yearsAgo)

  return validation
}

const isLessThanYear = (momentDate, year = 100) => {
  if (!momentDate.isValid()) return // this validation should be performed seperatly
  const yearsAgo = moment().subtract(year, 'y')
  return yearsAgo && momentDate.isBefore(yearsAgo)
}

export const isWithinCentury = value => {
  if (!value) return undefined
  const momentDate = moment()
    .year(value)
    .startOf('year')
  const validation = isGreaterThanYear(momentDate, 100)

  return validation ? undefined : 'The date cannot be more than 100 years in the past'
}

export const isValidDate = value => {
  if (!value) return undefined
  const validation = moment(value, 'MM/DD/YYYY', true).isValid()
  return validation ? undefined : 'Please enter a valid date (mm/dd/yyyy)'
}

export const isValidYear = value => {
  return unformattedString(value).length === 4 ? undefined : 'Please enter a valid year (yyyy)'
}

export const isNotFutureYear = value => {
  const momentDate = moment()
    .year(value)
    .startOf('year')

  const validation = value && momentDate.isValid() && momentDate.isBefore(moment())

  return validation ? undefined : 'The year cannot be in the future'
}

export const isNotFutureDate = value => {
  const momentDate = moment(value, 'MM/DD/YYYY', true)
  if (!momentDate.isValid()) return // this validation should be performed seperatly
  const validation = value && momentDate.isBefore(moment())

  return validation ? undefined : 'The date cannot be in the future'
}

export const isNotPastNow = value => {
  if (!value) return undefined

  const momentDate = moment(value, 'MM/DD/YYYY', true)
  return momentDate.isAfter(moment()) ? undefined : 'Please enter a date in the future.'
}

export const isValidBirthdayDateRange = value => {
  const momentDate = moment(value, 'MM/DD/YYYY', true)
  if (!momentDate.isValid()) return // this validation should be performed seperatly
  const validation = isLessThanYear(momentDate, 18)

  return validation ? undefined : 'The date has to be at least 18 years before today'
}

export const isValidBirthdateCentury = value => {
  return isValidDateWithinCentury(value)
}

export const isValidDateWithinCentury = value => {
  const momentDate = moment(value, 'MM/DD/YYYY', true)
  if (!momentDate.isValid()) return // this validation should be performed seperatly
  const validation = isGreaterThanYear(momentDate, 100)

  return validation ? undefined : 'The date cannot be more than 100 years in the past'
}

export const isValidFirstLastName = value => {
  if (!value) return

  if (typeof value === 'string') {
    return isValidStringName(value)
  }

  if (typeof value === 'object') {
    return isValidObjectName(value)
  }
}

const isValidStringName = value => {
  const [firstName, lastName] = value.split(' ')

  const isFirstName = firstName && firstName.length
  const isLastName = lastName && lastName.length

  return isFirstName && isLastName ? undefined : 'You must enter both a first and last name'
}

const isValidObjectName = value => {
  const isFirstName = value.first
  const isLastName = value.last

  return isFirstName && isLastName ? undefined : 'You must enter both a first and last name'
}

export const isPastEmploymentDates = (startDate, endDate) => {
  const mCurrentDate = moment()

  const mStartDate = moment(startDate, 'MM/DD/YYYY', true)
  const mEndDate = moment(endDate, 'MM/DD/YYYY', true)
  if (!mStartDate.isValid()) return
  if (!mEndDate.isValid()) return

  const isStartBeforeCurrent = mStartDate.isBefore(mCurrentDate)
  const isEndBeforeCurrent = mEndDate.isBefore(mCurrentDate)

  return isStartBeforeCurrent && isEndBeforeCurrent ? undefined : 'Start date and end date must be in the past'
}

export const isValidEmploymentDates = (startDate, endDate) => {
  const mStartDate = moment(startDate, 'MM/DD/YYYY', true)
  const mEndDate = moment(endDate, 'MM/DD/YYYY', true)
  if (!mStartDate.isValid()) return
  if (!mEndDate.isValid()) return

  const startBeforeEnd = mStartDate.isBefore(mEndDate)
  const endAfterStart = mEndDate.isAfter(mStartDate)

  return startBeforeEnd && endAfterStart ? undefined : 'Start date must be before the end date'
}

export const isWithinCenturyEmploymentDates = (startDate, endDate) => {
  const mStartDate = moment(startDate, 'MM/DD/YYYY', true)
  const mEndDate = moment(endDate, 'MM/DD/YYYY', true)
  if (!mStartDate.isValid()) return
  if (!mEndDate.isValid()) return

  const mCentury = moment().subtract(100, 'y')

  const withinStartCentury = mStartDate.isAfter(mCentury)
  const withinEndCentury = mEndDate.isAfter(mCentury)

  return withinStartCentury && withinEndCentury
    ? undefined
    : "Start date and end date can't be more than 100 years into the past"
}

export const isLqbLeadNumber = value => {
  return value && !validator.isAlphanumeric(value) ? 'Invalid lead number' : undefined
}

export const isEmail = value => {
  return value && !validator.isEmail(value) ? 'Invalid email address' : undefined
}

export const isMobilePhone = value => {
  return value && !validator.isMobilePhone(strippedPhone(value), 'en-US') ? 'Invalid phone number' : undefined
}

export const isPostalCode = value => {
  if (!value) return undefined
  return isNumeric(strippedCommas(value)) || value.length !== 5 ? 'Invalid zip code' : undefined
}

export const isNumeric = value => {
  return value && !validator.isNumeric(strippedCommas(value)) ? 'Invalid number' : undefined
}

export const isGreaterThanZero = value => {
  const parsedValue = parseInt(strippedPhone(value), 16)

  return value && parsedValue > 0 ? 'Needs to be more than 0' : undefined
}

export const isValidApproximatePrice = (purchasePrice, downpayment) => {
  const message = 'Purchase price needs to be greater than downpayment'
  const parsedPurchasePrice = purchasePrice ? parsePrice(purchasePrice) : 0
  const parsedDownPayment = downpayment ? parsePrice(downpayment) : 0
  return parsedPurchasePrice >= parsedDownPayment ? undefined : message
}

export const isValidDownpaymentPrice = (priceQuestion, price, downpayment) => {
  if (priceQuestion.hidden) return
  const message = 'Downpayment price needs to be less than purchase price'
  const parsedPrice = price ? parsePrice(price) : 0
  const parsedDownpayment = downpayment ? parsePrice(downpayment) : 0
  return parsedPrice <= parsedDownpayment ? message : undefined
}

export const isPriceUnderEncompassLimit = (purchasePrice) => {
  const message = 'Purchase price must be less than $2,100,000,000'
  const parsedApproxmiatePrice = purchasePrice ? parsePrice(purchasePrice) : 0
  return parsedApproxmiatePrice <= 2100000000 ? undefined : message
}

export const strippedCommas = value => {
  return value.toString().replace(/,/g, '')
}

const parsePrice = (str) => {
  return parseInt(strippedCommas(str), 10)
}

const strippedPhone = value => {
  return value.toString().replace(/\D/g, '')
}

export const isAndroid = () => {
  return navigator.userAgent.match(/Android/i)
}

export const determineMaskLength = mask => {
  return mask.replace(/ /g, '').length
}

export const valueMatchesMaskLength = (value, maskLength) => {
  return value.length === maskLength
}

export const valueIsLessThanMaskLength = (value, maskLength) => {
  return value.length < maskLength
}

export const formatCurrencyforNumberField = (value = '') => {
  if (value === 0 || value === '') return null
  const cleanedValue = removeCommas(value)
  return parseInt(cleanedValue, 10)
}

export const removeCommas = value => {
  return value.toString().replace(/,/g, '')
}

export const formatBackEndAndroidDate = date => {
  if (!date) return
  return moment(date).format('MM/DD/YYYY')
}

export const formatFrontEndAndroidDate = date => {
  if (!date) return
  return moment(date, 'MM-DD-YYYY').format('YYYY-MM-DD')
}

export const roundCurrencyToString = amount => {
  return Math.floor(amount).toString()
}

export const numericGreaterThanZero = value => {
  return parseInt(value.toString().replace(/\D/, ''), 10) > 0 ? undefined : 'Must be greater than zero'
}

export const currencyLessThan = maximumAmount => value => {
  const parsedValue = parseInt(value.toString().replace(/\D/g, ''), 10)
  const parsedMax = parseInt(maximumAmount.toString().replace(/\D/g, ''), 10)
  return parsedValue > parsedMax ? `Must be less than $${maximumAmount}` : undefined
}

const unformattedString = str => {
  return str.replace(/[() _-]/g, '')
}

export const isWholeNumber = value => {
  return value % 1 === 0 ? undefined : 'Must be a whole number'
}

export const pageNumberCanBeDeleted = (numberOfPages) => value => {
  const currentPages = pageNumbers(numberOfPages)
  const pagesToDelete = stringParsedAsArray(value)
  const nonExistentPages = without(pagesToDelete.filter(page => !currentPages.includes(page)), null)
  if (!isEmpty(nonExistentPages)) {
    return determinePageCountMessage(nonExistentPages)
  } else {
    return undefined
  }
}

export const notDeletingAllPages = (numberOfPages) => value => {
  const currentPages = pageNumbers(numberOfPages)
  const pagesToDelete = stringParsedAsArray(value)
  return isEqual(sortBy(pagesToDelete), sortBy(currentPages)) ? 'Cannot delete all pages' : undefined
}

export const isWithinPageNumberRange = (numberOfPages) => value => {
  const currentPages = pageNumbers(numberOfPages)
  return currentPages.includes(parseInt(value, 10)) ? undefined : "Page Number doesn't exist"
}

const determinePageCountMessage = (nonExistentPages) => {
  if (nonExistentPages.length === 1) {
    return `Page: ${nonExistentPages.join(' ')} does not exist`
  } else {
    return `Pages: ${nonExistentPages.join(' ')} do not exist`
  }
}

const stringParsedAsArray = (value) => {
  const pagesToDelete = value.split(',').map((num) => {
    if (num) {
      return parseInt(num, 10)
    } else {
      return null
    }
  })

  return pagesToDelete
}

const pageNumbers = (numberOfPages) => {
  const pages = []
  for (let index = 1; index <= numberOfPages; index++) {
    pages.push(index)
  }
  return pages
}

export const isRoutingNumber = (value) => {
  return (value.length !== 9) ? 'Routing Number should contain 9 digits' : undefined
}

// For Redux Form Checkbox
export const hasAtLeastOneBeenChecked = (allValues = {}) => () => {
  const message = 'Must check at least one item'
  if (isEmpty(allValues)) return message

  const checkedValues = values(allValues).filter(checkedValue => checkedValue === true)
  return checkedValues.length > 0 ? undefined : message
}

export const isValidFICOScore = (value) => {
  if (Number(value) >= 300 && Number(value) <= 850) {
    return ''
  }
  return 'Please enter a valid FICO score'
}

export const isAValidAmortizationTerm = value => {
  return Number(value) <= 360 && Number(value) > 0
    ? undefined : 'A value greater than “360” will cause an Out of Scope recommendation.'
}

export const maximumLength = (length) => (value) => {
  return (String(value).length > length && `Must be no more than ${length} characters.`) || undefined
}

export const minimumLength = (length) => (value) => {
  return (value && String(value).length < length && `Must be greater or equal to ${length} characters.`) || undefined
}

export const exactLength = (length) => (value) => {
  return (value && String(value).length !== length && `Must contain ${length} characters.`) || undefined
}
