import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'

export const createQuickPricerSettingsAPI = createAsyncThunk(
  'organizationQuickPricerSettings/create',
  async ({ organizationId, payload }) => {
    const { data } = await JSONAPIClient.request(
      '/api/v2/organizations_quick_pricer_setting',
      'POST',
      { organizationId },
      {
        type: 'organizations_quick_pricer_settings',
        attributes: {
          ...payload,
          organizationId,
        },
      },
    )
    return data
  }
)

export const updateQuickPricerSettingsAPI = createAsyncThunk(
  'organizationQuickPricerSettings/update',
  async ({ organizationId, quickPricerSettingId, payload }) => {
    const { data } = await JSONAPIClient.request(
      '/api/v2/organizations_quick_pricer_setting',
      'PATCH',
      { organizationId },
      {
        id: quickPricerSettingId,
        type: 'organizations_quick_pricer_settings',
        attributes: payload,
      },
    )
    return data
  }
)

export const showQuickPricerSettingsAPI = createAsyncThunk(
  'organizationQuickPricerSettings/show',
  async ({ organizationId }) => {
    try {
      const response = await JSONAPIClient.request(
        '/api/v2/organizations_quick_pricer_setting',
        'GET',
        { organizationId },
      )
      return response.data
    } catch {
      return {}
    }
  }
)
