import { combineReducers } from 'redux'
import loanFileDashboardStatSlice from './loan_file_dashboard_stat'
import lenderSettingsSlice from './lender_settings'

const lendersV2 = combineReducers({
  loanFileDashboardStats: loanFileDashboardStatSlice.reducer,
  lenderSettings: lenderSettingsSlice.reducer,
})

export default lendersV2
