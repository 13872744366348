import AT from 'actionTypes/'
import _ from 'underscore';

const initialState = {
  documentGroupTemplate: {},
  error: {},
};

export default function documentGroupTemplateForm(state = initialState, action) {
  switch (action.type) {
    case AT.SET_DOCUMENT_GROUP_TEMPLATE:
      return _.extend({}, state, { documentGroupTemplate: action.documentGroupTemplate });
    case AT.SET_DOCUMENT_GROUP_TEMPLATE_ERROR:
      return _.extend({}, state, { error: action.error });
    default:
      return state
  }
}
