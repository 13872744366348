import { pipelineSettings as actionTypes } from 'v2/actionTypes'

const initialState = {
  loading: false,
  updatingRecord: false,
}

export default function uiFlags(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case actionTypes.loading:
      return { ...state, loading: payload }
    case actionTypes.updatingRecord: {
      const { section, isUpdating } = payload
      return { ...state, updatingRecord: { ...state.updatingRecord, [section]: isUpdating } }
    }
    default:
      return state
  }
}
