import JSONAPIClient from '../JSONAPIClient'

JSONAPIClient.define('closing_task', {
  status: '',
  title: '',
  display_title: '',
  note: '',
  due_date: '',
  task_items: {
    jsonApi: 'hasMany',
    type: 'closing_task_items',
  },
})
