import JSONAPIClient from '../JSONAPIClient'

JSONAPIClient.define('payment_task', {
  amount_in_cents: '',
  created_at: '',
  creator_id: '',
  due_date: '',
  loan_file_id: '',
  note: '',
  payment_type: '',
  status: '',
  loan_file: {
    jsonApi: 'hasOne',
    type: 'loan_file',
  },
  display_title: '',
  stripe_publishable_apikey: '',
})
