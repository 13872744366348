import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'

export const indexTaskListTemplatesAPI = createAsyncThunk(
  'task_list_templates/index',
  async ({ teamId }) => {
    const response = await JSONAPIClient.all('task_list_templates', teamId).get()
    return response
  }
)
