import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'

export const showItem = createAsyncThunk(
  'bi/reports/items/show',
  async (id) => {
    const { data } = await JSONAPIClient.one('item', id).get()
    return data
  },
)
