import { each, isArray } from 'underscore'
import { createActionsFromActionTypes } from 'utils/reducer_utils'
import Route from 'Services/Route'
import toastr from 'utils/toastr'

import { hideVerificationTaskPicker } from 'v2/actions'
import { fetchIncompleteTasks } from 'v2/actions/tasks'
import { errorHandler } from 'v2/actions/system_notifications'
import { assetVerificationTasks as actionTypes } from '../actionTypes'
import api from '../../utils/api'
import { fetchCommunications } from './communications'

export const reducerActions = createActionsFromActionTypes(actionTypes)
const ApiRoutes = Route.api.loanFile.assetVerificationTasks
const ReportApiRoutes = Route.api.loanFile.assetVerificationReports

export function showCreateModal() {
  return dispatch => dispatch(reducerActions.showCreateModal(true))
}

export function hideCreateModal() {
  return dispatch => dispatch(reducerActions.showCreateModal(false))
}

export const showUpdateModal = (assetVerificationTask) => {
  return (dispatch) => {
    Promise.all([
      dispatch(reducerActions.setCurrentRecord(assetVerificationTask)),
      dispatch(reducerActions.showUpdateModal(true)),
    ])
  }
}

export function hideUpdateModal() {
  return dispatch => dispatch(reducerActions.showUpdateModal(false))
}

export function hideGenerateAssetReportModal() {
  return dispatch => dispatch(reducerActions.showGenerateAssetReportModal(false))
}

export const showGenerateAssetReportModal = (assetVerificationTask) => {
  return (dispatch) => {
    Promise.all([
      dispatch(reducerActions.setCurrentRecord(assetVerificationTask)),
      dispatch(reducerActions.showGenerateAssetReportModal(true)),
    ])
  }
}

export function destroy({ loanFileId, id }) {
  return async (dispatch, getState) => {
    const url = ApiRoutes.destroy({ loanFileId, id })
    try {
      await api(getState).delete(url)
      dispatch(fetchIncompleteTasks(loanFileId))
      toastr.success('Successfully deleted task!')
    } catch (error) {
      dispatch(errorHandler(error, 'There was an error deleting the task, please try again'))
    }
  }
}

export const show = (props) => {
  return async (dispatch, getState) => {
    try {
      const url = ApiRoutes.show(props)
      const res = await api(getState).get(url)
      dispatch(reducerActions.setCurrentRecord(res.data.assetVerificationTask))
    } catch (error) {
      dispatch(errorHandler(error, 'There was an error fetching the task, please try again'))
    }
  }
}

function create(values) {
  return async (dispatch, getState) => {
    const { v2LoanFiles: { loanFile: { id: loanFileId } } } = getState()

    const url = ApiRoutes.create({ loanFileId })
    try {
      const res = await api(getState).post(url, { asset_verification_task: values })
      const { assetVerificationTask } = res.data
      dispatch(reducerActions.create(assetVerificationTask))
      dispatch(fetchCommunications(loanFileId))
    } catch (error) {
      dispatch(errorHandler(error, 'There was an error completing the task, please try again'))
    }
  }
}

export function submitNewTask(values) {
  return (dispatch) => {
    const {
      title,
      dueDate,
      borrower,
      coborrower,
      note,
      who,
    } = values

    try {
      if (who.split(',').includes('borrower')) {
        const borrowerValues = {
          title,
          dueDate,
          note,
          user_id: borrower.id,
          ssn: borrower.ssn,
        }
        dispatch(create(borrowerValues))
        toastr.success('Successfully created task for ' + borrower.email)
      }
      if (who.split(',').includes('coborrower')) {
        const coborrowerValues = {
          title,
          dueDate,
          note,
          user_id: coborrower.id,
          ssn: coborrower.ssn,
        }
        dispatch(create(coborrowerValues))
        toastr.success('Successfully created task for ' + coborrower.email)
      }
      dispatch(hideCreateModal())
      dispatch(hideVerificationTaskPicker())
    } catch (error) {
      dispatch(errorHandler(error, 'There was an error creating the task, please try again'))
    }
  }
}

export function update(values) {
  return async (dispatch, getState) => {
    const {
      v2LoanFiles: { loanFile: { id: loanFileId } },
      v2: { assetVerificationTasks: { currentRecord: { id } } },
    } = getState()
    const url = ApiRoutes.update({ loanFileId, id })
    try {
      const res = await api(getState).patch(url, { asset_verification_task: values })
      const { assetVerificationTask } = res.data
      dispatch(reducerActions.update(assetVerificationTask))
      dispatch(hideUpdateModal())
      dispatch(fetchIncompleteTasks(loanFileId))
      toastr.success('Successfully updated task!')
    } catch (error) {
      dispatch(errorHandler(error, 'There was an error updating the task, please try again'))
    }
  }
}

export function generateReport(values) {
  return async (dispatch, getState) => {
    const {
      v2LoanFiles: { loanFile: { id: loanFileId } },
      v2: { assetVerificationTasks: { currentRecord: { id: taskId } } },
    } = getState()

    const {
      daysRequested,
      selectedAccountIds,
    } = values

    const accountIds = []
    each(selectedAccountIds, (value, key) => {
      if (value) accountIds.push(key)
    })
    const creationParams = {
      days_requested: daysRequested,
      accountIds: accountIds,
    }
    try {
      const url = ReportApiRoutes.create({ loanFileId, taskId })
      const res = await api(getState).post(url, { asset_verification_report: creationParams })
      const { assetVerificationTask } = res.data
      dispatch(reducerActions.create(assetVerificationTask))
      dispatch(hideGenerateAssetReportModal())
      dispatch(fetchIncompleteTasks(loanFileId))
      toastr.success('Successfully requested Asset Report!')
      dispatch(hideCreateModal())
    } catch (error) {
      const errors = error?.response?.data?.errors
      const message = isArray(errors) && errors[0]
      toastr.error(
        message,
        'There was an error generating the report',
        { timeOut: 0 }
      )
    }
  }
}

export function complete(taskId) {
  return async (dispatch, getState) => {
    const { v2LoanFiles: { loanFile: { id: loanFileId } } } = getState()
    const url = ApiRoutes.complete({ loanFileId, taskId })

    try {
      const res = await api(getState).post(url)
      const { assetVerificationTask } = res.data
      dispatch(reducerActions.update(assetVerificationTask))
      toastr.success('Successfully completed task!')
    } catch (error) {
      dispatch(errorHandler(error, 'There was an error completing the task, please try again'))
    }
  }
}
