import { createSlice } from '@reduxjs/toolkit'
import baseReducers from 'apiV2/store/base/reducers'
import baseInitialState from 'apiV2/store/base/initialState'
import {
  indexTemplateAPI,
  showTemplateAPI,
  createTemplateAPI,
  updateTemplateAPI,
  deleteTemplateAPI,
} from './asyncActions'

const templateSlice = createSlice({
  name: 'template',
  initialState: baseInitialState,
  reducers: baseReducers,
  extraReducers: {
    [createTemplateAPI.pending](state) {
      state.uiFlags.isCreating = true
    },
    [createTemplateAPI.fulfilled](state, action) {
      state.records = [...state.records, action.payload]
      state.uiFlags.isCreating = false
    },
    [createTemplateAPI.rejected](state) {
      state.uiFlags.isCreating = false
    },
    [indexTemplateAPI.pending](state) {
      state.uiFlags.isFetching = true
    },
    [indexTemplateAPI.fulfilled](state, action) {
      state.uiFlags.isFetching = false
      state.records = action.payload
    },
    [indexTemplateAPI.rejected](state) {
      state.uiFlags.isFetching = false
    },
    [showTemplateAPI.pending](state) {
      state.uiFlags.isFetching = true
    },
    [showTemplateAPI.fulfilled](state, action) {
      state.uiFlags.isFetching = false
      state.stagedRecord = action.payload
    },
    [showTemplateAPI.rejected](state) {
      state.uiFlags.isFetching = false
    },
    [updateTemplateAPI.pending](state) {
      state.uiFlags.isUpdating = true
    },
    [updateTemplateAPI.fulfilled](state, action) {
      state.uiFlags.isUpdating = false
      state.stagedRecord = action.payload
      state.records = state.records.map(record => (record.id === action.payload.id ? action.payload : record))
    },
    [updateTemplateAPI.rejected](state) {
      state.uiFlags.isUpdating = false
    },
    [deleteTemplateAPI.pending](state) {
      state.uiFlags.isDeleting = true
    },
    [deleteTemplateAPI.fulfilled](state, action) {
      const { id } = action.payload
      state.uiFlags.isDeleting = false
      state.records = state.records.filter(r => r.id !== id)
    },
    [deleteTemplateAPI.rejected](state) {
      state.uiFlags.isDeleting = false
    },
  },
})

export default templateSlice
