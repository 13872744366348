import JSONAPIClient from '../JSONAPIClient'

JSONAPIClient.define('organization_setting', {
  organization_id: '',
  disabled_loan_app_v2_template_ids: [],
  enhanced_loan_app_enabled: false,
  spanish_enabled: false,
  ela_setup_status: 'not_started',
  child_can_manage_templates: false,
  templates_management_allowed: false,
  spanish_management_allowed: false,
  verbal_consent_enabled: false,
  heloc_disclosure_enabled: false,
})
