import { organizationSettings as actionTypes } from 'v2/actionTypes'

const initialState = {
  loading: true,
  updating: false,
  editing: false,
}

export default function uiFlags(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case actionTypes.loading:
      return { ...state, loading: payload }
    case actionTypes.updatingRecord:
      return { ...state, updating: payload }
    case actionTypes.editing:
      return { ...state, editing: payload }
    default:
      return state
  }
}
