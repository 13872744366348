// This list is what is currently working in our application.
// Ideally we want to match what they say is available found here:


// https://www.pdftron.com/pdf-sdk/supported-file-formats/
export const PDF_TRON_ALLOW_LIST_DOCUMENTS_EXTENSIONS = [
  'pdf',
  'jpg',
  'jpeg',
  'png',
  'doc',
  'docx',
  'xlsx',
  'pptx',
]

export const PDF_TRON_MERGE_ALLOW_LIST_DOCUMENTS_EXTENSIONS = [
  'pdf',
  'jpg',
  'jpeg',
  'png',
]

export const PDF_TRON_LOAD_DOCUMENT_ERROR = `
  Failed to load this document because it is either corrupt or not a valid PDF.
`

export const PDF_TRON_HEADER_HEIGHT = 47
