import { usersSettings as actionTypes } from 'v2/actionTypes'

export default function stagedRecord(state = {}, action) {
  const { type, payload } = action

  switch (type) {
    case actionTypes.setStagedRecord: {
      return payload
    }
    default:
      return state
  }
}
