import JSONAPIClient from '../JSONAPIClient'

JSONAPIClient.define('task_list_template', {
  id: '',
  attributes: {},
  links: {},
  type: '',
  title: '',
  created_at: '',
})
