import AT from '../../actionTypes/'

const initialState = {}

export default function uiFlags(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case AT.SHOW_CREATE_LOAN_APP_TEMPLATE_SECTION_MODAL:
      return { ...state, showCreateModal: payload }
    case AT.SHOW_DESTROY_LOAN_APP_TEMPLATE_SECTION_MODAL:
      return { ...state, showDestroyModal: payload }
    case AT.LOAN_APP_TEMPALATE_SECTIONS_LOADING:
      return { ...state, loading: payload }
    case AT.SET_CURRENT_LOAN_APP_TEMPLATE_SECTION:
      return { ...state, currentSectionId: payload.id }
    default:
      return state
  }
}
