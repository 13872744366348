export function isUser(role) {
  return role === 'user'
}

export function isLender(role) {
  return role === 'lender'
}

export function isBorrower(role) {
  return role === 'borrower'
}

export function isCoborrower(role) {
  return role === 'coborrower'
}
