import AT from '../../actionTypes'

const initialState = { closingTasks: null }

export default function stagedRecord(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case AT.SET_CLOSINGS_TASK:
      return payload
    default:
      return state
  }
}
