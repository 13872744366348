import { combineReducers } from 'redux'

import allEmailTemplates from './allEmailTemplates'
import assetVerificationTasks from './assetVerificationTasks'
import attachments from './attachments'
import screenShareVisitors from './screenShareVisitors'
import communications from './communications'
import creditReportees from './creditReportees'
import creditTasks from './creditTasks'
import disclosuresTasks from './disclosuresTasks'
import disclosuresTaskItems from './disclosuresTaskItems'
import documentGroups from './document_groups'
import documentGroupTemplateDisplayOrder from './document_group_template_display_order'
import duCaseFiles from './duCaseFiles'
import duDecisionTemplates from './duDecisionTemplates'
import emailSignatureTemplates from './emailSignatureTemplates'
import emailTemplates from './emailTemplates'
import employmentIncomeVerificationTasks from './employmentIncomeVerificationTasks'
import encompassDocuments from './encompass'
import fileFetchConnectionDetails from './fileFetch'
import globalUI from './globalUI'
import insuranceTasks from './insuranceTasks'
import loss from './loss'
import lenderOnboarding from './lender_onboarding'
import lenders from './lenders/'
import lendingQb from './lendingQb'
import loanAppResponses from './loanAppResponses'
import loanApps from './loanApps'
import loanAppTemplateQuestions from './loanAppTemplateQuestions'
import loanAppTemplates from './loanAppTemplates'
import loanAppTemplateSections from './loanAppTemplateSections'
import loanFileParticipants from './loan_file_participants'
import loanManager from './loanManager'
import masterLoanFileTemplates from './masterLoanFileTemplates'
import messengerInfo from './messengerInfo'
import notifications from './notifications'
import optimalBlueStates from './optimalBlueStates'
import organizations from './organizations'
import paymentIntent from './paymentIntent'
import paymentTasks from './paymentTasks'
import plaidLinkToken from './plaidLinkToken'
import preApprovalLetterTasks from './preApprovalLetterTasks'
import preApprovalLetterTemplates from './preApprovalLetterTemplates'
import propertyAddress from './propertyAddress'
import quickPricer from './quickPricer'
import settings from './settings'
import signableDocuments from './signable_documents'
import systemNotifications from './system_notifications'
import taskTemplates from './taskTemplates'
import taskTypePicker from './taskTypePicker'
import closingTasks from './closingTasks'
import teamRoles from './team_roles'
import teams from './teams'
import textMessageSettings from './textMessageSettings'
import twoFactorAuth from './two_factor_auth'

const v2 = combineReducers({
  allEmailTemplates,
  assetVerificationTasks,
  attachments,
  screenShareVisitors,
  communications,
  creditReportees,
  creditTasks,
  disclosuresTasks,
  disclosuresTaskItems,
  documentGroups,
  documentGroupTemplateDisplayOrder,
  duCaseFiles,
  duDecisionTemplates,
  emailSignatureTemplates,
  emailTemplates,
  employmentIncomeVerificationTasks,
  encompassDocuments,
  fileFetchConnectionDetails,
  globalUI,
  insuranceTasks,
  loss,
  lenderOnboarding,
  lenders,
  lendingQb,
  loanAppResponses,
  loanApps,
  loanAppTemplateQuestions,
  loanAppTemplates,
  loanAppTemplateSections,
  loanFileParticipants,
  loanManager,
  masterLoanFileTemplates,
  messengerInfo,
  notifications,
  optimalBlueStates,
  organizations,
  paymentIntent,
  paymentTasks,
  plaidLinkToken,
  preApprovalLetterTasks,
  preApprovalLetterTemplates,
  propertyAddress,
  quickPricer,
  settings,
  signableDocuments,
  systemNotifications,
  taskTemplates,
  taskTypePicker,
  closingTasks,
  teamRoles,
  teams,
  textMessageSettings,
  twoFactorAuth,
})

export default v2
