import { paymentTasks as actionTypes } from 'v2/actionTypes'

const initialState = []

export default function records(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case actionTypes.set: {
      return payload
    }
    case actionTypes.create: {
      return [...state, payload]
    }
    case actionTypes.update: {
      const newState = [...state]
      const paymentTaskIndex = newState.findIndex(task => task.id === payload.id)
      if (paymentTaskIndex > -1) {
        newState[paymentTaskIndex] = payload
      }
      return newState
    }
    case actionTypes.markAsComplete: {
      const { taskId } = payload
      const newState = [...state]
      const taskIndex = newState.findIndex(record => record.id === taskId)
      if (taskIndex > -1) {
        newState[taskIndex] = { ...newState[taskIndex], isCompleted: true }
        return newState
      }
      return state
    }
    case actionTypes.destroy: {
      const { taskId } = payload
      return state.filter(record => record.id !== taskId)
    }
    default:
      return state
  }
}
