import AT from '../../actionTypes/'

const initialState = {
  document: {},
  documents: [],
  metadata: {},
}

export default function documents(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case AT.SET_LENDING_QB_DOCUMENTS: {
      return { ...state, documents: payload }
    }
    case AT.SET_LENDING_QB_DOCUMENTS_METADATA: {
      return { ...state, metadata: payload }
    }
    case AT.SET_LENDING_QB_DOCUMENT: {
      return { ...state, document: payload }
    }
    default: {
      return state
    }
  }
}
