import { createAsyncThunk } from '@reduxjs/toolkit'
import JSONAPIClient from 'apiV2/apiClient'
import { popToast } from 'v2/actions/system_notifications'

export const createExternalCreditConfigAPI = createAsyncThunk(
  'externalConfigs/credit/create',
  async (payload) => {
    try {
      const { data } = await JSONAPIClient.request(
        '/api/v2/external_configs/credit',
        'POST',
        {},
        {
          type: 'credits',
          attributes: payload,
        }
      )
      return data
    } catch (err) {
      const [ error ] = err?.response?.data?.errors || []
      const { title } = error || {}
      throw new Error(title)
    }
  }
)

export const indexExternalCreditConfigsAPI = createAsyncThunk(
  'externalConfigs/credit/index',
  async (filter) => {
    try {
      const { data } = await JSONAPIClient.request(
        '/api/v2/external_configs/credit',
        'GET',
        { filter },
      )
      return data
    } catch (error) {
      throw new Error(error)
    }
  }
)

export const updateExternalCreditConfigAPI = createAsyncThunk(
  'externalConfigs/credit/update',
  async (payload, thunkAPI) => {
    const { id, ...attributes } = payload
    try {
      const { data } = await JSONAPIClient.request(
        `/api/v2/external_configs/credit/${id}`,
        'PATCH',
        {},
        {
          type: 'credits',
          id,
          attributes,
        }
      )
      thunkAPI.dispatch(popToast({ notice: 'Provider credentials were successfully updated.' }))
      return data
    } catch (err) {
      const [ error ] = err?.response?.data?.errors || []
      const { title } = error || {}
      throw new Error(title)
    }
  }
)

export const deleteExternalCreditConfigAPI = createAsyncThunk(
  'externalConfigs/credit/delete',
  async (id, thunkAPI) => {
    try {
      await JSONAPIClient.request(
        `/api/v2/external_configs/credit/${id}`,
        'DELETE',
      )
      thunkAPI.dispatch(popToast({ notice: 'Provider details have been successfully removed.' }))
      return id
    } catch (error) {
      throw new Error(error)
    }
  }
)
