import AT from '../../actionTypes/'

const initialState = {
  borrowerEmailTemplates: [],
  lenderEmailTemplates: [],
  realtorEmailTemplates: [],
}

export default function records(state = initialState, action) {
  const {
    type,
    payload,
  } = action

  switch (type) {
    case AT.SET_BORROWER_EMAIL_TEMPLATES: {
      return { ...state, borrowerEmailTemplates: payload }
    }
    case AT.SET_LENDER_EMAIL_TEMPLATES: {
      return { ...state, lenderEmailTemplates: payload }
    }
    case AT.SET_REALTOR_EMAIL_TEMPLATES: {
      return { ...state, realtorEmailTemplates: payload }
    }
    default:
      return state
  }
}
