import api from 'utils/api'
import Route from 'Services/Route'
import toastr from 'utils/toastr'
import { setTeamRoles } from './team_roles'
import AT from '../actionTypes'
import { popToast } from './system_notifications'

export function setLoanFileParticipants(participants) {
  return { type: AT.SET_LOAN_FILE_PARTICIPANTS, payload: participants }
}

export function toggleTeamPickerModal(isViewable = false) {
  return { type: AT.TOGGLE_TEAM_PICKER_MODAL, payload: isViewable }
}

export function handleHideTeamPicker() {
  return toggleTeamPickerModal(false)
}

export function addLoanFileParticipant(id) {
  return { type: AT.ADD_LOAN_FILE_PARTICIPANT, payload: id }
}

export function setParticipantsToAdd(ids) {
  return { type: AT.SET_PARTICIPANTS_TO_ADD, payload: ids }
}

export function removeLoanFileParticipant(id) {
  return { type: AT.REMOVE_LOAN_FILE_PARTICIPANT, payload: id }
}

export function clearPreviousLoanFileParticipants() {
  return { type: AT.CLEAR_LOAN_FILE_PARTICIPANTS }
}

export function fetchLoanFileParticipants(id = null) {
  return (dispatch, getState) => {
    const url = Route.api.loanFileParticipants.index({ id })
    if (getState().v2.loanFileParticipants.participants.length > 0) return
    const req = api(getState).get(url)

    req.then((res) => {
      const { participants, teamRoles } = res.data
      dispatch(setLoanFileParticipants(participants))
      dispatch(setTeamRoles(teamRoles))
    })
  }
}

export function removeLoanFileParticipants(id = null, participantId = null, params, onSuccess) {
  return (dispatch, getState) => {
    const url = Route.api.loanFileParticipants.delete({ id, participantId }, params)
    const req = api(getState).delete(url)

    req.then((res) => {
      const { participants, teamRoles } = res.data
      dispatch(setLoanFileParticipants(participants))
      dispatch(setTeamRoles(teamRoles))
      toastr.success('Successfully removed team member!')
      dispatch(popToast({ notice: 'Successfully removed team member!' }))
      onSuccess && onSuccess()
    }).catch((error) => {
      const { errors } = error?.response?.data || {}
      dispatch(popToast({ error: 'Error removing team member' }))
      console.info(errors)
    })
  }
}

export function addLoanFileParticipants(id = null, callback = () => null) {
  return (dispatch, getState) => {
    const roleableIds = getState().v2.loanFileParticipants.participantsToAdd
    const data = { roleable_ids: roleableIds }
    const url = Route.api.bulkLoanFileParticipants.create({ id }, data)
    const req = api(getState).post(url)

    req.then((res) => {
      const { participants, teamRoles } = res.data
      dispatch(clearPreviousLoanFileParticipants())
      dispatch(setLoanFileParticipants(participants))
      dispatch(setTeamRoles(teamRoles))
      toastr.success('Successfully added team member(s)!')
      dispatch(popToast({ notice: 'Successfully added team member(s)!' }))
      callback()
    }).catch((error) => {
      const { errors } = error?.response?.data || {}
      dispatch(popToast({ error: 'Error added team member(s)' }))
      console.info(errors)
    })
  }
}
