import api from 'utils/api'
import Route from 'Services/Route'
import { toastrErrors } from 'utils/error_utils'

export function index(state, organizationId) {
  const url = Route.api.organizations.textMessageSettings.index({ organizationId })
  const req = api(state).get(url)

  return req.then((res) => {
    const { organizationTextMessageSettings } = res.data
    return organizationTextMessageSettings
  }).catch((error) => {
    console.error(error)
    if (error) toastrErrors('Something went wrong with your text message settings')
  })
}

export function create(state, organizationId, params) {
  const url = Route.api.organizations.textMessageSettings.deploys.create({ organizationId })
  const req = api(state).post(url, params)

  return req.then((res) => {
    const { organizationTextMessageSettings } = res.data
    return organizationTextMessageSettings
  }).catch((error) => {
    const { errors } = error?.response?.data || {}
    console.error(errors)
    if (errors) toastrErrors(errors)
  })
}
