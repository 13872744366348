import JSONAPIClient from '../../JSONAPIClient'

JSONAPIClient.define(
  'signature',
  {
    id: '',
    legal_agreement_id: '',
  },
  { collectionPath: 'legal_agreements/signatures' },
)
