import JSONAPIClient from '../JSONAPIClient'

JSONAPIClient.define('loan_file', {
  loan_type: '',
  purpose: '',
  property_address: '',
  loan_officer: '',
  loan_amount: '',
  created_at: '',
  user_id: '',
  status: '',
  display_status: '',
  next_incomplete_task_due_date: '',
  external_loan_number: '',
  new_borrower_dashboard_enabled: '',
  user: {
    jsonApi: 'hasOne',
    type: 'user',
  },
})

